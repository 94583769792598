import { Grid, Box, Typography, Button, Divider } from '@material-ui/core';
import { useStyles } from './style';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import useActiveGroceryStore from 'hooks/grocery/useActiveGroceryStore';
import GroceryStoreInfoView from './GroceryStoreInfoView';
import CustomStepper from 'components/CustomStepper';
import GroceryStoreProfile from './GroceryStoreProfile';
import CancelBtnGroceryStore from 'components/CancelBtnGroceryStore';
import Gallery from './Gallery';
import BusinessHours from './BusinessHours';

const GroceryStoreInfo = () => {
  const classes = useStyles();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(3);
  const steps = ['Profile', 'Business Hours', 'Gallery'];
  const [editing, setEditing] = useState(false);

  const groceryStoreProfileRef: any = React.useRef(null);
  const businessHoursRef: any = React.useRef(null);
  const galleryRef: any = React.useRef(null);

  const { groceryStoreId } = useActiveGroceryStore();

  /* Add step */
  const nextStep = () => {
    if (editing) {
      setActiveStep(3);
      setEditing(false);
    } else {
      setActiveStep((oldval) => {
        return oldval + 1;
      });
    }
  };

  /* reduce step */
  const prevStep = () => {
    if (activeStep !== 0) {
      setActiveStep((oldval) => {
        return oldval - 1;
      });
    }
  };

  /* handle before adding step */
  const handleNext = async () => {
    if (activeStep === 0) {
      const { submitForm } = groceryStoreProfileRef.current || {};
      if (submitForm) submitForm();
    } else if (activeStep === 1) {
      const { submitForm } = businessHoursRef.current || {};
      if (submitForm) submitForm();
    } else if (activeStep === 2) {
      const { submitForm } = galleryRef.current || {};
      if (submitForm) submitForm();
    } else if (activeStep === 3) {
      history.goBack();
    } else {
      return;
    }
  };
  const addService = () => {
    const { setOpenServiceDialog } = businessHoursRef.current;
    if (setOpenServiceDialog) setOpenServiceDialog(true);
  };
  return (
    <Box className={classes.pageContainer}>
      <Grid container={true} alignItems="center">
        <Grid item={true} xs={12} sm={4} md={4}>
          <Typography variant="h5">Grocery Store Info</Typography>
        </Grid>
        <Grid item={true} xs={12} sm={8} md={8}>
          {editing ? (
            <Box textAlign="right">
              <Button type="submit" variant="outlined" className={classes.headerBtns} startIcon={<CancelIcon />} onClick={nextStep}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} startIcon={<SaveIcon />} onClick={handleNext}>
                Save
              </Button>
              {activeStep === 1 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={addService}>
                  Add service
                </Button>
              )}
            </Box>
          ) : (
            <Box textAlign="right">
              {activeStep === 1 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={addService}>
                  Add service
                </Button>
              )}
              {activeStep !== 3 && <CancelBtnGroceryStore className={classes.headerBtns} groceryStoreId={groceryStoreId || ''} touched={true} />}
              {activeStep !== 3 && activeStep !== 0 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={prevStep}>
                  Prev
                </Button>
              )}
              {activeStep < 3 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={handleNext}>
                  Save & Next
                </Button>
              )}
              {activeStep >= 3 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} startIcon={<SaveIcon />} onClick={handleNext}>
                  {activeStep === 3 ? 'Done' : 'Save'}
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {activeStep !== 3 && <CustomStepper activeStep={activeStep} steps={steps} />}
      <Grid container={true} className={classes.pageContainer}>
        {activeStep === 0 && <GroceryStoreProfile ref={groceryStoreProfileRef} nextStep={nextStep} />}
        {activeStep === 1 && <BusinessHours ref={businessHoursRef} nextStep={nextStep} />}
        {activeStep === 2 && <Gallery ref={galleryRef} nextStep={nextStep} />}
        {activeStep === 3 && <GroceryStoreInfoView setActiveStep={setActiveStep} setEditing={setEditing} />}
      </Grid>
    </Box>
  );
};

export default GroceryStoreInfo;

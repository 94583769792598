import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider } from '@material-ui/core';
import { useStyles } from './style';
import CustomStepper from 'components/CustomStepper';
import Size from './Size';
import Crust from './Crust';
import DensityMultiplier from './DensityMultiplier';
import Sauce from './Sauce';
import Cheese from './Cheese';
import Toppings from './Toppings';
import Preview from './Preview';
import { useStore } from 'store';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const PizzaConfiguration = () => {
  const classes = useStyles();

  const history = useHistory();

  const {
    state: { restaurant }
  } = useStore();

  const [activeStep, setActiveStep] = useState(6);

  const [editing, setEditing] = useState(false);

  const steps = ['Pizza size', 'Crust', 'Density Multiplier', 'Sauce', 'Cheese', 'Toppings'];

  const sizeRef: any = React.useRef(null);

  const crustRef: any = React.useRef(null);

  const densityRef: any = React.useRef(null);

  const sauceRef: any = React.useRef(null);

  const cheeseRef: any = React.useRef(null);

  const toppingsRef: any = React.useRef(null);

  const nextStep = () => {
    if (editing) {
      setActiveStep(6);
      setEditing(false);
    } else {
      setActiveStep((oldval) => {
        return oldval + 1;
      });
    }
  };

  const prevStep = () => {
    if (activeStep !== 0) {
      setActiveStep((oldval) => {
        return oldval - 1;
      });
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      const { submitForm } = sizeRef.current || {};
      if (submitForm) {
        submitForm();
      }
    } else if (activeStep === 1) {
      const { submitForm } = crustRef.current || {};
      if (submitForm) {
        submitForm();
      }
    } else if (activeStep === 2) {
      const { submitForm } = densityRef.current || {};
      if (submitForm) {
        submitForm();
      }
    } else if (activeStep === 3) {
      const { submitForm } = sauceRef.current || {};
      if (submitForm) {
        submitForm();
      }
    } else if (activeStep === 4) {
      const { submitForm } = cheeseRef.current || {};
      if (submitForm) {
        submitForm();
      }
    } else if (activeStep === 5) {
      const { submitForm } = toppingsRef.current || {};
      if (submitForm) {
        submitForm();
      }
    } else if (activeStep === 6) {
      history.push('/home/' + restaurant);
    } else {
      return;
    }
  };

  if (process.env.REACT_APP_PIZZA_CONFIG_FLAG !== 'true') {
    return <Redirect to={'/home/' + restaurant} />;
  }

  return (
    <Box padding={1}>
      <Box display="flex">
        <Box>
          <Typography variant="h5">Pizza Configuration</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
          {activeStep !== 6 && !editing && (
            <Button variant="outlined" color="default" disableElevation={true} className={classes.headerBtns} onClick={prevStep}>
              Back
            </Button>
          )}

          <Button variant="contained" color="primary" disableElevation={true} className={classes.headerBtns} onClick={handleNext}>
            {activeStep === 6 ? 'Done' : editing ? 'Save' : 'Save & Next'}
          </Button>
        </Box>
      </Box>
      <Divider className={classes.divider} />
      {activeStep !== 6 && <CustomStepper activeStep={activeStep} steps={steps} />}
      <Box>
        {activeStep === 0 && <Size ref={sizeRef} nextStep={nextStep} />}
        {activeStep === 1 && <Crust ref={crustRef} nextStep={nextStep} />}
        {activeStep === 2 && <DensityMultiplier ref={densityRef} nextStep={nextStep} />}
        {activeStep === 3 && <Sauce ref={sauceRef} nextStep={nextStep} />}
        {activeStep === 4 && <Cheese ref={cheeseRef} nextStep={nextStep} />}
        {activeStep === 5 && <Toppings ref={toppingsRef} nextStep={nextStep} />}
        {activeStep === 6 && <Preview setEditing={setEditing} setActiveStep={setActiveStep} />}
      </Box>
    </Box>
  );
};

export default PizzaConfiguration;

import { gql } from 'apollo-boost';

export default gql`
  query getTextHistory($input: getTextHistoryInput!) {
    getTextHistory(input: $input) {
      bizId
      createdAt
      noOfTextSent
    }
  }
`;

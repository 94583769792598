import { makeStyles } from '@material-ui/core';
import white_line_bg from 'assets/img/white-lines-bg.svg';

export const useStyle = makeStyles((theme) => {
  return {
    root: {
      width: '100%'
    },
    container: {
      maxHeight: 580
    },
    divider: {
      margin: theme.spacing(2, 0)
    },
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    formControl: {
      minWidth: 50
    },
    resetMargin: {
      margin: 0
    },
    rowSpacing: {
      marginBottom: theme.spacing(2)
    },
    couponPreview: {
      background: `url('${white_line_bg}') ${theme.palette.primary.main}`,
      borderRadius: '15px',
      width: '100%',
      maxWidth: '500px',
      padding: theme.spacing(2),
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    borderedText: {
      border: '1px solid black',
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      whiteSpace: 'pre'
    },
    darkBorderedText: {
      background: 'black',
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main
    },
    selectDroprDown: {
      border: '1px solid grey',
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1, 3)
    },
    dialogContent: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        padding: 0
      }
    },
    boldText: {
      fontWeight: 'bolder'
    },
    dialog: {
      '& .MuiPaper-root': {
        height: 'auto%',
        zIndex: 1500
      }
    },
    textFieldPadding: {
      paddingRight: theme.spacing(2)
    },
    infoBtn: {
      opacity: '0.5',
      cursor: 'pointer'
    }
  };
});

import React, { useState, useContext } from 'react';
import { Box } from '@material-ui/core';
import 'react-phone-number-input/style.css';
import { AuthContext } from 'fbase/authContext';
import DisplayPhoneNumber from './DisplayPhoneNumber';
import VerifyOtp from './VerifyOtp';

const ChangePhoneNumber = () => {
  const { currentUser } = useContext(AuthContext);

  const initialphoneNumber = currentUser?.phoneNumber;
  const [activeStep, setActiveStep] = useState(1);

  const [verificationId, setVerificationId] = useState('');

  return (
    <Box padding={1}>
      {activeStep === 1 && <DisplayPhoneNumber initialphoneNumber={initialphoneNumber} activeStep={activeStep} setActiveStep={setActiveStep} setVerificationId={setVerificationId} />}
      {activeStep === 2 && <VerifyOtp verificationId={verificationId} activeStep={activeStep} setActiveStep={setActiveStep} />}
    </Box>
  );
};

export default ChangePhoneNumber;

import gql from 'graphql-tag';

export const pizzaSize = gql`
  fragment pizzaSize on PizzaParts {
    sizes {
      id
      title
      description
      enableHalfHalf
    }
  }
`;

export const pizzaCrusts = gql`
  fragment pizzaCrusts on PizzaParts {
    crusts {
      id
      title
      description
      prices {
        size {
          id
        }
        price {
          intValue
          shift
        }
      }
    }
  }
`;

export const pizzaSauce = gql`
  fragment pizzaSauce on PizzaParts {
    sauces {
      id
      title
      description
      enableHalfHalf
      maxSelect
      items {
        id
        title
        description
        preset
        prices {
          id
          size {
            id
          }
          densities
          densityPrices {
            density
            price {
              intValue
              shift
            }
          }
        }
      }
    }
  }
`;

export const pizzaCheese = gql`
  fragment pizzaCheese on PizzaParts {
    cheeses {
      id
      title
      description
      enableHalfHalf
      maxSelect
      items {
        id
        title
        description
        preset
        prices {
          id
          size {
            id
          }
          densities
          densityPrices {
            density
            price {
              intValue
              shift
            }
          }
        }
      }
    }
  }
`;

export const pizzaToppings = gql`
  fragment pizzaToppings on PizzaParts {
    toppings {
      id
      title
      description
      enableHalfHalf
      maxSelect
      items {
        id
        title
        description
        preset
        prices {
          id
          size {
            id
          }
          densities
          densityPrices {
            density
            price {
              intValue
              shift
            }
          }
        }
      }
    }
  }
`;

export const densityMultiplier = gql`
  fragment densityMultiplier on PizzaParts {
    densityMultipliers {
      density
      multiplier
    }
  }
`;

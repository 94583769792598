import { gql } from 'apollo-boost';
import { Profile, BusinessHours, Announcement, RewardSetting, Gallery, PaymentSettings } from 'graphql/fragments/groceryStore.fragments';

export default gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      timezone
    }
  }
`;

export const getGroceryStoreProfile = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      ...Profile
    }
  }
  ${Profile}
`;

export const getGroceryStoreBusinsessHours = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      ...BusinessHours
    }
  }
  ${BusinessHours}
`;

export const getGroceryStoreGallery = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      ...Gallery
    }
  }
  ${Gallery}
`;

export const getGroceryStoreAnnouncement = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      ...Announcement
    }
  }
  ${Announcement}
`;

export const getGroceryStoreRewards = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      ...RewardSetting
    }
  }
  ${RewardSetting}
`;

export const getGroceryStorePaymentSettings = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      ...PaymentSettings
      ...BusinessHours
    }
  }
  ${BusinessHours}
  ${PaymentSettings}
`;

export const getGroceryStore = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      ...Profile
      ...BusinessHours
      ...Gallery
    }
  }
  ${Profile}
  ${BusinessHours}
  ${Gallery}
`;

export const getGroceryStoreStatus = gql`
  query ownerGetGroceryStoreById($input: OwnerGetGroceryStoreByIdInput!) {
    ownerGetGroceryStoreById(input: $input) {
      id
      status
    }
  }
`;

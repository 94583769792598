import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(2)
  },
  selectInputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectInput: {
    minWidth: 150,
    marginRight: theme.spacing(2)
  },
  actionButton: {
    marginLeft: theme.spacing(1)
  },
  radioGroup: {
    display: 'inline'
  }
}));

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    border: 'solid 1px ' + theme.palette.grey[300],
    '& td': {
      padding: theme.spacing(1, 0.5)
    },
    '& th': {
      padding: theme.spacing(1, 0.5)
    }
  },

  btns: {
    margin: theme.spacing(0, 1)
  },
  addIcon: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  editIcon: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  deleteIcon: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  submitButton: {
    color: 'white',
    borderRadius: '4px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },

  verticleAlignMiddle: {
    verticalAlign: 'middle'
  },
  verticleAlignTop: {
    verticalAlign: 'top'
  },
  checkbox: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      height: '25px',
      width: '25px'
    }
  },
  csvReader: {
    padding: theme.spacing(2),
    display: 'block',
    border: '1px solid #ccc',
    borderRadius: '5px'
  }
}));

import getEmployeesQuery from 'graphql/query/getEmployees.query';
import { createBizEmployee, updateBizEmployee, deleteBizEmployee } from 'graphql/mutations/employee.mutation';
import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { useActiveRestaurant } from 'hooks/restaurant';
import { map, filter } from 'lodash';
import { ExecutionResult } from 'apollo-boost';
import { MutationFunctionOptions } from '@apollo/react-common';

export const useGetEmployees = (restaurantId: string) => {
  const data = useQueryWithLoader(getEmployeesQuery, {
    variables: {
      input: {
        bizId: restaurantId
      }
    }
  });

  return data;
};

export const useCreateEmployeeMutation = () => {
  const { restaurantId } = useActiveRestaurant();
  const [createEmployee, { data, loading, error }] = useMutationWithLoader(createBizEmployee, {
    update: (cache, { data: { createBizEmployee: newData } }) => {
      const variables = {
        input: {
          bizId: restaurantId
        }
      };

      const { getBizEmployees: oldData }: any = cache.readQuery({
        query: getEmployeesQuery,
        variables
      });

      cache.writeQuery({
        variables,
        query: getEmployeesQuery,
        data: { getBizEmployees: oldData ? [...oldData, newData] : [newData] }
      });
    }
  });

  return {
    createEmployee,
    data,
    loading,
    error
  };
};

export const useUpdateEmployeeMutation = () => {
  const { restaurantId } = useActiveRestaurant();
  const [updateEmployee, { data, loading, error }] = useMutationWithLoader(updateBizEmployee, {
    update: (cache, { data: { updateBizEmployee: newData } }) => {
      const variables = {
        input: {
          bizId: restaurantId
        }
      };

      const { getBizEmployees: oldData }: any = cache.readQuery({
        query: getEmployeesQuery,
        variables
      });

      const _newData = map(oldData, (emp) => {
        if (emp.uid === newData.uid) {
          return newData;
        } else {
          return emp;
        }
      });

      cache.writeQuery({
        variables,
        query: getEmployeesQuery,
        data: { getBizEmployees: _newData }
      });
    }
  });

  return {
    updateEmployee,
    data,
    loading,
    error
  };
};

export const useDeleteEmployeeMutation = () => {
  const { restaurantId } = useActiveRestaurant();
  const [deleteEmployeeCaller, { data, loading, error }] = useMutationWithLoader(deleteBizEmployee);

  const deleteEmployee = (uid: string, options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return deleteEmployeeCaller({
      ...options,
      variables: {
        ...options?.variables,
        input: { ...options?.variables?.input }
      },
      update: (cache) => {
        const variables = {
          input: {
            bizId: restaurantId
          }
        };

        const { getBizEmployees: oldData }: any = cache.readQuery({
          query: getEmployeesQuery,
          variables
        });

        cache.writeQuery({
          variables,
          query: getEmployeesQuery,
          data: { getBizEmployees: filter(oldData, (data) => data.uid !== uid) }
        });
      }
    });
  };

  return {
    deleteEmployee,
    data,
    loading,
    error
  };
};

import { gql } from 'apollo-boost';

export default gql`
  query getDishCategoriesOfRestaurant($input: GetDishCategoriesOfRestaurantInput!) {
    getDishCategoriesOfRestaurant(input: $input) {
      id
      title {
        text
      }
      dishes {
        status
      }
    }
  }
`;

import React from 'react';
import { useStore } from 'store';
import { Grid, Typography, Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import S3Image from 'components/S3Image';
import restaurantEmptyIcon from 'assets/img/restaurant-empty-icon.png';
import { useStyles } from './style';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import EditIcon from '@material-ui/icons/Edit';
import { formatPhoneNumber } from 'util/number';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useGetGroceryStoreById } from 'graphql/hooks/grocery.hooks';
import { Paragraph } from '@lokobee/lokobee-ui';
import GroceryStoreStatus from './GroceryStoreStatus';

const GroceryStoreInfoDisplay = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: { groceryStoreId }
  } = useStore();
  const { data } = useGetGroceryStoreById(groceryStoreId, 'PROFILE');

  if (data && data.ownerGetGroceryStoreById) {
    const { name, description, phone, logo, address, nanoid } = data.ownerGetGroceryStoreById;
    const _address = address ? address.text : '-';
    const _phone = phone ? formatPhoneNumber(phone) : '-';
    const { url } = logo || {};
    return (
      <>
        <Grid container={true} className={classes.container}>
          <Grid item={true} xs={12} sm={3} md={2}>
            <Box width="100%" height="100%" margin={0} padding={0} className={logo ? classes.imageContainer : ''}>
              <S3Image src={url} defaultImage={restaurantEmptyIcon} />
            </Box>
          </Grid>
          <Grid item={true} xs={12} sm={5} md={6}>
            <Box className={classes.decriptionContainer}>
              <Typography variant="h6">{name}</Typography>
              <Paragraph text={description} variant="body2" />
            </Box>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            {nanoid && (
              <Box display="flex" justifyItems="center" alignItems="center" marginY={1}>
                <Box marginRight={1}>
                  <AccountCircleIcon />
                </Box>
                <Typography variant="body2">ID (can be used for OPS login) : {nanoid} </Typography>
              </Box>
            )}
            <Box display="flex" justifyItems="center" alignItems="center" marginY={1}>
              <Box marginRight={1}>
                <BusinessTwoToneIcon />
              </Box>
              <Typography variant="body2">{_address}</Typography>
            </Box>
            <Box display="flex" justifyItems="center" alignItems="center" marginY={1}>
              <Box marginRight={1}>
                <PhoneIcon />
              </Box>
              <Typography variant="body2">{_phone ? _phone : phone}</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon fontSize="small" />}
                size="small"
                disableElevation={true}
                onClick={() => {
                  history.push(`/home/grocery/${groceryStoreId}/info`);
                }}>
                Edit info
              </Button>
            </Box>
            <Box>
              <GroceryStoreStatus />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  return null;
};

export default GroceryStoreInfoDisplay;

import { history } from 'routes';
import Logger from 'util/logger';
import { Firebase } from 'fbase';

/** Default state of apollo client local store */
export const defaults = {
  isLoggedIn: false,
  restaurant: null,
  waiterPage: 'as'
};

/** Middleware before request is made */
export const request = async (operation: any) => {
  const auth = new Firebase().getAuth();

  const token = await auth().currentUser?.getIdToken();

  // In case token is present bind it with all graphql request
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};

export default {
  clientState: {
    defaults
  },
  onError: ({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors && graphQLErrors.length) {
      const { message } = graphQLErrors[0];
      if (message === 'To query order, user needs to logIn') {
        history.push('/');
      }

      if (message.indexOf('Forbidden') > -1) {
        new Firebase().signOut();
      }
    }

    if (networkError) {
      Logger.log('Network Error');
    }
  },
  request,
  uri: process.env.REACT_APP_BACKEND_GRAPH_URI
  // uri: 'http://localhost:4000/staging/graphql'
};

import React, { useState, ReactElement } from 'react';
import { Box, RadioGroup, Typography, FormControlLabel, Radio, Grid } from '@material-ui/core';
import { findIndex } from 'lodash';
import DishOptionDetails from './DishOptionDetails';
import { Dialog } from '@lokobee/lokobee-ui';
import { Formik } from 'formik';
import validations from './validations';
import SelectOptions from './SelectOptions';
import { IDishOption } from '../types';
import { useStyles } from './style';

interface IProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  dishOptions: IDishOption[];
  hasInitial?: boolean;
  initialValues?: any;
  component: ReactElement;
}

const defaultInitialValue = {
  title: '',
  min: 0,
  max: 1,
  isOptional: 'true',
  isMultiQnty: 'false',
  items: [
    {
      title: '',
      price: ''
    }
  ]
};

const AddDishOptionDialog: React.FC<IProps> = ({ setFieldValue, component, dishOptions, hasInitial = false, initialValues = defaultInitialValue }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const _handleSubmit = ({ id, title, items, max, min, isOptional, isMultiQnty }: any, { setSubmitting, resetForm }: any) => {
    setSubmitting(true);

    if (dishOptions.length === 0) {
      setFieldValue('dishOptions', [{ id: 1, title, items, max, min, isOptional, isMultiQnty }]);
    } else {
      if (hasInitial) {
        const itemTobeUpdatedIndex = findIndex(dishOptions, ['id', id]);

        dishOptions[itemTobeUpdatedIndex] = { id, title, min, max, isOptional, items, isMultiQnty };

        setFieldValue('dishOptions', dishOptions);
      } else {
        setFieldValue('dishOptions', [...dishOptions, { id: dishOptions.length + 1, title, items, max, min, isOptional, isMultiQnty }]);
      }
    }

    setSubmitting(false);

    resetForm();
    /**
     * Close dialog box after form is submitted
     */
    setOpen(false);
  };

  const onIsOptionalChange = (e: React.ChangeEvent<HTMLInputElement>, _setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const { value } = e.target;

    _setFieldValue('isOptional', value.toString());
    _setFieldValue('min', value === 'true' ? 0 : 1);
  };

  const _component = React.cloneElement(component, { onClick: () => setOpen(!open) });

  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={validations} enableReinitialize={true}>
      {({ values, handleSubmit, handleChange, resetForm, isValid, errors, touched, setFieldValue: _setFieldValue }) => (
        <>
          {_component}
          <Dialog open={open} setOpen={setOpen} fullWidth={true} onEnter={() => resetForm()}>
            <Dialog.DialogContent className={classes.dialogContent}>
              <form>
                <Box>
                  <DishOptionDetails title={values.title} handleChange={handleChange} items={values.items} errors={errors} touched={touched} />
                </Box>
                <Box flex={1}>
                  <Typography variant="body1" display="inline">
                    Is this choice optional
                  </Typography>
                  <Box display="inline">
                    <RadioGroup name="isOptional" className={classes.radioGroup} value={values.isOptional} onChange={(e: any) => onIsOptionalChange(e, _setFieldValue)}>
                      <FormControlLabel value="true" label="Yes" control={<Radio />} labelPlacement="start" />
                      <FormControlLabel value="false" label="No" control={<Radio />} labelPlacement="start" />
                    </RadioGroup>
                  </Box>
                </Box>
                {values.isOptional === 'false' && (
                  <SelectOptions
                    name="min"
                    label="What is the minimum number of selections customer can make from the table above?"
                    value={values.min}
                    handleChange={handleChange}
                    errors={errors}
                    rangeEnd={values.items.length + 1}
                  />
                )}
                <SelectOptions
                  name="max"
                  label="What is the maximum number of selections customer can make from the table above?"
                  value={values.max}
                  handleChange={handleChange}
                  errors={errors}
                  rangeStart={values.isOptional === 'true' ? 1 : values.min}
                  rangeEnd={values.items.length + 1}
                />
                <Box flex={1}>
                  <Grid container={true} justify="center" alignItems="center">
                    <Grid item={true} lg={9}>
                      <Typography variant="body1" display="inline">
                        Can the items listed in table above be ordered in multiple quantities?
                      </Typography>
                    </Grid>
                    <Grid item={true} lg={3}>
                      <Box display="inline">
                        <RadioGroup name="isMultiQnty" className={classes.radioGroup} value={values.isMultiQnty} onChange={handleChange}>
                          <FormControlLabel value="true" label="Yes" control={<Radio />} labelPlacement="start" />
                          <FormControlLabel value="false" label="No" control={<Radio />} labelPlacement="start" />
                        </RadioGroup>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Dialog.DialogContent>
            <Dialog.DialogActions saveBtnText="OK" onSave={() => handleSubmit()} disableSaveBtn={!!Object.keys(errors).length} />
          </Dialog>
        </>
      )}
    </Formik>
  );
};

export default AddDishOptionDialog;

import React, { useEffect, useState, useContext } from 'react';
import AWS from 'aws-sdk';
import { FirebaseContext } from 'fbase';

export const AuthContext = React.createContext<any>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);

  const [idToken, setIdToken] = useState<string>('');

  const [loading, setLoading] = useState(true);

  const [s3, setAwsS3] = useState<null | AWS.S3>(null);

  /**
   * Get firebase reference
   */
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    const auth = firebase && firebase.getAuth();

    auth &&
      auth().onAuthStateChanged(async (user) => {
        setCurrentUser(user);

        const idToken = await user?.getIdToken();

        if (idToken) {
          AWS.config.update({
            region: 'us-west-2',
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: 'us-west-2:be4af9a1-8430-4cb5-aed2-d533579de090',
              Logins: {
                'securetoken.google.com/lokobee-dev': idToken
              }
            })
          });

          const newS3Instance = new AWS.S3({
            apiVersion: '2006-03-01',
            signatureVersion: 'v4'
          });

          setAwsS3(newS3Instance);

          setIdToken(idToken);
        }

        if (loading) {
          setLoading(false);
        }
      });
  }, [currentUser, firebase, loading]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        idToken,
        s3
      }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useIdToken = () => {
  const idToken = useContext(AuthContext);

  if (idToken) {
    return idToken;
  }

  return null;
};

import React, { useContext } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/Apps';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import { IconButton, Menu, MenuItem, Typography, Box, Hidden, useMediaQuery } from '@material-ui/core';
import { FirebaseContext } from 'fbase';
import { useStyles } from './style';
import { useActiveRestaurant } from 'hooks/restaurant';
import Fade from '@material-ui/core/Fade';
import { useApolloClient } from '@apollo/react-hooks';
import useActiveGroceryStore from 'hooks/grocery/useActiveGroceryStore';
interface IProps {
  isMspDisabled: boolean;
}
const Profile = ({ isMspDisabled }: IProps) => {
  const classes = useStyles();

  const { restaurantId } = useActiveRestaurant();

  const { groceryStoreId } = useActiveGroceryStore();

  const { currentUser } = useContext(AuthContext);

  const { firebase } = useContext(FirebaseContext);

  const history = useHistory();

  const { location } = history;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [groceryAnchorEl, setGroceryAnchorEl] = React.useState<null | HTMLElement>(null);

  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const openGroceryMenu = Boolean(groceryAnchorEl);

  const client = useApolloClient();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroceryMenuClose = () => {
    setGroceryAnchorEl(null);
  };

  const changeRoute = (route: string) => {
    setUserAnchorEl(null);
    setAnchorEl(null);
    setGroceryAnchorEl(null);
    history.push(route);
  };

  const handleSignOut = async () => {
    try {
      if (firebase) {
        await firebase.signOut();
        client.clearStore();
      }
    } catch {
      console.log('FAILED TO SIGN OUT');
    }
  };

  let count = 0;
  if (currentUser) {
    const { displayName } = currentUser;
    return (
      <>
        {restaurantId && (
          <IconButton onClick={handleMenu} className={classes.menuIcon}>
            <AppsIcon />
          </IconButton>
        )}

        {groceryStoreId && (
          <IconButton onClick={(e: React.MouseEvent<HTMLElement>) => setGroceryAnchorEl(e.currentTarget)} className={classes.menuIcon}>
            <AppsIcon />
          </IconButton>
        )}

        <Box display="inline" component="div" paddingX={1} onClick={(e: React.MouseEvent<HTMLElement>) => setUserAnchorEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
          <AccountCircle className={classes.userDefaultIcon} />
          <Typography variant="body1" component="span" className={classes.username}>
            {displayName}
          </Typography>
        </Box>

        <Menu
          anchorEl={userAnchorEl}
          getContentAnchorEl={null}
          open={Boolean(userAnchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          keepMounted={true}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={() => setUserAnchorEl(null)}
          TransitionComponent={Fade}>
          {!restaurantId && location.pathname !== '/home' && !isDesktop && <MenuItem onClick={() => changeRoute(`/home`)}>Restaurants</MenuItem>}
          <MenuItem onClick={() => changeRoute(`/home/profile`)}>Profile</MenuItem>
          <MenuItem onClick={() => changeRoute(`/home/privacypolicy`)}>Privacy policy</MenuItem>
          <MenuItem onClick={() => changeRoute(`/home/useragreement`)}>User agreement</MenuItem>
          <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          keepMounted={true}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleClose}
          TransitionComponent={Fade}>
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home`)}>Home</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/${restaurantId}`)}>Menu</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/analytics`)}>Analytics</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/reward`)}>Rewards</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/announcement`)}>Announcement</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/crypto-promotions`)}>Coupon creation</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/text-marketing`)}>Text Marketing</MenuItem>}

          <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/info`)} key={count++}>
            Restaurant Info
          </MenuItem>

          <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/orderreport`)} key={count++}>
            Orders report
          </MenuItem>

          {process.env.REACT_APP_PIZZA_CONFIG_FLAG === 'true' && (
            <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/pizzaconfig`)} key={count++}>
              Pizza configuration
            </MenuItem>
          )}
          <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/employeemanagement`)} key={count++}>
            Employee Management
          </MenuItem>
          <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/paymentsetup`)} key={count++}>
            Payment Setup
          </MenuItem>
          <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/ratings`)}>Ratings</MenuItem>
          <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/websiteform`)}>Website Form</MenuItem>
          <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/blogs`)}>Website Blogs</MenuItem>

          {!isMspDisabled && !isDesktop && <MenuItem onClick={() => changeRoute(`/home/${restaurantId}/msp`)}>MSP</MenuItem>}
        </Menu>

        <Menu
          anchorEl={groceryAnchorEl}
          getContentAnchorEl={null}
          open={openGroceryMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          keepMounted={true}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleGroceryMenuClose}
          TransitionComponent={Fade}>
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home`)}>Home</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/grocery/${groceryStoreId}`)}>Grocery items</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/grocery/${groceryStoreId}/promotions`)}>Promotions</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/grocery/${groceryStoreId}/reward`)}>Rewards</MenuItem>}
          {!isDesktop && <MenuItem onClick={() => changeRoute(`/home/grocery/${groceryStoreId}/announcement`)}>Announcement</MenuItem>}

          <MenuItem onClick={() => changeRoute(`/home/grocery/${groceryStoreId}/info`)} key={count++}>
            Store Info
          </MenuItem>

          <MenuItem onClick={() => changeRoute(`/home/grocery/${groceryStoreId}/employeemanagement`)} key={count++}>
            Employee Management
          </MenuItem>
          <MenuItem onClick={() => changeRoute(`/home/grocery/${groceryStoreId}/paymentsetup`)} key={count++}>
            Payment Setup
          </MenuItem>
        </Menu>
      </>
    );
  }

  return null;
};

export default Profile;

import React, { useState, ChangeEvent } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Box, TextField, makeStyles, Theme } from '@material-ui/core';

interface IProps {
  value: string;
  component: 'div' | 'input';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent) => void;
  error?: boolean;
  errorMsg?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  eyeIcon: {
    marginLeft: theme.spacing(1)
  }
}));
const PasswordField = ({ value, component, onChange, error, errorMsg }: IProps) => {
  const classes = useStyles();
  const [show, setshow] = useState(false);

  const renderComponent = () => {
    if (component === 'div') {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {'*'.repeat(6)}
        </Box>
      );
    } else if (component === 'input') {
      return (
        <Box>
          <TextField
            type={show ? 'text' : 'password'}
            value={value}
            name="password"
            onChange={onChange}
            label="password"
            variant="outlined"
            error={error}
            helperText={errorMsg ? errorMsg : ''}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {show ? (
                    <VisibilityIcon className={classes.eyeIcon} cursor="pointer" onClick={() => setshow(!show)} />
                  ) : (
                    <VisibilityOffIcon className={classes.eyeIcon} cursor="pointer" onClick={() => setshow(!show)} />
                  )}
                </React.Fragment>
              )
            }}
          />
        </Box>
      );
    }

    return null;
  };

  return renderComponent();
};

export default PasswordField;

import React, { useState } from 'react';
import { IPizzaToppingGroup } from '../../../types';
import { TableCell, TableRow, TextField, MenuItem } from '@material-ui/core';
import { useStyles } from '../../style';
import { useFormik, getIn } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import validate from './validations';
import { ConfirmDialog } from '@lokobee/lokobee-ui';
import { filter } from 'lodash';

interface IProps {
  action: 'edit' | 'new';
  rowData: IPizzaToppingGroup;
  rows: IPizzaToppingGroup[];
  onAdd?: (newSize: IPizzaToppingGroup) => void;
  onEdit?: (updatedSize: IPizzaToppingGroup) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  selected: boolean;
  onClick: () => void;
  allowDelete: boolean;
}

const Row = ({ rowData, action, onAdd, onEdit, onDelete, onCancel, selected, onClick, rows, allowDelete }: IProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);

  const formik = useFormik({
    initialValues: { ...rowData },
    onSubmit: (formValues) => handleFormSubmit(formValues),
    validationSchema: validate,
    validate: (values: IPizzaToppingGroup) => {
      const errors: any = {};

      const groupPresent = filter(rows, ({ title }) => title.toLowerCase().replaceAll(' ', '') === values.title.toLowerCase().replaceAll(' ', ''));

      if (action === 'new' && groupPresent.length) {
        errors.title = 'Duplicate group';
      }

      return errors;
    }
  });

  const handleFormSubmit = (formValues: IPizzaToppingGroup) => {
    if (action === 'new') {
      if (onAdd) {
        onAdd(formValues);
      }
    }
    if (action === 'edit') {
      if (onEdit) {
        setEditing(false);
        onEdit(formValues);
      }
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    setEditing(false);
    if (onCancel) {
      onCancel();
    }
  };

  const { handleChange, handleBlur, errors, touched } = formik;
  const { title, enableHalfHalf } = formik.values;

  const editForm = (
    <TableRow className={classes.verticleAlignTop}>
      <TableCell>
        <TextField
          name="title"
          variant="outlined"
          fullWidth={true}
          value={title}
          placeholder="Size"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!getIn(touched, 'title') && !!getIn(errors, 'title')}
          helperText={!!getIn(touched, 'title') && !!getIn(errors, 'title') ? errors.title : ''}
        />
      </TableCell>

      <TableCell>
        <TextField
          select={true}
          fullWidth={true}
          name="enableHalfHalf"
          variant="outlined"
          placeholder="Half and Half supported"
          value={enableHalfHalf}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { values } = formik;
            formik.setValues({
              ...values,
              enableHalfHalf: e.target.value === 'true'
            });
          }}
          onBlur={handleBlur}
          error={!!getIn(touched, 'enableHalfHalf') && !!getIn(errors, 'enableHalfHalf')}>
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </TextField>
      </TableCell>
      <TableCell className={classes.verticleAlignMiddle}>
        <CheckIcon fontSize="small" className={classes.successBtn} onClick={formik.submitForm} />
        <ClearIcon fontSize="small" className={classes.deleteIcon} onClick={handleCancel} />
      </TableCell>
    </TableRow>
  );

  if (action === 'new' || editing) {
    return editForm;
  } else {
    return (
      <TableRow className={`${classes.selectiveRow} ${selected ? classes.selectedRow : ''}`}>
        <TableCell onClick={onClick}>{title}</TableCell>
        <TableCell onClick={onClick}>{enableHalfHalf ? 'Yes' : 'No'}</TableCell>
        <TableCell>
          <EditIcon fontSize="small" className={classes.editIcon} onClick={() => setEditing(true)} />
          {allowDelete && (
            <ConfirmDialog
              onYes={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
              message="Are you sure want to delete this row?">
              <DeleteIcon fontSize="small" className={classes.deleteIcon} />
            </ConfirmDialog>
          )}
        </TableCell>
      </TableRow>
    );
  }
};

export default Row;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

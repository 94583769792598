import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    justifyContent: 'center'
  },
  successBtn: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 2),
    minWidth: 'max-content',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      bottom: 0,
      left: 0,
      right: 0,
      position: 'sticky'
    }
  },
  customInput: {
    padding: theme.spacing(1.5),
    border: 'solid 1px ' + theme.palette.grey[400],
    borderRadius: theme.spacing(0.5),
    '& input': {
      border: 'none',
      outline: 'none'
    },
    '&:hover': {
      border: 'solid 1px black'
    },
    '& .MuiSelect-select': {
      backgroundColor: 'white'
    },
    '&:after': { border: 'none', outline: 'none' },
    '&:before': { content: 'none', outline: 'none' },
    '& .PhoneInputCountryIcon': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none'
    }
  },
  customInputError: {
    border: 'solid 1px ' + theme.palette.error.main,
    '&:hover': {
      border: 'solid 1px ' + theme.palette.error.main
    },
    '& input': {
      border: 'none'
    }
  }
}));

import * as Yup from 'yup';
import Big from 'big.js';
import Moment from 'moment';

const regex = new RegExp(/^(?!0+$)[0-9]+(\.[0-9][0-9]?)?$/);

export const step1 =  Yup.object().shape({
  code: Yup.string().required('Coupon code is required').max(15),
  ocassion: Yup.string().required('Ocassion is required').max(100),
  startDate: Yup.string().nullable().required('Start Date is required')
  .test({
    name: 'date-test',
    message: 'Start Date must be before End Date',
    test: function (startDate: string) {
      if (startDate && this.parent.endDate) {
        if (Moment(startDate).isAfter(Moment(this.parent.endDate))) {
          return false;
        }
      }
      return true;
    }
  }),
  endDate: Yup.string().nullable().required('End Date is required')
});

export const step2 = Yup.object().shape({
  subtotalThreshold: Yup.number().required('Min Spending is required').lessThan(99999999, 'Invalid Min Spending')
  .test({
    name: 'equality-test', 
    message: 'Min Spending must be more than Discount', 
    test: function (subtotalThreshold: string) {
      if (this.parent.discountType === 'DOLLAR' && subtotalThreshold && this.parent.sdiscountValue && Big(subtotalThreshold).lte(this.parent.discountValue)) {
        return false;
      }
      return true;
    }
  }),
  taxMethod: Yup.string().oneOf(['PRE_DISCOUNT', 'POST_DISCOUNT']).required('Tax Method is required'),
  discountType: Yup.string().oneOf(['PERCENT', 'DOLLAR']).required('Discount type is required'),
  discountValue: Yup.string().required('Discount is required').matches(regex, 'Invalid Discount'),
  maxDiscount: Yup.number().when('discountType', {
    is: (value: string) => value === 'PERCENT',
    then: Yup.number().moreThan(0, 'Invalid Max Discount').lessThan(99999999, 'Invalid Max Discount'),
  }),
  maxUse: Yup.number().moreThan(0, 'Invalid Maximum # of Coupons').lessThan(9999999999, 'Invalid Maximum # of Coupons'),
  description: Yup.string().required('Description is required'),
  dayOfWeek: Yup.array().of(Yup.number()).required('Days of week is required'),
  orderType: Yup.array().of(Yup.string()).required('Order Type is required')
})
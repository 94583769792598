import React, { ChangeEvent, useState, useImperativeHandle, useEffect, useCallback } from 'react';
import { Box, Dialog, DialogContent, useMediaQuery, Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './style';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import useDownloadImage from 'hooks/useDownloadImage';

interface IProps {
  width: number | string;
  height: number | string;

  onCrop?: (filename: string, dataurl: string) => void;
  initialValue?: string;

  /**
   * We use id to link input to the label
   * If multple upload image in same file then parent component needs to pass id
   * */
  id?: string;
}

const UploadImage = React.forwardRef(({ onCrop, width, height, initialValue, id }: IProps, ref) => {
  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { fetchImage } = useDownloadImage();

  const [file, setFile] = useState<string | null>(null);

  const [filename, setFilename] = useState<string | null>(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [dragging, setDragging] = useState(false);

  let cropperObj: any = React.createRef();

  const classes = useStyles({
    noPadding: !!file,
    dragging
  });

  const labelRef: React.RefObject<HTMLLabelElement> = React.createRef();

  useEffect(() => {
    if (initialValue && initialValue !== '' && !file) {
      setFile(initialValue);
    }
  }, [fetchImage, file, initialValue, onCrop]);

  const _crop = useCallback(
    (e: any) => {
      if (!cropperObj) {
        return;
      }

      if (!cropperObj.getCroppedCanvas()) {
        return;
      }

      cropperObj
        .getCroppedCanvas({
          fillColor: '#fff'
        })
        .toBlob((blob: any) => {
          const url = URL.createObjectURL(blob);

          setFile(url);

          setOpenDialog(false);

          if (onCrop) {
            if (filename && blob) {
              onCrop(filename, blob);
            } else {
              throw Error('Upload Image data not set');
            }
          }
        }, 'image/jpeg');
    },
    [cropperObj, filename, onCrop]
  );

  useImperativeHandle(ref, () => {
    return {
      removeImage: () => {
        setFile(null);
      }
    };
  });

  const _onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { current } = labelRef;
    if (current) {
      current.click();
    }
  };

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    if (target.files && target.files[0] && target.files[0].type.indexOf('image') > -1) {
      setFilename(target.files[0].name);

      setFile(URL.createObjectURL(target.files[0]));

      setOpenDialog(true);

      target.value = '';
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);

    setFile(null);
  };

  const _onDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const _onDragStop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const _onDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length && droppedFiles[0].type.indexOf('image') > -1) {
      setFilename(droppedFiles[0].name);
      setFile(URL.createObjectURL(droppedFiles[0]));
      setOpenDialog(true);
    }
  };

  return (
    <Grid container={true}>
      <label ref={labelRef} htmlFor={id ? id : 'file'} className={classes.label}>
        <Box className={classes.root} width={width} height={height} onDragEnter={_onDrag} onDragOver={_onDrag} onDragLeave={_onDragStop} onDrop={_onDrop}>
          <input id={id ? id : 'file'} type="file" className={classes.input} onChange={_onChange} accept="image/*" />
          {!file && (
            <>
              <Typography variant="body2">Drag and drop files here</Typography>
              <Typography variant="body2">or</Typography>
              <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={_onClick}>
                Add files
              </Button>
            </>
          )}
          {file && <img src={file} alt="" className={classes.image} />}
        </Box>
        <Dialog open={openDialog} fullWidth={true} maxWidth="sm" disableBackdropClick={true} fullScreen={fullScreen}>
          <DialogContent className={classes.dialogContent}>
            <Box onClick={closeDialog} className={classes.closeButton}>
              <CloseIcon color="inherit" />
            </Box>
            <Box className={classes.cropperContainer}>
              <Cropper
                ref={(cropper: any) => {
                  cropperObj = cropper;
                }}
                src={file}
                className={classes.cropper}
                aspectRatio={3 / 2}
                autoCropArea={10}
              />
            </Box>
            <Button variant="contained" color="primary" className={classes.btn} onClick={_crop}>
              Save
            </Button>
          </DialogContent>
        </Dialog>
      </label>
    </Grid>
  );
});

export default UploadImage;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(1),
    flex: '0 0 auto'
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },
  button: {
    cursor: 'pointer'
  }
}));

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GroceryStoreHome from './GroceryStoreHome';
import GroceryStoreInfo from './GroceryStoreInfo';
import GroceryAnnouncement from './GroceryAnnouncement';
import GroceryEmployeeManagement from './GroceryEmployeeManagement';
import PaymentSetup from './PaymentSetup';
import GroceryReward from './GroceryReward';

const DashboardRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/home/grocery/:groceryStoreId/info" component={GroceryStoreInfo} />
      <Route path="/home/grocery/:groceryStoreId/employeemanagement" component={GroceryEmployeeManagement} />
      <Route path="/home/grocery/:groceryStoreId/announcement" component={GroceryAnnouncement} />
      <Route path="/home/grocery/:groceryStoreId/paymentsetup" component={PaymentSetup} />
      <Route path="/home/grocery/:groceryStoreId/reward" component={GroceryReward} />
      <Route path="/home/grocery/:groceryStoreId" component={GroceryStoreHome} />
    </Switch>
  );
};

export default DashboardRoutes;

import gql from 'graphql-tag';
import { storeItemFragment } from 'graphql/fragments/groceryStore.fragments';

export const getGroceryDefaultItems = gql`
  query getGroceryDefaultItems {
    getGroceryDefaultItems {
      id
      title
      status
      subCategories {
        id
        title
        status
      }
      items {
        ...storeItemFragment
      }
    }
  }
  ${storeItemFragment}
`;

import { gql } from 'apollo-boost';

export default gql`
  mutation updateWebsiteBlog($input: UpdateWebsiteBlogInput!) {
    updateWebsiteBlog(input: $input) {
      version
      id
      bizId
      createdAt
      updatedAt
      title
      text
      picture {
        id
        url
      }
      headline1
      headline2
      headline1Image {
        url
      }
      headline2Image {
        url
      }
    }
  }
`;

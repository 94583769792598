import * as Yup from 'yup';

const tableNamePattern = new RegExp('^([A-Z][0-9][0-9]?|[0-9][0-9][0-9])$');

export default Yup.object().shape({
  tables: Yup.array()
    .of(
      Yup.object().shape({
        tableName: Yup.string()
          .required('Table setup error')
          .matches(tableNamePattern, 'Table Name pattern error'),
        seats: Yup.number().required('Table setup error'),
        cardURL: Yup.string()
      })
    )
    .min(1)
});

import { makeStyles, Theme } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: blue[900]
    },
    '& .MuiStepIcon-root': {
      color: blue[100],
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
      '& text': {
        display: 'none'
      }
    }
  },
  label: {
    '& .MuiStepLabel-label': {
      color: 'black'
    }
  }
}));

import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, CircularProgress, MenuItem, Select, Typography } from '@material-ui/core';
import { useStyle } from '../style';
import { useHistory } from 'react-router-dom';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useGetAllCryptoCoupons } from 'graphql/hooks/coupons.hooks';
import { ICouponType } from '../type';
import { convertPriceTo, convertToCCTokens } from 'util/number';
const CouponsList = () => {
  const classes = useStyle();
  const history = useHistory();

  const { restaurantId } = useActiveRestaurant();

  const { activeCouponItemsList, historyCouponItemsList, fetchMoreData, newPageCursor, loading, fetching } = useGetAllCryptoCoupons(restaurantId);

  const [couponsToShow, setCouponsToShow] = useState<string>('ACTIVE');

  const openCoupon = (couponId: string) => {
    history.push(`/home/${restaurantId}/coupon/${couponId}`);
  };

  const onChangeCouponType = (e: any) => {
    setCouponsToShow(e);
  };
  return (
    <Box>
      <Box display={'flex'} justifyContent="flex-end" width={'100%'} paddingY={2}>
        <Select id="demo-simple-select-error-1" className={classes.selectDroprDown} value={couponsToShow} onChange={(e) => onChangeCouponType(e.target.value)}>
          <MenuItem value={'ACTIVE'} key={'ACTIVE'}>
            Active
          </MenuItem>
          <MenuItem value={'HISTORY'} key={'HISTORY'}>
            History
          </MenuItem>
        </Select>
      </Box>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="coupon data table">
            <TableHead>
              <TableRow>
                <TableCell>Coupon Code</TableCell>
                <TableCell>Description</TableCell>
                <TableCell># of Promotion Used</TableCell>
                <TableCell>$ Revenue Generated</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell># of Coins Earned</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {couponsToShow === 'ACTIVE' ? (
                <>
                  {activeCouponItemsList && activeCouponItemsList.length > 0 ? (
                    activeCouponItemsList.map((couponItem: ICouponType, index: number) => {
                      return (
                        <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => openCoupon(couponItem.id)}>
                          <TableCell>
                            <strong>{couponItem.code}</strong>
                          </TableCell>
                          <TableCell>{couponItem.description}</TableCell>
                          <TableCell>{couponItem.used}</TableCell>
                          <TableCell> {convertPriceTo(couponItem.totalSales, 'DOLLAR') === '' ? 0 : convertPriceTo(couponItem.totalSales, 'DOLLAR')}</TableCell>
                          <TableCell>{couponItem.startDate}</TableCell>
                          <TableCell>{couponItem.endDate}</TableCell>
                          <TableCell>{convertToCCTokens(couponItem.cryptoEarned, couponItem.cryptoUnit)}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No Coupons added yet
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ) : (
                <>
                  {historyCouponItemsList && historyCouponItemsList.length > 0 ? (
                    historyCouponItemsList.map((couponItem: ICouponType, index: number) => {
                      return (
                        <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => openCoupon(couponItem.id)}>
                          <TableCell>
                            <strong>{couponItem.code}</strong>
                          </TableCell>
                          <TableCell>{couponItem.description}</TableCell>
                          <TableCell>{couponItem.used}</TableCell>
                          <TableCell>{convertPriceTo(couponItem.totalSales, 'DOLLAR')}</TableCell>
                          <TableCell>{couponItem.startDate}</TableCell>
                          <TableCell>{couponItem.endDate}</TableCell>
                          <TableCell>{convertToCCTokens(couponItem.cryptoEarned, couponItem.cryptoUnit)}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No Coupons added yet
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {couponsToShow === 'HISTORY' && newPageCursor && (
        <Box display={'flex'} justifyContent="center" marginTop={2} flex={2}>
          <Button variant="contained" color="primary" disabled={!newPageCursor || fetching} onClick={fetchMoreData}>
            {fetching ? (
              <>
                <Box display={'flex'} alignItems="center" marginRight={1}>
                  <CircularProgress size={18} />
                </Box>
                Loading...
              </>
            ) : (
              'Load More'
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CouponsList;

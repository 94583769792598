import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    color: '#424242'
  },
  formItem: {
    padding: theme.spacing(1),
  },
  tiles: {
    border: "2px",
    borderStyle: 'solid',
    borderColor: '#e0e0e0',
    borderRadius: "20px",
    padding: theme.spacing(2),
    minWidth: "200px",
    '& .MuiTypography-h6': {
      fontSize: '1.05rem',
      fontWeight:  500
    },
    '& .MuiTypography-h4': {
      fontSize: '1.8rem',
      fontWeight:  500
    },
    '& .MuiTypography-h3': {
      fontSize: '2.2rem',
      fontWeight:  500
    },
  },
  dailyTilesData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0 ${theme.spacing(4)}px`,
    '& *': {
      paddingTop: theme.spacing(2)
    }
  },
  vr: {
    backgroundColor: '#0000001f',
    width: '2px',
    height: '100px',
    display: 'block',
  },
  monthRevenueContainer: {
    overflowY: 'scroll',
    paddingRight: theme.spacing(1),
    height: '350px',
  },
  listContainer: {
    overflowY: 'scroll',
    paddingRight: theme.spacing(1),
    height: '350px',
    '& :nth-child(even)': {
      background: '#f2f2f2',
    }
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: '5px',
    fontSize: '1.1rem',
    marginBottom: theme.spacing(1)
  },
  infoIcon: {
    color: 'grey',
    cursor: 'pointer'
  },
  revenueBar: {
    backgroundColor: 'orange',
    height: '100%',
    display: 'block',
    borderRadius: '10px'
  },
  progress: {
    position: 'relative',
    background: "#ddd",
    width: "100%",
    height: '10px',
    borderRadius: '10px'
  },
  iconButton: {
    padding: theme.spacing(0)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

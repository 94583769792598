import getGroceryBizEmployeesQuery from 'graphql/query/grocery/getGroceryBizEmployees';
import { createGroceryBizEmployee, updateGroceryBizEmployee, deleteGroceryBizEmployee } from 'graphql/mutations/groceryEmployee.mutations';
import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import useActiveGroceryStore from 'hooks/grocery/useActiveGroceryStore';
import { map, filter } from 'lodash';
import { ExecutionResult } from 'apollo-boost';
import { MutationFunctionOptions } from '@apollo/react-common';

export const useGetGroceryEmployees = (groceryStoreId: string) => {
  const data = useQueryWithLoader(getGroceryBizEmployeesQuery, {
    variables: {
      input: {
        storeId: groceryStoreId
      }
    }
  });

  return data;
};

export const useCreateGroceryEmployeeMutation = () => {
  const { groceryStoreId } = useActiveGroceryStore();

  const [createEmployee, { data, loading, error }] = useMutationWithLoader(createGroceryBizEmployee, {
    update: (cache, { data: { createGroceryBizEmployee: newData } }) => {
      const variables = {
        input: {
          storeId: groceryStoreId
        }
      };

      const { getGroceryBizEmployees: oldData }: any = cache.readQuery({
        query: getGroceryBizEmployeesQuery,
        variables
      });

      cache.writeQuery({
        variables,
        query: getGroceryBizEmployeesQuery,
        data: { getGroceryBizEmployees: oldData ? [...oldData, newData] : [newData] }
      });
    }
  });

  return {
    createEmployee,
    data,
    loading,
    error
  };
};

export const useUpdateGroceryEmployeeMutation = () => {
  const { groceryStoreId } = useActiveGroceryStore();
  const [updateEmployee, { data, loading, error }] = useMutationWithLoader(updateGroceryBizEmployee, {
    update: (cache, { data: { updateGroceryBizEmployee: newData } }) => {
      const variables = {
        input: {
          storeId: groceryStoreId
        }
      };

      const { getGroceryBizEmployees: oldData }: any = cache.readQuery({
        query: getGroceryBizEmployeesQuery,
        variables
      });

      const _newData = map(oldData, (emp) => {
        if (emp.uid === newData.uid) {
          return newData;
        } else {
          return emp;
        }
      });

      cache.writeQuery({
        variables,
        query: getGroceryBizEmployeesQuery,
        data: { getGroceryBizEmployees: _newData }
      });
    }
  });

  return {
    updateEmployee,
    data,
    loading,
    error
  };
};
export const useDeleteGroceryEmployeeMutation = () => {
  const { groceryStoreId } = useActiveGroceryStore();
  const [deleteEmployeeCaller, { data, loading, error }] = useMutationWithLoader(deleteGroceryBizEmployee);

  const deleteEmployee = (uid: string, options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return deleteEmployeeCaller({
      ...options,
      variables: {
        ...options?.variables,
        input: { ...options?.variables?.input }
      },
      update: (cache) => {
        const variables = {
          input: {
            storeId: groceryStoreId
          }
        };

        const { getGroceryBizEmployees: oldData }: any = cache.readQuery({
          query: getGroceryBizEmployeesQuery,
          variables
        });

        cache.writeQuery({
          variables,
          query: getGroceryBizEmployeesQuery,
          data: { getGroceryBizEmployees: filter(oldData, (data) => data.uid !== uid) }
        });
      }
    });
  };

  return {
    deleteEmployee,
    data,
    loading,
    error
  };
};

import React, { useEffect, useState } from 'react';
import { useGetPizzaParts, useUpdatePizzaSize } from 'graphql/hooks/pizza.hooks';
import { useStore } from 'store';
import { ISize } from '../types';
import Size from './Size';
import { getDefaultSizes, parseSizes } from '../utils';
import { useSnackbar } from 'notistack';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const data = useGetPizzaParts(restaurant);

  const { updatePizzaSize } = useUpdatePizzaSize();

  const [initialValues, setInitialValues] = useState<ISize[]>([]);

  const [allowDelete, setAllowDelete] = useState(false);

  const snackbar = useSnackbar();

  useEffect(() => {
    if (data) {
      if (data.getPizzaParts) {
        const { sizes } = data.getPizzaParts;

        if (sizes && sizes.length) {
          setInitialValues(parseSizes(sizes));
        } else {
          setInitialValues(getDefaultSizes());
          setAllowDelete(true);
        }
      } else {
        if (!data.getPizzaParts) {
          setInitialValues(getDefaultSizes());
          setAllowDelete(true);
        }
      }
    }
  }, [data]);

  const updateAPI = async (sizes: ISize[]) => {
    const response = await updatePizzaSize({
      variables: {
        input: {
          bizId: restaurant,
          sizes
        }
      }
    });

    if (response) {
      setAllowDelete(false);
      nextStep();
    } else {
      snackbar.enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };
  if (data) {
    return <Size ref={ref} initialSizes={initialValues} nextStep={nextStep} updateAPI={updateAPI} allowDelete={allowDelete} />;
  }

  return null;
});

import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { useStyle } from '../style';
import coinImg from 'assets/img/cc_coin_img.png';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
interface IProps {
  setStep: Dispatch<SetStateAction<number>>;
}
const MainStates: React.FC<IProps> = ({ setStep }) => {
  const classes = useStyle();
  return (
    <Box width={'80%'} display="flex" marginX={'auto'} marginY={0} flexDirection={'column'} alignItems={'center'}>
      <Box display={'flex'} flex="1" width={'100%'} justifyContent={'center'} marginBottom={5}>
        <Box marginRight={2}>
          <img src={coinImg} className={classes.coinImg} width="100%" alt="CBT Coin" />
        </Box>
        <Box marginRight={2}>
          <Card className={classes.yellowCard}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                CashBack Tokens Owned
              </Typography>
              <Typography variant="body1" component="p">
                5.5 CBT
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Card className={classes.yellowCard}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Current Value per Contribution
              </Typography>
              <Typography variant="body1" component="p">
                $1
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box display={'flex'} flex="1" width={'100%'} justifyContent={'center'} marginBottom={5}>
        <Box marginRight={2} width={'50%'}>
          <Card className={classes.cardItem}>
            <CardContent>
              <Box marginBottom={2}>
                <Typography variant="body1" gutterBottom>
                  Learn how the price of <br /> <strong>CashBack Token</strong> increases!
                </Typography>
              </Box>
              <Button variant="contained" color="primary" size="small">
                Learn more
              </Button>
            </CardContent>
          </Card>
        </Box>
        <Box marginRight={2} width={'50%'}>
          <Card className={classes.cardItem}>
            <CardContent>
              <Box marginBottom={2}>
                <Typography variant="body1" gutterBottom>
                  Learn about out <br />
                  <strong>CashBack Promotion</strong> Partner
                </Typography>
              </Box>
              <Button variant="contained" color="primary" size="small">
                MaxCashback Website
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box display={'flex'} flex="1" width={'100%'} justifyContent={'center'} marginBottom={5}>
        <Box marginRight={2}>
          <Card>
            <CardContent>
              <Box display={'flex'} flexDirection="column" justifyContent={'space-between'}>
                <Typography variant="subtitle2" paragraph={true}>
                  Value of MaxCashback Blockchain
                </Typography>
                <Box display={'flex'} alignItems="center">
                  <Typography variant="subtitle2" className={classes.marginRight} component="p">
                    <ArrowUpwardIcon className={classes.greenText} />
                  </Typography>
                  <Typography variant="body1" className={classes.marginRight} component="p">
                    $1,939,323
                  </Typography>
                  <Typography variant="subtitle2" className={classes.greenText}>
                    +0.77%
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box marginRight={2}>
          <Card>
            <CardContent>
              <Box display={'flex'} flexDirection="column" justifyContent={'space-between'}>
                <Typography variant="subtitle2" paragraph={true}>
                  CashBack Tokens Mined
                </Typography>
                <Box display={'flex'} alignItems="center">
                  <Typography variant="subtitle2" className={classes.marginRight} component="p">
                    <ArrowUpwardIcon className={classes.greenText} />
                  </Typography>
                  <Typography variant="body1" className={classes.marginRight} component="p">
                    $10,000
                  </Typography>
                  <Typography variant="subtitle2" className={classes.greenText}>
                    +0.34%
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box marginRight={2}>
          <Card>
            <CardContent>
              <Box display={'flex'} flexDirection="column" justifyContent={'space-between'}>
                <Typography variant="subtitle2" paragraph={true}>
                  Number of Crypto Ads Validated
                </Typography>
                <Box display={'flex'} alignItems="center">
                  <Typography variant="subtitle2" className={classes.marginRight} component="p">
                    <ArrowUpwardIcon className={classes.greenText} />
                  </Typography>
                  <Typography variant="body1" className={classes.marginRight} component="p">
                    $10,0000
                  </Typography>
                  <Typography variant="subtitle2" className={classes.greenText}>
                    +0.34%
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box marginRight={2}>
          <Card>
            <CardContent>
              <Box display={'flex'} flexDirection="column" justifyContent={'space-between'}>
                <Typography variant="subtitle2" paragraph={true}>
                  Value per CashBack Tokens
                </Typography>
                <Box display={'flex'} alignItems="center">
                  <Typography variant="subtitle2" className={classes.marginRight} component="p">
                    <ArrowUpwardIcon className={classes.greenText} />
                  </Typography>
                  <Typography variant="body1" className={classes.marginRight} component="p">
                    $1.00
                  </Typography>
                  <Typography variant="subtitle2" className={classes.greenText}>
                    +0.34%
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => {
          setStep(1);
        }}>
        How do I Sell my CashBack Tokens?
      </Button>
    </Box>
  );
};

export default MainStates;

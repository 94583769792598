import React, { useState, useEffect, useImperativeHandle } from 'react';
import { ISize } from '../types';
import { Box, Button, Table, TableBody, TableHead, TableCell, TableRow, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './style';
import { map } from 'lodash';
import Row from './Row';
import { DragContainerWrapper, DragElementWrapper } from 'components/DragDrop';
import { SortEnd } from 'react-sortable-hoc';

interface IProps {
  initialSizes: ISize[];
  nextStep: () => void;
  updateAPI: (sizes: ISize[]) => Promise<any>;
  allowDelete: boolean;
}

const newRow: ISize = {
  title: '',
  description: '',
  enableHalfHalf: true
};

const Size = React.forwardRef(({ initialSizes, nextStep, updateAPI, allowDelete }: IProps, ref) => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const [sizes, setSizes] = useState(initialSizes);

  const [adding, setAdding] = useState(false);

  useEffect(() => {
    setSizes(initialSizes);
  }, [initialSizes]);

  const onAdd = (newSize: ISize) => {
    setSizes([...sizes, newSize]);
    setAdding(false);
  };
  const onEdit = (updatedSize: ISize, index: number) => {
    const sizesCopy = [...sizes];
    sizesCopy[index] = updatedSize;
    setSizes(sizesCopy);
  };
  const onDelete = (index: number) => {
    const sizesCopy = [...sizes];
    sizesCopy.splice(index, 1);
    setSizes(sizesCopy);
  };

  const submitForm = async () => {
    if (sizes.length) {
      await updateAPI(sizes);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  const onDragEnd = (sortResult: SortEnd) => {
    const { oldIndex, newIndex } = sortResult;

    if (oldIndex === newIndex) {
      // No dragging
      return;
    }
    const sizesCopy = [...sizes];
    const value = { ...sizes[oldIndex] };

    if (newIndex < oldIndex) {
      /*
       * All indexes after newIndex incremented along with oldIndex
       * So add at newIndex
       * Remove at oldIndex + 1
       * */
      sizesCopy.splice(newIndex, 0, value);
      sizesCopy.splice(oldIndex + 1, 1);
    } else {
      /*
       * Indexes before newIndex should be decremented
       * So add at newIndex + 1
       * Remove at oldIndex
       * */
      sizesCopy.splice(newIndex + 1, 0, value);
      sizesCopy.splice(oldIndex, 1);
    }

    setSizes(sizesCopy);
  };

  return (
    <DragContainerWrapper useDragHandle={true} onSortEnd={onDragEnd} axis="y">
      <Box display="flex" justifyContent="center" className={classes.pageContainer}>
        <Box className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>Pizza Size</TableCell>
                <TableCell style={{ width: '40%' }}>Description</TableCell>
                <TableCell style={{ width: '20%' }}>Half and half supported?</TableCell>
                <TableCell style={{ width: '20%' }}>
                  <Button variant="contained" color="primary" disableElevation={true} size="small" onClick={() => setAdding(true)}>
                    Add new size
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            {sizes.length > 0 && (
              <TableBody>
                {adding && <Row allowDelete={allowDelete} rowData={newRow} rows={sizes} action="new" onAdd={onAdd} onCancel={() => setAdding(false)} />}
                {map(sizes, (size: ISize, index) => {
                  return (
                    <DragElementWrapper key={`${index}-${Date.now()}`} index={index}>
                      <Row allowDelete={allowDelete} rowData={size} rows={sizes} action="edit" onEdit={(updatedSize) => onEdit(updatedSize, index)} onDelete={() => onDelete(index)} />
                    </DragElementWrapper>
                  );
                })}
              </TableBody>
            )}
            {sizes.length === 0 && (
              <TableBody>
                {adding && <Row allowDelete={allowDelete} rowData={newRow} rows={sizes} action="new" onAdd={onAdd} onCancel={() => setAdding(false)} />}
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography variant="body1"> No Sizes Added</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </Box>
      </Box>
    </DragContainerWrapper>
  );
});

export default Size;

import gql from 'graphql-tag';

export const Coupon = gql`
  fragment Coupon on Coupon {
    bizId
    code
    id
    createdAt
    updatedAt
    name
    description
    discountType
    discountValue
    maxDiscount
    startDate
    endDate
    subtotalThreshold
    taxMethod
    maxUse
    useCnt
    image {
      id
      url
    }
    isPrivate
    orderType
    dayOfWeek
  }
`;


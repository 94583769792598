import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  userDefaultIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer'
  },
  username: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1)
  },
  menuIcon: {
    margin: 0,
    padding: 0
  }
}));

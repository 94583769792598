import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  picker: {
    margin: 0
  },
  icon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.dark
    }
  },
  table: {
    border: 'solid 1px ' + theme.palette.grey[300]
  },
  toggleIcon: {
    border: 'solid 1px black',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  divider: {
    margin: theme.spacing(1, 0),
    height: '2px'
  },
  boldText: {
    fontWeight: 'bolder'
  },
  gradient: {
    background: 'transparent linear-gradient(269deg, #00ABEA 0%, #753CBE 100%) 0% 0% no-repeat padding-box',
    color: 'white'
  }
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0)
  },
  media: {
    height: 200,
    backgroundSize: 'auto'
  },
  defaultImg: {
    transform: 'translateY(50%)'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deleteBtn: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '5',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  cardContainer: {
    padding: theme.spacing(1)
  },
  defaultDishImage: {
    margin: 'auto'
  },
  iconsWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 16,
    left: 8
  },
  hiddenIcon: {
    color: '#fff',
    margin: theme.spacing(0, 0.5)
  },
  hiddenIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    padding: '2px 6px',
    margin: theme.spacing(0, 1),
    background: theme.palette.error.main
  },
  discountIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    padding: '2px 6px',
    margin: theme.spacing(0, 1),
    background: theme.palette.secondary.main
  },
  opaque: {
    opacity: '0.3'
  }
}));

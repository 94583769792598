const validate = (values: any) => {
  const errors: any = {};

  const { minStartPoints, maxRedeemPointsPerUsage, minRedeemPurchase, pricePointRatio } = values;

  if (minStartPoints === '') errors.minStartPoints = 'Required';

  if (minStartPoints !== '' && minStartPoints < 0) errors.minStartPoints = 'Invalid Value';

  if (minStartPoints === '') errors.minStartPoints = 'Required';

  if (maxRedeemPointsPerUsage !== '' && maxRedeemPointsPerUsage < 0) errors.maxRedeemPointsPerUsage = 'Invalid Value';

  if (minStartPoints === '') errors.minStartPoints = 'Required';

  if (minRedeemPurchase !== '' && minRedeemPurchase < 0) errors.minRedeemPurchase = 'Invalid Value';

  if (pricePointRatio === '') errors.pricePointRatio = 'Required';

  if (pricePointRatio !== '' && pricePointRatio < 0) errors.pricePointRatio = 'Invalid Value';

  return errors;
};

export default validate;

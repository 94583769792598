import React from 'react';
import { Grid, Typography, Box, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import { useStyles } from './style';
import { Date } from 'util/date';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { IBizHour } from '../../types';
import { combineDaysOfWeek } from 'util/convertBusinessHours';
import * as _ from 'lodash';
interface IProps {
  service: IBizHour;
  onEdit?: () => void;
  onDelete?: () => void;
}

interface combinedHours {
  openDay: number[];
  closeDay: number[];
  openHour: number;
  openMinute: number;
  closeHour: number;
  closeMinute: number;
}

const BusinessHoursTable = ({ service, onEdit, onDelete }: IProps) => {
  const classes = useStyles();
  const dayConverter: any = {
    1: 'Mon',
    2: 'Tues',
    3: 'Wed',
    4: 'Thurs',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  const serviceTypes: any = {
    RESTAURANT_PICK_UP: 'Pick up',
    RESTAURANT_DINING: 'Dining',
    RESTAURANT_DELIVERY: 'Delivery'
  };

  /* combine days with same timings */
  const combinedBusinessHours: combinedHours[] = combineDaysOfWeek(service.hours);

  return (
    <Box className={classes.table}>
      <Box padding={1} className={classes.tableHeader}>
        {onEdit || onDelete ? (
          <Grid container={true} alignItems="center">
            <Grid item={true} xs={8}>
              <Typography variant="h6" className={classes.headerTitle}>
                {service.serviceName}
              </Typography>
              {/* <Typography variant="caption"> {serviceTypes[service.serviceType]}</Typography> */}
            </Grid>
            <Grid item={true} xs={4}>
              <Box textAlign="right">
                {onEdit && <EditIcon className={`${classes.icons} ${classes.iconEdit}`} onClick={onEdit} />}
                {onDelete && <DeleteIcon className={`${classes.icons} ${classes.iconDelete}`} onClick={onDelete} />}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6">{service.serviceName}</Typography>
              {/* <Typography variant="caption">{serviceTypes[service.serviceType]}</Typography> */}
            </Grid>
          </Grid>
        )}
      </Box>
      {service.hours.length === 0 ? (
        <Typography variant="body1" color="textSecondary" className={classes.hoursNotFound}>
          Business Hours not set for this service.
        </Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>Days</TableCell>
              <TableCell className={classes.tableCell} align="right">
                Timings
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(combinedBusinessHours, (hours: combinedHours, index: number) => {
              return (
                <TableRow className={classes.tableRow} key={index}>
                  {hours.openDay.length === 1 && <TableCell className={classes.tableCell}>{dayConverter[hours.openDay[0]]}</TableCell>}
                  {hours.openDay.length > 1 && <TableCell className={classes.tableCell}>{dayConverter[hours.openDay[0]] + ' - ' + dayConverter[hours.openDay[hours.openDay.length - 1]]}</TableCell>}

                  <TableCell className={classes.tableCell} align="right">
                    {Date.convertDateToMeridienFormat(hours.openHour, Date.lpadNumber(hours.openMinute, 2)) +
                      ' - ' +
                      Date.convertDateToMeridienFormat(hours.closeHour, Date.lpadNumber(hours.closeMinute, 2))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default BusinessHoursTable;

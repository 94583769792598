import React from 'react';
import { Box, Checkbox } from '@material-ui/core';

interface IProps {
  imgSrc?: string;
  custom?: boolean;
  children?: React.ReactNode;
  checked: boolean;
  imgHeight?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PaymentOption = ({ imgSrc, custom, children, checked, onChange, imgHeight }: IProps) => {
  return (
    <Box margin={2} display="flex" alignItems="center">
      <Checkbox color="primary" checked={checked} onChange={onChange} />
      {custom ? children : <img src={imgSrc} height={imgHeight ? imgHeight : '35px'} alt="" />}
    </Box>
  );
};

export default PaymentOption;

import gql from 'graphql-tag';
import { Coupon } from 'graphql/fragments/coupon.fragments';

export const createCouponMutation = gql`
  mutation createCoupon($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      ...Coupon
    }
  }
  ${Coupon}
`;

export const deleteCouponMutation = gql`
  mutation deleteCoupon($input: DeleteCouponInput!) {
    deleteCoupon(input: $input)
  }
`;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem } from '@material-ui/core';
import { map, range } from 'lodash';
import { getIn } from 'formik';

interface IProps {
  value: number | string;
  name: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  label: string;
  errors: any;
  rangeStart?: number;
  rangeEnd?: number;
}

const SelectOptions: React.FC<IProps> = ({ value, handleChange, label, errors, name, rangeStart = null, rangeEnd = null }) => {
  const [start, setStart] = useState(1);

  const [end, setEnd] = useState(2);

  useEffect(() => {
    if (rangeStart) {
      setStart(rangeStart);
    }

    if (rangeEnd) {
      setEnd(rangeEnd);
    }
  }, [rangeStart, rangeEnd]);

  return (
    <Box display="flex" marginBottom={1} alignItems="center">
      <Typography variant="body1">{label}</Typography>
      <Select name={name} value={value} onChange={handleChange} variant="outlined" error={!!getIn(errors, name)}>
        {map(range(start, end), (item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default SelectOptions;

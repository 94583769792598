import { gql } from 'apollo-boost';

export default gql`
  query getGroceryBizEmployees($input:GetGroceryBizEmployeesInput!) {
    getGroceryBizEmployees(input: $input) {
      uid
      displayName
      userName
    }
  }
`;

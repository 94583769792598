import * as Yup from 'yup';

export default Yup.object().shape({
  densityMultipliers: Yup.array()
    .of(
      Yup.object().shape({
        density: Yup.string().required('Density value required'),
        multiplier: Yup.number()
          .required('Required')
          .min(0, 'Multiplier should be positive.')
      })
    )
    .min(1)
});

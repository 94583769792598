import React, { ChangeEvent } from 'react';
import { FieldArray, getIn } from 'formik';
import { Box, TextField, Fab, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './style';
import { CurrencyInputField } from '@lokobee/lokobee-ui';

interface IItem {
  title: string;
  price: string;
}

interface IProps {
  title: string;
  handleChange: (e: ChangeEvent<any>) => void;
  items: IItem[];
  errors: any;
  touched: any;
}

const DishOptionDetails: React.FC<IProps> = ({ title, handleChange, items, errors, touched }) => {
  const classes = useStyles();

  return (
    <FieldArray name="items">
      {(arrayHelper) => {
        return (
          <Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <TextField
                className={classes.formInput}
                name="title"
                value={title}
                onChange={handleChange}
                variant="outlined"
                label="Extra choice name"
                error={!!getIn(touched, 'title') && !!getIn(errors, 'title')}
                placeholder="Ex: Cheese, Butter etc"
              />
            </Box>
            <Box>
              {items &&
                items.length &&
                items.map(({ title: itemTitle, price }, index) => (
                  <Box display="flex" alignItems="center" marginBottom={1} key={index}>
                    <Box>
                      <TextField
                        name={`items[${index}].title`}
                        value={itemTitle}
                        onChange={handleChange}
                        variant="outlined"
                        label="Item name"
                        error={!!getIn(touched, `items[${index}].title`) && !!getIn(errors, `items[${index}].title`)}
                      />
                    </Box>
                    <Box textAlign="right" marginX={1}>
                      <CurrencyInputField
                        name={`items[${index}].price`}
                        value={price}
                        onChange={handleChange}
                        variant="outlined"
                        label="Price"
                        error={!!getIn(touched, `items[${index}].price`) && !!getIn(errors, `items[${index}].price`)}
                      />
                    </Box>
                    {items.length > 1 && (
                      <Fab className={classes.deleteIcon} color="primary" size="small" onClick={() => arrayHelper.remove(index)}>
                        <DeleteIcon />
                      </Fab>
                    )}
                  </Box>
                ))}
              <Box component="span" alignSelf="center">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    arrayHelper.push({
                      title: '',
                      price: ''
                    })
                  }>
                  Add item
                </Button>
              </Box>
            </Box>
          </Box>
        );
      }}
    </FieldArray>
  );
};

export default DishOptionDetails;

import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Tabs, Tab, withStyles, useTheme, Typography } from '@material-ui/core';
import { useStyle } from './style';
import { useActiveRestaurant } from 'hooks/restaurant';
import TextHistory from './TextHistory';
import TextScheduler from './TextScheduler';
import RequestEnable from '../../../../components/RequestEnable';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import AITextMarketing from './AITextMarketing';
import { useRequestTextMarketing } from 'graphql/hooks/textMarketing.hooks';
import { useSnackbar } from 'notistack';
import UploadUsers from './UploadUsers';
import BroadcastHistory from './BroadcastHistory';
import { TextBroadcastHistory } from 'generated/custom';

const TextMarketing = () => {
  const classes = useStyle();
  const theme = useTheme();

  const { restaurantId } = useActiveRestaurant();

  const snackbar = useSnackbar();
  const { data } = useGetRestaurantById(restaurantId || '', 'STATUS');
  const [activeTab, setActiveTab] = useState(0);
  const [selectedBroadcast, setSelectedBroadcast] = useState<TextBroadcastHistory | null>(null);
  const [isTextMarketingEnabledState, setIsTextMarketingEnabledState] = useState(false);
  const [isBroadcastExist, setIsBroadcastExist] = useState(true);
  const [isTextMarketingRequestedState, setIsTextMarketingRequestedState] = useState(false);

  const _setActiveTab = (_event: React.ChangeEvent<{}>, index: number) => {
    setActiveTab(index);
  };
  const { requestTextMarketing } = useRequestTextMarketing();
  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { isTextMarketingEnabled, isTextMarketingRequested } = data.ownerGetRestaurantById;
      setIsTextMarketingEnabledState(isTextMarketingEnabled);
      setIsTextMarketingRequestedState(isTextMarketingRequested);
    }
  }, [data]);
  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const requestEnableTextMarketing = async () => {
    const response = await requestTextMarketing({
      variables: {
        input: {
          bizId: restaurantId
        }
      }
    });
    if (!response.errors) {
      snackbar.enqueueSnackbar('Your request for enabling text marketing has been submitted!', {
        variant: 'success'
      });
    } else {
      snackbar.enqueueSnackbar('Oops!, something went wrong!', {
        variant: 'error'
      });
    }
  };
  return (
    <Box bgcolor="white" padding={1}>
      <Grid container={true} alignItems="center">
        <Grid item={true} xs={4}>
          <Typography variant="h5">Text Marketing</Typography>
        </Grid>
        <Grid item={true} xs={8}></Grid>
      </Grid>
      <Divider className={classes.divider} />
      {isTextMarketingEnabledState ? (
        <Box>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <StyledTabs value={activeTab} onChange={_setActiveTab}>
                <Tab label="Text Scheduler" id="0" />
                <Tab label="Broadcast History" id="1" />
                <Tab label="Text History" id="2" />
                <Tab label="Upload Users" id="3" />
                {/* <Tab label="AI Text Marketing" id="2" /> */}
              </StyledTabs>
            </Grid>
          </Grid>
          <Box display="flex" alignItems="center" marginTop={2}>
            {activeTab === 0 && <TextScheduler restaurantId={restaurantId} selectedBroadcast={selectedBroadcast} isBroadcastExist={isBroadcastExist} setIsBroadcastExist={setIsBroadcastExist} />}
            {activeTab === 1 && <BroadcastHistory restaurantId={restaurantId} setActiveTab={setActiveTab} setSelectedBroadcast={setSelectedBroadcast} isBroadcastExist={isBroadcastExist} />}
            {activeTab === 2 && <TextHistory restaurantId={restaurantId} />}
            {activeTab === 3 && <UploadUsers restaurantId={restaurantId} />}
            {/* {activeTab === 4 && <AITextMarketing />} */}
          </Box>
        </Box>
      ) : (
        <RequestEnable isRequested={isTextMarketingRequestedState} requestEnableFeature={requestEnableTextMarketing} />
      )}
    </Box>
  );
};

export default TextMarketing;

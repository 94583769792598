import React, { useContext } from 'react';
import { FirebaseContext } from 'fbase';
import { useSnackbar } from 'notistack';
import { useFormik, getIn } from 'formik';
import validationSchema from './validations';
import { Button, Box, TextField, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { useStore } from 'store';

const ForgotPassword = () => {
  const classes = useStyles();

  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const snackbar = useSnackbar();

  const { dispatch } = useStore();

  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };

  const stopLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    initialErrors: {
      email: ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ email }, { setSubmitting, resetForm }) => {
      startLoader();
      setSubmitting(true);

      try {
        await auth().sendPasswordResetEmail(email);

        resetForm();

        snackbar.enqueueSnackbar('Password reset send to your email', {
          variant: 'success'
        });

        setSubmitting(false);
        stopLoader();
      } catch (e) {
        stopLoader();
        console.log(e);
        if (e.code === 'auth/user-not-found') {
          snackbar.enqueueSnackbar('An account with given email does not exist.', {
            variant: 'error'
          });
        } else {
          snackbar.enqueueSnackbar('Email was not sent', {
            variant: 'error'
          });
        }

        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = formik;

  return (
    <Box className={classes.root}>
      <Typography variant="h5">Reset password</Typography>

      <Box className={classes.wrapper}>
        <form className={classes.formWrapper} onSubmit={handleSubmit}>
          <Typography variant="body1" paragraph={true}>
            Enter your email to reset your password
          </Typography>
          <TextField
            name="email"
            type="email"
            value={formik.values.email}
            placeholder="Enter your email"
            onChange={formik.handleChange}
            error={getIn(formik.touched, 'email') && getIn(formik.errors, 'email')}
            autoComplete="off"
            onBlur={formik.handleBlur}
            fullWidth={true}
          />
          <Box paddingY={1}>
            <Button type="submit" variant="contained" color="primary" disabled={!formik.isValid}>
              Reset
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';
import Gallery from './Gallery';
import { useStore } from 'store';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { IImage } from '../types';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import { useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import * as _ from 'lodash';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const { data } = useGetRestaurantById(restaurant, 'GALLERY');

  const [initialValues, setInitialValues] = useState<IImage[]>([]);

  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();

  const { updateRestaurant } = useUpdateRestaurantMutation('GALLERY');

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { images } = data.ownerGetRestaurantById;
      if (images) {
        setInitialValues(
          _.map(images, (img: any) => {
            if (img.md) {
              return img.md;
            }
          })
        );
      }
    }
  }, [data]);

  const getUploadId = async (filename: string, imageData: string) => {
    try {
      const { data: uploadImageResponse } = await createImageUploadUrl({
        variables: {
          input: {
            usage: 'RESTAURANT_IMG',
            fileName: filename
          }
        }
      });

      if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
        const { id, url } = uploadImageResponse.createImageUploadUrl;
        await uploadImage(url, imageData);

        return id;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const _updateRestaurant = async (ids: string[]) => {
    return await updateRestaurant({
      variables: {
        input: {
          imageIds: ids
        }
      }
    });
  };

  if (data && data.ownerGetRestaurantById) {
    return <Gallery ref={ref} initialValues={initialValues} updateRestaurant={_updateRestaurant} nextStep={nextStep} getUploadId={getUploadId} />;
  }

  return null;
});

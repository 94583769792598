import React, { useState } from 'react';
import { ITable } from '../../types';
import { Button, Box, Grid } from '@material-ui/core';
import { useStyles } from './style';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import { Dialog } from '@lokobee/lokobee-ui';
import GetAppIcon from '@material-ui/icons/GetApp';
interface IProps {
  table: ITable;
}

export const TableCard = ({ table }: IProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button className={classes.btns} size="small" variant="contained" color="secondary" startIcon={<ArtTrackIcon />} onClick={() => setOpen(true)}>
        View card
      </Button>
      <Dialog open={open} setOpen={setOpen} dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: true }}>
        <Dialog.DialogContent>
          <Box className={classes.cardContainer} width="100%" height={table.cardURL ? 'auto' : '500px'}>
            <img src={table.cardURL} height="100%" width="100%" alt="" />
          </Box>
        </Dialog.DialogContent>

        <Dialog.DialogActions showBtns={false}>
          <Grid container={true} className={classes.modalBtns}>
            <a href={table.cardURL} download={'Lokobee_' + table.tableName + '.png'}>
              <Button variant="contained" color="primary" disableElevation={true} startIcon={<GetAppIcon />}>
                Download
              </Button>
            </a>
            <Button variant="outlined" color="default" onClick={() => setOpen(false)}>
              Close
            </Button>
          </Grid>
        </Dialog.DialogActions>
      </Dialog>
    </>
  );
};

export default TableCard;

import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import { useFormik, getIn } from 'formik';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useSnackbar } from 'notistack';
import { FirebaseContext } from 'fbase';
import validate from './validations';
import { useStyles } from './style';
import { useStore } from 'store';
import { isEmpty } from 'lodash';

interface IProps {
  initialphoneNumber: string;
  setVerificationId: React.Dispatch<React.SetStateAction<string>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const DisplayPhoneNumber = ({ initialphoneNumber, setVerificationId, activeStep, setActiveStep }: IProps) => {
  const classes = useStyles();

  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const recapchaEl = useRef(null);

  const snackbar = useSnackbar();

  const [recaptchVerifier, setRepactchVerifier] = useState<any | null>(null);

  const [isRecaptchaResolved, setRecaptchaResolved] = useState<boolean>(false);

  const { dispatch } = useStore();

  const [initialValue, setInitialValue] = useState('');

  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };

  const stopLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };

  useEffect(() => {
    if (initialphoneNumber) {
      setInitialValue(initialphoneNumber);
    }
  }, [initialphoneNumber]);

  const createRecaptcha = useCallback(() => {
    setRecaptchaResolved(false);

    const verifier = new auth.RecaptchaVerifier(recapchaEl.current, {
      size: 'normal',
      callback: (response: any) => {
        if (response) {
          setRecaptchaResolved(true);
        }
      },
      'expired-callback': () => {
        setRecaptchaResolved(false);
      }
    });

    verifier.render().catch((e) => snackbar.enqueueSnackbar(e.message, { variant: 'error' }));

    setRepactchVerifier(verifier);
  }, [auth.RecaptchaVerifier, snackbar]);

  const formik = useFormik({
    initialValues: {
      phone: initialValue
    },
    validate,
    onSubmit: async (_values, actions) => {
      actions.setSubmitting(true);

      try {
        if (recaptchVerifier && isRecaptchaResolved) {
          startLoader();

          // Send OTP for phone verification
          const phoneProvider = new auth.PhoneAuthProvider();

          const verificationId = await phoneProvider.verifyPhoneNumber(_values.phone, recaptchVerifier);

          setVerificationId(verificationId);

          stopLoader();
          setActiveStep(2);
        } else {
          snackbar.enqueueSnackbar('Captcha not verified.', { variant: 'error' });
          console.log('RECATCH REFERENCE NOT AVAILABLE');
        }
      } catch (e) {
        if (e.code === 'auth/invalid-phone-number') {
          snackbar.enqueueSnackbar('Invalid phone number.', { variant: 'error' });
        } else {
          snackbar.enqueueSnackbar(e.message, { variant: 'error' });
        }

        recaptchVerifier?.clear();

        createRecaptcha();
        stopLoader();
      }
    },
    enableReinitialize: true
  });

  useEffect(() => {
    createRecaptcha();
  }, [createRecaptcha]);

  const { setFieldTouched, touched, values, errors, submitForm } = formik;

  return (
    <Box padding={1} width="50%">
      <PhoneInput
        className={classes.phoneInput}
        country="US"
        value={values.phone}
        onChange={(val: string) => formik.setValues({ ...values, phone: val })}
        name="phone"
        label="Cell Phone"
        onBlur={() => setFieldTouched('phone', true)}
        error={!!getIn(touched, 'phone') && !!getIn(errors, 'phone')}
      />

      <div ref={recapchaEl} />
      <Button color="primary" type="submit" size="large" variant="contained" onClick={submitForm} disabled={!isRecaptchaResolved || !isEmpty(errors) || isEmpty(touched)}>
        Update
      </Button>
    </Box>
  );
};

export default DisplayPhoneNumber;

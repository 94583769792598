import { Box, Button, Dialog, DialogContent, Divider, Typography } from '@material-ui/core';
import { useStyle } from './style';
import React, { useState } from 'react';
import MainStates from './MainStates';
import SellCC from './SellCC';

const Wallet = () => {
  const classes = useStyle();
  const [step, setStep] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(true);
  return (
    <>
      <Box bgcolor="white" padding={1}>
        <Box display={'flex'} justifyContent="space-between" width="100%">
          <Typography variant="h5">MaxCashback Wallet</Typography>
          {step === 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setStep(0);
              }}>
              I'll Kepp Holding
            </Button>
          )}
        </Box>
        <Divider className={classes.divider} />
      </Box>
      {step === 1 ? <SellCC /> : <MainStates setStep={setStep} />}
      <Dialog open={openDialog} fullWidth={false} maxWidth="md">
        <Box className={classes.walletHead} display="flex" flexDirection={'column'} flex={1} padding={2}>
          <Typography variant="h5">
            Welcome to <br />
            <strong>CashBack Promotions</strong>
          </Typography>
        </Box>
        <DialogContent>
          <Box paddingY={2}>
            <Box marginBottom={2}>
              <Typography variant="subtitle1">
                We've partnered with <a href="#">MaxCashback</a> to run CashBack Promotions on <strong>Lokobee!</strong>
                <br />
                You can earn cryptocurrency called CashBack for simply redeeming deals or ordering food on <strong>Lokobee!</strong>
                <br />
                <br />
                You can view your CashBack Tokens from this page
              </Typography>
            </Box>
            <Box>
              <Button onClick={() => setOpenDialog(false)} variant="contained" color="primary" size="small">
                Got it
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Wallet;

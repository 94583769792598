import { gql } from 'apollo-boost';

export default gql`
  query getDishById($id: ID!) {
    getDishById(id: $id) {
      id
      title
      imageUrl
      price
      description
      specialDesc
      restaurantId
      dishSizes {
        title
        price
      }
      dishExtras {
        mutuallyExclusive
        price
        title
      }
    }
  }
`;

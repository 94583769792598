import React from 'react';
import { useStyles } from './style';
import { Grid, TextField } from '@material-ui/core';
import { getIn } from 'formik';

/*
 * @params {formik object} - formik - formik form object returned from useFormik to handle form data
 * @params {string} - errorMsg - error Message to display in case of required
 * @params {string} - inputLabel -  label for Text Field
 * @params {boolean} - required - if input is required or not
 * other props related to input fields like name,type,value etc..
 */

const InfoInputField = ({ errorMsg, inputLabel, children, formik, required, ...props }: any) => {
  const classes = useStyles();

  const field: string = props.name;

  const isError = formik && !!getIn(formik.touched, field) && !!getIn(formik.errors, field);

  return (
    <Grid container={true} className={classes.inputContainer}>
      <Grid item={true} xs={12}>
        {children ? (
          children
        ) : (
          <TextField
            className={classes.inputField}
            label={inputLabel}
            error={isError}
            helperText={isError ? getIn(formik.errors, field) : ''}
            value={formik.values[field]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // blur handler required to get formik.touched
            {...props}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default InfoInputField;

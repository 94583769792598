import React, { useEffect, useState } from 'react';
import { useGetPizzaParts, useUpdatePizzaDensityMultiplier } from 'graphql/hooks/pizza.hooks';
import { useStore } from 'store';
import { IDensityMultiplier } from '../types';
import { map } from 'lodash';
import DensityMultiplier from './DensityMultiplier';
import { getDefaultDensityMultipliers, parseDensityMultipliers } from '../utils';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const data = useGetPizzaParts(restaurant);

  const { updatePizzaDensityMultiplier } = useUpdatePizzaDensityMultiplier();

  const [initialValues, setInitialValues] = useState<IDensityMultiplier[]>([]);

  useEffect(() => {
    if (data) {
      if (data.getPizzaParts) {
        const { densityMultipliers } = data.getPizzaParts;

        if (densityMultipliers && densityMultipliers.length) {
          setInitialValues(parseDensityMultipliers(densityMultipliers));
        } else {
          setInitialValues(getDefaultDensityMultipliers());
        }
      } else {
        if (!data.getPizzaParts) {
          setInitialValues(getDefaultDensityMultipliers());
        }
      }
    }
  }, [data]);

  const updateAPI = async (densities: IDensityMultiplier[]) => {
    return await updatePizzaDensityMultiplier({
      variables: {
        input: {
          bizId: restaurant,
          densityMultipliers: map(densities, ({ density, multiplier }) => {
            return {
              density,
              multiplier: multiplier
            };
          })
        }
      }
    });
  };

  if (data) {
    return <DensityMultiplier ref={ref} initialDensityMultipliers={initialValues} nextStep={nextStep} updateAPI={updateAPI} />;
  }

  return null;
});

import { makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1)
    }
  },
  defaultItemBox: {
    backgroundColor: grey[200]
  },
  imageContainer: {
    borderRadius: 5,
    boxShadow: theme.shadows[3],
    maxHeight: '150px',

    [theme.breakpoints.down('xs')]: {
      maxHeight: '250px',
      height: '250px'
    }
  },
  decriptionContainer: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0)
    }
  }
}));

import React from 'react';
import { useStyles } from '../style';
import { IPizzaToppingGroup } from 'pages/Home/RestaurantDashboard/PizzaConfiguration/types';
import { IPizzaToppingGroup as IPizzaDishToppingGroup } from '../types';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Grid, Checkbox } from '@material-ui/core';
import { map, filter, reduce } from 'lodash';

interface IProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  field: string;
  groups: IPizzaToppingGroup[];
  pizzaGroups: IPizzaDishToppingGroup[];
}

const PizzaGroupTable = ({ groups, pizzaGroups, setFieldValue, field }: IProps) => {
  const classes = useStyles();

  const handleAllow = (groupId: string, itemId: string, checked: boolean, alreadyPresent: boolean) => {
    let groupFound = false;

    const updatedGroups = map(pizzaGroups, (group) => {
      if (group.groupId === groupId) {
        groupFound = true;
        if (alreadyPresent) {
          return {
            ...group,
            items: filter(group.items, ({ partId }) => partId !== itemId)
          };
        } else {
          return {
            ...group,
            items: [...group.items, { partId: itemId, preset: false }]
          };
        }
      } else {
        return group;
      }
    });
    if (groupFound) {
      setFieldValue(field, updatedGroups);
    } else {
      setFieldValue(field, [...pizzaGroups, { groupId, items: [{ partId: itemId, preset: false }], maxSelect: 0 }]);
    }
  };

  const handleStandard = (groupId: string, itemId: string, checked: boolean, alreadyPresent: boolean) => {
    let groupFound = false;

    const updatedGroups = map(pizzaGroups, (pizzaGroup) => {
      if (pizzaGroup.groupId === groupId) {
        groupFound = true;
        if (alreadyPresent) {
          const updatedItems = map(pizzaGroup.items, (item) => {
            if (item.partId === itemId) {
              return {
                ...item,
                preset: checked
              };
            } else {
              return item;
            }
          });
          return {
            ...pizzaGroup,
            items: updatedItems
          };
        } else {
          return {
            ...pizzaGroup,
            items: [...pizzaGroup.items, { partId: itemId, preset: checked }]
          };
        }
      } else {
        return pizzaGroup;
      }
    });

    if (groupFound) {
      setFieldValue(field, updatedGroups);
    } else {
      setFieldValue(field, [...pizzaGroups, { groupId, items: [{ partId: itemId, preset: true }], maxSelect: 0 }]);
    }
  };

  const allowAll = (group: IPizzaToppingGroup, checked: boolean) => {
    let groupFound = false;
    const { id, items } = group;

    const updatedGroups = map(pizzaGroups, (pizzaGroup) => {
      if (pizzaGroup.groupId === id) {
        groupFound = true;

        const selectedItems = reduce(
          pizzaGroup.items,
          (result: string[], { preset, partId }) => {
            if (preset) {
              result.push(partId);
            }
            return result;
          },
          []
        );

        return {
          ...pizzaGroup,
          items: checked
            ? map(items, ({ id }) => {
                return {
                  partId: id,
                  preset: id ? selectedItems.indexOf(id) > -1 : false
                };
              })
            : []
        };
      } else {
        return pizzaGroup;
      }
    });
    if (groupFound) {
      setFieldValue(field, updatedGroups);
    } else {
      setFieldValue(field, [
        ...pizzaGroups,
        {
          groupId: id,
          items: checked
            ? map(items, ({ id }) => {
                return {
                  partId: id,
                  preset: false
                };
              })
            : [],
          maxSelect: 0
        }
      ]);
    }
  };

  return (
    <Box paddingY={4}>
      <Grid container={true}>
        {map(groups, (group) => {
          const { id: groupId, title: groupTitle, items: groupItems } = group;

          const pizzaGroup = filter(pizzaGroups, ({ groupId: pizzaGroupId }) => pizzaGroupId === groupId);

          const pizzaItems = pizzaGroup.length ? pizzaGroup[0].items : [];

          return (
            <Grid key={groupId} item={true} xs={12} sm={6} md={4}>
              <Box padding={0} margin={1} boxShadow={2}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>{groupTitle}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="center">Standard</TableCell>
                      <TableCell align="center">
                        <Box display="flex">
                          Allowed
                          <Checkbox checked={pizzaItems.length === groupItems.length} className={classes.tableCheckBox} color="primary" onChange={(e: any, checked) => allowAll(group, checked)} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(groupItems, ({ id: itemId, title: itemTitle }) => {
                      const pizzaItem = filter(pizzaItems, ({ partId }) => partId === itemId);

                      return (
                        <TableRow key={itemId}>
                          <TableCell>{itemTitle}</TableCell>
                          <TableCell align="center">
                            <Checkbox
                              className={classes.tableCheckBox}
                              color="primary"
                              checked={pizzaItem.length ? pizzaItem[0].preset : false}
                              onChange={(e: any, checked) => handleStandard(groupId ? groupId : '', itemId ? itemId : '', checked, !!pizzaItem.length)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Checkbox
                              className={classes.tableCheckBox}
                              color="primary"
                              checked={!!pizzaItem.length}
                              onChange={(e: any, checked) => handleAllow(groupId ? groupId : '', itemId ? itemId : '', checked, !!pizzaItem.length)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PizzaGroupTable;

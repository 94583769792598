import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    alignItems: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0)
    }
  },
  inputLabel: {
    fontWeight: 'bolder'
  },
  inputField: {
    width: '100%'
  }
}));

import React from 'react';
import { useGetPizzaParts } from 'graphql/hooks/pizza.hooks';
import { parseSizes, parseCrusts, parseDensityMultipliers, parseGroups } from 'pages/Home/RestaurantDashboard/PizzaConfiguration/utils';
import { useStore } from 'store';
import { Typography } from '@material-ui/core';
import { ISize, ICrust, IDensityMultiplier, IPizzaToppingGroup } from 'pages/Home/RestaurantDashboard/PizzaConfiguration/types';
import PizzaDishAddOrEditPage from './PizzaDish';

interface IProps {
  dish: any;
}

const PizzaDish = ({ dish }: IProps) => {
  const {
    state: { restaurant }
  } = useStore();

  const data = useGetPizzaParts(restaurant);

  if (data) {
    if (data.getPizzaParts) {
      const { sizes, crusts, cheeses, sauces, densityMultipliers, toppings } = data.getPizzaParts;

      if (
        !(sizes && sizes.length) ||
        !(crusts && crusts.length) ||
        !(cheeses && cheeses.length) ||
        !(sauces && sauces.length) ||
        !(densityMultipliers && densityMultipliers.length) ||
        !(toppings && toppings.length)
      ) {
        return <Typography>Please complete pizza configuration before creating pizza dish</Typography>;
      } else {
        const parsedSizes: ISize[] = parseSizes(sizes);

        const parsedCrusts: ICrust[] = parseCrusts(crusts);

        const parsedDensityMultipliers: IDensityMultiplier[] = parseDensityMultipliers(densityMultipliers);

        const parsedSauces: IPizzaToppingGroup[] = parseGroups(sauces, parsedSizes, parsedDensityMultipliers);

        const parsedCheeses: IPizzaToppingGroup[] = parseGroups(cheeses, parsedSizes, parsedDensityMultipliers);

        const parsedToppings: IPizzaToppingGroup[] = parseGroups(toppings, parsedSizes, parsedDensityMultipliers);

        return <PizzaDishAddOrEditPage dish={dish} sizes={parsedSizes} crusts={parsedCrusts} sauces={parsedSauces} cheeses={parsedCheeses} toppings={parsedToppings} />;
      }
    } else {
      return <Typography>Please complete pizza configuration before creating pizza dish</Typography>;
    }
  }

  return null;
};

export default PizzaDish;

import React from 'react';
import Routes from './routes';
import useGroceryStore from 'hooks/grocery/useGroceryStore';
import { Box } from '@material-ui/core';
import { useStyles } from './style';

const Dashboard: React.FC = () => {
  const { isValid } = useGroceryStore();

  const classes = useStyles();

  if (isValid) {
    return (
      <Box className={classes.gridContainer} paddingX={2} margin="auto">
        <Routes />
      </Box>
    );
  }

  return null;
};

export default Dashboard;

import React from 'react';
import { withStyles, useTheme, Tabs } from '@material-ui/core';

const StyledTabs = (props: any) => {
  const theme = useTheme();

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  return <StyledTabs {...props}>{props.children}</StyledTabs>;
};

export default StyledTabs;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    flexGrow: 1,
    color: '#000',
    paddingLeft: theme.spacing(1),
    backgroundColor: '#fff'
  },
  logo: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    margin: theme.spacing(0.5, 3),
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  colorSpan: {
    margin: theme.spacing(0, 0.5),
    color: theme.palette.primary.main
  },
  navigationWrapper: {
    '& > a': {
      textDecoration: 'none',
      marginLeft: theme.spacing(1)
    }
  },
  activeLink: {
    '& > button': {
      color: theme.palette.primary.main
    }
  }
}));

import React, { useState } from 'react';
import { IPizzaToppingItem } from '../../types';
import { Button, Table, TableBody, TableHead, TableCell, TableRow, Typography } from '@material-ui/core';
import { useStyles } from '../style';
import Row from './Row';
import { DragContainerWrapper, DragElementWrapper } from 'components/DragDrop';
import { map } from 'lodash';
import { SortEnd } from 'react-sortable-hoc';

interface IProps {
  groupTitle: string;
  items: IPizzaToppingItem[];
  onChange: (updatedItems: IPizzaToppingItem[]) => void;
  allowDelete: boolean;
}

const ItemsTable = ({ groupTitle, items, onChange, allowDelete }: IProps) => {
  const classes = useStyles();

  const newRow: IPizzaToppingItem = {
    title: '',
    description: '',
    prices: []
  };

  const [adding, setAdding] = useState(false);

  const onAdd = (newItem: IPizzaToppingItem) => {
    onChange([...items, newItem]);
    setAdding(false);
  };
  const onEdit = (updatedItem: IPizzaToppingItem, index: number) => {
    const itemsCopy = [...items];
    itemsCopy[index] = updatedItem;
    onChange(itemsCopy);
  };
  const onDelete = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy.splice(index, 1);
    onChange(itemsCopy);
  };

  const onDragEnd = (sortResult: SortEnd) => {
    const { oldIndex, newIndex } = sortResult;

    if (oldIndex === newIndex) {
      // No dragging
      return;
    }
    const itemsCopy = [...items];
    const value = { ...items[oldIndex] };

    if (newIndex < oldIndex) {
      /*
       * All indexes after newIndex incremented along with oldIndex
       * So add at newIndex
       * Remove at oldIndex + 1
       * */
      itemsCopy.splice(newIndex, 0, value);
      itemsCopy.splice(oldIndex + 1, 1);
    } else {
      /*
       * Indexes before newIndex should be decremented
       * So add at newIndex + 1
       * Remove at oldIndex
       * */
      itemsCopy.splice(newIndex + 1, 0, value);
      itemsCopy.splice(oldIndex, 1);
    }

    onChange(itemsCopy);
  };

  return (
    <DragContainerWrapper useDragHandle={true} onSortEnd={onDragEnd} axis="y">
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{groupTitle}</TableCell>
            <TableCell align="center" style={{ width: '50%' }}>
              <Button variant="contained" color="primary" disableElevation={true} size="small" onClick={() => setAdding(true)}>
                Add new item
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        {items.length > 0 && (
          <TableBody>
            {adding && <Row allowDelete={allowDelete} rowData={newRow} rows={items} action="new" onAdd={onAdd} onCancel={() => setAdding(false)} />}
            {map(items, (item: IPizzaToppingItem, index) => {
              return (
                <DragElementWrapper key={`${index}-${Date.now()}`} index={index}>
                  <Row allowDelete={allowDelete} rowData={item} rows={items} action="edit" onEdit={(updatedSize) => onEdit(updatedSize, index)} onDelete={() => onDelete(index)} />
                </DragElementWrapper>
              );
            })}
          </TableBody>
        )}

        {items.length === 0 && (
          <TableBody>
            {adding && <Row allowDelete={allowDelete} rowData={newRow} rows={items} action="new" onAdd={onAdd} onCancel={() => setAdding(false)} />}
            <TableRow>
              <TableCell align="center" colSpan={4}>
                <Typography variant="body1"> No Items Added</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </DragContainerWrapper>
  );
};

export default ItemsTable;

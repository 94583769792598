import { gql } from 'apollo-boost';

export default gql`
  query getRestaurantTextBroadcasts($input: getRestaurantTextBroadcastsInput!) {
    getRestaurantTextBroadcasts(input: $input) {
      broadcasts {
        bizId
        id
        bizPhoneNumber
        dayOfWeek
        timeHour
        timeMin
        createdAt
        textBody
        lastBroadcast
        nextBroadcast
        status
      }
      pageCursor
    }
  }
`;

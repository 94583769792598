import React, { useState } from 'react';
import { Box, Typography, Grid, Divider, Container, Tab } from '@material-ui/core';
import { useStyles } from './style';
import StyledTabs from 'components/StyledTabs';
import RestaurantList from './Restaurant';
import GroceryStoreList from './Grocery';

const BizList: React.FC = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState('restaurant');

  return (
    <Container className={classes.gridContainer} disableGutters maxWidth="lg">
      <StyledTabs value={activeTab} onChange={(_e: any, val: any) => setActiveTab(val)}>
        <Tab label="Restaurants" value="restaurant" />
        <Tab label="Grocery" value="grocery" />
      </StyledTabs>
      {activeTab === 'restaurant' && <RestaurantList />}
      {activeTab === 'grocery' && <GroceryStoreList />}
    </Container>
  );
};

export default BizList;

import React from 'react';
import { withStyles, Tabs, Tab, useMediaQuery } from '@material-ui/core';

interface IProps {
  selectedTab: string;
  setselectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const ProfileTabs = ({ selectedTab, setselectedTab }: IProps) => {
  const StyledTabs = withStyles({
    indicator: {
      display: 'none'
    }
  })((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

  const StyledTab = withStyles((theme) => ({
    root: {
      textAlign: 'left',

      [theme.breakpoints.up('md')]: {
        maxWidth: '100%'
      }
    },
    selected: {
      backgroundColor: theme.palette.primary.main
    },
    wrapper: {
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        textTransform: 'none'
      }
    }
  }))((props: any) => <Tab {...props} />);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <StyledTabs value={selectedTab} variant={isDesktop ? 'standard' : 'scrollable'} scrollButtons={isDesktop ? 'off' : 'on'} orientation={isDesktop ? 'vertical' : 'horizontal'}>
      <StyledTab label="Change Display Name" value="updateName" selected={'updateName' === selectedTab} onClick={() => setselectedTab('updateName')} />
      <StyledTab label="Change Password" value="updatePassword" selected={'updatePassword' === selectedTab} onClick={() => setselectedTab('updatePassword')} />
      <StyledTab label="Change Phone Number" value="updatePhoneNumber" selected={'updatePhoneNumber' === selectedTab} onClick={() => setselectedTab('updatePhoneNumber')} />
    </StyledTabs>
  );
};

export default ProfileTabs;

import { gql } from 'apollo-boost';

export default gql`
  query getBizEmployees($input: GetBizEmployeesInput!) {
    getBizEmployees(input: $input) {
      uid
      displayName
      userName
    }
  }
`;

import React, { useState, useEffect } from 'react';
import EmployeeManagement from './EmployeeManagement';
import { useGetGroceryEmployees, useCreateGroceryEmployeeMutation, useUpdateGroceryEmployeeMutation, useDeleteGroceryEmployeeMutation } from 'graphql/hooks/groceryEmployee.hooks';
import { useStore } from 'store';
import { IEmployee } from './types';

const GroceryEmployeeManagement = () => {
  const {
    state: { groceryStoreId }
  } = useStore();

  const { data } = useGetGroceryEmployees(groceryStoreId);

  const { createEmployee: createEmployeeApi } = useCreateGroceryEmployeeMutation();

  const { updateEmployee: updateEmployeeApi } = useUpdateGroceryEmployeeMutation();

  const { deleteEmployee: deleteEmployeeApi } = useDeleteGroceryEmployeeMutation();

  const [initialValues, setinitialValues] = useState([]);

  useEffect(() => {
   
    if (data) {
      setinitialValues(data.getGroceryBizEmployees ? data.getGroceryBizEmployees : []);
    }
  }, [data]);
  const createEmployee = async (newEmployee: IEmployee) => {
    return await createEmployeeApi({
      variables: {
        input: {
          userName: newEmployee.userName.replace(/\s/g, ''),
          displayName: newEmployee.displayName,
          password: newEmployee.password,
          storeId: groceryStoreId
        }
      }
    });
  };

  const updateEmployee = async (editedEmployee: IEmployee) => {
    return await updateEmployeeApi({
      variables: {
        input: {
          userName: editedEmployee.userName.replace(/\s/g, ''),
          displayName: editedEmployee.displayName,
          uid: editedEmployee.uid,
          password: editedEmployee.password,
          storeId: groceryStoreId
        }
      }
    });
  };
  const deleteEmployee = async (uid: string) => {
    return await deleteEmployeeApi(uid, {
      variables: {
        input: {
          uid,
          storeId: groceryStoreId
        }
      }
    });
  };

  return <EmployeeManagement initialEmployees={initialValues} createEmployee={createEmployee} updateEmployee={updateEmployee} deleteEmployee={deleteEmployee} />;
};

export default GroceryEmployeeManagement;

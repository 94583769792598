import { map } from 'lodash';
import Big from 'big.js';
import { CryptoUnit } from 'generated/custom';

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

interface IPrice {
  size: [
    {
      text: string;
      lang: string;
    }
  ];
  isDefault: boolean;
  price: {
    currencyCode: string;
    intValue: number;
    shift: number;
  };
}

interface IOut {
  title: string;
  price: number | string;
  isDefault: boolean;
}

type Output = IOut[] | undefined;

export const parsePrice = (arg: [IPrice]): Output => {
  if (arg && arg.length) {
    const output: IOut[] = map(arg, ({ size, price, isDefault }) => {
      const [{ text: title = '' }] = size || [{ text: '' }];

      const { intValue, shift } = price;

      return {
        title,
        price: convertPrice(intValue, shift),
        shift,
        isDefault
      };
    });

    return output;
  }

  return undefined;
};

export const convertPrice = (value: number, shift: number) => {
  let strValue = `${value}`;

  let prefix = '';

  let prefixZero = strValue.length + shift - 1;

  while (prefixZero < 0) {
    prefix += '0';
    prefixZero += 1;
  }

  strValue = prefix + strValue;

  return (strValue = strValue.slice(0, shift) + '.' + strValue.slice(shift));
};

export const getPercentageDecrease = (changeInValue: string, orignalValue: string) => {
  if (changeInValue === '' || orignalValue === '') {
    return '-';
  }

  if (Big(changeInValue).gte(orignalValue)) {
    return '-';
  }

  return Big(changeInValue)
    .div(orignalValue)
    .mul(100)
    .round()
    .toString();
};

export const convertPriceTo = (price: string | number, convertTo: 'DOLLAR' | 'CENT') => {
  if (price) {
    if (convertTo === 'CENT')
      return Big(price)
        .mul(100)
        .toString();

    if (convertTo === 'DOLLAR')
      return Big(price)
        .div(100)
        .toFixed(2);
  }

  return '';
};

export const convertToCCTokens = (tokens: number, cryptoUnit: string) => {
  if (tokens && cryptoUnit === CryptoUnit.Qwei) {
    return (tokens / Math.pow(10, 3)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Twei) {
    return (tokens / Math.pow(10, 6)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Gwei) {
    return (tokens / Math.pow(10, 9)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Wei) {
    return (tokens / Math.pow(10, 18)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Cc) {
    return tokens + ' CBT';
  }

  return '0 CBT';
};

export interface IDiscount {
  price: string;
  percentage: string;
}

// export const convertToCCTokens = (ccTokenData: CcTokenOutput) => {
//   if (ccTokenData && ccTokenData.tokens && ccTokenData.cryptoUnit) {
//     if (ccTokenData.cryptoUnit === 'TWEI') {
//       return (ccTokenData.tokens / Math.pow(10, 6)).toString() + ' CC';
//     }
//   }
//   return '';
// };

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  footerContainer: {
    left: 0,
    bottom: 0,
    position: 'fixed',
    width: '100%',
    color: '#000',
    backgroundColor: '#fff',
    padding: theme.spacing(1, 2)
  }
}));

import { convertPrice } from 'util/number';
import { map } from 'lodash';

export const setInitialValues = (paymentSettings: any) => {
  const { onlinePaymentTypes, onsitePaymentTypes, tipPercentOptions, tipDefaultPercent, onlinePaymentFee, serviceFee } = paymentSettings;

  const [{ amount: serviceFeeAmount, threshold: serviceFeeThreshold }] = serviceFee || [{ amount: null, threshold: null }];
  const { amount, threshold } = onlinePaymentFee || { amount: null, threshold: null };

  let tipDefaultIndex = 2;
  if (tipPercentOptions) {
    tipDefaultIndex = tipPercentOptions.indexOf(tipDefaultPercent) < 0 ? 2 : tipPercentOptions.indexOf(tipDefaultPercent);
  }

  return {
    onlinePaymentTypes: onlinePaymentTypes ? onlinePaymentTypes : [],
    onsitePaymentTypes: onsitePaymentTypes ? onsitePaymentTypes : [],
    tipPercentOptions: tipPercentOptions ? tipPercentOptions : [0, 10, 15, 20],
    tipDefaultPercentIndex: tipDefaultIndex,
    onlinePaymentFeeAmount: amount ? convertPrice(amount.intValue, amount.shift) : '30.00',
    onlinePaymentFeeThreshold: threshold ? convertPrice(threshold.intValue, threshold.shift) : '0.30',
    serviceFeeAmount: serviceFeeAmount ? convertPrice(serviceFeeAmount.intValue, serviceFeeAmount.shift) : '0.30',
    serviceFeeThreshold: serviceFeeThreshold ? convertPrice(serviceFeeThreshold.intValue, serviceFeeThreshold.shift) : '30.00',
    addOnlinePaymentFee: onlinePaymentFee ? true : false,
    addServiceFee: serviceFee ? true : false
  };
};

export const setInitialDeliverySettings = (deliverySetting: any) => {
  const { waitMinutes, minDeliveryAmount, freeDeliveryAmount, fees } = deliverySetting;

  return {
    waitMinutes: waitMinutes,
    freeDeliveryAmount: freeDeliveryAmount ? convertPrice(freeDeliveryAmount.intValue, freeDeliveryAmount.shift) : '100.00',
    minDeliveryAmount: minDeliveryAmount ? convertPrice(minDeliveryAmount.intValue, minDeliveryAmount.shift) : '25.00',

    fees: fees
      ? map(fees, ({ mile, fee }) => {
          return {
            mile,
            fee: convertPrice(fee.intValue, fee.shift)
          };
        })
      : [
          {
            mile: 3,
            fee: '4.50'
          },
          {
            mile: 5,
            fee: '4.50'
          },
          {
            mile: 6,
            fee: '4.50'
          },
          {
            mile: 7,
            fee: '4.50'
          }
        ]
  };
};

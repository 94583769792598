import gql from 'graphql-tag';
import { TextBroadcastInfo } from 'graphql/fragments/textBroadcast.fregments';

export const createTextBroadcastMutation = gql`
  mutation createTextBroadcast($input: createTextBroadcastInput!) {
    createTextBroadcast(input: $input) {
      ...TextBroadcastInfo
    }
  }
  ${TextBroadcastInfo}
`;

export const updateTextBroadCastMutation = gql`
  mutation updateTextBroadcast($input: updateTextBroadcastInput!) {
    updateTextBroadcast(input: $input) {
      ...TextBroadcastInfo
    }
  }
  ${TextBroadcastInfo}
`;

export const updateNextMonthBroadcastMutation = gql`
  mutation updateNextMonthBroadcast($input: updateNextMonthBroadcastInput!) {
    updateNextMonthBroadcast(input: $input) {
      bizId
      id
      bizPhoneNumber
      frequency
      dayOfWeek
      timeHour
      timeMin
      status
    }
  }
`;

export const requestTextMarketingMutation = gql`
  mutation requestTextMarketing($input: requestTextMarketingInput!) {
    requestTextMarketing(input: $input)
  }
`;

export const addBroadcastPhoneNumbersMutation = gql`
  mutation addBroadcastPhoneNumbers($input: addBroadcastPhoneNumbersInput!) {
    addBroadcastPhoneNumbers(input: $input)
  }
`;

export const deleteTextBroadcastMutation = gql`
  mutation deleteTextBroadcast($input: deleteTextBroadcastInput!) {
    deleteTextBroadcast(input: $input)
  }
`;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box, Typography, Button, Divider, Grid, IconButton } from '@material-ui/core';
import DatePicker from 'components/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Info } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useStyles } from './style';
import TotalRevenueIcon from 'assets/img/total-revenue.svg';
import AverageOrderIcon from 'assets/img/average-order.svg';
import TotalOrdersIcon from 'assets/img/total-orders.svg';
import TotalCustomersIcon from 'assets/img/total-customers.svg';
import AverageTipIcon from 'assets/img/average-tip.svg';
import { useGetDataBetweenDates, useGetDailyWeeklyData } from 'api/hooks/analytics';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { validationSchema } from './validation';
import { useActiveRestaurant } from 'hooks/restaurant';
import { isEmpty } from 'lodash';
import WeeklyChart from './WeeklyChart';
import RevenueChart from './WeeklyRevenueChart';
import Moment from 'moment';
import { parseRangeData, parseDailyData } from './helper';
import { TDailyData, IFormValues, TRangeData, initialDailyData, initialRangeData, initialValues, TFrequencyList, TMonthlyRevenue, TWeeklyRevenue } from './types';
import moment from 'moment';

const Analytics: React.FC = () => {
  const classes = useStyles();

  const { restaurantId } = useActiveRestaurant();

  const { data: businessProfileData } = useGetRestaurantById(restaurantId as any, 'PROFILE');

  const { sendRequest, data, error } = useGetDataBetweenDates();

  const { data: dailyDataResponse, error: dailyDataError, loading: dailyDataLoading, sendRequest: sendDailyWeeklyRequest } = useGetDailyWeeklyData();

  const [rangeData, setRangeData] = useState<TRangeData>(initialRangeData);

  const [dailyData, setDailyData] = useState<TDailyData>(initialDailyData);

  const [weekData, setWeekData] = useState<any>([]);

  const [weeklyRevenue, setWeeklyRevenue] = useState<TWeeklyRevenue[]>([]);

  const [monthlyRevenue, setMonthlyRevenue] = useState<TMonthlyRevenue[]>([]);

  const [businessProfile, setBusinessProfile] = useState<any>(null);

  const handleDateChange = (date: MaterialUiPickersDate, name: string) => {
    if (date) {
      let y = date?.getFullYear();
      let m = date?.getMonth();
      let d = date?.getDate();
      const selectedDateCustom = moment()
        .year(y)
        .month(m)
        .date(d)
        .format('YYYY-MM-DD');
      setFieldValue(name, selectedDateCustom);
    }
    // setFieldValue(name, date ? moment(date).format('yyyy-MM-DD') : null);
  };

  useEffect(() => {
    // Parsing daily and weekly data
    if (dailyDataResponse) {
      const response = parseDailyData(dailyDataResponse);

      if (!isEmpty(response.dailyData)) {
        setDailyData(response.dailyData as any);
      }

      if (!isEmpty(response.weekData)) {
        setWeekData(response.weekData);
      }
    }

    // Parsing analytics data between dates
    if (data) {
      const response = parseRangeData(data);

      setRangeData(response.rangeData as any);

      setWeeklyRevenue(response.weeklyRevenue as TWeeklyRevenue[]);

      setMonthlyRevenue(response.monthlyRevenue as TMonthlyRevenue[]);
    }
  }, [dailyDataResponse, data]);

  useEffect(() => {
    if (businessProfileData && businessProfileData.ownerGetRestaurantById) {
      setBusinessProfile(businessProfileData.ownerGetRestaurantById);
    }
  }, [businessProfileData]);

  const onSubmit = async (values: IFormValues) => {
    const { startDate, endDate } = values;
    const payload = {
      startDate: startDate,
      endDate: endDate,
      sellerBizId: restaurantId
    };
    await sendRequest(payload);
    window.scrollTo(0, 50);
  };

  const getBusinessName = () => {
    if (businessProfile && !isEmpty(businessProfile)) {
      return businessProfile.name[0].text;
    }

    return '';
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema,
    onSubmit
  });

  const { values, handleBlur, isValid, dirty, submitForm, touched, errors, setFieldValue } = formik;

  return (
    <Box className={classes.root}>
      <Box marginY={4} className={classes.tiles}>
        <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography className={classes.bold} variant="h5">
              {getBusinessName()}
            </Typography>
            <Typography variant="h6">
              Daily Report (
              {Moment()
                .subtract(1, 'days')
                .format('MM/DD/YYYY')}
              )
            </Typography>
          </Box>
          <Tooltip title="Reload" placement="top">
            <IconButton aria-label="reload" className={classes.iconButton}>
              <RefreshIcon
                fontSize="large"
                fontWeight="bold"
                onClick={() => {
                  sendDailyWeeklyRequest({ sellerBizId: restaurantId });
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider />
        <Box paddingTop={1} display="flex" justifyContent="space-between" alignItems="center">
          {!dailyDataLoading && (
            <>
              <Box className={classes.dailyTilesData}>
                <Typography variant="h6">Total Revenue (Subtotal)</Typography>
                <Typography variant="h3">${dailyData.subtotal}</Typography>
              </Box>
              <Box className={classes.vr}></Box>
              <Box className={classes.dailyTilesData}>
                <Typography variant="h6"># Orders</Typography>
                <Typography variant="h3">{dailyData.totalOrders}</Typography>
              </Box>
              <Box className={classes.vr}></Box>
              <Box className={classes.dailyTilesData}>
                <Typography variant="h6">Average Order</Typography>
                <Typography variant="h3">${dailyData.averageOrders}</Typography>
              </Box>
              <Box className={classes.vr}></Box>
              <Box className={classes.dailyTilesData}>
                <Typography variant="h6">Tax</Typography>
                <Typography variant="h3">${dailyData.tax}</Typography>
              </Box>
              <Box className={classes.vr}></Box>
              <Box className={classes.dailyTilesData}>
                <Typography variant="h6">Average Tip(%)</Typography>
                <Typography variant="h3">{dailyData.tipPercentage}%</Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box marginY={4} className={classes.tiles}>
        <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Weekly Report</Typography>
          {/* <Tooltip title="Add" placement="top">
            <Info className={classes.infoIcon} />
          </Tooltip> */}
        </Box>
        <Divider />
        <Box>{!!weekData.length && !dailyDataLoading && <WeeklyChart data={weekData} />}</Box>
      </Box>

      <Divider variant="fullWidth"></Divider>
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <Box>
          <Typography style={{ fontWeight: 'bold' }} variant="h5">
            Select date range
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" paddingY={1}>
          <Typography className={classes.formItem} variant="h6">
            Start Date
          </Typography>
          <Box className={classes.formItem}>
            <DatePicker
              label=""
              name="startDate"
              value={values.startDate}
              minDate={Date.parse('2018-01-01')}
              maxDate={values.endDate ? moment(values.endDate) : moment().subtract(1, 'days')}
              readOnly={false}
              onBlur={handleBlur}
              onChange={(date) => handleDateChange(date, 'startDate')}
              errors={!!touched.startDate && !!errors.startDate ? errors.startDate : ''}
              disablePast={false}
              disableFuture={true}
            />
          </Box>
          <Typography className={classes.formItem} variant="h6">
            End Date
          </Typography>
          <Box className={classes.formItem}>
            <DatePicker
              label=""
              name="endDate"
              value={values.endDate}
              minDate={values.startDate ? moment(values.startDate) : Date.parse('2018-01-01')}
              maxDate={moment().subtract(1, 'days')}
              readOnly={false}
              onBlur={handleBlur}
              onChange={(date) => handleDateChange(date, 'endDate')}
              errors={!!touched.endDate && !!errors.endDate ? errors.endDate : ''}
              disablePast={false}
              disableFuture={true}
            />
          </Box>
          <Box className={classes.formItem}>
            <Button disabled={!(isValid && dirty)} size="large" variant="contained" color="primary" onClick={submitForm}>
              Generate Report
            </Button>
          </Box>
        </Box>
      </Box>
      {!!data && (
        <>
          <Grid container spacing={3}>
            <Grid item md>
              <Box className={classes.tiles}>
                <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Total Revenue (Subtotal)</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <img src={TotalRevenueIcon} alt="" />
                  <Typography variant="h4">${rangeData.totalRevenue}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md>
              <Box className={classes.tiles}>
                <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Average Order</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <img src={AverageOrderIcon} alt="" />
                  <Typography variant="h4">${rangeData.averageOrders}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md>
              <Box className={classes.tiles}>
                <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Total Orders</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <img src={TotalOrdersIcon} alt="" />
                  <Typography variant="h4">{rangeData.totalOrders}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md>
              <Box className={classes.tiles}>
                <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Total Customers</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <img src={TotalCustomersIcon} alt="" />
                  <Typography variant="h4">{rangeData.totalCustomers}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md>
              <Box className={classes.tiles}>
                <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Average Tip(%)</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <img src={AverageTipIcon} alt="" />
                  <Typography variant="h4">{rangeData.tipPercentage}%</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {!!weeklyRevenue.length && (
            <Box marginY={4} className={classes.tiles}>
              <Box paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Weekly Revenue</Typography>
                {/* <Tooltip title="Add" placement="top">
                <Info className={classes.infoIcon} />
              </Tooltip> */}
              </Box>
              <Divider />
              <Box>
                <RevenueChart data={weeklyRevenue} />
              </Box>
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {!!monthlyRevenue.length && (
                <Box marginTop={5} className={classes.tiles}>
                  <Box paddingBottom={2}>
                    <Typography variant="h6">Monthly Revenue</Typography>
                  </Box>
                  <Divider />
                  <Box marginTop={6} className={classes.monthRevenueContainer}>
                    {monthlyRevenue.map((data: TMonthlyRevenue) => {
                      return (
                        <Box key={data.month} paddingBottom={2}>
                          <Box paddingBottom={1} display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">${data.revenue}</Typography>
                            <Typography variant="h6">{data.month}</Typography>
                          </Box>

                          <Box className={classes.progress}>
                            <Box style={{ width: `${data.percentage}%` }} className={classes.revenueBar}></Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {!!rangeData.dishFrequency.length && (
                <Box marginTop={5} className={classes.tiles}>
                  <Box paddingBottom={2}>
                    <Typography variant="h6">Dish Ordered</Typography>
                  </Box>
                  <Divider />
                  <Box paddingTop={2} fontWeight="bold" display="flex" justifyContent="space-between">
                    <Typography variant="h6">Dishes</Typography>
                    <Typography variant="h6"># Orders</Typography>
                  </Box>
                  <Box className={classes.listContainer}>
                    {rangeData.dishFrequency.map((item: TFrequencyList) => {
                      return (
                        <Box key={item.value} className={classes.listItem} display="flex" justifyContent="space-between">
                          <Typography variant="body1">{item.value}</Typography>
                          <Typography variant="body1">{item.freq}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              {!!rangeData.pairedDishFrequency.length && (
                <Box marginTop={5} className={classes.tiles}>
                  <Box paddingBottom={2}>
                    <Typography variant="h6">Frequently Ordered Combos</Typography>
                  </Box>
                  <Divider />
                  <Box paddingTop={2} fontWeight="bold" display="flex" justifyContent="space-between">
                    <Typography variant="h6">Combos</Typography>
                    <Typography variant="h6">Frequency</Typography>
                  </Box>
                  <Box className={classes.listContainer}>
                    {rangeData.pairedDishFrequency.map((item: TFrequencyList) => {
                      return (
                        <Box key={item.value} className={classes.listItem} display="flex" justifyContent="space-between">
                          <Typography variant="body1">{item.value}</Typography>
                          <Typography variant="body1">{item.freq}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Analytics;

import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';

interface IProps {
  data: any;
}

const WeeklyChart: React.FC<IProps> = ({ data }) => {
  const [pastWeekRevenue, setPastWeekRevenue] = useState<number[]>([0, 0, 0, 0, 0, 0, 0]);

  const [currentWeekRevenue, setCurrentWeekRevenue] = useState<number[]>([0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    if (data[0] && data[0].length) {
      setPastWeekRevenue(data[0]);
    }

    if (data[1] && data[1].length) {
      setCurrentWeekRevenue(data[1]);
    }
  }, [data]);

  var pastWeek = {
    x: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    y: pastWeekRevenue,
    name: 'Past Week',
    marker: { color: '#43aa8b' },
    type: 'bar',
    width: 0.3
  };

  var currentWeek = {
    x: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    y: currentWeekRevenue,
    name: 'Current Week',
    marker: { color: '#125D98' },
    type: 'bar',
    width: 0.3
  };

  var plotData = [pastWeek, currentWeek];

  return (
    <>
      <Plot
        data={plotData as any}
        layout={{
          autosize: true,
          xaxis: {
            tickfont: {
              size: 14,
              color: 'rgb(107, 107, 107)'
            }
          },
          yaxis: {
            titlefont: {
              size: 16,

              color: 'rgb(107, 107, 107)'
            },
            tickfont: {
              size: 14,
              color: 'rgb(107, 107, 107)'
            },
            title: 'Revenue',
            tickprefix: '$',
            rangemode: 'nonnegative'
          },
          legend: {
            x: 1.0,
            y: 1.0,
            orientation: 'v',
            bgcolor: 'rgba(255, 255, 255, 0)',
            bordercolor: 'rgba(255, 255, 255, 0)'
          },
          barmode: 'group',
          bargap: 0.3,
          bargroupgap: 0.1
        }}
        config={{
          displayModeBar: true,
          modeBarButtons: [['toImage']],
          displaylogo: false
        }}
        style={{ width: '100%', height: '100%' }}
      />
    </>
  );
};

export default WeeklyChart;

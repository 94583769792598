import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formControls: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  textField: {
    '& input': {
      textAlign: 'right'
    }
  },
  icons: {
    color: theme.palette.primary.main
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  gridContainer: {
    paddingBottom: theme.spacing(2)
  },
  iconButton: {
    margin: theme.spacing(1)
  }
}));

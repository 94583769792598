import { gql } from 'apollo-boost';
import { CryptoCoupon } from 'graphql/fragments/cryptoCoupon.fragments';

export default gql`
  query getCoupons($input1: GetCryptoCouponHistoryInput!, $input2: OwnerGetCryptoCouponsByBizIdInput!) {
    getCryptoCouponHistory(input: $input1) {
      coupons {
        ...CryptoCoupon
      }
      pageCursor
    }
    ownerGetCryptoCouponsByBizId(input: $input2) {
      ...CryptoCoupon
    }
  }
  ${CryptoCoupon}
`;

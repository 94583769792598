import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200]
  },
  closeButton: {
    marginLeft: 'auto',
    cursor: 'poiter'
  },
  dialogContent: {
    margin: 0,
    padding: 0,
    '&:first-child': {
      margin: 0,
      padding: 0
    }
  },
  contentContainer: {
    padding: theme.spacing(0, 2)
  },
  serviceButton: {
    margin: theme.spacing(1)
  },
  serviceButtonContainer: {
    textAlign: 'right'
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: 2
  }
}));

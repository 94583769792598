import { gql } from 'apollo-boost';
import { TextBroadcastInfo } from 'graphql/fragments/textBroadcast.fregments';

export default gql`
  query getRestaurantActiveTextBroadcast($input: getRestaurantActiveBroadcastInput!) {
    getRestaurantActiveTextBroadcast(input: $input) {
      ...TextBroadcastInfo
    }
  }
  ${TextBroadcastInfo}
`;

import { Box, Checkbox, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { GroceryStoreItem } from '../../types';
import { useStyles } from '../style';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmDialog } from '@lokobee/lokobee-ui';
import UpdateDialog from '../UpdateDialog';

interface IProps {
  rowData: GroceryStoreItem;
  onEdit: (updatedItem: GroceryStoreItem) => void;
  onDelete: () => void;
  taxModes: string[];
  pricePointRatio: number;
  isSelected: boolean;
  onSelect: (check: boolean) => void;
  onRewardsChanged: (checked: boolean) => void;
  onDiscountChanged: (checked: boolean) => void;
  rewardsEnabled: boolean;
}

const Row = ({ rowData, onEdit, onDelete, taxModes, pricePointRatio, isSelected, onSelect, onRewardsChanged, onDiscountChanged, rewardsEnabled }: IProps) => {
  const classes = useStyles();

  const [openEditDialog, setOpenEditDialog] = useState(false);

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          className={classes.checkbox}
          color="secondary"
          checked={isSelected}
          onChange={(e: any, checked) => {
            onSelect(checked);
          }}
        />
      </TableCell>
      <TableCell>{rowData.upcCode}</TableCell>
      <TableCell>{rowData.title}</TableCell>
      <TableCell>{rowData.brand}</TableCell>
      <TableCell>{rowData.size}</TableCell>
      <TableCell>{rowData.category}</TableCell>
      <TableCell>{rowData.subCategory}</TableCell>
      <TableCell>{rowData.price}</TableCell>
      <TableCell>{rowData.taxMode}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Checkbox
            disabled={!rewardsEnabled}
            className={classes.checkbox}
            color="primary"
            checked={rowData.isReward}
            onChange={(e: any, checked: boolean) => {
              onRewardsChanged(checked);
            }}
          />
          <Box>
            <Typography variant="caption">{rowData.rewardPoints}</Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          <Checkbox
            className={classes.checkbox}
            color="primary"
            checked={rowData.isDiscounted}
            onChange={(e: any, checked: boolean) => {
              onDiscountChanged(checked);
            }}
          />
        </Box>
      </TableCell>

      <TableCell>{rowData.description && rowData.description.length > 25 ? `${rowData.description?.slice(0, 25)}...` : rowData.description}</TableCell>
      {/* <TableCell align="right">
        <EditIcon fontSize="small" className={classes.editIcon} onClick={() => setOpenEditDialog(true)} />

        <ConfirmDialog
          onYes={() => {
            onDelete();
          }}
          message="Are you sure want to close this row?">
          <DeleteIcon fontSize="small" className={classes.deleteIcon} />
        </ConfirmDialog>
        {openEditDialog && (
          <UpdateDialog
            open={openEditDialog}
            rowData={rowData}
            onSubmit={(item) => {
              onEdit(item);
              setOpenEditDialog(false);
            }}
            taxModes={taxModes}
            onClose={() => setOpenEditDialog(false)}
            pricePointRatio={pricePointRatio}
          />
        )}
      </TableCell> */}
    </TableRow>
  );
};

export default Row;

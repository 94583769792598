import React, { useEffect, useState } from 'react';
import RestaurantProfile from './RestaurantProfile';
import { useStore } from 'store';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { currentTimeZone } from './timeZone';
import { IRestaurantProfile } from './types';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const { data } = useGetRestaurantById(restaurant, 'PROFILE');

  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();

  const [initialValues, setInitialValues] = useState<IRestaurantProfile>({
    name: '',
    description: '',
    taxRate: '',
    phone: '',
    address: {
      text: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      lat: 0,
      lng: 0,
      street: ''
    },
    logo: null,
    logoImageData: null,
    timeZone: currentTimeZone,
    subName: '',
    restaurantType: 'REGULAR_RESTAURANT',
    addressNote: '',
    email: '',
    orderEmails: '',
    reportEmails: '',
    googleReviewLink: '',
    webSite: ''
  });

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { name, subName, addressNote, description, taxRate, phone, address, logo, type, i18n, email, reportEmails, orderEmails, googleReviewLink, webSite } = data.ownerGetRestaurantById;

      setInitialValues({
        name: name ? name[0].text : '',
        description: description ? description[0].text : '',
        taxRate: taxRate ? (taxRate.intValue * Math.pow(10, taxRate.shift + 2)).toFixed(2) : '',
        phone: phone || '',
        address: address
          ? {
              text: address.text,
              city: address.city,
              state: address.state,
              country: address.country,
              zipcode: address.zipcode,
              lat: address.lat,
              lng: address.lng,
              street: address.street
            }
          : {
              text: '',
              city: '',
              state: '',
              country: '',
              zipcode: '',
              lat: 0,
              lng: 0,
              street: ''
            },
        logo: logo ? logo : null,
        logoImageData: null,
        timeZone: i18n ? i18n.timezone : currentTimeZone,
        restaurantType: type ? type : 'REGULAR_RESTAURANT',
        addressNote: addressNote ? addressNote[0].text : '',
        subName: subName ? subName[0].text : '',
        email: email ? email : '',
        orderEmails: orderEmails ? orderEmails.join(',') : '',
        reportEmails: reportEmails ? reportEmails.join(',') : '',
        googleReviewLink: googleReviewLink ? googleReviewLink : '',
        webSite: webSite ? webSite.replaceAll('https://', '') : ''
      });
    }
  }, [data]);

  const getUploadId = async (filename: string, imageData: string) => {
    try {
      const { data: uploadImageResponse } = await createImageUploadUrl({
        variables: {
          input: {
            usage: 'BIZ_LOGO',
            fileName: filename
          }
        }
      });

      if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
        const { id, url } = uploadImageResponse.createImageUploadUrl;
        await uploadImage(url, imageData);

        return id;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  if (data && data.ownerGetRestaurantById) {
    return <RestaurantProfile ref={ref} initialValues={initialValues} nextStep={nextStep} getUploadId={getUploadId} />;
  }

  return null;
});

import gql from 'graphql-tag';
import { storeItemFragment } from 'graphql/fragments/groceryStore.fragments';

export const getGroceryItemsByStoreId = gql`
  query getCategoriesWithItemsByStoreId($input: GetCategoriesWithItemsByStoreIdInput!) {
    getCategoriesWithItemsByStoreId(input: $input) {
      id
      title
      status
      subCategories {
        id
        title
        status
      }
      items {
        ...storeItemFragment
      }
    }
  }
  ${storeItemFragment}
`;

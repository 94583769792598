import React, { useEffect, useState } from 'react';
import GroceryStoreProfile from './GroceryStoreProfile';
import { useStore } from 'store';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import { useGetGroceryStoreById } from 'graphql/hooks/grocery.hooks';
import { IGroceryStoreProfile } from './types';
import { currentTimeZone } from './timeZone';
import { map } from 'lodash';
import { ITaxModeItem, ITaxModeItemResponse } from '../types';
import { convertPrice } from 'util/number';
interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { groceryStoreId }
  } = useStore();
  const { data } = useGetGroceryStoreById(groceryStoreId, 'PROFILE');
  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();

  const [initialValues, setInitialValues] = useState<IGroceryStoreProfile>({
    name: '',
    description: '',
    phone: '',
    address: {
      text: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      lat: 0,
      lng: 0,
      street: ''
    },
    logo: null,
    logoImageData: null,
    timeZone: currentTimeZone,
    email: '',
    orderEmails: '',
    reportEmails: '',
    googleReviewLink: '',
    taxModes: []
  });
  useEffect(() => {
    if (data && data.ownerGetGroceryStoreById) {
      const { name, description, phone, address, logo, timezone, email, reportEmails, orderEmails, googleReviewLink, taxModes } = data.ownerGetGroceryStoreById;

      setInitialValues({
        name: name || '',
        description: description || '',
        phone: phone || '',
        address: address
          ? {
              text: address.text,
              city: address.city,
              state: address.state,
              country: address.country,
              zipcode: address.zipcode,
              lat: address.lat,
              lng: address.lng,
              street: address.street
            }
          : {
              text: '',
              city: '',
              state: '',
              country: '',
              zipcode: '',
              lat: 0,
              lng: 0,
              street: ''
            },
        logo: logo ? logo : null,
        logoImageData: null,
        timeZone: timezone ? timezone : currentTimeZone,
        email: email ? email : '',
        orderEmails: orderEmails ? orderEmails.join(',') : '',
        reportEmails: reportEmails ? reportEmails.join(',') : '',
        googleReviewLink: googleReviewLink ? googleReviewLink : '',
        taxModes: taxModes
          ? taxModes.map((taxModeItem: ITaxModeItemResponse) => {
              return {
                title: taxModeItem.title,
                tax: taxModeItem.tax ? (taxModeItem.tax.intValue * Math.pow(10, taxModeItem.tax.shift + 2)).toFixed(2) : ''
              };
            })
          : [
              {
                title: '',
                tax: ''
              }
            ]
      });
    }
  }, [data]);

  const getUploadId = async (filename: string, imageData: string) => {
    try {
      const { data: uploadImageResponse } = await createImageUploadUrl({
        variables: {
          input: {
            usage: 'GROCERY_STORE_LOGO',
            fileName: filename
          }
        }
      });

      if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
        const { id, url } = uploadImageResponse.createImageUploadUrl;
        await uploadImage(url, imageData);

        return id;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  if (data && data.ownerGetGroceryStoreById) {
    return <GroceryStoreProfile ref={ref} initialValues={initialValues} nextStep={nextStep} getUploadId={getUploadId} />;
  }
  return null;
});

import gql from 'graphql-tag';

export const createGroceryBizEmployee = gql`

mutation createGroceryBizEmployee($input: CreateGroceryBizEmployeeInput!){
  createGroceryBizEmployee(input: $input) {
      uid
      displayName
      userName
    }
}
`;

export const updateGroceryBizEmployee = gql`
  mutation updateGroceryBizEmployee($input: UpdateGroceryBizEmployeeInput!) {
    updateGroceryBizEmployee(input: $input) {
      uid
      displayName
      userName
    }
  }
`;

export const deleteGroceryBizEmployee = gql`
  mutation deleteGroceryBizEmployee($input: DeleteGroceryBizEmployeeInput!) {
    deleteGroceryBizEmployee(input: $input)
  }
`;

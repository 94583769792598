import { gql } from 'apollo-boost';

export default gql`
  mutation($email: String!, $password: String!, $nickname: String!, $phone: String!) {
    signUpUser(email: $email, password: $password, nickname: $nickname, phone: $phone) {
      id
      email
      sessionToken
    }
  }
`;

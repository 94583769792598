import { useState, useEffect } from 'react';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useQueryWithLoader, useMutationWithLoader, useLazyQueryWithLoader } from 'hooks/loader';
import { createCouponMutation, deleteCouponMutation } from 'graphql/mutations/coupon.mutation';
import { getCouponsByRestaurantQuery } from 'graphql/query/coupon.query';
import { filter } from 'lodash';
import { Coupon } from 'generated/custom';
import { GET_ALL_CRYPTO_COUPONS, GET_CC_TOKEN_VALUE, GET_CRYPTO_COUPONS_BY_BIZ_ID, GET_CRYPTO_COUPONS_HISTORY, GET_CRYPTO_COUPON_BY_COUPON_ID } from 'graphql/query';
import { CREATE_CRYPTO_COUPON, REQUEST_CRYPTO_PROMOTION } from 'graphql/mutations';
import { useLazyQuery } from '@apollo/react-hooks';
import { ICouponType } from 'pages/Home/RestaurantDashboard/CryptoPromotions/type';
import { getRestaurantStatus } from 'graphql/query/getRestaurantById.query';
import { gql } from 'apollo-boost';

export const useCreateCoupon = () => {
  const { restaurantId } = useActiveRestaurant();

  const [createCoupon, { data, loading, error }] = useMutationWithLoader(createCouponMutation, {
    update: (cache, { data: { createCoupon: newData } }) => {
      const variables = {
        input: {
          bizId: restaurantId
        }
      };

      const { getCouponsByRestaurant: oldData }: any = cache.readQuery({
        query: getCouponsByRestaurantQuery,
        variables
      });

      cache.writeQuery({
        variables,
        query: getCouponsByRestaurantQuery,
        data: { getCouponsByRestaurant: oldData ? [newData, ...oldData] : [newData] }
      });
    }
  });

  return {
    createCoupon,
    data,
    loading,
    error
  };
};

export const useGetCoupons = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const [getCoupons, { data, error, loading }] = useLazyQueryWithLoader(getCouponsByRestaurantQuery, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      setCoupons(data.getCouponsByRestaurant);
    }
  }, [data]);

  return {
    loading,
    data: coupons,
    error,
    getCoupons
  };
};

export const useDeleteCoupon = (bizId: string, code: string) => {
  const [deleteCoupon, { data, error, loading }] = useMutationWithLoader(deleteCouponMutation, {
    variables: {
      input: {
        bizId,
        code
      }
    },
    update: (cache) => {
      const variables = {
        input: {
          bizId
        }
      };

      const { getCouponsByRestaurant: oldData }: any = cache.readQuery({
        query: getCouponsByRestaurantQuery,
        variables
      });

      cache.writeQuery({
        variables,
        query: getCouponsByRestaurantQuery,
        data: { getCouponsByRestaurant: filter(oldData, (coupon) => coupon.code !== code) }
      });
    }
  });

  return {
    deleteCoupon,
    data,
    error,
    loading
  };
};

////////////////////////////////////////////////////////////////////////////////////
//                              Crypto Coupon hooks                              //
////////////////////////////////////////////////////////////////////////////////////

export const useGetAllCryptoCoupons = (bizId: string | null) => {
  const [newPageCursor, setNewPageCursor] = useState(null);
  const maxPageCount = 20;
  const [fetching, setFetching] = useState(true);
  const [activeCouponItemsList, setActiveCouponList] = useState<ICouponType[]>([]);
  const [historyCouponItemsList, setHistoryCouponList] = useState<ICouponType[]>([]);

  const { loading, data, error, fetchMore } = useQueryWithLoader(GET_ALL_CRYPTO_COUPONS, {
    skip: !bizId,
    variables: {
      input1: {
        bizId,
        count: maxPageCount,
        pageCursor: null
      },
      input2: {
        bizId
      }
    }
  });

  useEffect(() => {
    if (data) {
      const { getCryptoCouponHistory, ownerGetCryptoCouponsByBizId } = data;
      if (getCryptoCouponHistory && getCryptoCouponHistory.coupons) {
        setHistoryCouponList(getCryptoCouponHistory.coupons);
      }

      setNewPageCursor(getCryptoCouponHistory.pageCursor);
      if (ownerGetCryptoCouponsByBizId) {
        setActiveCouponList(ownerGetCryptoCouponsByBizId);
      }
      setFetching(false);
    }
    if (error) {
      setFetching(false);
    }
  }, [data, error, newPageCursor]);

  const fetchMoreData = async () => {
    if (newPageCursor) {
      try {
        setFetching(true);
        await fetchMore({
          query: GET_CRYPTO_COUPONS_HISTORY,
          variables: {
            input: {
              bizId,
              count: maxPageCount,
              pageCursor: newPageCursor
            }
          },
          updateQuery: (previusResult: any, { fetchMoreResult }: any) => {
            if (fetchMoreResult && fetchMoreResult.getCryptoCouponHistory) {
              const { pageCursor } = fetchMoreResult.getCryptoCouponHistory;
              const newCursor = pageCursor;
              setNewPageCursor(newCursor);
              return {
                getCryptoCouponHistory: {
                  coupons: [...previusResult.getCryptoCouponHistory.coupons, ...fetchMoreResult.getCryptoCouponHistory.coupons],
                  pageCursor: newCursor,
                  __typename: previusResult.getCryptoCouponHistory.__typename
                },
                ownerGetCryptoCouponsByBizId: [...previusResult.ownerGetCryptoCouponsByBizId]
              };
            }
          }
        });
      } catch (e) {
        setFetching(false);
        console.log('e', e);
      }
    }
  };

  return {
    loading,
    error,
    activeCouponItemsList,
    historyCouponItemsList,
    fetchMoreData,
    newPageCursor,
    fetching
  };
};

export const useGetCryptoCouponByCouponId = (couponId: string | null) => {
  const { loading, data, error } = useQueryWithLoader(GET_CRYPTO_COUPON_BY_COUPON_ID, {
    skip: !couponId,
    variables: {
      input: {
        couponId
      }
    }
  });

  return {
    loading,
    data,
    error
  };
};

export const useCreateCryptoCoupon = () => {
  const { restaurantId } = useActiveRestaurant();

  const [createCryptoCoupon, { data, loading, error }] = useMutationWithLoader(CREATE_CRYPTO_COUPON, {
    update: (cache, { data: { createCryptoCoupon: newData } }) => {
      const variables = {
        input: {
          bizId: restaurantId
        }
      };
      const oldData: any = cache.readQuery({
        query: GET_CRYPTO_COUPONS_BY_BIZ_ID,
        variables
      });
      if (oldData && oldData.ownerGetCryptoCouponsByBizId) {
        let oldDataCopy = oldData;
        let tempCoupons = [...oldData.ownerGetCryptoCouponsByBizId];
        tempCoupons.push(newData);
        oldDataCopy.ownerGetCryptoCouponsByBizId = tempCoupons;
        cache.writeQuery({
          variables,
          query: GET_CRYPTO_COUPONS_BY_BIZ_ID,
          data: oldDataCopy
        });
      }
    }
  });
  return {
    createCryptoCoupon,
    data,
    loading,
    error
  };
};
export const useGetCCTokenValue = () => {
  const [getCCTokenValue, { error, loading, data }] = useLazyQuery(GET_CC_TOKEN_VALUE);

  return {
    data,
    loading,
    error,
    getCCTokenValue
  };
};

export const useRequestCryptoCouponPromotion = () => {
  const { restaurantId } = useActiveRestaurant();

  const [requestCryptoCouponPromotion, { data, loading, error }] = useMutationWithLoader(REQUEST_CRYPTO_PROMOTION, {
    update: (cache, { data: { requestCryptoCouponPromotion } }) => {
      const variables = {
        input: {
          id: restaurantId
        }
      };

      const { ownerGetRestaurantById: oldData }: any = cache.readQuery({
        query: getRestaurantStatus,
        variables
      });
      let tempOldData = oldData;
      if (tempOldData && requestCryptoCouponPromotion) {
        tempOldData.cryptoCouponEnabled = requestCryptoCouponPromotion;
        cache.writeQuery({
          variables,
          query: getRestaurantStatus,
          data: { ownerGetRestaurantById: tempOldData }
        });
      }
    }
  });
  return {
    requestCryptoCouponPromotion,
    data,
    loading,
    error
  };
};

export const useLazyGetCryptoCouponByCouponId = () => {
  const [getCouponById, { loading, data, error }] = useLazyQueryWithLoader(GET_CRYPTO_COUPON_BY_COUPON_ID);

  return {
    getCouponById,
    loading,
    data,
    error
  };
};

export const useGetCCTokenValueMultipleQuery = (orderData: any[]) => {
  let query = `
  query {
  `;

  let count = 0;

  orderData.forEach((data, index) => {
    const { serviceCharges } = data;
    if (serviceCharges && serviceCharges.couponCost) {
      count++;
      query += `
      q${index}:getCCTokenValue(
        input:{
          cents:${serviceCharges.couponCost.intValue},
          rewardType:BIZ_REWARD
        }){
          tokens
          cryptoUnit
        },
      `;
    }
  });

  query += `
      }
    `;

  const [getCCTokenValue, { error, loading, data }] = useLazyQuery(count > 0 ? gql(query) : GET_CC_TOKEN_VALUE);

  return {
    data,
    loading,
    error,
    getCCTokenValue
  };
};

import { Box, Button, Dialog, DialogContent, CircularProgress, Typography } from '@material-ui/core';

import React, { useState } from 'react';
import { useStyle } from '../style';
import { IInitialValueType } from '../type';
import InfoIcon from '@material-ui/icons/Info';
import Moment from 'moment';
import DialogHeader from 'components/DialogHeader';

interface IProps {
  formValues: IInitialValueType;
  earnedToken: string;
  loading: boolean;
}

const CouponPreview: React.FC<IProps> = ({ formValues, earnedToken, loading }) => {
  const classes = useStyle();
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Box className={classes.couponPreview} display="flex" flexDirection={'column'} alignItems="flex-start">
        <Box display={'flex'}>
          <Typography variant="h5">
            <strong>{formValues.code}</strong>
          </Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="h6">{formValues.name}</Typography>
        </Box>
        <Box display={'flex'} marginBottom={3}>
          <Typography variant="body1">{formValues.description}</Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          {formValues.orderType.map((type: string, index: number) => {
            return (
              <Typography key={index} variant="subtitle2" className={classes.borderedText}>
                {type}
              </Typography>
            );
          })}
        </Box>
        <Box display={'flex'} marginBottom={1}>
          {formValues.endDate && formValues.endDate !== '' && (
            <Typography variant="subtitle2" className={classes.borderedText}>
              Valid till: {Moment(formValues.endDate).format('MMM/DD/YYYY')}
            </Typography>
          )}
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="subtitle2" className={classes.darkBorderedText}>
            {loading && <CircularProgress size={18} />} Earn Crypto: {earnedToken} <InfoIcon style={{ cursor: 'pointer' }} onClick={handleOpen} />
          </Typography>
        </Box>
      </Box>
      <Dialog className={classes.dialog} open={isOpen} fullScreen={false} fullWidth={true} maxWidth="xs" onClose={handleClose}>
        <DialogHeader text="Info" onBack={handleClose} />

        <DialogContent className={classes.dialogContent}>
          <Box display={'flex'} padding={2} flexDirection="column" alignItems={'flex-start'}>
            <Typography variant="subtitle1">
              You will earn {earnedToken} (CashBack) for using this promotion!
              <br />
              <a href="https://biz.maxcashback.ai/cashbacktoken" style={{ color: '#000', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                Learn about CashBack promotions and see how you can get rich from CashBack
              </a>
              <br />
            </Typography>
            <br />
            <br />
            <Typography variant={'body1'}>
              <strong>View your Tokens</strong>
            </Typography>

            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                console.log('Clicked');
                handleClose();
                // history.push('/wallet');
              }}
              size="small">
              Open Wallet
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CouponPreview;

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2, 4),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  successBtn: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 2),
    minWidth: 'max-content',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      bottom: 0,
      left: 0,
      right: 0,
      position: 'sticky'
    }
  },
  headerBtns: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: '2px'
  }
}));

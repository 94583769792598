import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  businessHours: {
    width: '100%'
  },
  businessHoursHeading: {
    display: 'flex',
    alignItems: 'center'
  },
  addIcon: {
    right: 0,
    margin: theme.spacing(0, 1.5),
    padding: theme.spacing(1),
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200]
  },
  closeButton: {
    marginLeft: 'auto',
    cursor: 'poiter'
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  serviceButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  serviceTable: {
    padding: theme.spacing(1)
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2) + 'px auto',
    width: '100%',
    '& button': {
      margin: theme.spacing(0, 1)
    }
  }
}));

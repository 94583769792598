import React from 'react';
import { Box, Grid, Table, TableHead, TableBody, TableCell, Paper, Typography, TableRow, Button } from '@material-ui/core';
import { map, filter } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import { formatter } from 'util/number';
import AddDishOptionDialog from '../AddDishOptionDialog';
import { IDishOption } from '../types';
import { DragContainerWrapper, DragElementWrapper, DragHandleWrapper } from 'components/DragDrop';
import { SortEnd } from 'react-sortable-hoc';

interface IProps {
  values: IDishOption[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const DishChoice: React.FC<IProps> = ({ values, setFieldValue }) => {
  const getMessage = ({ min, max, isOptional }: Partial<IDishOption>) => {
    if (isOptional === 'true') {
      return `Choose  ${max} of these items`;
    } else {
      if (min === max) {
        return `Choose ${max} of these items`;
      }

      return `Choose ${min} of these items, up to a maximum of ${max} items`;
    }
  };

  const onDeleteHandler = (id: string | undefined) => {
    const newValue = filter(values, ({ id: dishOptionId }) => dishOptionId !== id);

    setFieldValue('dishOptions', newValue);
  };

  const onSortEnd = (sortResult: SortEnd) => {
    const { oldIndex, newIndex } = sortResult;

    if (oldIndex === newIndex) {
      // No dragging
      return;
    }
    const _values = [...values];
    const oldValue = { ..._values[oldIndex] };

    if (newIndex < oldIndex) {
      /*
       * All indexes after newIndex incremented along with oldIndex
       * So add at newIndex
       * Remove at oldIndex + 1
       * */
      _values.splice(newIndex, 0, oldValue);
      _values.splice(oldIndex + 1, 1);
    } else {
      /*
       * Indexes before newIndex should be decremented
       * So add at newIndex + 1
       * Remove at oldIndex
       * */
      _values.splice(newIndex + 1, 0, oldValue);
      _values.splice(oldIndex, 1);
    }
    setFieldValue('dishOptions', _values);
  };

  return (
    <DragContainerWrapper useDragHandle={true} onSortEnd={onSortEnd} axis="xy">
      <Box paddingTop={2}>
        <Grid container={true} spacing={2}>
          {map(values, ({ id, title, items, isOptional, min, max, isMultiQnty }: IDishOption, dishOptionIndex: number) => {
            const initialValues = {
              id,
              title,
              items,
              isOptional,
              min,
              max,
              isMultiQnty
            };

            const message = getMessage({ min, max, isOptional });
            return (
              <DragElementWrapper key={dishOptionIndex} index={dishOptionIndex}>
                <Grid item={true} xs={12} sm={6} lg={4}>
                  <Paper elevation={2}>
                    <Box padding={1} display="flex" justifyContent="space-between" alignItems="baseline">
                      <Grid container={true}>
                        <DragHandleWrapper>
                          <Grid item={true} xs={6}>
                            <Typography variant="body1">{title}</Typography>
                            <Typography variant="caption">{isOptional === 'true' ? 'OPTIONAL' : 'REQUIRED'}</Typography>
                            <Typography variant="subtitle2">{message}</Typography>
                          </Grid>
                        </DragHandleWrapper>
                        <Grid item={true} xs={6}>
                          <Box textAlign="right">
                            <AddDishOptionDialog
                              component={
                                <Button color="primary" size="small" startIcon={<EditIcon />} variant="contained">
                                  Edit
                                </Button>
                              }
                              setFieldValue={setFieldValue}
                              dishOptions={values}
                              hasInitial={true}
                              initialValues={initialValues}
                            />
                            <Box component="span" marginLeft={1}>
                              <Button color="primary" size="small" variant="contained" onClick={() => onDeleteHandler(id)}>
                                Delete
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Item name</TableCell>
                          <TableCell align="right">Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {map(items, ({ title: itemTitle, price }, index: number) => (
                          <TableRow key={index}>
                            <TableCell>{itemTitle}</TableCell>
                            <TableCell align="right">{formatter.format(price)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </DragElementWrapper>
            );
          })}
        </Grid>
      </Box>
    </DragContainerWrapper>
  );
};

export default DishChoice;

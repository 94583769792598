import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200]
  },
  servicesSection: {
    borderTop: 'solid 2px ' + theme.palette.grey[100]
  },
  dialogContent: {
    margin: 0,
    padding: 0,
    '&:first-child': {
      margin: 0,
      padding: 0
    }
  },
  contentContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1)
    }
  },
  tableHeader: {
    borderBottom: 'solid 2px ' + theme.palette.grey[500],
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1)
    }
  },
  serviceButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  closeButton: {
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  openClose: {
    marginLeft: 'auto'
  },
  dayContainer: {
    alignItems: 'center'
  },
  day: {
    color: theme.palette.grey[600]
  },
  timeclosebtn: {
    textAlign: 'center',
    color: theme.palette.grey[600]
  },
  modalBtns: {
    justifyContent: 'flex-end',
    '& button': {
      margin: theme.spacing(1)
    }
  },
  switchContainer: {
    textAlign: 'center'
  },
  copyBtn: {
    margin: 'auto',
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(1.5) + 'px',
    textTransform: 'unset'
  },
  timeInput: {
    margin: theme.spacing(1)
  },
  checkBoxesContainer: {
    padding: theme.spacing(0, 1),
    alignItems: 'center'
  },
  applyBtn: {
    margin: theme.spacing(0.5) + 'px auto',
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(1.5) + 'px',
    textTransform: 'unset'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: 2
  }
}));

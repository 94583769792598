import React from 'react';
import { useStore } from 'store';
import { useActivateGroceryMutation, useGetGroceryStoreById } from 'graphql/hooks/grocery.hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { Dialog } from '@lokobee/lokobee-ui';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

const GroceryStoreStatus = () => {
  const {
    state: { groceryStoreId }
  } = useStore();
  const { activateGroceryStore, error } = useActivateGroceryMutation();
  const { data } = useGetGroceryStoreById(groceryStoreId, 'STATUS');
  const snackbar = useSnackbar();
  const publishGroceryStore = async () => {
    const response = await activateGroceryStore({
      variables: {
        input: {
          id: groceryStoreId
        }
      }
    });
    if (response) {
      snackbar.enqueueSnackbar('Grocery store published successfully.', { variant: 'success' });
    }
  };
  useEffect(() => {
    if (error) {
      snackbar.enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [error, snackbar]);
  if (data && data.ownerGetGroceryStoreById) {
    const { status } = data.ownerGetGroceryStoreById;

    if (status !== 'ACTIVE') {
      return (
        <Box paddingY={1}>
          <Dialog>
            <Button variant="contained" color="primary" disableElevation={true} size="small">
              Publish
            </Button>
            <Dialog.DialogContent>
              <Typography>Are you sure you want to publish your grocery store ?</Typography>
            </Dialog.DialogContent>
            <Dialog.DialogActions onSave={publishGroceryStore} saveBtnText="Yes" cancelBtnText="No" />
          </Dialog>
        </Box>
      );
    }
  }

  return null;
};

export default GroceryStoreStatus;

import React from 'react';
import { useStore } from 'store';
import { useGetRestaurantById, useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { Grid, Typography, Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import restaurantEmptyIcon from 'assets/img/restaurant-empty-icon.png';
import EditIcon from '@material-ui/icons/Edit';
import S3Image from 'components/S3Image';
import { formatPhoneNumber } from 'util/number';
import { useStyles } from './style';
import { useHistory } from 'react-router-dom';
import { Paragraph } from '@lokobee/lokobee-ui';
import RestaurantStatus from './RestaurantStatus';
import { useSnackbar } from 'notistack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StoreCard from '../../RestaurantInfo/TableSetup/StoreCard';
import StripeConnect from '../../PaymentSetup/Setup/StripeConnect';
import MenuCard from '../../RestaurantInfo/TableSetup/MenuCard';

const RestaurantInfoDisplay = () => {
  const classes = useStyles();

  const history = useHistory();

  const {
    state: { restaurant }
  } = useStore();

  const snackbar = useSnackbar();

  const { data } = useGetRestaurantById(restaurant, 'PROFILE');

  const { updateRestaurant } = useUpdateRestaurantMutation('PROFILE');

  const updateDishNoteFlag = async (flag: boolean) => {
    console.log(flag);
    const response = await updateRestaurant({
      variables: {
        input: {
          enableDishNote: flag
        }
      }
    });

    if (response) {
      snackbar.enqueueSnackbar(flag ? 'Dish Note enabled' : 'Dish Note disabled', { variant: 'success' });
    } else {
      snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }
  };

  if (data && data.ownerGetRestaurantById) {
    const { name, description, phone, logo, address, enableDishNote, nanoid, hasStripeAccount } = data.ownerGetRestaurantById;

    const _name = name ? name[0].text : '-';
    const _description = description ? description[0].text : '-';
    const _address = address ? address.text : '-';
    const _phone = phone ? formatPhoneNumber(phone) : '-';
    const { url } = logo || {};

    const redirectToConsumerApp = () => {
      let domain = process.env.NODE_ENV === 'production' ? 'https://lokobee.com' : 'https://lokowoo.com';
      window.open(domain + '/restaurants/' + restaurant, '_blank');
    };

    return (
      <>
        <Grid container={true} className={classes.container}>
          <Grid item={true} xs={12} sm={3} md={2}>
            <Box width="100%" height="100%" margin={0} padding={0} className={logo ? classes.imageContainer : ''}>
              <S3Image src={url} defaultImage={restaurantEmptyIcon} />
            </Box>
          </Grid>
          <Grid item={true} xs={12} sm={5} md={6}>
            <Box className={classes.decriptionContainer}>
              <Typography variant="h6">{_name}</Typography>
              <Paragraph text={_description} variant="body2" />
            </Box>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            {nanoid && (
              <Box display="flex" justifyItems="center" alignItems="center" marginY={1}>
                <Box marginRight={1}>
                  <AccountCircleIcon />
                </Box>
                <Typography variant="body2">ID (can be used for OPS login) : {nanoid} </Typography>
              </Box>
            )}
            <Box display="flex" justifyItems="center" alignItems="center" marginY={1}>
              <Box marginRight={1}>
                <BusinessTwoToneIcon />
              </Box>
              <Typography variant="body2">{_address}</Typography>
            </Box>
            <Box display="flex" justifyItems="center" alignItems="center" marginY={1}>
              <Box marginRight={1}>
                <PhoneIcon />
              </Box>
              <Typography variant="body2">{_phone ? _phone : phone}</Typography>
            </Box>
            <Box display="flex" marginY={1}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon fontSize="small" />}
                  size="small"
                  disableElevation={true}
                  onClick={() => {
                    history.push(`/home/${restaurant}/info`);
                  }}>
                  Edit info
                </Button>
              </Box>
              <Box marginLeft={1}>
                <Button variant="contained" color="primary" size="small" disableElevation={true} onClick={redirectToConsumerApp}>
                  Preview
                </Button>
              </Box>
              <Box marginLeft={1}>
                <RestaurantStatus />
              </Box>
            </Box>
            <Box display="flex" marginY={1}>
              <Box>
                <StoreCard />
              </Box>
              <Box marginLeft={1}>
                <MenuCard />
              </Box>
              {!hasStripeAccount && (
                <Box marginLeft={1}>
                  <StripeConnect />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box padding={1} textAlign="right">
          <FormControlLabel
            control={<Switch checked={enableDishNote} onChange={(event: any, checked: boolean) => updateDishNoteFlag(checked)} color="secondary" />}
            label="Allow dish note : "
            labelPlacement="start"
          />
        </Box>
      </>
    );
  }

  return null;
};

export default RestaurantInfoDisplay;

import gql from 'graphql-tag';
import { CryptoCoupon } from 'graphql/fragments/cryptoCoupon.fragments';

export default gql`
  mutation createCryptoCoupon($input: CreateCryptoCouponInput!) {
    createCryptoCoupon(input: $input) {
      ...CryptoCoupon
    }
  }
  ${CryptoCoupon}
`;

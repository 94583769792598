import React, { useState } from 'react';
import { IDensityMultiplier } from '../../../types';
import { TableCell, TableRow } from '@material-ui/core';
import { useFormik, getIn } from 'formik';
import { useStyles } from '../../style';
import { map } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import * as Yup from 'yup';

interface IProps {
  sizeTitle: string;
  allowedDensities: IDensityMultiplier[];
  regularDensityPrice: string;
  onChange: (value: string) => void;
}

const Row = ({ sizeTitle, allowedDensities, regularDensityPrice, onChange }: IProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);

  const formik = useFormik({
    initialValues: {
      regularPrice: regularDensityPrice
    },
    validationSchema: Yup.object().shape({
      regularPrice: Yup.string().required()
    }),
    onSubmit: (formValues: any) => {
      const { regularPrice } = formValues;
      setEditing(false);
      onChange(regularPrice);
    }
  });

  const { handleChange, handleBlur, touched, errors } = formik;
  const { regularPrice } = formik.values;

  const editForm = (
    <TableRow>
      <TableCell className={classes.cell}>{sizeTitle}</TableCell>
      {map(allowedDensities, ({ density, multiplier }, index) => {
        if (density === 'Regular') {
          return (
            <TableCell key={index} align="center" className={classes.cell}>
              <CurrencyInputField
                name="regularPrice"
                variant="outlined"
                value={regularPrice}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!getIn(touched, 'regularPrice') && !!getIn(errors, 'regularPrice')}
              />
            </TableCell>
          );
        } else {
          return (
            <TableCell key={index} align="center">
              {parseFloat(regularPrice) ? (parseFloat(regularPrice) * multiplier).toFixed(2) : '0.00'}
            </TableCell>
          );
        }
      })}
      <TableCell>
        <CheckIcon fontSize="small" className={classes.successBtn} onClick={formik.submitForm} />
        <ClearIcon
          fontSize="small"
          className={classes.deleteIcon}
          onClick={() => {
            formik.resetForm();
            setEditing(false);
          }}
        />
      </TableCell>
    </TableRow>
  );

  if (editing) {
    return editForm;
  } else {
    return (
      <TableRow>
        <TableCell className={classes.cell}>{sizeTitle}</TableCell>

        {map(allowedDensities, ({ density, multiplier }, index) => {
          if (density === 'Regular') {
            return (
              <TableCell key={index} align="center" className={classes.cell}>
                {regularPrice}
              </TableCell>
            );
          } else {
            return (
              <TableCell key={index} align="center">
                {parseFloat(regularPrice) ? (parseFloat(regularPrice) * multiplier).toFixed(2) : '0.00'}
              </TableCell>
            );
          }
        })}
        <TableCell>
          <EditIcon fontSize="small" className={classes.editIcon} onClick={() => setEditing(true)} />
        </TableCell>
      </TableRow>
    );
  }
};

export default Row;

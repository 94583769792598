import { Box, TextField, Grid, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../style';
import { useGetRestaurantStoreCard } from 'graphql/hooks/restaurant.hooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import NoInfo from '../NoInfo';
interface IProps {
  restaurantId: string;
  initialValues: any;
}
const StoreCardMSP = ({ restaurantId, initialValues }: IProps) => {
  const classes = useStyles();
  const { data } = useGetRestaurantStoreCard(restaurantId);
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (data && data.getStoreCard) {
      const { cardImg } = data.getStoreCard;

      if (cardImg && cardImg.url) {
        setUrl(cardImg.url);
      }
    }
  }, [data]);
  return initialValues && initialValues.name === '' ? (
    <NoInfo text="You need to provide restaurant details" restaurantId={restaurantId} />
  ) : (
    <Grid container>
      <Grid item xs={6} lg={6} md={6}>
        <Box paddingLeft={1} width={'60%'}>
          {initialValues && initialValues.name && (
            <Box marginBottom={2}>
              <TextField className={classes.inputField} variant="outlined" label={'Restaurant Name'} value={initialValues.name} />
            </Box>
          )}
          {initialValues && initialValues.subName && (
            <Box marginBottom={2}>
              <TextField className={classes.inputField} variant="outlined" label={'Restaurant Sub Name'} value={initialValues.subName} />
            </Box>
          )}
          {url !== '' && (
            <Box>
              <a href={url} download="StoreCard.png">
                <Button variant="contained" color="primary" disableElevation={true} startIcon={<GetAppIcon />}>
                  Download
                </Button>
              </a>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={6} lg={6} md={6}>
        <Box display={'flex'} alignItems="center" justifyContent={'center'}>
          {url !== '' && (
            <Box className={classes.cardContainer} width="100%" height={url ? 'auto' : '500px'}>
              <img src={url} className={classes.cardStyle} height="100%" width="100%" alt="StoreCard" />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
export default StoreCardMSP;

import { ITable, ITableSortData } from '../types';
import * as _ from 'lodash';

const sortRows = (data: ITable[], sortBy: ITableSortData) => {
  const _data = [...data];

  if (sortBy.orderBy === 'seats') {
    return _.orderBy(_data, 'seats', sortBy.orderType);
  } else {
    _data.sort((a, b) => {
      if (sortBy.orderType === 'asc') {
        return a.tableName.localeCompare(b.tableName, undefined, { numeric: true, sensitivity: 'base' });
      } else {
        return -a.tableName.localeCompare(b.tableName, undefined, { numeric: true, sensitivity: 'base' });
      }
    });
  }

  return _data;
};

export default sortRows;

import { Box, Button, Divider, Typography } from '@material-ui/core';
import RequestEnable from 'components/RequestEnable';
import React, { useEffect, useState } from 'react';
import CouponsList from './CouponsList';
import AddIcon from '@material-ui/icons/Add';
import { useStyle } from './style';
import { useHistory } from 'react-router-dom';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { useSnackbar } from 'notistack';
import { useRequestCryptoCouponPromotion } from 'graphql/hooks/coupons.hooks';

const CryptoPromotion = () => {
  const classes = useStyle();
  const history = useHistory();
  const snackbar = useSnackbar();

  const { restaurantId } = useActiveRestaurant();
  const { data } = useGetRestaurantById(restaurantId || '', 'STATUS');

  const { requestCryptoCouponPromotion, loading } = useRequestCryptoCouponPromotion();
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  useEffect(() => {
    if (data && data.ownerGetRestaurantById && data.ownerGetRestaurantById.cryptoCouponEnabled) {
      setIsFeatureEnabled(data.ownerGetRestaurantById.cryptoCouponEnabled);
    }
  }, [data]);

  const requestEnableCryptoPromotion = async () => {
    const response = await requestCryptoCouponPromotion({
      variables: {
        input: {
          bizId: restaurantId
        }
      }
    });

    if (response) {
      const { data, errors } = response;

      if (!response.errors) {
        if (data && data.requestCryptoCouponPromotion) {
          snackbar.enqueueSnackbar('Your request has been Processed!', {
            variant: 'success'
          });
          setIsFeatureEnabled(true);
        } else {
          snackbar.enqueueSnackbar('Something went wrong', {
            variant: 'error'
          });
        }
      } else {
        if (errors && errors.length > 0) {
          snackbar.enqueueSnackbar(errors[0].message, {
            variant: 'error'
          });
        }
      }
    } else {
      if (data && data.ownerGetRestaurantById && data.ownerGetRestaurantById.onlineStatus !== 'ACTIVE') {
        snackbar.enqueueSnackbar('Restaurant is not active yet!', {
          variant: 'error'
        });
      } else {
        snackbar.enqueueSnackbar('Something went wrong', {
          variant: 'error'
        });
      }
    }
  };
  if (!data) {
    return null;
  }
  return (
    <>
      <Box bgcolor="white" padding={1}>
        <Box display={'flex'} justifyContent="space-between" width="100%">
          <Typography variant="h5">CashBack Promotions</Typography>

          {isFeatureEnabled && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                history.push(`/home/${restaurantId}/new-coupon`);
              }}>
              Create Promotions
            </Button>
          )}
        </Box>
        <Divider className={classes.divider} />
        {isFeatureEnabled ? <CouponsList /> : <RequestEnable isRequested={loading} requestEnableFeature={requestEnableCryptoPromotion} />}
      </Box>
    </>
  );
};

export default CryptoPromotion;

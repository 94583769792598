import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  phoneInput: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    width: 'max-content',
    borderRadius: 5,
    border: `2px solid ${grey[400]}`,
    '& input': {
      border: 'none'
    },
    '&.react-phone-number-input--focus': {
      border: `2px solid ${theme.palette.primary.main}`
    },
    '&.react-phone-number-input--invalid': {
      border: `2px solid ${theme.palette.error.main}`
    }
  },
  phoneInputLabel: {
    fontSize: '12px'
  }
}));

import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, Button, List, ListItem, ListItemText, Divider, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import { map } from 'lodash';
import arrayMove from 'array-move';
import { useStyles } from './style';
import { useSnackbar } from 'notistack';
import { Theme } from '@material-ui/core/styles';
import { ISortableData } from 'generated/custom';

interface IProps {
  buttonText: string;
  dialogHeaderText: string;
  data: ISortableData[];
  onSave: (data: ISortableData[]) => Promise<boolean>;
}

const Sortable: React.FC<IProps> = ({ buttonText, dialogHeaderText, data, onSave }) => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [masterData, setMasterData] = useState<ISortableData[] | null>(null);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setMasterData(data);
  }, [data]);

  const updateMasterData = (data: ISortableData[], oldIndex: number, newIndex: number) => {
    const orderedData = arrayMove(data, oldIndex, newIndex);

    setMasterData(orderedData);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (masterData && masterData.length) {
      updateMasterData(masterData, oldIndex, newIndex);
    }
  };

  const onMoveUp = (index: number) => {
    if (masterData && masterData.length && index > 0) {
      updateMasterData(masterData, index, index - 1);
    }
  };

  const onMoveDown = (index: number) => {
    if (masterData && masterData.length) {
      updateMasterData(masterData, index, index + 1);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pingErrorSnack = (message: string) => {
    snackbar.enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  const onSaveClick = async () => {
    if (masterData) {
      try {
        const response = await onSave(masterData);
        if (response) {
          handleClose();
        } else {
          pingErrorSnack('Operation failed. Please try again.');
        }
      } catch {
        pingErrorSnack('Operation failed. Please try again.');
      }
    } else {
      pingErrorSnack('Operation failed. Please try again.');
    }
  };

  const DragHandle = SortableHandle(({ index }: any) => (
    <>
      <Box className={classes.dragActions} style={{ cursor: 'move' }}>
        <DragHandleIcon />
      </Box>
      <Box onClick={() => onMoveUp(index)} className={classes.dragActions}>
        <KeyboardArrowUpOutlinedIcon />
      </Box>
      <Box onClick={() => onMoveDown(index)} className={classes.dragActions}>
        <KeyboardArrowDownOutlinedIcon />
      </Box>
    </>
  ));

  const SortableItem = SortableElement(({ value, sortIndex }: any) => (
    <>
      <ListItem className={classes.listItem} button>
        <ListItemText primary={value} />
        <DragHandle index={sortIndex} />
      </ListItem>
      <Divider />
    </>
  ));

  const SortableList = SortableContainer(({ items }: any) => {
    return (
      <List component="nav">
        {map(items, ({ id, title }, index: number) => (
          <SortableItem lockAxis="x" sortIndex={index} key={`item-${title}`} index={index} value={title} />
        ))}
      </List>
    );
  });

  const display = () => {
    if (masterData && masterData.length) {
      return <SortableList useDragHandle helperClass={classes.sorting} items={masterData} onSortEnd={onSortEnd} />;
    }

    return (
      <Box marginTop={6} textAlign="center">
        <Typography variant="subtitle2">No data available</Typography>{' '}
      </Box>
    );
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="contained" color="default" size="small" startIcon={<SwapVerticalCircleIcon fontSize="small" />}>
        {buttonText}
      </Button>

      <Dialog open={open} onClose={handleClose} scroll="paper" fullScreen={fullScreen} fullWidth={true} maxWidth="sm">
        <DialogTitle id="scroll-dialog-title">{dialogHeaderText}</DialogTitle>
        <DialogContent className={classes.dialogContent}>{display()}</DialogContent>
        <DialogActions>
          <Box margin={1}>
            <Button onClick={handleClose} variant="outlined" color="default">
              Cancel
            </Button>
          </Box>
          <Box margin={1}>
            <Button onClick={onSaveClick} variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Sortable;

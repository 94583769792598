import React, { useState, FocusEvent } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { useGetDishCategories } from 'graphql/hooks/dish.hooks';
import { map, uniq, filter } from 'lodash';
import { ICategory } from '../types';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  onBlur: (e: FocusEvent) => void;
  error: boolean;
  setCategories?: React.Dispatch<React.SetStateAction<ICategory[]>>;
}

const CategoriesField = ({ value, onChange, onBlur, error, setCategories }: IProps) => {
  const { data } = useGetDishCategories();

  const [categories, _setCategories] = useState<string[]>([]);

  React.useEffect(() => {
    if (data && data.getDishCategoriesOfRestaurant) {
      const _categories = uniq(
        map(data.getDishCategoriesOfRestaurant, ({ title }) => {
          return title ? title[0].text : '';
        })
      );

      _setCategories(_categories);
      if (setCategories) {
        setCategories(
          map(data.getDishCategoriesOfRestaurant, (category) => {
            const { title } = category;
            return {
              title: title ? title[0].text : '',
              id: category.id
            };
          })
        );
      }
    }
  }, [data, _setCategories, setCategories]);

  return (
    <Autocomplete
      selectOnFocus={false}
      freeSolo={true}
      options={categories}
      value={value}
      onChange={(_e: any, _value: string | null) => {
        onChange(_value ? _value : '');
      }}
      openOnFocus={true}
      renderInput={(params) => (
        <TextField name="category" label="Category" {...params} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)} onBlur={onBlur} variant="outlined" error={error} />
      )}
    />
  );
};

export default CategoriesField;

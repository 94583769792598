import { gql } from 'apollo-boost';

export default gql`
  mutation deleteWebsiteBlog($input: DeleteWebsiteBlogInput!) {
    deleteWebsiteBlog(input: $input) {
      status
      version
    }
  }
`;

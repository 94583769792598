import React, { useState } from 'react';
import { TableCell, TableRow, TextField, MenuItem } from '@material-ui/core';
import { ISize } from '../../types';
import { useStyles } from '../style';
import { useFormik, getIn } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import validate from './validations';
import { ConfirmDialog } from '@lokobee/lokobee-ui';
import { DragHandleWrapper } from 'components/DragDrop';
import { filter } from 'lodash';

interface IProps {
  action: 'edit' | 'new';
  rowData: ISize;
  rows: ISize[];
  onAdd?: (newSize: ISize) => void;
  onEdit?: (updatedSize: ISize) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  allowDelete: boolean;
}

const Row = ({ rowData, action, onAdd, onEdit, onDelete, onCancel, rows, allowDelete }: IProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);

  const formik = useFormik({
    initialValues: { ...rowData },
    onSubmit: (formValues) => handleFormSubmit(formValues),
    validate: (values: ISize) => {
      const errors: any = {};

      const sizePresent = filter(rows, ({ title }) => title.toLowerCase().replaceAll(' ', '') === values.title.toLowerCase().replaceAll(' ', ''));

      if (action === 'new' && sizePresent.length) {
        errors.title = 'Duplicate size';
      }

      return errors;
    }
  });

  const handleFormSubmit = (formValues: ISize) => {
    if (action === 'new') {
      if (onAdd) {
        onAdd(formValues);
      }
    }
    if (action === 'edit') {
      if (onEdit) {
        setEditing(false);
        onEdit(formValues);
      }
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    setEditing(false);
    if (onCancel) {
      onCancel();
    }
  };

  const { handleChange, handleBlur, errors, touched } = formik;
  const { title, description, enableHalfHalf } = formik.values;

  const editForm = (
    <TableRow className={classes.verticleAlignTop}>
      <TableCell>
        <TextField
          name="title"
          variant="outlined"
          fullWidth={true}
          value={title}
          placeholder="Size"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!getIn(touched, 'title') && !!getIn(errors, 'title')}
          helperText={!!getIn(touched, 'title') && !!getIn(errors, 'title') ? errors.title : ''}
        />
      </TableCell>
      <TableCell>
        <TextField
          name="description"
          variant="outlined"
          fullWidth={true}
          value={description ? description : ''}
          placeholder="Description"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!getIn(touched, 'description') && !!getIn(errors, 'description')}
        />
      </TableCell>
      <TableCell>
        <TextField
          select={true}
          fullWidth={true}
          name="enableHalfHalf"
          variant="outlined"
          placeholder="Half and Half supported"
          value={enableHalfHalf}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { values } = formik;
            formik.setValues({
              ...values,
              enableHalfHalf: e.target.value === 'true'
            });
          }}
          onBlur={handleBlur}
          error={!!getIn(touched, 'enableHalfHalf') && !!getIn(errors, 'enableHalfHalf')}>
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </TextField>
      </TableCell>
      <TableCell className={classes.verticleAlignMiddle}>
        <CheckIcon fontSize="small" className={classes.successBtn} onClick={formik.submitForm} />
        <ClearIcon fontSize="small" className={classes.deleteIcon} onClick={handleCancel} />
      </TableCell>
    </TableRow>
  );

  if (action === 'new' || editing) {
    return editForm;
  } else {
    return (
      <TableRow>
        <TableCell style={{ width: '20%' }}>{title}</TableCell>
        <TableCell style={{ width: '40%' }}>{description}</TableCell>
        <TableCell style={{ width: '20%' }}>{enableHalfHalf ? 'Yes' : 'No'}</TableCell>
        <TableCell style={{ width: '20%' }}>
          <EditIcon fontSize="small" className={classes.editIcon} onClick={() => setEditing(true)} />
          {allowDelete && (
            <ConfirmDialog
              onYes={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
              message="Are you sure want to close this row?">
              <DeleteIcon fontSize="small" className={classes.deleteIcon} />
            </ConfirmDialog>
          )}
          <DragHandleWrapper>
            <DragHandleIcon fontSize="small" className={classes.dragIcon} />
          </DragHandleWrapper>
        </TableCell>
      </TableRow>
    );
  }
};

export default Row;

export type PizzaToppingDensity = 'None' | 'Light' | 'Regular' | 'Extra' | 'Double' | 'Triple';
export const PizzaToppingDensityValues: PizzaToppingDensity[] = ['Regular', 'None', 'Light', 'Extra', 'Double', 'Triple'];

export interface ISize {
  id?: string;
  title: string;
  description: string;
  enableHalfHalf: boolean;
}

export interface ICrust {
  id?: string;
  title: string;
  description: string;
  prices: Array<{
    sizeId: string;
    price: string;
  }>;
}

export interface IDensityMultiplier {
  density: PizzaToppingDensity;
  multiplier: number;
  densityAllowed: boolean;
}

export interface IPizzaToppingPrice {
  id?: string;
  sizeId: string;
  regularPrice: string;
  // densityPrices:Array<{
  //   density:PizzaToppingDensity;
  //   price:string;
  // }>
}

export interface IPizzaToppingItem {
  id?: string;
  title: string;
  description: string;
  prices: IPizzaToppingPrice[];
}

export interface IPizzaToppingGroup {
  id?: string;
  title: string;
  description: string;
  enableHalfHalf: boolean;
  densities: IDensityMultiplier[];
  groupPrices: IPizzaToppingPrice[];
  items: IPizzaToppingItem[];
}

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    height: '2px',
    marginTop: '-1px'
  },
  tabsContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  tabs: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5)
  },
  inputContainer: {
    alignItems: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0)
    }
  },
  inputLabel: {
    fontWeight: 'bolder'
  },
  inputField: {
    width: '100%'
  },
  cardContainer: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      '& img': {
        margin: 0,
        padding: 0
      }
    }
  },
  cardStyle: {
    borderRadius: theme.spacing(1.5),
    boxShadow: '0 0 13px -3px black',
    width: '100%'
  },
  table: {
    border: 'solid 1px ' + theme.palette.grey[300],
    borderCollapse: 'unset'
  },
  borderRight: {
    borderRight: 'solid 1px ' + theme.palette.grey[300]
  },
  selectedTableCell: {
    background: '#F4F4F4'
  }
}));

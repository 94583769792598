import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import req_enable from 'assets/img/request_enable.svg';
import TelegramIcon from '@material-ui/icons/Telegram';
interface IProps {
  requestEnableFeature: () => void;
  isRequested?: boolean | null;
}
const RequestEnable: React.FC<IProps> = ({ requestEnableFeature, isRequested }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems="center" width={'100%'} marginTop={2}>
      {isRequested ? (
        <Typography variant="body1">Please wait while we process your request</Typography>
      ) : (
        <>
          <Typography variant="body1">This Feature is not enabled for your restaurant.</Typography>
          <Typography variant="body1">
            The <strong>Request Button</strong> is all you need to do to start the program.
          </Typography>{' '}
        </>
      )}
      <Box marginTop={3} display={'flex'} justifyContent="center" width={'100%'}>
        <img src={req_enable} alt="" />
      </Box>
      {!isRequested && (
        <Box marginTop={2}>
          <Button startIcon={<TelegramIcon />} onClick={requestEnableFeature} variant="contained" color="primary">
            REQUEST TO ENABLE
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RequestEnable;

import moment, {Moment} from 'moment';

export const isSameYear = (startDate: Moment, endDate: Moment) => {
  return startDate.format("YYYY") === endDate.format("YYYY") ? true : false
}

export const Date = {
  convertDateToMeridienFormat: (hour: number, minutes: string) => {
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? ' AM' : ' PM';
    return h + ':' + minutes.toString() + ampm;
  },

  lpadNumber: (input: any, digits: any) => {
    return Array(Math.max(digits - String(input).length + 1, 0)).join('0') + input;
  }
};

export const getWeekRange = (subtractWeek: number = 0) => {
  const weekStart = moment()
    .subtract(subtractWeek, 'weeks')
    .startOf('isoWeek')

  const weekEnd = moment()
    .subtract(subtractWeek, 'weeks')
    .endOf('isoWeek')

  return {weekStart, weekEnd}
}

export const formatTo = (date: string, sourceFormat: string, format: string): string => {
  return moment(date, sourceFormat).format(format)
}
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Articles } from 'generated/types';
import { useHistory } from 'react-router-dom';
import { Box, Button, TextField } from '@material-ui/core';
import UploadImage from 'components/UploadImage';
import { FormikProps, useFormik, getIn } from 'formik';
import SaveIcon from '@material-ui/icons/Save';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useCreateBlogMutation, useUpdateBlogMutation } from 'graphql/hooks/websiteAutomation.hooks';
import { IArticles } from '../types';
import DeleteBlogArticle from '../DeleteBlogArticle';
import ValidationSchema from './validations';

interface IProps {
  blogId?: string;
  initialValues: IArticles;

  getUploadId: (filename: string, imageData: string) => Promise<string | null>;
  isNewArticle: boolean;
}
const AddEditSingleBlogArticleForm: React.FC<IProps> = ({ blogId, initialValues, isNewArticle, getUploadId }) => {
  const history = useHistory();
  const snackbar = useSnackbar();

  const { restaurantId } = useActiveRestaurant();
  const [openDelete, setOpenDelete] = useState(false);
  const { updateWebsiteBlog, error: updateError } = useUpdateBlogMutation(restaurantId);
  const { createWebsiteBlog, error: createError } = useCreateBlogMutation(restaurantId);

  const submitForUpdate = async (articleData: IArticles) => {
    const response = await updateWebsiteBlog({
      variables: {
        input: {
          bizId: restaurantId,
          id: blogId,
          title: articleData.title,
          text: articleData.text,
          picture: articleData.picture,
          version: articleData.version
        }
      }
    });
    if (response && response.data) {
      snackbar.enqueueSnackbar('Article Updated Successfully', {
        variant: 'success'
      });
      history.push(`/home/${restaurantId}/blogs`);
    }
  };
  const submitForCreate = async (articleData: IArticles) => {
    formik.setSubmitting(true);
    const response = await createWebsiteBlog({
      variables: {
        input: {
          bizId: restaurantId,
          title: articleData.title,
          text: articleData.text,
          picture: articleData.picture,
          version: articleData.version
        }
      }
    });
    formik.setSubmitting(false);
    if (response && response.data) {
      snackbar.enqueueSnackbar('Article Created Successfully', {
        variant: 'success'
      });
      history.push(`/home/${restaurantId}/blogs`);
    }
  };
  const handleFormSubmit = async (formValues: IArticles) => {
    formik.setSubmitting(true);
    const articleData = await new Promise<any>(async (resolve, reject) => {
      if (formValues.imageData) {
        const imageId = await getUploadId(formValues.imageData.filename, formValues.imageData.imageFiledata);
        if (!imageId) {
          reject('Blog section image upload failed');
        } else {
          resolve({
            picture: formValues.imageData.filename ? imageId : undefined,
            title: formValues.title,
            text: formValues.text,
            id: initialValues && initialValues.id ? initialValues.id : undefined,
            version: initialValues.version
          });
        }
      } else {
        resolve({
          title: formValues.title,
          text: formValues.text,
          id: initialValues && initialValues.id ? initialValues.id : undefined,
          version: initialValues.version
        });
      }
    });
    if (isNewArticle) {
      submitForCreate(articleData);
    } else {
      submitForUpdate(articleData);
    }
  };
  const formik: FormikProps<IArticles> = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });
  useEffect(() => {
    if (updateError && updateError.graphQLErrors && updateError.graphQLErrors.length > 0) {
      updateError.graphQLErrors.forEach((gqlError) => {
        snackbar.enqueueSnackbar(gqlError.message, {
          variant: 'error'
        });
      });
    }
    if (createError && createError.graphQLErrors && createError.graphQLErrors.length > 0) {
      createError.graphQLErrors.forEach((gqlError) => {
        snackbar.enqueueSnackbar(gqlError.message, {
          variant: 'error'
        });
      });
    }
  }, [snackbar, updateError, createError]);
  const { values, handleChange, handleSubmit, handleBlur } = formik;
  const onBlogItemImageCrop = (filename: string, fileData: string) => {
    formik.setValues({
      ...formik.values,
      imageData: {
        filename,
        imageFiledata: fileData
      }
    });
  };
  const goBack = () => {
    history.goBack();
  };
  const onDeleteSucess = () => {
    history.goBack();
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        {' '}
        <Box marginY={3} display="flex" flexDirection={'column'} alignItems="flex-start">
          <Box paddingX={1} marginBottom={3} overflow="hidden">
            <UploadImage
              id={`blog${values.id}`}
              width={300}
              height={200}
              onCrop={(filename, imgData) => onBlogItemImageCrop(filename, imgData)}
              initialValue={values.picture && values.picture.id ? values.picture.url : undefined}
            />
          </Box>
          <Box width={'100%'} display="flex" flexDirection={'column'}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              name={'title'}
              value={formik.values.title}
              type="text"
              label="Enter title"
              style={{ marginBottom: '12px' }}
              error={!!getIn(formik.touched, 'title') && !!getIn(formik.errors, 'title')}
              helperText={!!getIn(formik.touched, 'title') && <>{!!getIn(formik.errors, 'title')}</>}
            />
            <TextField
              variant="outlined"
              name={'text'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.text}
              type="text"
              multiline={true}
              rows={30}
              label="Enter article body"
              style={{ marginBottom: '12px' }}
              error={!!getIn(formik.touched, 'text') && !!getIn(formik.errors, 'text')}
              helperText={!!getIn(formik.touched, 'text') && !!getIn(formik.errors, 'text')}
            />
          </Box>
          <Box display={'flex'}>
            <Box marginRight={1}>
              <Button variant="contained" color="primary" onClick={goBack}>
                Cancel
              </Button>
            </Box>

            {!isNewArticle && (
              <Box marginRight={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpenDelete(true);
                  }}>
                  Remove
                </Button>
              </Box>
            )}
            <Box marginRight={1}>
              <Button disabled={formik.isSubmitting || !formik.isValid} variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      <DeleteBlogArticle
        bizId={restaurantId || ''}
        blogId={initialValues.id || ''}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        version={initialValues.version}
        onDeleteSucess={onDeleteSucess}
      />
    </>
  );
};

export default AddEditSingleBlogArticleForm;

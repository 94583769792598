import React, { useEffect, useState } from 'react';
import BusinessHours from './BusinessHours';
import { IBizHour } from '../types';
import mockdata from './mockdata.json';
import { useStore } from 'store';
import * as _ from 'lodash';
import { useGetGroceryStoreById } from 'graphql/hooks/grocery.hooks';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { groceryStoreId }
  } = useStore();

  const [initialValues, setinitialValues] = useState(
    mockdata.map((mockdata: any) => {
      return { ...mockdata };
    })
  );
  const { data } = useGetGroceryStoreById(groceryStoreId, 'BUSINESS_HOURS');

  useEffect(() => {
    if (data && data.ownerGetGroceryStoreById) {
      const { bizHours: _bizHours } = data.ownerGetGroceryStoreById;
      if (_bizHours) {
        setinitialValues(
          _.map(_bizHours, (hour: IBizHour) => {
            return { serviceName: hour.serviceName, serviceType: hour.serviceType, hours: hour.hours.map((h) => _.omit(h, ['__typename'])) };
          })
        );
      }
    }
  }, [data]);

  if (data && data.ownerGetGroceryStoreById) {
    return <BusinessHours ref={ref} initialValues={initialValues} nextStep={nextStep} />;
  }

  return null;
});

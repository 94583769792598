import { makeStyles } from '@material-ui/core';
import { IStyleProps } from './types';

export const useStyles = makeStyles((theme) => ({
  root: ({ backgroundImage }: IStyleProps) => ({
    backgroundImage,
    backgroundSize: 'cover',
    height: '100vh',
    position: 'relative' as any
  }),
  paper: {
    minWidth: 500,
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 20,
    position: 'absolute',
    top: 100,
    left: 100
  },
  appIcon: {
    width: 100,
    height: 100,
    margin: 20
  },
  backIcon: {
    alignSelf: 'flex-start',
    padding: theme.spacing(2),
    position: 'absolute',
    cursor: 'pointor'
  }
}));

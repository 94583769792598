import gql from 'graphql-tag';
import { Profile, BusinessHours, Announcement, RewardSetting, Gallery, PaymentSettings } from 'graphql/fragments/groceryStore.fragments';

export const createGroceryStore = gql`
  mutation createGroceryStore($input: CreateGroceryStoreInput!) {
    createGroceryStore(input: $input) {
      id
    }
  }
`;

export const deleteGroceryStore = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
    }
  }
`;

export const updateGroceryStoreProfile = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
      ...Profile
    }
  }
  ${Profile}
`;

export const updateGroceryStoreBusinessHours = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
      ...BusinessHours
    }
  }
  ${BusinessHours}
`;

export const updateGroceryStoreGallery = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
      ...Gallery
    }
  }
  ${Gallery}
`;

export const updateGroceryStoreAnnouncement = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
      ...Announcement
    }
  }
  ${Announcement}
`;

export const updateGroceryStoreRewardSetting = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
      ...RewardSetting
    }
  }
  ${RewardSetting}
`;

export const updateGroceryStorePaymentSettings = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
      ...PaymentSettings
      ...BusinessHours
    }
  }
  ${PaymentSettings}
  ${BusinessHours}
`;

export const updateGroceryStoreItems = gql`
  mutation updateGroceryStore($input: UpdateGroceryStoreInput!) {
    updateGroceryStore(input: $input) {
      id
    }
  }
`;

export const activateGroceryStoreMutation = gql`
  mutation activateGroceryStore($input: ActivateGroceryStoreInput!) {
    activateGroceryStore(input: $input)
  }
`;

import { makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => ({
  addItemContainer: {
    padding: theme.spacing(2)
  },
  defaultItemBox: {
    backgroundColor: grey[200]
  },
  addItemBox: {
    backgroundColor: grey[200],
    minHeight: '250px'
  },
  addIcon: {
    fontSize: 40
  },
  divider: {
    margin: theme.spacing(1, 0),
    height: '2px'
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  dataBox: {
    cursor: 'pointer'
  },
  gridContainer: {
    marginTop: theme.spacing(2),
    
    paddingBottom:theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formControls: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  formInput: {
    margin: theme.spacing(1, 0)
  },
  deleteIcon: {
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none'
    }
  }
}));

import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useStyle } from '../style';
import coinImg from 'assets/img/cc_coin_img.png';

const SellCC = () => {
  const classes = useStyle();
  return (
    <>
      <Box width={'80%'} display="flex" marginX={'auto'} marginY={0} flexDirection={'column'} alignItems={'center'}>
        <Box display={'flex'} flex="1" width={'100%'} justifyContent={'center'} marginBottom={5}>
          <Box marginRight={2}>
            <img src={coinImg} className={classes.coinImg} width="100%" alt="CBT Coin" />
          </Box>
          <Typography variant="h6" align="center" className={classes.askToSell}>
            Are you sure <br /> you want to sell your CashBack Tokens (CBT)?
          </Typography>
        </Box>
        <Box display={'flex'} flex="1" width={'100%'} justifyContent={'center'} marginBottom={5}>
          <Box marginRight={2}>
            <Card className={classes.cardItem}>
              <CardContent>
                <Box marginBottom={2}>
                  <Typography variant="body1" gutterBottom>
                    Learn how the price of CBT increases as more CashBack Promotions get used over time!
                  </Typography>
                </Box>
                <Button variant="contained" color="primary" size="small">
                  Learn more
                </Button>
              </CardContent>
            </Card>
          </Box>
          <Box marginRight={2}>
            <Card className={classes.cardItem}>
              <CardContent>
                <Box marginBottom={2}>
                  <Typography variant="body1" gutterBottom>
                    <strong>CashBack Tokens </strong> represents your ownership in <strong>MaxCashback</strong>
                  </Typography>
                </Box>
                <Button variant="contained" color="primary" size="small">
                  MaxCashback Website
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box display={'flex'} flex="1" width={'100%'} justifyContent={'center'} marginBottom={5}>
          <Typography variant="body1">
            Sell your CBT when token starts trading on Exchange <br />
            <i>Fun Fact: BitCoin started trading at 7 centes</i>
          </Typography>
        </Box>
        <Button variant="contained" color="primary" size="medium" disabled>
          Sell CashBack Tokens (Coming Soon)
        </Button>
      </Box>
    </>
  );
};

export default SellCC;

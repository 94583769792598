import { Box, Button, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyle } from '../style';
import { FormikProps, useFormik } from 'formik';
import { ITextBroadcastUI } from '../types';
import * as _ from 'lodash';
import PhoneInput from 'react-phone-number-input';
import TimePicker from './TimePicker';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Moment from 'moment';
import { useCreateRestaurantTextBroadcast, useUpdateRestaurantTextBroadcast, useDeleteTextBroadcast } from 'graphql/hooks/textMarketing.hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useSnackbar } from 'notistack';
import { Dialog } from '@lokobee/lokobee-ui';
import { validationSchema } from './validations';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
interface IProps {
  initialValues: ITextBroadcastUI;
  isBroadcastExist: boolean;
  setIsBroadcastExist: React.Dispatch<React.SetStateAction<boolean>>;
  setInitialValues: React.Dispatch<React.SetStateAction<ITextBroadcastUI>>;
}
const TextSchedulerForm: React.FC<IProps> = ({ initialValues, isBroadcastExist, setIsBroadcastExist, setInitialValues }) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednsday', 'Thursday', 'Friday', 'Saturday'];
  const classes = useStyle();
  const { restaurantId } = useActiveRestaurant();
  const [countDownValue, setCountDownValue] = useState<string>('');
  const [open, setOpenToCreate] = useState(false);
  const [isOpenToUpdate, setIsOpenToUpdate] = useState(false);
  const [isOpenToStop, setIsOpenToStop] = useState(false);
  const [isEditEnable, setIsEditEnable] = useState(false);
  const snackbar = useSnackbar();
  const { updateTextBroadcast } = useUpdateRestaurantTextBroadcast();
  const { createTextBroadcast } = useCreateRestaurantTextBroadcast();
  const { deleteTextBroadcast } = useDeleteTextBroadcast();

  const handleFormSubmit = async (formValues: ITextBroadcastUI) => {
    try {
      if (isBroadcastExist && !isEditEnable) {
        setIsEditEnable(true);
      } else {
        if (isBroadcastExist) {
          setIsOpenToUpdate(true);
        } else {
          submitNewBroadcast(formik.values);
          // setOpenToCreate(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleDeleteTextBroadcast = async (formValues: ITextBroadcastUI) => {
    try {
      if (isBroadcastExist) {
        const response = await deleteTextBroadcast({
          variables: {
            input: {
              bizId: restaurantId,
              id: formValues.id
            }
          }
        });
        if (response) {
          setIsOpenToStop(false);
          if (!response.errors) {
            setIsEditEnable(true);
            setIsBroadcastExist(false);
            setInitialValues({
              id: '',
              textDescription: '',
              bizPhoneNumber: '',
              frequency: 'WEEKLY',
              dayOfWeek: 1,
              timeHour: 0,
              timeMin: 0,
              nextBroadcast: null,
              lastBroadcast: null
            });
            snackbar.enqueueSnackbar('Broadcast Stopped successfully!', {
              variant: 'success'
            });
          } else {
            snackbar.enqueueSnackbar('Oops!, something went wrong!', {
              variant: 'error'
            });
          }
        } else {
          snackbar.enqueueSnackbar('Oops!, something went wrong!', {
            variant: 'error'
          });
        }
      }
    } catch (e) {}
  };
  const handleUpdateTextBroadcast = async (formValues: ITextBroadcastUI) => {
    try {
      if (isBroadcastExist) {
        const response = await updateTextBroadcast({
          variables: {
            input: {
              bizId: restaurantId,
              bizPhoneNumber: formValues.bizPhoneNumber,
              dayOfWeek: formValues.dayOfWeek + 1,
              timeHour: formValues.timeHour,
              timeMin: formValues.timeMin,
              textBody: formValues.textHeading && formValues.textHeading !== '' ? formValues.textHeading + ' ' + formValues.textDescription : formValues.textDescription
            }
          }
        });
        if (response) {
          if (!response.errors) {
            setIsEditEnable(false);
            setIsOpenToUpdate(false);
            snackbar.enqueueSnackbar('Broadcast updated successfully!', {
              variant: 'success'
            });
          } else {
            setIsEditEnable(false);
            setIsOpenToUpdate(false);
            snackbar.enqueueSnackbar('Oops!, something went wrong!', {
              variant: 'error'
            });
          }
        } else {
          setIsEditEnable(false);
          setIsOpenToUpdate(false);
          snackbar.enqueueSnackbar('Oops!, something went wrong!', {
            variant: 'error'
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const submitNewBroadcast = async (formValues: ITextBroadcastUI) => {
    const response = await createTextBroadcast({
      variables: {
        input: {
          bizId: restaurantId,
          bizPhoneNumber: formValues.bizPhoneNumber,
          // frequency: formValues.frequency,
          dayOfWeek: formValues.dayOfWeek + 1,
          timeHour: formValues.timeHour,
          timeMin: formValues.timeMin,
          textBody: formValues.textHeading && formValues.textHeading !== '' ? formValues.textHeading + ' ' + formValues.textDescription : formValues.textDescription
        }
      }
    });
    if (!response.errors) {
      setOpenToCreate(false);
      setIsEditEnable(false);
      snackbar.enqueueSnackbar('Broadcast created successfully!', {
        variant: 'success'
      });
    } else {
      setOpenToCreate(false);
      snackbar.enqueueSnackbar('Oops!, something went wrong!', {
        variant: 'error'
      });
    }
  };
  const formik: FormikProps<ITextBroadcastUI> = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });

  const onChange = (e: any, name: string) => {
    formik.setValues({
      ...formik.values,
      [name]: e
    });
  };

  const handleTimeChange = (value: string) => {
    let newHour = -1;
    let newMin = -1;
    if (value.trim() !== '') {
      const [_hour, _min] = value.split(':');
      newHour = parseInt(_hour);
      newMin = parseInt(_min);
      formik.setValues({ ...formik.values, timeHour: newHour, timeMin: newMin });
    }
  };

  useEffect(() => {
    const calculateCountDown = () => {
      let eventTime = initialValues.nextBroadcast ? initialValues.nextBroadcast : Math.floor(Date.now() / 1000);
      let countDownDate = new Date(eventTime).getTime();
      // Update the count down every 1 second
      // Get today's date and time
      let now = new Date().getTime();
      // Find the distance between now and the count down date
      let distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // Display the result in the element with id="demo"
      return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
    };
    setCountDownValue(calculateCountDown);
    const interval = setInterval(() => {
      setCountDownValue(calculateCountDown);
    }, 1000);
    return () => clearInterval(interval);
  }, [initialValues.nextBroadcast]);

  const isError = (formik: any, field: string) => {
    return formik && formik.touched[field] && formik.errors[field] !== '' && formik.errors[field] !== undefined;
  };
  return (
    <>
      <Box width={'100%'} display="flex" flexDirection={'column'}>
        <Box marginBottom={3} display="flex" justifyContent={'space-between'} width="100%">
          <Typography variant="h6">Current Month</Typography>
          <Box display={'flex'}>
            <Grid container spacing={2}>
              <Grid item>
                {initialValues && initialValues.lastBroadcast && initialValues.lastBroadcast !== 0 && (
                  <Box paddingX={2} paddingY={1} style={{ textAlign: 'right' }}>
                    <Typography variant="body1" style={{ textAlign: 'right' }} align="right">
                      <strong>Last Text broadcast Happened</strong>
                    </Typography>
                    <Typography variant="caption" style={{ textAlign: 'right' }} align="right">
                      {Moment(initialValues.lastBroadcast).format('MMMM Do, yyyy | hh:mm a')}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item>
                {initialValues && initialValues.nextBroadcast && initialValues.nextBroadcast !== 0 && (
                  <Box className={classes.greenBg} paddingX={2} paddingY={1}>
                    <Typography className={classes.greenText} variant="body1">
                      <strong>Countdown to next Broadcast</strong>
                    </Typography>
                    <Typography className={classes.greenText} variant="caption">
                      {Moment(initialValues.nextBroadcast).format('MMMM Do, yyyy | hh:mm a')}&nbsp;{countDownValue}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            {formik.values['textHeading'] && formik.values['textHeading'] !== '' && (
              <Grid item xs={12}>
                <Box className={classes.inputContainer}>
                  <TextField
                    name="textHeading"
                    type="text"
                    className={classes.inputStyle}
                    placeholder="Heading (read only)"
                    label="Heading (read only)"
                    inputProps={{
                      maxLength: 200,
                      readOnly: true
                    }}
                    rows={4}
                    value={formik.values['textHeading']}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={isError(formik, 'textHeading')}
                    helperText={isError(formik, 'textHeading') ? formik.errors['textHeading'] : ''}
                    fullWidth={true}
                    multiline={true}
                    required={true}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box className={classes.inputContainer}>
                <TextField
                  name="textDescription"
                  type="text"
                  className={`${classes.inputStyle} ${isBroadcastExist && !isEditEnable ? 'customClassTimePicker' : ''}`}
                  placeholder="Text Body*"
                  label="Text Body"
                  inputProps={{
                    maxLength: 400,
                    readOnly: !isEditEnable && isBroadcastExist
                  }}
                  rows={6}
                  value={formik.values['textDescription']}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={isError(formik, 'textDescription')}
                  helperText={isError(formik, 'textDescription') ? formik.errors['textDescription'] : ''}
                  fullWidth={true}
                  multiline={true}
                  required={true}
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Box className={classes.inputContainer}>
                <InputLabel className={classes.inputLabel}>Phone number (for test Message)</InputLabel>
                <PhoneInput
                  placeholder="Phone Number *"
                  className={formik.errors.bizPhoneNumber && formik.touched.bizPhoneNumber ? `${classes.customInputError} + ${classes.customInput}` : `${classes.customInput}`}
                  name="bizPhoneNumber"
                  defaultCountry="US"
                  value={formik.values.bizPhoneNumber}
                  onChange={(value: string) => onChange(value, 'bizPhoneNumber')}
                  onBlur={formik.handleBlur('bizPhoneNumber')}
                  variant="outlined"
                  label="Phone Number"
                  readOnly={!isEditEnable && isBroadcastExist}
                  style={{ padding: '18.5px 14px', border: isBroadcastExist && !isEditEnable ? 'none' : 'solid 1px #bdbdbd' }}
                />
                {formik.errors.bizPhoneNumber && formik.touched.bizPhoneNumber && (
                  <Typography variant="caption" color="error">
                    {formik.errors.bizPhoneNumber}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.inputContainer}>
                <FormControl error={formik.errors.dayOfWeek !== '' && formik.errors.dayOfWeek !== undefined && formik.touched.dayOfWeek} fullWidth={true}>
                  <InputLabel>Send Day</InputLabel>
                  <Select
                    id="demo-simple-select-error"
                    value={formik.values.dayOfWeek}
                    IconComponent={isBroadcastExist && !isEditEnable ? 'br' : undefined}
                    className={
                      formik.errors.dayOfWeek !== '' && formik.errors.dayOfWeek !== undefined && formik.touched.dayOfWeek
                        ? `${classes.customInputError} + ${classes.customInput}`
                        : `${classes.customInput}`
                    }
                    style={{ border: isBroadcastExist && !isEditEnable ? 'none' : 'solid 1px #bdbdbd' }}
                    readOnly={isBroadcastExist && !isEditEnable}
                    onChange={(e) => onChange(e.target.value, 'dayOfWeek')}
                    onBlur={formik.handleBlur('dayOfWeek')}>
                    {_.map(days, (day: any, index: number) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {day}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.errors.dayOfWeek !== '' && formik.errors.dayOfWeek !== undefined && formik.touched.dayOfWeek && <FormHelperText>{formik.errors.dayOfWeek}</FormHelperText>}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.inputContainer}>
                <InputLabel className={classes.inputLabel}>Send Time</InputLabel>
                <TimePicker
                  className={isBroadcastExist && !isEditEnable ? 'customClassTimePicker' : ''}
                  hour={formik.values.timeHour}
                  minute={formik.values.timeMin}
                  onChange={(value: string) => {
                    handleTimeChange(value);
                  }}
                  readOnly={isBroadcastExist && !isEditEnable}
                />
              </Box>
            </Grid>
          </Grid>
          <Box marginTop={2}>
            <Button type="submit" variant="contained" color="primary">
              {isBroadcastExist && !isEditEnable ? 'Update' : 'Schedule'}
            </Button>{' '}
            &nbsp;&nbsp;
            {isBroadcastExist && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setIsOpenToStop(true);
                }}>
                Stop Sending Text
              </Button>
            )}
          </Box>
          <Box>
            {/* Dialog at Create Broadcast */}
            {/* <Dialog open={open} setOpen={setOpenToCreate}>
              <Box padding={2}>
                <DialogTitle id="scroll-dialog-title">
                  <Box display={'flex'} alignItems="center">
                    <CheckCircleOutlineIcon className={classes.checkIconStyle} width={40} />
                    <Typography variant="h4">Are you sure ?</Typography>
                  </Box>
                </DialogTitle>
                <Dialog.DialogContent>
                  <Typography variant="body1">Once schedule is enabled, it is fixed for entire month. You can change it only on first day of next month. Some fields are still editable.</Typography>
                </Dialog.DialogContent>
                <Box width={'100%'} display="flex" justifyContent={'flex-end'}>
                  <Box padding={2} display="flex" justifyContent={'space-between'}>
                    <Box marginRight={2}>
                      <Button variant="contained" onClick={() => setOpenToCreate(false)}>
                        No
                      </Button>
                    </Box>
                    <Box>
                      <Button type="submit" onClick={() => submitNewBroadcast(formik.values)} variant="contained" className={classes.successBtn}>
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Dialog> */}
            {/* Dialog at Update Broadcast */}
            <Dialog open={isOpenToUpdate} setOpen={setIsOpenToUpdate}>
              <Box padding={2}>
                <DialogTitle id="scroll-dialog-title">
                  <Box display={'flex'} alignItems="center">
                    <CheckCircleOutlineIcon className={classes.checkIconStyle} width={40} />
                    <Typography variant="h4">Are you sure ?</Typography>
                  </Box>
                </DialogTitle>
                <Dialog.DialogContent>
                  <Typography variant="body1">Beginning a new text broadcast. Previous broadcast will be removed.</Typography>
                </Dialog.DialogContent>
                <Box width={'100%'} display="flex" justifyContent={'flex-end'}>
                  <Box padding={2} display="flex" justifyContent={'space-between'}>
                    <Box marginRight={2}>
                      <Button variant="contained" onClick={() => setIsOpenToUpdate(false)}>
                        No
                      </Button>
                    </Box>
                    <Box>
                      <Button type="submit" onClick={() => handleUpdateTextBroadcast(formik.values)} variant="contained" className={classes.successBtn}>
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Dialog>
            {/* Dialog at Stop Broadcast */}
            <Dialog open={isOpenToStop} setOpen={setIsOpenToStop}>
              <Box padding={2}>
                <DialogTitle id="scroll-dialog-title">
                  <Box display={'flex'} alignItems="center">
                    <HelpOutlineIcon className={classes.checkIconStyleRed} width={40} />
                    <Typography variant="h4">Are you sure ?</Typography>
                  </Box>
                </DialogTitle>
                <Dialog.DialogContent>
                  <Typography variant="body1">Are you sure you want to cancel the text schedule?</Typography>
                  <Typography variant="body1">Even if you disable the schedule, You'll still be charged until you stop the subscription with the provider</Typography>
                </Dialog.DialogContent>
                <Box width={'100%'} display="flex" justifyContent={'flex-end'}>
                  <Box padding={2} display="flex" justifyContent={'space-between'}>
                    <Box marginRight={2}>
                      <Button variant="contained" onClick={() => setIsOpenToStop(false)}>
                        No
                      </Button>
                    </Box>
                    <Box>
                      <Button type="submit" onClick={() => handleDeleteTextBroadcast(formik.values)} variant="contained" className={classes.successBtn}>
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Dialog>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default TextSchedulerForm;

import { gql } from 'apollo-boost';

export default gql`
  query($id: GeoPointInput!, $miles: Float!) {
    getRestaurantsWithinMiles(userGeoPoint: $id, maxDistance: $miles) {
      id
      name
      imageUrls
      logoUrl
      description
    }
  }
`;

import * as Yup from 'yup';
import Big from 'big.js';

const regex = new RegExp(/^[0-9]+(\.[0-9][0-9]?)?$/);

export default Yup.object().shape({
  dishName: Yup.string().required('Please enter Dish name'),
  category: Yup.string().required('Please enter category'),
  isDiscounted: Yup.boolean(),
  prices: Yup.array().of(
    Yup.object().shape({
      size: Yup.string().required('Please enter size'),
      price: Yup.string()
        .required('Please enter price')
        .matches(regex, 'Invalid price value'),
      discountAmount: Yup.string()
        .matches(regex, 'Invalid discount value')
        .test({
          name: 'equality-test', 
          message: 'Discount must be less than Price', 
          test: function (discountAmount: string) {
            
            if (!this.parent.isDiscounted || !discountAmount) {
              return true;
            }
            if (Big(discountAmount).gte(this.parent.price)) {
              return false;
            } else {
              return true;
            }
          }
        })
    })
  )
});

import { useContext } from 'react';
import { AuthContext } from 'fbase/authContext';

const useDownloadImage = () => {
  const { s3 } = useContext(AuthContext);

  const fetchImage = async (bucket: string, key: string, repeat = 5): Promise<string | null> => {
    try {
      if (s3) {
        const signedUrl = await s3.getSignedUrlPromise('getObject', {
          Bucket: bucket,
          Key: key
        });

        const response = await fetch(signedUrl);

        if (response && response.status === 200) {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        } else return null;
      } else {
        console.log('[ERROR] S3 NOT DEFINED');
        return null;
      }
    } catch (e) {
      if (repeat === 1) {
        console.log('FETCH IMAGE PROBLEM');
        return null;
      }

      return fetchImage(bucket, key, repeat - 1);
    }
  };

  return {
    fetchImage
  };
};

export default useDownloadImage;

import gql from 'graphql-tag';
export const TextBroadcastInfo = gql`
  fragment TextBroadcastInfo on TextBroadcast {
    bizId
    id
    bizPhoneNumber
    dayOfWeek
    timeHour
    timeMin
    textBody
    lastBroadcast

    nextBroadcast
    status
  }
`;

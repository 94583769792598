import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  modalBtns: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
    '& button': {
      margin: theme.spacing(1)
    }
  },
  cardContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      '& img': {
        margin: 0,
        padding: 0
      }
    }
  }
}));

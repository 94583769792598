import React, { useEffect, useState } from 'react';
import Gallery from './Gallery';
import { useStore } from 'store';
import { IImage } from '../types';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import * as _ from 'lodash';
import { useGetGroceryStoreById, useUpdateGroceryStoreMutation } from 'graphql/hooks/grocery.hooks';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { groceryStoreId }
  } = useStore();

  const { data } = useGetGroceryStoreById(groceryStoreId, 'GALLERY');

  const [initialValues, setInitialValues] = useState<IImage[]>([]);

  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();

  const { updateGroceryStore } = useUpdateGroceryStoreMutation('GALLERY');

  useEffect(() => {
    if (data && data.ownerGetGroceryStoreById) {
      const { gallery } = data.ownerGetGroceryStoreById;
      if (gallery) {
        setInitialValues(
          _.map(gallery, (img: any) => {
            if (img.md) {
              return img.md;
            }
          })
        );
      }
    }
  }, [data]);

  const getUploadId = async (filename: string, imageData: string) => {
    try {
      const { data: uploadImageResponse } = await createImageUploadUrl({
        variables: {
          input: {
            usage: 'GROCERY_STORE_GALLERY',
            fileName: filename
          }
        }
      });

      if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
        const { id, url } = uploadImageResponse.createImageUploadUrl;
        await uploadImage(url, imageData);

        return id;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const _updateGroceryStore = async (ids: string[]) => {
    return await updateGroceryStore({
      variables: {
        input: {
          galleryImageIds: ids
        }
      }
    });
  };

  if (data && data.ownerGetGroceryStoreById) {
    return <Gallery ref={ref} initialValues={initialValues} updateGroceryStore={_updateGroceryStore} nextStep={nextStep} getUploadId={getUploadId} />;
  }

  return null;
});

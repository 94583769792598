import { gql } from 'apollo-boost';
export const getWebsiteData = gql`
  query getWebsiteDataForBiz($bizId: ID!) {
    getWebsiteDataForBiz(input: { bizId: $bizId }) {
      version
      url
      bizId
      legalEntityName
      customDomain
      menuHeadline
      keywords
      googleAnalyticsTrackingId
      googleAdsId
      seoPageTitle
      seoPageDescription
      googleSiteVerification
      displayImage {
        id
        url
      }
      headline1
      headline2
      about {
        picture {
          id
          url
        }
        title
        description
      }
      menuPicture {
        id
        url
      }
      fbLink
      instaLink
      yelpLink
      tikTokLink
      blogspostLink
      googleReviewsLink
      yelpWriteReviewLink
      googleWriteReviewLink
      testimonials {
        name
        text
        rating
      }
      orderPlatform {
        id
        name
        url
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import TableSetup from './TableSetup';
import { ITable } from '../types';
import { useStore } from 'store';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';

import * as _ from 'lodash';
import { useCreateTableMutation, useDeleteTableMutation } from 'graphql/hooks/restaurant.hooks';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const { createTable } = useCreateTableMutation();

  const { deleteTable } = useDeleteTableMutation();

  const [initialValues, setinitialValues] = useState<ITable[]>([]);

  const { data } = useGetRestaurantById(restaurant, 'TABLE');

  const addTableApi = async (newRow: ITable) => {
    return await createTable({
      variables: {
        input: {
          bizId: restaurant,
          tableName: newRow.tableName,
          seats: parseInt(newRow.seats + '')
        }
      }
    });
  };

  const deleteTableApi = async (tableName: string) => {
    return await deleteTable(tableName, {
      variables: {
        input: {
          bizId: restaurant,
          tableName
        }
      }
    });
  };

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { tables } = data.ownerGetRestaurantById;

      if (tables) {
        setinitialValues(
          _.map(tables, (table: any) => {
            return { tableName: table.tableName, seats: table.seats, cardURL: table.cardImg.url };
          })
        );
      }
    }
  }, [data]);

  if (data && data.ownerGetRestaurantById) {
    return <TableSetup ref={ref} initialValues={initialValues} nextStep={nextStep} addTableApi={addTableApi} deleteTableApi={deleteTableApi} />;
  }

  return null;
});

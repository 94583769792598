import gql from 'graphql-tag';

export const createBizEmployee = gql`
  mutation createBizEmployee($input: CreateBizEmployeeInput!) {
    createBizEmployee(input: $input) {
      uid
      displayName
      userName
    }
  }
`;

export const updateBizEmployee = gql`
  mutation updateBizEmployee($input: UpdateBizEmployeeInput!) {
    updateBizEmployee(input: $input) {
      uid
      displayName
      userName
    }
  }
`;

export const deleteBizEmployee = gql`
  mutation deleteBizEmployee($input: DeleteBizEmployeeInput!) {
    deleteBizEmployee(input: $input)
  }
`;

import * as Yup from 'yup';

export default Yup.object().shape({
  images: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('Gallery error'),
      url: Yup.string().required('Gallery error')
    })
  )
});

import React, { useState } from 'react';
import { useGetGroceryStoreById, useUpdateGroceryStoreMutation } from 'graphql/hooks/grocery.hooks';
import useActiveGroceryStore from 'hooks/grocery/useActiveGroceryStore';
import validate from './validations';
import {
  MIN_POINTS_REQUIRED_LABEL,
  MAX_REDEEMABLE_LABEL,
  MIN_PURCHASE_REQUIRED,
  POINT_EXCHANGE_RATIO,
  MIN_START_POINTS,
  MAX_REDEEM_POINTS_PER_USAGE,
  MIN_REDEEEM_PURCHASE,
  PRICE_POINT_RATIO
} from './constants';
import { useFormik } from 'formik';
import { Box, Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { useStyles } from './style';
import SaveIcon from '@material-ui/icons/Save';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import { useSnackbar } from 'notistack';

interface IInitialValueType {
  minStartPoints: number;
  maxRedeemPointsPerUsage: number;
  minRedeemPurchase: string;
  pricePointRatio: string;
  issueRewardPoint: boolean;
}

const GroceryReward = () => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const { groceryStoreId } = useActiveGroceryStore();

  const { data, loading } = useGetGroceryStoreById(groceryStoreId, 'REWARD_SETTING');

  const { updateGroceryStore } = useUpdateGroceryStoreMutation('REWARD_SETTING');

  const { rewardSetting = {}, issueRewardPoint = false } = data && data.ownerGetGroceryStoreById ? data.ownerGetGroceryStoreById : {};

  const { minStartPoints = MIN_START_POINTS, maxRedeemPointsPerUsage = MAX_REDEEM_POINTS_PER_USAGE, minRedeemPurchase = MIN_REDEEEM_PURCHASE, pricePointRatio = PRICE_POINT_RATIO } =
    rewardSetting || {};

  const initialValues: IInitialValueType = {
    minStartPoints,
    maxRedeemPointsPerUsage,
    minRedeemPurchase: minRedeemPurchase.toString(),
    pricePointRatio,
    issueRewardPoint
  };

  const onSubmit = async (values: IInitialValueType) => {
    setDisableButton(true);
    const response = await updateGroceryStore({
      variables: {
        input: {
          id: groceryStoreId,
          issueRewardPoint: values.issueRewardPoint,
          rewardSetting: {
            minStartPoints: values.minStartPoints,
            maxRedeemPointsPerUsage: values.maxRedeemPointsPerUsage,
            minRedeemPurchase: parseInt(values.minRedeemPurchase),
            pricePointRatio: parseInt(values.pricePointRatio)
          }
        }
      }
    });
    if (!response.errors) {
      snackbar.enqueueSnackbar('Rewards configurations updated successfully', {
        variant: 'success'
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate
  });

  const [disableButton, setDisableButton] = useState(true);

  const { values, handleChange, errors } = formik;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);

    setDisableButton(false);
  };

  if (loading) {
    return null;
  }

  if (!values.issueRewardPoint) {
    return (
      <Box textAlign="center">
        <Typography variant="h6" color="secondary">
          This feature is not enabled for your restaurant. Contact Lokobee for customer loyalty reward program.
        </Typography>
      </Box>
    );
  }

  return (
    <Box padding={1}>
      <Grid container={true} alignItems="center">
        <Grid item={true} xs={4}>
          <Typography variant="h5">Announcement</Typography>
        </Grid>
        <Grid item={true} xs={8}>
          <Box textAlign="right" display="flex" justifyContent="flex-end" alignItems="center">
            {values.issueRewardPoint ? (
              <Typography variant="body1" className={classes.success}>
                Customer loyalty reward program enabled
              </Typography>
            ) : (
              <Typography variant="body1" color="error">
                Customer loyalty reward program disabled
              </Typography>
            )}
            <Button className={classes.formControls} type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} disabled={disableButton} onClick={formik.submitForm}>
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
        <Grid container={true} alignItems="center" spacing={2}>
          <Grid item={true} xs={12} sm={10}>
            <Typography variant="h6">Min Points Required</Typography>
            <Typography variant="body1" align="justify">
              {MIN_POINTS_REQUIRED_LABEL}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={2}>
            <TextField
              name="minStartPoints"
              type="number"
              value={values.minStartPoints}
              variant="outlined"
              fullWidth={true}
              onChange={onChange}
              error={errors.minStartPoints ? true : false}
              helperText={errors.minStartPoints}
              inputProps={{
                min: 0
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
        <Grid container={true} alignItems="center" spacing={2}>
          <Grid item={true} xs={12} sm={10}>
            <Typography variant="h6">Max Redeemable Points Per Usage</Typography>
            <Typography variant="body1" align="justify">
              {MAX_REDEEMABLE_LABEL}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={2}>
            <TextField
              name="maxRedeemPointsPerUsage"
              type="number"
              value={values.maxRedeemPointsPerUsage}
              variant="outlined"
              fullWidth={true}
              onChange={onChange}
              error={errors.maxRedeemPointsPerUsage ? true : false}
              helperText={errors.maxRedeemPointsPerUsage}
              inputProps={{
                min: 0
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
        <Grid container={true} alignItems="center" spacing={2}>
          <Grid item={true} xs={12} sm={10}>
            <Typography variant="h6">Min Purchase Required</Typography>
            <Typography variant="body1" align="justify">
              {MIN_PURCHASE_REQUIRED}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={2}>
            <CurrencyInputField
              style={{ textAlign: 'right' }}
              name="minRedeemPurchase"
              variant="outlined"
              value={values.minRedeemPurchase}
              onChange={onChange}
              error={errors.minRedeemPurchase ? true : false}
              helperText={errors.minRedeemPurchase}
              InputProps={{
                startAdornment: <AttachMoneyIcon fontSize="small" />
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
        <Grid container={true} alignItems="center" spacing={2}>
          <Grid item={true} xs={12} sm={10}>
            <Typography variant="h6">Point Exchange Ratio</Typography>
            <Typography variant="body1" align="justify">
              {POINT_EXCHANGE_RATIO}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={2}>
            <TextField
              name="pricePointRatio"
              type="number"
              value={values.pricePointRatio}
              variant="outlined"
              fullWidth={true}
              onChange={onChange}
              error={errors.pricePointRatio ? true : false}
              helperText={errors.pricePointRatio}
              inputProps={{
                min: 0
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GroceryReward;

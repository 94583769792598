import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { useFormik, getIn } from 'formik';
import { AuthContext } from 'fbase/authContext';
import { useSnackbar } from 'notistack';
import validationSchema from './validations';
import { useStore } from 'store';

const ChangeDisplayName: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  const [initialValue, setInitialValue] = useState('');

  const snackbar = useSnackbar();

  const { dispatch } = useStore();

  const initialDisplayName = currentUser?.displayName;

  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };

  const stopLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };

  useEffect(() => {
    if (initialDisplayName) {
      setInitialValue(initialDisplayName);
    }
  }, [initialDisplayName]);

  const formik = useFormik({
    initialValues: {
      displayName: initialValue
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      startLoader();
      actions.setSubmitting(true);

      try {
        await currentUser?.updateProfile({
          displayName: values.displayName
        });

        snackbar.enqueueSnackbar('Display Name Updated', { variant: 'success' });

        actions.setSubmitting(false);

        stopLoader();
      } catch (e) {
        snackbar.enqueueSnackbar('Something went please try again', { variant: 'error' });

        actions.setSubmitting(false);
        stopLoader();
      }
    },
    enableReinitialize: true
  });

  const isButtonDisabled = !formik.isValid || initialDisplayName === formik.values.displayName;

  const error = !!getIn(formik.errors, 'displayName');

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box padding={2}>
        <TextField
          label="Display name"
          name="displayName"
          value={formik.values.displayName}
          onChange={formik.handleChange}
          variant="outlined"
          onBlur={formik.handleBlur}
          autoComplete="off"
          autoCapitalize="off"
          error={error}
        />
        <Box paddingY={1}>
          <Button type="submit" variant="contained" color="primary" disabled={isButtonDisabled}>
            Update
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ChangeDisplayName;

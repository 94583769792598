import React, { useContext } from 'react';
import { Formik, getIn } from 'formik';
import { isEmpty, includes } from 'lodash';
import 'react-phone-number-input/style.css';
import { Button, Box, TextField, InputAdornment, Typography } from '@material-ui/core';
import { useStyles } from './style';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { FirebaseContext } from 'fbase';
import { useSnackbar } from 'notistack';
import { RegisterFormValues } from './types';
import validations from './validations';
import { useStore } from 'store';
import { useHistory } from 'react-router-dom';

const Register: React.FC = () => {
  const classes = useStyles();

  /**
   * Get firebase reference
   */
  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const snackbar = useSnackbar();

  const { dispatch } = useStore();

  const history = useHistory();

  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };

  const stopLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };

  const _handleSubmit = async (values: RegisterFormValues) => {
    const { email, displayName, password } = values;

    try {
      startLoader();
      const response = await auth().fetchSignInMethodsForEmail(email);

      // If user already exists
      if (includes(response, 'password')) {
        snackbar.enqueueSnackbar('User already exists.', { variant: 'error' });
        stopLoader();
        return false;
      }

      const user = (await auth().createUserWithEmailAndPassword(email, password)).user;

      if (user) {
        await user.updateProfile({
          displayName
        });
        stopLoader();
        history.push('/home');
      } else {
        stopLoader();
        snackbar.enqueueSnackbar('Sign up error.', { variant: 'error' });
      }
    } catch (e) {
      if (e.code === 'auth/invalid-phone-number') {
        snackbar.enqueueSnackbar('Invalid phone number.', { variant: 'error' });
      } else {
        snackbar.enqueueSnackbar(e.message, { variant: 'error' });
      }

      stopLoader();
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          displayName: '',
          email: '',
          password: '',
          confirmPassword: ''
        }}
        onSubmit={_handleSubmit}
        initialStatus={null}
        validationSchema={validations}>
        {({ handleSubmit, isSubmitting, status, isValid, touched, values, handleBlur, handleChange, errors, setFieldTouched }) => {
          return (
            <Box className={classes.root}>
              <Typography variant="h5">Create Account</Typography>

              <Box className={classes.wrapper}>
                <form className={classes.formWrapper} onSubmit={handleSubmit}>
                  <TextField
                    className={classes.textfield}
                    name="displayName"
                    value={values.displayName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Full Name"
                    error={!!getIn(touched, 'displayName') && !!getIn(errors, 'displayName')}
                    helperText={!!getIn(touched, 'displayName') && !!getIn(errors, 'displayName') ? getIn(errors, 'displayName') : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    className={classes.textfield}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    error={!!getIn(touched, 'email') && !!getIn(errors, 'email')}
                    helperText={!!getIn(touched, 'email') && !!getIn(errors, 'email') ? getIn(errors, 'email') : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    className={classes.textfield}
                    name="password"
                    value={values.password}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Password"
                    error={!!getIn(touched, 'password') && !!getIn(errors, 'password')}
                    helperText={!!getIn(touched, 'password') && !!getIn(errors, 'password') ? getIn(errors, 'password') : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKeyIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    className={classes.textfield}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Confirm Password"
                    error={!!getIn(touched, 'confirmPassword') && !!getIn(errors, 'confirmPassword')}
                    helperText={!!getIn(touched, 'confirmPassword') && !!getIn(errors, 'confirmPassword') ? getIn(errors, 'confirmPassword') : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKeyIcon />
                        </InputAdornment>
                      )
                    }}
                  />

                  <Button color="primary" type="submit" size="large" variant="contained" disabled={!isEmpty(errors) || isEmpty(touched)}>
                    Register
                  </Button>
                </form>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default Register;

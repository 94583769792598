import { gql } from 'apollo-boost';

export default gql`
  query getCCTokenValue($input: GetCCTokenValue!) {
    getCCTokenValue(input: $input) {
      tokens
      cryptoUnit
    }
  }
`;

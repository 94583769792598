import React, { useState } from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react';
import IAddress from '../types';
import InputField from '../InputField';
import * as _ from 'lodash';
import { useStyles } from './style';
import { Dialog } from '@lokobee/lokobee-ui';

interface IProps {
  onConfirm: (address: IAddress) => void;
  onCancel: () => void;
  address: IAddress | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MarkerCoordinates {
  latLng: {
    lat: () => number;
    lng: () => number;
  };
}

const AddressMapDialog = ({ onConfirm, onCancel, address, open, setOpen }: IProps) => {
  const classes = useStyles();

  const [lat, setLat] = useState(address ? address.lat : 41.850033);

  const [long, setLong] = useState(address ? address.lng : -87.6500523);

  const [search, setSearch] = useState('');

  const [selectedAddress, setselectedAddress] = useState<IAddress | null>(address);

  const geocoder = new window.google.maps.Geocoder();

  const ref = React.useRef(new Marker());

  const [showPopup, setShowPopup] = useState(false);

  const onSearchChange = (val: string) => {
    setSearch(val);
    setShowPopup(false);
  };

  const onSelect = (val: IAddress) => {
    setShowPopup(false);
    setselectedAddress(val);
    setLat(val.lat);
    setLong(val.lng);
    setTimeout(() => {
      setShowPopup(true);
    }, 1000);
  };

  const onMarkerDragEnd = (coord: MarkerCoordinates) => {
    setShowPopup(false);
    const { latLng } = coord;
    const newLat = latLng.lat();
    const newLng = latLng.lng();
    setLat(newLat);
    setLong(newLng);

    geocoder.geocode({ location: { lat: newLat, lng: newLng } }, (results: any) => {
      const { address_components } = results[0];
      const { long_name: country } = _.filter(address_components, (obj: any) => obj.types[0] === 'country')[0] || {};
      const { long_name: city } = _.filter(address_components, (obj: any) => obj.types[0] === 'locality')[0] || {};
      const { long_name: state } = _.filter(address_components, (obj: any) => obj.types[0] === 'administrative_area_level_1')[0] || {};
      const { long_name: postalCode } = _.filter(address_components, (obj: any) => obj.types[0] === 'postal_code')[0] || {};
      const { long_name: street_number } = _.filter(address_components, (obj: any) => obj.types[0] === 'street_number')[0] || {};
      const { long_name: route } = _.filter(address_components, (obj: any) => obj.types[0] === 'route')[0] || {};

      setselectedAddress({ text: results[0].formatted_address, lat: newLat, lng: newLng, country, city, state, zipcode: postalCode, street: street_number + ' ' + route });

      setTimeout(() => {
        setShowPopup(true);
      }, 1000);
    });
  };

  const _onConfirm = () => {
    if (selectedAddress) onConfirm(selectedAddress);
  };

  const _onCancel = () => {
    onCancel();
  };

  const onMarkerClick = () => {
    setShowPopup(true);
  };
  React.useEffect(() => {
    if (selectedAddress && selectedAddress.lat !== 0 && selectedAddress.lng !== 0) {
      setTimeout(() => {
        setShowPopup(true);
      }, 1000);
    }
  }, [selectedAddress]);

  return (
    <Dialog open={open} setOpen={setOpen} dialogProps={{ fullWidth: true, maxWidth: 'md' }}>
      <Dialog.DialogContent className={classes.dialogContent}>
        <Grid container={true} direction="column" justify="center" className={classes.container}>
          <Grid item={true} className={classes.addressContainer}>
            <InputField name="address" variant="outlined" value={search} onChange={onSearchChange} onAddressSelect={onSelect} label="location" mapSelection={false} />
          </Grid>

          <Grid item={true}>
            <Box position="relative" height="400px">
              <Map
                google={window.google}
                className={classes.map}
                initialCenter={{
                  lat: lat,
                  lng: long
                }}
                center={{
                  lat: lat,
                  lng: long
                }}
                zoom={selectedAddress ? 15 : 3}>
                <Marker ref={ref} position={{ lat: lat, lng: long }} draggable={true} onDragend={(_t: any, _map: any, coord: MarkerCoordinates) => onMarkerDragEnd(coord)} onClick={onMarkerClick} />
                {selectedAddress && selectedAddress.lat !== 0 && selectedAddress.lng !== 0 && (
                  <InfoWindow marker={ref.current.marker} visible={showPopup} onClose={() => setShowPopup(false)}>
                    <div>
                      <Typography variant="subtitle2">Address : {selectedAddress.text}</Typography>
                      <Typography variant="subtitle2">City : {selectedAddress.city}</Typography>
                      <Typography variant="subtitle2">State : {selectedAddress.state}</Typography>
                      <Typography variant="subtitle2">Country : {selectedAddress.country}</Typography>
                      <Typography variant="subtitle2">Postal Code : {selectedAddress.zipcode}</Typography>
                    </div>
                  </InfoWindow>
                )}
              </Map>
            </Box>
          </Grid>
        </Grid>
      </Dialog.DialogContent>
      <Dialog.DialogActions saveBtnText="Confirm" onSave={_onConfirm} onCancel={_onCancel} />
    </Dialog>
  );
};

export default AddressMapDialog;

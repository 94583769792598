import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
  text: string;
  restaurantId: string;
}

const NoInfo = ({ text, restaurantId }: IProps) => {
  const history = useHistory();

  return (
    <Box justifyContent="center" alignItems={'center'} display="flex" width="100%">
      <Box padding={4} justifyContent="center" alignItems={'center'} flexDirection="column" display="flex" width="80%">
        <Typography variant="h6" align="center">
          {text}
        </Typography>
        <Box marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/home/${restaurantId}/info`);
            }}>
            Add info
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NoInfo;

import * as Yup from 'yup';
import { PaymentOptionsArray } from './types';

const regex = new RegExp(/^[0-9]+(\.[0-9][0-9]?)?$/);

/**
 * Custom validation method to check if array is sorted
 */
Yup.addMethod(Yup.array, 'sorted', function(message: string, parser = (a: any) => a) {
  return this.test('sorted', message, function(list) {
    return Boolean(list.map(parser).reduce((accumulator: any, currentValue: any) => accumulator && currentValue > accumulator && currentValue));
  });
});

export default Yup.object().shape({
  takeoutPaymentSetting: Yup.object().shape({
    onlinePaymentTypes: Yup.array().of(Yup.string().oneOf(PaymentOptionsArray, 'Invalid Payment Type')),

    onsitePaymentTypes: Yup.array().of(Yup.string().oneOf(PaymentOptionsArray, 'Invalid Payment Type')),

    tipPercentOptions: Yup.array()
      .of(
        Yup.number()
          .required()
          .min(0, 'Invalid  Tip')
          .max(100, 'Invalid Tip')
      )
      .required('tipPercentOptions required'),
    tipDefaultPercentIndex: Yup.number()
      .min(0, 'Invalid Tip')
      .max(3, 'Invalid Tip')
      .required('tipDefaultPercentIndex required')
  }),

  deliveryPaymentSetting: Yup.object().shape({
    onlinePaymentTypes: Yup.array().of(Yup.string().oneOf(PaymentOptionsArray, 'Invalid Payment Type')),

    onsitePaymentTypes: Yup.array().of(Yup.string().oneOf(PaymentOptionsArray, 'Invalid Payment Type')),

    tipPercentOptions: Yup.array()
      .of(
        Yup.number()
          .required()
          .min(0, 'Invalid  Tip')
          .max(100, 'Invalid Tip')
      )
      .required('tipPercentOptions required'),
    tipDefaultPercentIndex: Yup.number()
      .min(0, 'Invalid Tip')
      .max(3, 'Invalid Tip')
      .required('tipDefaultPercentIndex required')
  }),
  hasStripeAccount: Yup.boolean().required('hasStripeAccount required'),
  deliveryProvider: Yup.string()
    .required('Should be one of SELF  | DOORDASH_CLASSIC')
    .oneOf(['SELF', 'DOORDASH_CLASSIC']),
  deliverySetting: Yup.object()
    .when('deliveryProvider', {
      is: (val) => {
        return val === 'SELF';
      },
      then: Yup.object().shape({
        waitMinutes: Yup.number()
          .min(1, 'Invalid Delivery Time')
          .required('Delivery Time Required'),
        freeDeliveryAmount: Yup.string()
          .required('freeDeliveryAmount required')
          .matches(regex, 'Invalid price value.'),
        minDeliveryAmount: Yup.string()
          .required('minDeliveryAmount required')
          .matches(regex, 'Invalid price value.'),
        fees: Yup.array()
          .of(
            Yup.object().shape({
              mile: Yup.number()
                .min(1, 'Invalid mile value.')
                .required('mile Required'),
              fee: Yup.string()
                .required('fee required')
                .matches(regex, 'Invalid price value.')
            })
          )
          .sorted('Invalid mile order.', (a: any) => a.mile)
      }),
      otherwise: Yup.object().notRequired()
    })
    .when('deliveryProvider', {
      is: (val) => {
        return val === 'DOORDASH_CLASSIC';
      },
      then: Yup.object().shape({
        waitMinutes: Yup.number()
          .min(1, 'Invalid Delivery Time')
          .required('Delivery Time Required'),
        freeDeliveryAmount: Yup.string()
          .required('freeDeliveryAmount required')
          .matches(regex, 'Invalid price value.'),
        minDeliveryAmount: Yup.string()
          .required('minDeliveryAmount required')
          .matches(regex, 'Invalid price value.')
      }),
      otherwise: Yup.object().notRequired()
    })
});

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    padding: theme.spacing(3),
    width: '70%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[300],
    width: '100%'
  },
  table: {
    border: 'solid 1px ' + theme.palette.grey[200],
    '& td': {
      padding: theme.spacing(1)
    }
  },
  btns: {
    margin: theme.spacing(0, 1)
  },
  equalWidth: {
    width: 'calc(100vw/3)'
  },
  deleteIcon: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '5',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  verticleAlignMiddle: {
    verticalAlign: 'middle'
  },
  verticleAlignTop: {
    verticalAlign: 'top'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2) + 'px auto',
    width: '100%',
    '& button': {
      margin: theme.spacing(0, 1)
    }
  }
}));

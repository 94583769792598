import React, { useState, useEffect } from 'react';
import { Button, Box, Grid } from '@material-ui/core';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import { Dialog } from '@lokobee/lokobee-ui';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { useStore } from 'store';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useStyles } from './style';

const MenuCard = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [url, setUrl] = useState('');

  const {
    state: { restaurant }
  } = useStore();

  const { data } = useGetRestaurantById(restaurant, 'MENUCARD');

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { menuCard } = data.ownerGetRestaurantById;

      if (menuCard && menuCard.url) {
        setUrl(menuCard.url);
      }
    }
  }, [data]);

  return (
    <>
      {url !== '' && (
        <Button size="small" variant="contained" color="secondary" startIcon={<ArtTrackIcon />} disableElevation={true} onClick={() => setOpen(true)}>
          Menu card
        </Button>
      )}
      <Dialog open={open} setOpen={setOpen} dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: true }}>
        <Dialog.DialogContent>
          <Box className={classes.cardContainer} width="100%" height={url ? 'auto' : '500px'}>
            <img src={url} height="100%" width="100%" alt="MenuCard" />
          </Box>
        </Dialog.DialogContent>

        <Dialog.DialogActions showBtns={false}>
          <Grid container={true} className={classes.modalBtns}>
            <a href={url} download="MenuCard.png">
              <Button variant="contained" color="primary" disableElevation={true} startIcon={<GetAppIcon />}>
                Download
              </Button>
            </a>
            <Button variant="outlined" color="default" onClick={() => setOpen(false)}>
              Close
            </Button>
          </Grid>
        </Dialog.DialogActions>
      </Dialog>
    </>
  );
};

export default MenuCard;

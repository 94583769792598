import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  headerBtns: {
    margin: theme.spacing(0, 1)
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: '2px'
  }
}));

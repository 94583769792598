import { gql } from 'apollo-boost';

export default gql`
  query getBlog($input: GetBlogInput!) {
    getBlog(input: $input) {
      id
      bizId
      createdAt
      updatedAt
      title
      text
      picture {
        id
        url
      }
      headline1
      headline2
      headline1Image {
        url
      }
      headline2Image {
        url
      }
      version
    }
  }
`;

import { isEmpty, max, maxBy } from 'lodash';
import Big from 'big.js'
import { getWeekRange, formatTo, isSameYear } from 'util/date';
import { TMonthlyRevenue, initialRangeData } from './types';

const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getWeekKey = (week: any, format: string) => {
  return `${week.weekStart.format(format)}-${week.weekEnd.format(format)}`
}

export const parseDailyData = (dailyDataResponse: any) => {
  const { daily, weekly } = dailyDataResponse;

  const weekRange = [1, 0].map((nWeekBefore: number) => {
    return getWeekRange(nWeekBefore);
  })

  let weekRangeKey = [];

  if (isSameYear(weekRange[0].weekStart, weekRange[weekRange.length - 1].weekEnd)) {
    weekRangeKey = weekRange.map((week) => {
      return getWeekKey(week, "MM/DD")
    })
  } else {
    weekRangeKey = weekRange.map((week) => {
      return getWeekKey(week, "MM/DD/YYYY")
    })
  }

  const response = {"dailyData": {}, "weekData": {}}

  if (!isEmpty(weekly)) {
    const pastWeekData = weekly[weekRangeKey[0]] || {};
    const currentWeekData = weekly[weekRangeKey[1]] || {};
    const pastWeekRevenue: number[] = [], currentWeekRevenue: number[] = [];
    week.forEach((day: string) => {
      if (pastWeekData) {
        pastWeekRevenue.push(pastWeekData[day] ? pastWeekData[day].subtotal : 0);
      }
      if (currentWeekData) {
        currentWeekRevenue.push(currentWeekData[day] ? currentWeekData[day].subtotal : 0)
      }
    });
    if (max(pastWeekRevenue) || max(currentWeekRevenue)) {
      response.weekData = [pastWeekRevenue, currentWeekRevenue]
    } else {
      response.weekData = []
    }
  }

  if (!isEmpty(daily)) {
    response.dailyData = {
      averageOrders: daily.avg_order_subtotal ? Big(daily.avg_order_subtotal).toFixed(2) : "0.00",
      subtotal: daily.subtotal ? Big(daily.subtotal).toFixed(2) : "0.00",
      tax: daily.tax ? Big(daily.tax).toFixed(2) : "0",
      tipPercentage: daily.percentage_tip ? Big(daily.percentage_tip).round().toString() : "0",
      totalOrders: daily.orders
    }
  }
  return response;
}

export const parseRangeData = (apiResponse: any) => {
  const { data, monthly, weekly } = apiResponse;

  const response = {"rangeData": {}, "weeklyRevenue": {}, "monthlyRevenue": {}}

  if (!isEmpty(monthly)) {
    const dates = Object.keys(monthly as any);
    let monthlyRevenue: TMonthlyRevenue[] = [];
  
    dates.forEach((date: string) => {
      if (!isEmpty(monthly[date])) {
        monthlyRevenue.push({
          month: `${formatTo(date, "MM/DD/YYYY", "MMMM")} (${date})`,
          revenue: parseFloat(monthly[date].subtotal)
        })
      }
    });
  
    const maxRevenue = maxBy(monthlyRevenue, (o) => (o.revenue))?.revenue as number
  
    monthlyRevenue = monthlyRevenue.map((data: TMonthlyRevenue) => {
      const percentage = data.revenue/maxRevenue * 100
      return {percentage, ...data}
    })

    response.monthlyRevenue = maxRevenue > 0 ? monthlyRevenue : [];
  }

  if (!isEmpty(data)) {
    response.rangeData = {
      totalOrders: data.orders,
      totalCustomers: data.unique_customers,
      totalRevenue: Big(data.subtotal).toFixed(2),
      averageOrders: Big(data.avg_order_subtotal).toFixed(2),
      tipPercentage: Big(data.percentage_tip).round().toString(),
      dishFrequency: transformAndSort(data.item_freqs, 0),
      pairedDishFrequency: transformAndSort(data.item_pairings, 0),
    };
  } else {
    response.rangeData = initialRangeData
  }

  if (!isEmpty(weekly)) {
    const weeklyRevenue = Object.keys(weekly).map((weekRange: any) => {
      let weekRevenue = 0;
      week.forEach((day) => {
        weekRevenue += weekly[weekRange][day] ? weekly[weekRange][day].subtotal : 0;
      });
      return {
        "revenue": parseFloat(Big(weekRevenue).toFixed(2)),
        "week": weekRange
      }
    })

    const maxRevenue = maxBy(weeklyRevenue, (o) => (o.revenue))?.revenue as number

    response.weeklyRevenue = maxRevenue > 0 ? weeklyRevenue : []
  }

  return response
}


const transformAndSort = (obj: any, frequencyThreshold = 10) => {
  const sortable: any = [];
  for (var item in obj) {
    if (obj[item] >= frequencyThreshold) {
      sortable.push({ value: item, freq: obj[item] });
    }
  }

  return sortable.sort((a: any, b: any) => {
    return b.freq - a.freq;
  });
};
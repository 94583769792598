import React, { useEffect } from 'react';
import { useGetPizzaParts } from 'graphql/hooks/pizza.hooks';
import { useStyles } from './style';
import { useStore } from 'store';
import { parseSizes, parseCrusts, parseDensityMultipliers, parseGroups } from '../utils';
import { Box, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ISize, ICrust, IDensityMultiplier, IPizzaToppingGroup, IPizzaToppingItem, IPizzaToppingPrice } from '../types';
import { map, filter } from 'lodash';

interface IProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const Preview = ({ setActiveStep, setEditing }: IProps) => {
  const classes = useStyles();

  const {
    state: { restaurant }
  } = useStore();

  const editData = (step: number) => {
    setEditing(true);
    setActiveStep(step);
  };

  const data = useGetPizzaParts(restaurant);

  useEffect(() => {
    if (data) {
      if (data.getPizzaParts) {
        const { sizes, crusts, cheeses, sauces, densityMultipliers, toppings } = data.getPizzaParts;

        if (
          !(sizes && sizes.length) &&
          !(crusts && crusts.length) &&
          !(cheeses && cheeses.length) &&
          !(sauces && sauces.length) &&
          !(densityMultipliers && densityMultipliers.length) &&
          !(toppings && toppings.length)
        ) {
          setActiveStep(0);
        }
      } else {
        setActiveStep(0);
      }
    }
  }, [data, setActiveStep]);

  if (data && data.getPizzaParts) {
    const { sizes, crusts, cheeses, sauces, densityMultipliers, toppings } = data.getPizzaParts;

    const parsedSizes: ISize[] = sizes && sizes.length ? parseSizes(sizes) : [];

    const parsedCrusts: ICrust[] = crusts && crusts.length ? parseCrusts(crusts) : [];

    const parsedDensityMultipliers: IDensityMultiplier[] = densityMultipliers && densityMultipliers.length ? parseDensityMultipliers(densityMultipliers) : [];

    const parsedSauces: IPizzaToppingGroup[] = sauces && sauces.length && parsedDensityMultipliers.length ? parseGroups(sauces, parsedSizes, parsedDensityMultipliers) : [];

    const parsedCheeses: IPizzaToppingGroup[] = cheeses && cheeses.length && parsedDensityMultipliers.length ? parseGroups(cheeses, parsedSizes, parsedDensityMultipliers) : [];

    const parsedToppings: IPizzaToppingGroup[] = toppings && toppings.length && parsedDensityMultipliers.length ? parseGroups(toppings, parsedSizes, parsedDensityMultipliers) : [];

    return (
      <Box padding={2}>
        {/* Pizza sizes */}

        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
            <Box width="100%" display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h6">Sizes</Typography>
              </Box>
              <Button variant="contained" color="primary" size="small" onClick={() => editData(0)}>
                Edit
              </Button>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {sizes.length === 0 ? (
              <Box padding={2}>
                <Typography variant="body2">No sizes added.</Typography>
              </Box>
            ) : (
              <Box width="100%" padding={2} display="flex" justifyContent="center">
                <Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pizza Size</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Half & Half Supported</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(sizes, ({ title, description, enableHalfHalf }, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{title}</TableCell>
                            <TableCell>{description}</TableCell>
                            <TableCell>{enableHalfHalf ? 'Yes' : 'No'}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* Pizza crusts */}

        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
            <Box width="100%" display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h6">Crusts</Typography>
              </Box>
              <Button variant="contained" color="primary" size="small" onClick={() => editData(1)}>
                Edit
              </Button>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {parsedSizes.length === 0 && parsedCrusts.length === 0 ? (
              <Box padding={2}>
                <Typography variant="body2">No crusts added.</Typography>
              </Box>
            ) : (
              <Box width="100%" padding={2} display="flex" justifyContent="center">
                <Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell rowSpan={2} align="center" className={classes.borderRight}>
                          Crust
                        </TableCell>
                        <TableCell colSpan={sizes.length + 1} align="center">
                          Sizes
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        {map(parsedSizes, ({ id, title }, index) => {
                          return (
                            <TableCell key={id} align="center">
                              {title}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(parsedCrusts, (crust) => {
                        const { title, prices, id } = crust;

                        return (
                          <TableRow key={id}>
                            <TableCell align="center" className={classes.borderRight}>
                              {title}
                            </TableCell>
                            {map(parsedSizes, ({ id }, index) => {
                              const price = filter(prices, ({ sizeId }) => sizeId === id);

                              if (price.length) {
                                return (
                                  <TableCell key={`${index}-${Date.now()}`} align="center">
                                    <Typography variant="body2">{price[0].price}</Typography>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={`${index}-${Date.now()}`} align="center">
                                    <Typography variant="body2">NA</Typography>
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* Pizza density multipliers */}

        <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
            <Box width="100%" display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h6">Density Multipliers</Typography>
              </Box>

              <Button variant="contained" color="primary" size="small" onClick={() => editData(2)}>
                Edit
              </Button>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {parsedDensityMultipliers.length === 0 ? (
              <Box padding={2}>
                <Typography variant="body2">No Density Multipliers added.</Typography>
              </Box>
            ) : (
              <Box width="100%" padding={2} display="flex" justifyContent="center">
                <Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Density</TableCell>
                        <TableCell>Multiplier</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(
                        filter(parsedDensityMultipliers, ({ densityAllowed }) => densityAllowed),
                        ({ density, multiplier }, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{density}</TableCell>
                              <TableCell align="center">{multiplier}</TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* Sauces */}
        <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
            <Box width="100%" display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h6">Sauces</Typography>
              </Box>
              <Button variant="contained" color="primary" size="small" onClick={() => editData(3)}>
                Edit
              </Button>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {parsedSauces.length === 0 ? (
              <Box padding={2}>
                <Typography variant="body2">No Sauces added.</Typography>
              </Box>
            ) : (
              <Box width="100%" padding={2} display="flex" justifyContent="center">
                <Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Group</TableCell>
                        <TableCell> Densities allowed</TableCell>
                        <TableCell>Half & Half</TableCell>
                        <TableCell style={{ width: '40%' }}>Items</TableCell>
                        <TableCell style={{ width: '20%' }}>Regular Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(parsedSauces, ({ title, densities, enableHalfHalf, items, groupPrices }, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{title}</TableCell>
                            <TableCell>
                              {map(
                                filter(densities, ({ densityAllowed }) => densityAllowed),
                                ({ density }) => density
                              ).join(', ')}
                            </TableCell>
                            <TableCell>{enableHalfHalf ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{map(items, ({ title: itemTitle }) => itemTitle).join(', ')}</TableCell>
                            <TableCell>
                              {map(parsedSizes, ({ id, title: sizeTitle }, index1) => {
                                const priceBySize = filter(groupPrices, ({ sizeId }: IPizzaToppingPrice) => sizeId === id);
                                if (priceBySize && priceBySize.length) {
                                  const regularPrice = priceBySize[0].regularPrice || '0.00';

                                  return (
                                    <Typography key={index1} variant="body2">
                                      {sizeTitle} : {regularPrice}
                                    </Typography>
                                  );
                                } else {
                                  return (
                                    <Typography key={index1} variant="body2">
                                      {sizeTitle} : NA
                                    </Typography>
                                  );
                                }
                              })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* Cheeses */}
        <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
            <Box width="100%" display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h6">Cheeses</Typography>
              </Box>
              <Button variant="contained" color="primary" size="small" onClick={() => editData(4)}>
                Edit
              </Button>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {parsedCheeses.length === 0 ? (
              <Box padding={2}>
                <Typography variant="body2">No Cheeses added.</Typography>
              </Box>
            ) : (
              <Box width="100%" padding={2} display="flex" justifyContent="center">
                <Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Group</TableCell>
                        <TableCell> Densities allowed</TableCell>
                        <TableCell>Half & Half</TableCell>
                        <TableCell style={{ width: '40%' }}>Items</TableCell>
                        <TableCell style={{ width: '20%' }}>Regular Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(parsedCheeses, ({ title, densities, enableHalfHalf, items, groupPrices }, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{title}</TableCell>
                            <TableCell>
                              {map(
                                filter(densities, ({ densityAllowed }) => densityAllowed),
                                ({ density }) => density
                              ).join(', ')}
                            </TableCell>
                            <TableCell>{enableHalfHalf ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{map(items, ({ title: itemTitle }) => itemTitle).join(', ')}</TableCell>
                            <TableCell>
                              {map(parsedSizes, ({ id, title: sizeTitle }, index1) => {
                                const priceBySize = filter(groupPrices, ({ sizeId }: IPizzaToppingPrice) => sizeId === id);
                                if (priceBySize && priceBySize.length) {
                                  const regularPrice = priceBySize[0].regularPrice || '0.00';

                                  return (
                                    <Typography key={index1} variant="body2">
                                      {sizeTitle} : {regularPrice}
                                    </Typography>
                                  );
                                } else {
                                  return (
                                    <Typography key={index1} variant="body2">
                                      {sizeTitle} : NA
                                    </Typography>
                                  );
                                }
                              })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* Toppings */}
        <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
            <Box width="100%" display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h6">Toppings</Typography>
              </Box>
              <Button variant="contained" color="primary" size="small" onClick={() => editData(5)}>
                Edit
              </Button>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {parsedToppings.length === 0 ? (
              <Box padding={2}>
                <Typography variant="body2">No Toppings added.</Typography>
              </Box>
            ) : (
              <Box width="100%" padding={2} display="flex" justifyContent="center">
                <Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Group</TableCell>
                        <TableCell> Densities allowed</TableCell>
                        <TableCell>Half & Half</TableCell>
                        <TableCell style={{ width: '40%' }}>Items</TableCell>
                        <TableCell style={{ width: '20%' }}>Regular Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(parsedToppings, ({ title, densities, enableHalfHalf, items, groupPrices }, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{title}</TableCell>
                            <TableCell>
                              {map(
                                filter(densities, ({ densityAllowed }) => densityAllowed),
                                ({ density }) => density
                              ).join(', ')}
                            </TableCell>
                            <TableCell>{enableHalfHalf ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{map(items, ({ title: itemTitle }) => itemTitle).join(', ')}</TableCell>
                            <TableCell>
                              {map(parsedSizes, ({ id, title: sizeTitle }, index1) => {
                                const priceBySize = filter(groupPrices, ({ sizeId }: IPizzaToppingPrice) => sizeId === id);
                                if (priceBySize && priceBySize.length) {
                                  const regularPrice = priceBySize[0].regularPrice || '0.00';

                                  return (
                                    <Typography variant="body2" key={index1}>
                                      {sizeTitle} : {regularPrice}
                                    </Typography>
                                  );
                                } else {
                                  return (
                                    <Typography key={index1} variant="body2">
                                      {sizeTitle} : NA
                                    </Typography>
                                  );
                                }
                              })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    );
  }

  return null;
};

export default Preview;

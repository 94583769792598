import React, { useState } from 'react';
import { TableCell, TableRow, TextField } from '@material-ui/core';
import { IPizzaToppingItem } from '../../../types';
import { useStyles } from '../../style';
import { useFormik, getIn } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import validate from './validations';
import { ConfirmDialog } from '@lokobee/lokobee-ui';
import { DragHandleWrapper } from 'components/DragDrop';
import { filter } from 'lodash';

interface IProps {
  action: 'edit' | 'new';
  rows: IPizzaToppingItem[];
  rowData: IPizzaToppingItem;
  onAdd?: (newItem: IPizzaToppingItem) => void;
  onEdit?: (updatedItem: IPizzaToppingItem) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  allowDelete: boolean;
}

const Row = ({ rowData, action, onAdd, onEdit, onDelete, onCancel, rows, allowDelete }: IProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);

  const formik = useFormik({
    initialValues: { ...rowData },
    onSubmit: (formValues) => handleFormSubmit(formValues),
    validate: (values: IPizzaToppingItem) => {
      const errors: any = {};

      const itemPresent = filter(rows, ({ title }) => title.toLowerCase().replaceAll(' ', '') === values.title.toLowerCase().replaceAll(' ', ''));

      if (action === 'new' && itemPresent.length) {
        errors.title = 'Duplicate item';
      }

      return errors;
    }
  });

  const handleFormSubmit = (formValues: IPizzaToppingItem) => {
    if (action === 'new') {
      if (onAdd) {
        onAdd(formValues);
      }
    }
    if (action === 'edit') {
      if (onEdit) {
        setEditing(false);
        onEdit(formValues);
      }
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    setEditing(false);
    if (onCancel) {
      onCancel();
    }
  };

  const { handleChange, handleBlur, errors, touched } = formik;
  const { title } = formik.values;

  const editForm = (
    <TableRow className={classes.verticleAlignTop}>
      <TableCell>
        <TextField
          name="title"
          variant="outlined"
          fullWidth={true}
          value={title}
          placeholder="Item"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!getIn(touched, 'title') && !!getIn(errors, 'title')}
          helperText={!!getIn(touched, 'title') && !!getIn(errors, 'title') ? errors.title : ''}
        />
      </TableCell>

      <TableCell className={classes.verticleAlignMiddle}>
        <CheckIcon fontSize="small" className={classes.successBtn} onClick={formik.submitForm} />
        <ClearIcon fontSize="small" className={classes.deleteIcon} onClick={handleCancel} />
      </TableCell>
    </TableRow>
  );

  if (action === 'new' || editing) {
    return editForm;
  } else {
    return (
      <TableRow>
        <TableCell>{title}</TableCell>

        <TableCell style={{ width: '50%' }}>
          <EditIcon fontSize="small" className={classes.editIcon} onClick={() => setEditing(true)} />
          {allowDelete && (
            <ConfirmDialog
              onYes={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
              message="Are you sure want to close this row?">
              <DeleteIcon fontSize="small" className={classes.deleteIcon} />
            </ConfirmDialog>
          )}
          <DragHandleWrapper>
            <DragHandleIcon fontSize="small" className={classes.dragIcon} />
          </DragHandleWrapper>
        </TableCell>
      </TableRow>
    );
  }
};

export default Row;

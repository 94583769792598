import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_RESTAURANT_BY_ID } from 'graphql/query';
import { useLazyQuery } from '@apollo/react-hooks';
import { useStore } from 'store';
import moment from 'moment-timezone';

export default () => {
  const { restaurantId } = useParams<any>();

  const { dispatch } = useStore();

  const [isValid, setValid] = useState<boolean>(false);

  const [getRestaurant, { data }] = useLazyQuery(GET_RESTAURANT_BY_ID);

  const [restId, setResId] = useState(null);

  useEffect(() => {
    if (restId && restId === restaurantId) {
      return;
    } else {
      getRestaurant({
        variables: {
          input: {
            id: restaurantId
          }
        }
      });
    }
  }, [getRestaurant, restId, restaurantId]);

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { id, i18n } = data.ownerGetRestaurantById;

      const timezone = i18n ? i18n.timezone || 'us/pacific' : 'us/pacific';

      if (moment.tz) {
        moment.tz.setDefault(timezone);
      }

      setResId(id);

      dispatch({
        type: 'SET_RESTAURANT',
        payload: id
      });
      
      setValid(true);
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => dispatch({ type: 'RESET_RESTAURANT' });
  }, [dispatch]);

  return isValid;
};

import React from 'react';
import { useStyles } from './style';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import * as _ from 'lodash';
interface IProps {
  activeStep: number;
  steps: Array<string>;
}

const CustomStepper = ({ steps, activeStep }: IProps) => {
  const classes = useStyles();

  const CustomConnector = withStyles((theme: Theme) => {
    return {
      alternativeLabel: {
        top: 9,
        left: 'calc(-50% + 10px)',
        right: 'calc(50% + 9px)'
      },
      active: {
        '& $line': {
          borderColor: blue[900]
        }
      },
      completed: {
        '& $line': {
          borderColor: blue[900]
        }
      },

      line: {
        borderColor: blue[100],
        borderTopWidth: 3
      }
    };
  })(StepConnector);

  return (
    <Stepper activeStep={activeStep} alternativeLabel connector={<CustomConnector />}>
      {_.map(steps, (label: string) => (
        <Step key={label} className={classes.root}>
          <StepLabel className={classes.label}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;

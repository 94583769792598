import React, { useState } from 'react';
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import ProfileTabs from './ProfileTabs';
import ChangePassword from './ChangePassword';
import ChangeDisplayName from './ChangeDisplayName';
import ChangePhoneNumber from './ChangePhoneNumber';
import { useStyles } from './style';

const Profile = () => {
  const classes = useStyles();

  const [selectedTab, setselectedTab] = useState('updateName');

  return (
    <Box width="100%">
      <Grid container={true} alignItems="center">
        <Typography variant="h5">Profile</Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Box paddingY={1}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} md={3}>
            <Box boxShadow={2}>
              <Box textAlign="center">
                <ProfileTabs selectedTab={selectedTab} setselectedTab={setselectedTab} />
              </Box>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={9} className={classes.container}>
            <Box boxShadow={2} padding={1}>
              {selectedTab === 'updateName' && <ChangeDisplayName />}
              {selectedTab === 'updatePassword' && <ChangePassword />}
              {selectedTab === 'updatePhoneNumber' && <ChangePhoneNumber />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;

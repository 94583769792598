import React, { useEffect, useContext, useState } from 'react';
import Unsplash from 'unsplash-js';
import { Redirect, useHistory } from 'react-router-dom';
import AppIcon from 'assets/img/icon-1024.png';
import { Paper, Box } from '@material-ui/core';
import AuthenticationRoutes from './routes';
import { useStyles } from './style';
import { AuthContext } from 'fbase/authContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Authentication: React.FC = () => {
  const [backgroundImage, setBackGroundImage] = useState<string>('');

  const classes = useStyles({ backgroundImage });

  const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const { location } = history;

  useEffect(() => {
    const accessKey = process.env.REACT_APP_UNSPLASH_ACCESS_TOKEN || '';

    const unplash = new Unsplash({
      accessKey
    });

    const fetchBackgroundImages = async () => {
      try {
        const images = await unplash.photos.getPhoto('W9OKrxBqiZA');

        const response = await images.json();

        if (response) {
          const { urls: { full } = { full: '' } } = response;

          setBackGroundImage(`url('${full}')`);
        }
      } catch (e) {
        console.log('Unable to fetch pictures', e);
      }

      return () => setBackGroundImage('');
    };

    fetchBackgroundImages();
  }, []);

  if (currentUser) {
    return <Redirect to="/home" />;
  }

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        {location.pathname !== '/login' && <ArrowBackIcon fontSize="large" className={classes.backIcon} onClick={() => history.push('/login')} />}
        <img className={classes.appIcon} src={AppIcon} alt="App Icon" />
        <AuthenticationRoutes />
      </Paper>
    </Box>
  );
};

export default Authentication;

import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';
import { IAddress } from '../types';

const validateEmail = Yup.string()
  .email('Invalid email')
  .min(2, 'Too short!')
  .max(100, 'Too long!');

const taxRegex = new RegExp(/^100$|^100.0$|^100.00$|^\d{0,2}(\.\d{1,2})?$/);

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  timeZone: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email')
    .min(2, 'Too short!')
    .max(100, 'Too long!'),
  address: Yup.object().test('addressCheck', 'Invalid Address', (value: IAddress) => {
    if (!value.text || value.text.trim() === '') return false;

    if (!value.city || value.city.trim() === '') return false;

    if (!value.state || value.state.trim() === '') return false;

    if (!value.country || value.country.trim() === '') return false;

    if (!value.street || value.street.trim() === '') return false;

    if (!value.zipcode || value.zipcode.trim() === '') return false;

    return true;
  }),
  phone: Yup.string()
    .required('Required')
    .test('phoneTest', 'Invalid phone number', (value) => {
      if (value && !isValidPhoneNumber(value)) {
        return false;
      }
      return true;
    }),
  taxModes: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Please enter title'),
      tax: Yup.string()
        .required('Please enter tax')
        .matches(taxRegex, 'Invalid tax value.')
    })
  ),
  logoImageData: Yup.object().when('logo', {
    is: (val) => {
      return val === null;
    },
    then: Yup.object().shape({
      filename: Yup.string().required(),
      imageFiledata: Yup.string().required()
    }),
    otherwise: Yup.object()
      .notRequired()
      .nullable()
  }),
  orderEmails: Yup.string()
    .required('Required')
    .test('emailCheck', 'Invalid Email', (value) => {
      let flag = true;

      if (value) {
        const orderEmails = value
          .toString()
          .replace(/,\s*/g, ',')
          .split(',');

        orderEmails.forEach((email: string) => {
          if (!validateEmail.isValidSync(email)) flag = false;
        });
      }

      return flag;
    }),
  reportEmails: Yup.string()
    .required('Required')
    .test('emailCheck', 'Invalid Email', (value) => {
      let flag = true;
      if (value) {
        const orderEmails = value
          .toString()
          .replace(/,\s*/g, ',')
          .split(',');

        orderEmails.forEach((email: string) => {
          if (!validateEmail.isValidSync(email)) flag = false;
        });
      }

      return flag;
    })
});

import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import restaurant_empty_screen from 'assets/img/restaurant_empty_screen.png';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStyles } from './style';

export enum Biztype {
  RESTAURANT = 'restaurant',
  GROCERY = 'grocery'
}

interface IProps {
  onAdd: () => void;
  bizType?: Biztype;
}

const RestaurantEmptyScreen = ({ onAdd, bizType }: IProps) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.emptyScreenContainer} display="flex" flexDirection="column" alignItems="center">
        <img src={restaurant_empty_screen} className={classes.emptyScreenImage} alt="" />
        <AddCircleIcon color="disabled" className={classes.addIcon} onClick={onAdd} />
        <Typography variant="h6">Add New {bizType === Biztype.GROCERY ? 'grocery store' : 'restaurant'}</Typography>
      </Box>
    </>
  );
};

export default RestaurantEmptyScreen;

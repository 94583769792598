import React, { useEffect } from 'react';
import { useStyles } from '../style';
import { ICrust } from 'pages/Home/RestaurantDashboard/PizzaConfiguration/types';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Radio, Checkbox } from '@material-ui/core';
import { map, filter } from 'lodash';

interface IProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  crusts: ICrust[];
  defaultCrust: string;
  pizzaCrustIds: string[];
}

const CrustTable = ({ crusts, setFieldValue, pizzaCrustIds, defaultCrust }: IProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (crusts.length) {
      const defaultSelected = crusts[0];
      if (!pizzaCrustIds.length) {
        setFieldValue('pizzaCrustIds', [defaultSelected.id]);
      }

      if (defaultCrust === '') {
        setFieldValue('pizzaDefaultCrustId', defaultSelected.id);
        if (defaultSelected.id && pizzaCrustIds.indexOf(defaultSelected.id) < 0) {
          setFieldValue('pizzaCrustIds', [...pizzaCrustIds, defaultSelected.id]);
        }
      }
    }
  }, [crusts, defaultCrust, pizzaCrustIds, pizzaCrustIds.length, setFieldValue]);

  return (
    <Box paddingY={4} display="flex">
      <Box padding={0} boxShadow={2}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>Crust</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell align="center">Standard</TableCell>
              <TableCell align="center">
                <Box display="flex">
                  Allowed
                  <Checkbox
                    className={classes.tableCheckBox}
                    color="primary"
                    checked={pizzaCrustIds.length === crusts.length}
                    onChange={(e: any, checked) => {
                      if (checked) {
                        setFieldValue(
                          'pizzaCrustIds',
                          map(crusts, ({ id }) => (id ? id : ''))
                        );
                      } else {
                        if (crusts.length) {
                          const defaultSelected = crusts[0];
                          setFieldValue('pizzaCrustIds', [defaultSelected.id]);
                        }
                      }
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(crusts, ({ id, title }, index) => {
              const allowed = pizzaCrustIds.indexOf(id ? id : '') > -1;
              return (
                <TableRow key={id}>
                  <TableCell>{title}</TableCell>
                  <TableCell align="center">
                    <Radio
                      className={classes.tableCheckBox}
                      color="primary"
                      checked={defaultCrust === id}
                      onChange={(e: any, checked) => {
                        if (checked) {
                          setFieldValue('pizzaDefaultCrustId', id);
                          if (!allowed) {
                            setFieldValue('pizzaCrustIds', [...pizzaCrustIds, id]);
                          }
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      className={classes.tableCheckBox}
                      color="primary"
                      checked={allowed}
                      onChange={(e: any, checked) => {
                        if (checked) {
                          setFieldValue('pizzaCrustIds', [...pizzaCrustIds, id]);
                        } else {
                          setFieldValue(
                            'pizzaCrustIds',
                            filter(pizzaCrustIds, (crustId) => crustId !== id)
                          );
                          if (defaultCrust === id) {
                            setFieldValue('pizzaDefaultCrustId', '');
                          }
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default CrustTable;

import React from 'react';
import { chain, filter } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid, Card, CardActionArea, Typography, Box, CardActions, Button } from '@material-ui/core';
import { useStyles } from './style';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useUpdateDishMutation } from 'graphql/hooks/dish.hooks';
import { parsePrice } from 'util/number';
import EditIcon from '@material-ui/icons/Edit';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStore } from 'store';
import S3Image from 'components/S3Image';
import { Dialog } from '@lokobee/lokobee-ui';
import dishEmptyIcon from 'assets/img/dish-empty-icon.png';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

interface IProps {
  dishes: any;
  showActions?: Boolean;
  statusIcon?: Boolean;
}

const DishList: React.FC<IProps> = ({ dishes, showActions = true, statusIcon = true }) => {
  const classes = useStyles();

  const history = useHistory();

  const { restaurantId } = useActiveRestaurant();

  const { updateDish, restaurantId: bizId } = useUpdateDishMutation({ showLoader: true });

  const editButtonOnClickHandler = (dishId: string) => {
    if (dishId && restaurantId) {
      const REDIRECT_LINK = `/home/${restaurantId}/dish/${dishId}`;
      history.push(REDIRECT_LINK);
    } else {
      history.push('/home');
    }
  };

  const onVisibilityChangeHandler = async (dishId: string, status: string) => {
    try {
      const updatedStatus = status === 'ACTIVE' ? 'HIDDEN' : 'ACTIVE';

      await updateDish({
        variables: {
          input: {
            bizId,
            id: dishId,
            status: updatedStatus
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteDishHandler = async (dishId: string) => {
    try {
      await updateDish({
        variables: {
          input: {
            bizId,
            id: dishId,
            status: 'DELETED'
          }
        }
      });
    } catch (e) {}
  };

  if (dishes && dishes.length) {
    return (
      <Grid container={true} spacing={2}>
        {dishes.map(({ id: dishId, title: dishTitleArray, images, price, status, isReward, rewardPoints, pizzaBasePrice, type, isDiscounted }: any) => {
          const [{ text: dishTitle }] = dishTitleArray || [{ text: '' }];

          const output = parsePrice(type === 'PIZZA' || type === 'COMBO_PIZZA' ? pizzaBasePrice : price);

          const { price: dishPrice } = (output && output[0]) || { title: '', price: '' };

          const {
            md: { url }
          } = (images && images[0]) || { md: { url: null, bucket: null, key: null } };

          return (
            <Grid item={true} key={dishId} xs={12} sm={6} md={4} className={classes.cardContainer}>
              <Card className={classes.card}>
                <CardActionArea onClick={() => editButtonOnClickHandler(dishId)}>
                  <Box width="100%" height="200px" display="flex">
                    <Box>
                      <S3Image src={url} defaultImage={dishEmptyIcon} />
                    </Box>

                    <Box className={classes.iconsWrapper}>
                      {statusIcon && status === 'HIDDEN' && (
                        <Box className={classes.hiddenIconWrapper}>
                          <VisibilityOffIcon className={classes.hiddenIcon} />
                          <Typography variant="caption">Hidden</Typography>
                        </Box>
                      )}
                      {statusIcon && isDiscounted && (
                        <Box className={classes.discountIconWrapper}>
                          <LoyaltyIcon className={classes.hiddenIcon} />
                          <Typography variant="caption">Discounted</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box paddingX={1}>
                    <Typography gutterBottom={true} variant="subtitle1">
                      {dishTitle}
                    </Typography>
                  </Box>
                  <Box paddingX={1} display="flex" justifyContent="space-between">
                    <Typography gutterBottom={true} variant="subtitle1">
                      ${(!!dishPrice && dishPrice) || 0}
                    </Typography>
                    {isReward && (
                      <Typography gutterBottom={true} variant="subtitle1">
                        {rewardPoints} PTS
                      </Typography>
                    )}
                  </Box>
                </CardActionArea>
                {showActions && (
                  <CardActions className={classes.cardActions}>
                    <Button size="small" color="primary" variant="contained" startIcon={<EditIcon fontSize="small" />} onClick={() => editButtonOnClickHandler(dishId)}>
                      Edit
                    </Button>
                    <Dialog>
                      <Button size="small" color="secondary" variant="contained" startIcon={status === 'ACTIVE' ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}>
                        {status === 'ACTIVE' ? 'Hide' : 'Show'}
                      </Button>
                      <Dialog.DialogContent>
                        <Typography variant="body2">Are you sure want to {status === 'ACTIVE' ? 'hide' : 'show'} this dish?</Typography>
                      </Dialog.DialogContent>
                      <Dialog.DialogActions onSave={() => onVisibilityChangeHandler(dishId, status)} saveBtnText="Yes" cancelBtnText="No" />
                    </Dialog>
                    <Dialog>
                      <Button size="small" variant="contained" startIcon={<DeleteIcon fontSize="small" />}>
                        Delete
                      </Button>
                      <Dialog.DialogContent>
                        <Typography variant="body2">Are you sure want to delete this dish?</Typography>
                      </Dialog.DialogContent>
                      <Dialog.DialogActions onSave={() => onDeleteDishHandler(dishId)} saveBtnText="Yes" cancelBtnText="No" />
                    </Dialog>
                  </CardActions>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return null;
};

export default DishList;

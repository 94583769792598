import { Box, Typography, Button, Divider, withStyles, useTheme, Tabs, Tab, Switch, Dialog } from '@material-ui/core';
import { useFormik, getIn, FormikProps } from 'formik';
import { useGetGroceryStoreById, useUpdateGroceryStoreMutation } from 'graphql/hooks/grocery.hooks';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useStore } from 'store';
import { useStyles } from './style';
import { IDeliverySetting, IInitialValueType, IPaymentSetting, ITabOptions } from './types';
import { setInitialDeliverySettings, setInitialValues } from './utils';
import validations from './validations';
import SaveIcon from '@material-ui/icons/Save';
import Setup from './Setup';
import { map, filter } from 'lodash';
import { convertPriceTo } from 'util/number';

const defaultPaymentSetting: IPaymentSetting = {
  onlinePaymentTypes: [],
  onsitePaymentTypes: [],
  tipPercentOptions: [0, 10, 15, 20],
  tipDefaultPercentIndex: 2,
  enable: false
};

const defaultDeliverySetting: IDeliverySetting = {
  waitMinutes: 45,
  freeDeliveryAmount: '100.0',
  minDeliveryAmount: '25.0',
  fees: [
    {
      mile: 3,
      fee: '4.5'
    },
    {
      mile: 5,
      fee: '6.0'
    },
    {
      mile: 6,
      fee: '7.0'
    },
    {
      mile: 7,
      fee: '8.0'
    }
  ]
};
const PaymentSetup = () => {
  const classes = useStyles();

  const {
    state: { groceryStoreId }
  } = useStore();

  const snackbar = useSnackbar();

  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<ITabOptions>('deliveryPaymentSetting');

  const [openDialog, setOpenDialog] = useState(false);

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const { data } = useGetGroceryStoreById(groceryStoreId, 'PAYMENT_SETTINGS');

  const { updateGroceryStore } = useUpdateGroceryStoreMutation('PAYMENT_SETTINGS');

  const {
    takeoutPaymentSetting,
    deliveryPaymentSetting,
    hasStripeAccount,
    deliverySetting,
    enableTakeoutOrder,
    enableDeliveryOrder,
    takeoutWaitMinutes,
    bizHours,
    deliveryProvider,
    stripeInProgress
  } = (data && data.ownerGetGroceryStoreById) || {};

  const initialValues: IInitialValueType = {
    takeoutPaymentSetting: takeoutPaymentSetting ? { ...setInitialValues(takeoutPaymentSetting), enable: enableTakeoutOrder } : defaultPaymentSetting,
    deliveryPaymentSetting: deliveryPaymentSetting ? { ...setInitialValues(deliveryPaymentSetting), enable: enableDeliveryOrder } : defaultPaymentSetting,
    hasStripeAccount: hasStripeAccount,
    deliverySetting: deliverySetting ? setInitialDeliverySettings(deliverySetting) : defaultDeliverySetting,
    takeoutWaitMinutes: takeoutWaitMinutes ? takeoutWaitMinutes : 10,
    deliveryProvider: deliveryProvider ? deliveryProvider : 'SELF',
    stripeInProgress
  };
  const formik: FormikProps<IInitialValueType> = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validations,
    onSubmit: () => {}
  });

  const { values, errors, touched } = formik;

  console.log('errors', errors);
  const disableSaveBtn = () => {
    if (activeTab === 'deliveryPaymentSetting') {
      return (!!getIn(touched, activeTab) && !!getIn(errors, activeTab)) || !!getIn(errors, 'deliverySetting');
    }

    return !!getIn(touched, activeTab) && !!getIn(errors, activeTab);
  };

  const onSubmit = async () => {
    const { onlinePaymentTypes, onsitePaymentTypes, tipDefaultPercentIndex, tipPercentOptions, enable } = values[activeTab];

    const { deliverySetting, takeoutWaitMinutes, deliveryProvider } = values;

    const { waitMinutes, minDeliveryAmount, freeDeliveryAmount, fees } = deliverySetting;

    const response = await updateGroceryStore({
      variables: {
        input: {
          [activeTab]: {
            onlinePaymentTypes,
            onsitePaymentTypes,
            tipPercentOptions,
            tipDefaultPercent: tipPercentOptions[tipDefaultPercentIndex]
          },
          deliveryProvider: activeTab === 'deliveryPaymentSetting' ? deliveryProvider : undefined,
          deliverySetting:
            activeTab === 'deliveryPaymentSetting'
              ? {
                  waitMinutes,
                  minDeliveryAmount: parseInt(convertPriceTo(minDeliveryAmount, 'CENT'), 10),
                  freeDeliveryAmount: parseInt(convertPriceTo(freeDeliveryAmount, 'CENT'), 10),
                  fees:
                    deliveryProvider === 'DOORDASH_CLASSIC'
                      ? map(formik.initialValues.deliverySetting.fees, ({ mile, fee }) => {
                          return {
                            mile,
                            fee: convertPriceTo(fee, 'CENT') !== '' ? parseInt(convertPriceTo(fee, 'CENT'), 10) : 0
                          };
                        })
                      : map(fees, ({ mile, fee }) => {
                          return {
                            mile,
                            fee: convertPriceTo(fee, 'CENT') !== '' ? parseInt(convertPriceTo(fee, 'CENT'), 10) : 0
                          };
                        })
                }
              : undefined,
          enableTakeoutOrder: activeTab === 'takeoutPaymentSetting' ? enable : undefined,
          enableDeliveryOrder: activeTab === 'deliveryPaymentSetting' ? enable : undefined,
          takeoutWaitMinutes
        }
      }
    });

    if (response) {
      snackbar.enqueueSnackbar('Payment settings updated successfully !', {
        variant: 'success'
      });
    } else {
      snackbar.enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error'
      });
    }
  };

  const enableOrderType = (checked: boolean) => {
    let orderTypeBizHours = [];
    if (activeTab === 'takeoutPaymentSetting') {
      orderTypeBizHours = filter(bizHours ? bizHours : [], ({ serviceType }) => serviceType === 'GROCERY_TAKEOUT');
    }
    if (activeTab === 'deliveryPaymentSetting') {
      orderTypeBizHours = filter(bizHours ? bizHours : [], ({ serviceType }) => serviceType === 'GROCERY_DELIVERY');
    }
    console.log('orderTypeBizHours', orderTypeBizHours);
    if (checked === true && !orderTypeBizHours.length) {
      setOpenDialog(true);
    } else {
      formik.setValues({
        ...values,
        [activeTab]: {
          ...values[activeTab],
          enable: checked
        }
      });
    }
  };

  if (data && data.ownerGetGroceryStoreById) {
    return (
      <Box padding={1} bgcolor="white">
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="h5">Payment Setup</Typography>
          </Box>
          <Box flexGrow={1} textAlign="right">
            <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} disableElevation={true} disabled={disableSaveBtn()} onClick={onSubmit}>
              Save
            </Button>
          </Box>
        </Box>
        <Divider className={classes.headerDivider} />
        <Box display="flex">
          <Box flexGrow={1}>
            <StyledTabs value={activeTab} onChange={(_e: any, index: any) => setActiveTab(index)}>
              <Tab label="Take out" value="takeoutPaymentSetting" />
              <Tab label="Delivery" value="deliveryPaymentSetting" />
            </StyledTabs>
          </Box>
          <Box display="flex" alignItems="center">
            {activeTab === 'takeoutPaymentSetting' && <Typography variant="body2">Enable Takeout:</Typography>}
            {activeTab === 'deliveryPaymentSetting' && <Typography variant="body2">Enable Delivery:</Typography>}
            <Switch checked={values[activeTab].enable} onChange={(_e: any, checked: boolean) => enableOrderType(checked)} color="secondary" />
          </Box>
        </Box>
        <Box padding={2}>
          {activeTab === 'takeoutPaymentSetting' && <Setup formik={formik} field="takeoutPaymentSetting" />}
          {activeTab === 'deliveryPaymentSetting' && <Setup formik={formik} field="deliveryPaymentSetting" />}
        </Box>
        <Dialog open={openDialog}>
          <Box padding={2}>
            <Typography variant="body2">Business hours not found for given order type. Please set to enable.</Typography>
            <Box display="flex" justifyContent="center" paddingY={1}>
              <Button variant="contained" color="primary" disableElevation={true} onClick={() => setOpenDialog(false)}>
                OK
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    );
  }
  return null;
};

export default PaymentSetup;

import { gql } from 'apollo-boost';
import { CryptoCoupon } from 'graphql/fragments/cryptoCoupon.fragments';

export default gql`
  query getCouponByCouponId($input: GetCouponByCouponIdInput!) {
    getCouponByCouponId(input: $input) {
      ...CryptoCoupon
    }
  }
  ${CryptoCoupon}
`;

import * as Yup from 'yup';
import { PizzaToppingDensityValues } from '../types';

const regex = new RegExp(/^[0-9]+(\.[0-9][0-9]?)?$/);

export default Yup.object().shape({
  groups: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required('Title required'),
        enableHalfHalf: Yup.boolean().required('enableHalfHalf required'),
        densities: Yup.array().of(
          Yup.object().shape({
            density: Yup.string()
              .required('density required')
              .oneOf(PizzaToppingDensityValues),
            multiplier: Yup.number()
              .required('multiplier required')
              .min(0),
            densityAllowed: Yup.boolean().required('densityAllowed required')
          })
        ),
        prices: Yup.array().of(
          Yup.object().shape({
            sizeId: Yup.string().required('size ID required'),
            densityPrices: Yup.array().of(
              Yup.object().shape({
                density: Yup.string()
                  .required('density required')
                  .oneOf(PizzaToppingDensityValues),
                price: Yup.string()
                  .required('Price required')
                  .matches(regex, 'Invalid price value')
              })
            )
          })
        ),
        items: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required('Title required')
            })
          )
          .min(1)
      })
    )
    .min(1)
});

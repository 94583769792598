import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  expansionPanel: {
    width: '100%',
    margin: theme.spacing(2, 0)
  },
  expansionPanelHeader: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '5px 5px 0 0'

    // '& .MuiExpansionPanelSummary-content': {
    //   alignItems: 'center'
    // }
  },
  expansionPanelDetails: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1)
    }
  },
  table: {
    border: 'solid 1px ' + theme.palette.grey[300],
    borderCollapse: 'unset'
  },

  borderRight: {
    borderRight: 'solid 1px ' + theme.palette.grey[300]
  }
}));

import { gql } from 'apollo-boost';

export default gql`
  query getGroceryStoresByOwner {
    getGroceryStoresByOwner {
      id
      name
      logo {
        id
        url
      }
    }
  }
`;

import * as Yup from 'yup';
import Moment from 'moment';


export const validationSchema =  Yup.object().shape({
  startDate: Yup.string().nullable().required('Start Date is required')
  .test({
    name: 'date-test',
    message: 'Start Date must be before End Date',
    test: function (startDate: string) {
      if (startDate && this.parent.endDate) {
        if (Moment(startDate).isAfter(Moment(this.parent.endDate))) {
          return false;
        }
      }
      return true;
    }
  }),
  endDate: Yup.string().nullable().required('End Date is required')
});
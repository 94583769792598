import React, { useContext } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { useFormik, getIn } from 'formik';
import { AuthContext } from 'fbase/authContext';
import { useSnackbar } from 'notistack';
import validationSchema from './validations';
import { FirebaseContext } from 'fbase';
import { useStore } from 'store';

const ChangePassword: React.FC = () => {
  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const { currentUser } = useContext(AuthContext);

  const snackbar = useSnackbar();

  const { dispatch } = useStore();

  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };

  const stopLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      currentPassword: ''
    },
    initialErrors: {
      newPassword: 'Required',
      confirmPassword: 'Required',
      currentPassword: ''
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      startLoader();
      actions.setSubmitting(true);

      try {
        if (currentUser && currentUser.email) {
          const credential = auth.EmailAuthProvider.credential(currentUser.email, values.currentPassword);

          await currentUser?.reauthenticateWithCredential(credential);

          await currentUser?.updatePassword(values.newPassword);

          snackbar.enqueueSnackbar('Password updated', { variant: 'success' });

          actions.setSubmitting(false);

          stopLoader();
        }
      } catch (e) {
        let message = '';

        if (e.code) {
          switch (e.code) {
            case 'auth/weak-password':
              message = 'Password is weak :(';
              break;
            case 'auth/requires-recent-login':
              message = 'Please login again';
              break;
            case 'auth/wrong-password':
              message = 'Current password is wrong';
              break;
            default:
              message = e.message;
              console.log(e);
          }
        }

        snackbar.enqueueSnackbar(message, { variant: 'error' });

        actions.setSubmitting(false);
        stopLoader();
      }
    },
    enableReinitialize: true
  });

  const isButtonDisabled = !formik.isValid;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box padding={1}>
        <Box marginBottom={2}>
          <TextField
            type="password"
            label="Current Password"
            name="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            variant="outlined"
            onBlur={formik.handleBlur}
            autoComplete="off"
            autoCapitalize="off"
            error={!!getIn(formik.touched, 'currentPassword') && !!getIn(formik.errors, 'currentPassword')}
            helperText={!!getIn(formik.touched, 'currentPassword') && getIn(formik.errors, 'currentPassword')}
          />
        </Box>
        <Box marginBottom={2}>
          <TextField
            type="password"
            label="New password"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            variant="outlined"
            onBlur={formik.handleBlur}
            autoComplete="off"
            error={!!getIn(formik.touched, 'newPassword') && !!getIn(formik.errors, 'newPassword')}
            helperText={!!getIn(formik.touched, 'newPassword') && getIn(formik.errors, 'newPassword')}
          />
        </Box>
        <Box marginBottom={2}>
          <TextField
            type="password"
            label="Confrim password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            variant="outlined"
            onBlur={formik.handleBlur}
            autoComplete="off"
            error={!!getIn(formik.touched, 'confirmPassword') && !!getIn(formik.errors, 'confirmPassword')}
            helperText={!!getIn(formik.touched, 'confirmPassword') && getIn(formik.errors, 'confirmPassword')}
          />
        </Box>
        <Box paddingY={1}>
          <Button type="submit" variant="contained" color="primary" disabled={isButtonDisabled}>
            Update
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ChangePassword;

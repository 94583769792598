import { makeStyles, Theme } from '@material-ui/core';

interface IProps {
  colLength: number;
}

export const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
    height: '2px'
  },
  equalWidth: (props: IProps) => ({
    width: 'calc(100vw/' + props.colLength + ')'
  }),
  editIcon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  successBtn: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  tableContainer: {
    width: '70%',
    margin: 'auto'
  },
  table: {
    border: 'solid 1px ' + theme.palette.grey[200]
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2) + 'px auto',
    width: '100%',
    '& button': {
      margin: theme.spacing(0, 1)
    }
  },
  verticleAlignMiddle: {
    verticalAlign: 'middle'
  },
  verticleAlignTop: {
    verticalAlign: 'top'
  }
}));

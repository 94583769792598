import React, { useState, useEffect } from 'react';
import { useStyles } from './style';
import { IBizHour } from '../../types';
import { Grid, Typography, Button, TextField, Box, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Dialog } from '@lokobee/lokobee-ui';
import { map, filter } from 'lodash';

interface IProps {
  businessHours: IBizHour[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addService: (service: string, type: string) => void;
  services: string[];
}

const ServiceDialog = ({ businessHours, open, setOpen, addService, services }: IProps) => {
  const classes = useStyles();

  const serviceTypes = [
    { label: 'Pickup', value: 'RESTAURANT_PICK_UP' },
    { label: 'Dining', value: 'RESTAURANT_DINING' },
    { label: 'Delivery', value: 'RESTAURANT_DELIVERY' }
  ];

  const [selectedService, setSelectedService] = useState('');

  const [serviceType, setServiceType] = useState('RESTAURANT_DINING');

  const [error, setError] = useState(false);

  const serviceChangeHandler = (value: string) => {
    setSelectedService(value);
  };

  const modalClose = () => {
    setSelectedService('');
    setError(false);
    setOpen(false);
  };

  const handleServiceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceType((event.target as HTMLInputElement).value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedService(value);
  };

  const _addService = () => {
    if (!error && selectedService && selectedService !== '') {
      addService(selectedService, serviceType);
      modalClose();
    }
  };

  useEffect(() => {
    if (selectedService) {
      const existingServices = filter(businessHours, (obj: IBizHour) => obj.serviceName.toLowerCase() === selectedService.toLowerCase());

      let flag = false;

      existingServices.forEach((service) => {
        if (service.serviceType === serviceType) {
          setError(true);
          flag = true;
        }
      });

      if (!flag) {
        setError(false);
      }
    } else {
      setError(false);
    }
  }, [serviceType, selectedService, businessHours]);

  return (
    <Dialog open={open} setOpen={setOpen} dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: true }}>
      <Dialog.DialogContent className={classes.dialogContent}>
        <Box className={classes.dialogTitle}>
          <Typography variant="h5">Add Service</Typography>
        </Box>
        <Grid className={classes.contentContainer}>
          <Grid item={true} xs={6}>
            <Autocomplete
              id="free-solo-demo"
              freeSolo={true}
              onChange={(e: any, value: any) => serviceChangeHandler(value)}
              options={services}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Service"
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  value={selectedService}
                  onChange={onChange}
                  error={error}
                  helperText={error ? 'Service exists for selected service type.' : ''}
                />
              )}
            />
          </Grid>

          {/* <Grid item={true}>
            <RadioGroup value={serviceType} onChange={handleServiceTypeChange}>
              <Grid container={true}>
                {map(serviceTypes, (type, index) => {
                  return <FormControlLabel value={type.value} control={<Radio />} label={type.label} key={index} />;
                })}
              </Grid>
            </RadioGroup>
          </Grid> */}
        </Grid>
        <Box textAlign="right">
          <Button className={classes.serviceButton} variant="outlined" color="default" onClick={modalClose}>
            Cancel
          </Button>
          <Button className={classes.serviceButton} variant="contained" color="primary" onClick={_addService}>
            Add
          </Button>
        </Box>
      </Dialog.DialogContent>
      <Dialog.DialogActions showBtns={false} />
    </Dialog>
  );
};

export default ServiceDialog;

import { gql } from 'apollo-boost';

export default gql`
  query($id: ID!, $status: String, $category: String) {
    getDishesOfRestaurant(restaurantId: $id, status: $status, category: $category) {
      id
      title
      category
      description
      imageUrl
      price
      status
      specialDesc
      isPopular
    }
  }
`;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  divider: {
    height: '2px',
    marginTop: '-1px'
  },
  tabsContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  headerContainer: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1)
    }
  },
  emptyScreenContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  emptyScreenImage: {
    margin: theme.spacing(2),
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  },
  addIcon: {
    fontSize: 40,
    cursor: 'pointer'
  },
  hide: {
    display: 'none'
  }
}));

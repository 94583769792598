import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  actionButtonWrapper: {
    marginBottom: theme.spacing(1)
  },
  formControls: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  deleteIcon: {
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none'
    }
  },
  toppingGroup: {
    padding: theme.spacing(1)
  }
}));

import { isValidPhoneNumber } from 'react-phone-number-input';

const validate = (values: any) => {
  const errors: any = {};

  if (!values.phone || values.phone.trim() === '') errors.phone = 'Required';
  else if (!isValidPhoneNumber(values.phone)) errors.phone = 'Invalid phone number';

  return errors;
};

export default validate;

import React, { useEffect } from 'react';
import { useStyles } from './style';
import { Grid, Box, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import S3Image from 'components/S3Image';
import * as _ from 'lodash';
import { useStore } from 'store';
import { formatPhoneNumber } from 'util/number';
import { useGetGroceryStoreById } from 'graphql/hooks/grocery.hooks';
import BusinessHoursTable from '../BusinessHours/BusinessHoursTable';
import { IBizHour } from '../types';

interface IProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}
interface ITaxValue {
  intValue: number;
  shift: number;
}
export interface ITaxModeItemResponse {
  title: string;
  tax: ITaxValue;
}

const GroceryStoreInfoView = ({ setActiveStep, setEditing }: IProps) => {
  const classes = useStyles();
  const {
    state: { groceryStoreId }
  } = useStore();
  const { data } = useGetGroceryStoreById(groceryStoreId, 'ALL');

  useEffect(() => {
    if (data && data.ownerGetGroceryStoreById) {
      const { name, gallery, bizHours } = data.ownerGetGroceryStoreById;
      if (!name && !gallery && !bizHours) {
        setActiveStep(0);
      }
    }
  }, [data, setActiveStep]);

  const editData = (step: number) => {
    setEditing(true);
    setActiveStep(step);
  };
  if (data && data.ownerGetGroceryStoreById) {
    const { name, description, phone, address, logo, bizHours, gallery, timezone, googleReviewLink, taxModes } = data.ownerGetGroceryStoreById;
    if (name || gallery || bizHours) {
      const _phone = formatPhoneNumber(phone);

      return (
        <Grid container={true} className={classes.previewContainer}>
          <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
              <Typography variant="h6">Profile</Typography>
              <Button variant="contained" color="primary" size="small" className={classes.editBtn} onClick={() => editData(0)}>
                Edit
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <Grid container={true}>
                <Grid item={true} xs={12} sm={6} md={4}>
                  <S3Image src={logo.url} />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={8}>
                  <Box className={classes.profileDetailsContainer}>
                    <Box padding={1}>
                      <Typography variant="h6">{name}</Typography>
                      <Typography variant="body2">{description}</Typography>
                    </Box>
                    <Box className={classes.profileDetails}>
                      <Box padding={1}>
                        <Typography variant="h6">Phone</Typography>
                        <Typography variant="body2">{_phone ? _phone : phone}</Typography>
                      </Box>
                      <Box padding={1}>
                        <Typography variant="h6">Address</Typography>
                        <Typography variant="body2">{address.text}</Typography>
                      </Box>
                      <Box padding={1}>
                        <Typography variant="h6">Time Zone</Typography>
                        <Typography variant="body2">{timezone}</Typography>
                      </Box>
                      {taxModes && (
                        <Box padding={1}>
                          <Typography variant="h6">Tax modes</Typography>
                          <Box display={'flex'}>
                            {taxModes.map((taxModeItem: ITaxModeItemResponse, index: number) => {
                              return (
                                <Box key={index} marginRight={2}>
                                  <Typography variant="body2">{taxModeItem.title}</Typography>
                                  <Typography variant="body2">{taxModeItem.tax ? (taxModeItem.tax.intValue * Math.pow(10, taxModeItem.tax.shift + 2)).toFixed(2) : ''}%</Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      )}
                      {googleReviewLink && (
                        <Box padding={1}>
                          <Typography variant="h6">Google Review Link</Typography>
                          <Typography variant="body2">{googleReviewLink}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
              <Typography variant="h6">Business Hours</Typography>
              <Button variant="contained" color="primary" size="small" className={classes.editBtn} onClick={() => editData(1)}>
                Edit
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container={true}>
                {!bizHours ? (
                  <Box paddingY={1}>
                    <Typography variant="body1"> Business Hours Not Added</Typography>
                  </Box>
                ) : (
                  <>
                    {_.map(bizHours, (hour: IBizHour, index: number) => {
                      return (
                        <Grid item={true} xs={12} sm={6} md={4} key={index} className={classes.serviceTable}>
                          <BusinessHoursTable service={hour} />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
              <Typography variant="h6">Gallery</Typography>
              <Button variant="contained" color="primary" size="small" className={classes.editBtn} onClick={() => editData(2)}>
                Edit
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container={true} spacing={2}>
                {!gallery ? (
                  <Box paddingY={1}>
                    <Typography variant="body1"> Gallery images not added.</Typography>
                  </Box>
                ) : (
                  <>
                    {_.map(gallery, (img: any, index) => {
                      const { url } = img.md || { url: '' };
                      return (
                        <Grid item={true} xs={12} sm={6} md={4} key={index}>
                          <Box height="200px" width="100%">
                            <S3Image src={url} />
                          </Box>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      );
    } else {
      return null;
    }
  }
  return null;
};

export default GroceryStoreInfoView;

import { TextBroadcastHistory } from 'generated/custom';
import {
  addBroadcastPhoneNumbersMutation,
  createTextBroadcastMutation,
  deleteTextBroadcastMutation,
  requestTextMarketingMutation,
  updateTextBroadCastMutation
} from 'graphql/mutations/RestaurantTextBroadCasts.mutation';
import { GET_BROADCAST_HISTORY, GET_RESTAURANT_TEXT_BROADCASTS, GET_TEXT_BROADCASTS_TEXT_NUMBERS, GET_TEXT_HISTORY } from 'graphql/query';
import { getRestaurantStatus } from 'graphql/query/getRestaurantById.query';
import getTextBroadcastPhoneNumbersQuery from 'graphql/query/getTextBroadcastPhoneNumbers.query';
import { useLazyQueryWithLoader, useMutationWithLoader, useQueryWithLoader } from 'hooks/loader';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useEffect, useState } from 'react';

const count = 10;
export const useGetTextHistory = (bizId: string | number | null) => {
  const { loading, data } = useQueryWithLoader(GET_TEXT_HISTORY, {
    variables: {
      input: {
        bizId: bizId
      }
    }
  });

  if (data && data.getTextHistory) {
    const getTextHistory = data.getTextHistory;

    return {
      loading,
      getTextHistory
    };
  }

  return {
    loading,
    getTextHistory: null
  };
};

export const useGetTextBroadcastHistory = (restaurantId: string | null) => {
  const [hasMore, setHasMore] = useState(false);
  const [textBroadcasts, setBroadcasts] = useState<TextBroadcastHistory[] | null>();
  const [cursor, setCursor] = useState(null);

  const [fetching, setFetching] = useState(true);
  const [fetchBroadcastHistory, { data, error, fetchMore }] = useLazyQueryWithLoader(GET_BROADCAST_HISTORY, {
    variables: {
      input: {
        bizId: restaurantId,
        pageCursor: null,
        count: count
      }
    }
  });

  useEffect(() => {
    if (data && data.getRestaurantTextBroadcasts && !error) {
      const { getRestaurantTextBroadcasts } = data;

      const { pageCursor, broadcasts } = getRestaurantTextBroadcasts;
      setBroadcasts(broadcasts);
      setHasMore(!!pageCursor);
      setCursor(pageCursor);

      setFetching(false);
    }
    if (error) {
      setFetching(false);
      console.log('ERROR: ' + error);
    }
  }, [data, error]);
  const fetchMoreData = async () => {
    if (hasMore) {
      try {
        setFetching(true);
        await fetchMore({
          query: GET_BROADCAST_HISTORY,
          variables: {
            input: {
              bizId: restaurantId,
              pageCursor: cursor,
              count: count
            }
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            const { pageCursor } = fetchMoreResult.getRestaurantTextBroadcasts;
            const newCursor = pageCursor;

            return {
              getRestaurantTextBroadcasts: {
                broadcasts: [...previousResult.getRestaurantTextBroadcasts.broadcasts, ...fetchMoreResult.getRestaurantTextBroadcasts.broadcasts],
                pageCursor: newCursor,
                __typename: previousResult.getRestaurantTextBroadcasts.__typename
              }
            };
          }
        });
      } catch (e) {
        setFetching(false);
        console.log(e);
      }
    }
  };

  return {
    textBroadcasts,
    fetchBroadcastHistory,
    fetchMoreData,
    fetching,
    hasMore,
    error
  };
};

export const useGetRestaurantActiveTextBroadcast = (bizId: string | number | null) => {
  const { loading, data } = useQueryWithLoader(GET_RESTAURANT_TEXT_BROADCASTS, {
    variables: {
      input: {
        bizId: bizId
      }
    }
  });

  if (data && data.getRestaurantActiveTextBroadcast) {
    const getRestaurantActiveTextBroadcast = data.getRestaurantActiveTextBroadcast;

    return {
      loading,
      getRestaurantActiveTextBroadcast
    };
  }

  return {
    loading,
    getRestaurantActiveTextBroadcast: null
  };
};

export const useUpdateRestaurantTextBroadcast = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updateTextBroadcast, { loading, data, error }] = useMutationWithLoader<any, any>(updateTextBroadCastMutation, {
    update: (cache, { data: { updateTextBroadcast: newData } }) => {
      let updatedCurrentBroadcast: TextBroadcastHistory = newData;
      try {
        // Update Current Broadcast cache
        const { getRestaurantActiveTextBroadcast: oldActiveData }: any = cache.readQuery({
          query: GET_RESTAURANT_TEXT_BROADCASTS,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });
        let textBroadcastTypename = (oldActiveData && oldActiveData.__typename) || 'TextBroadcast';
        cache.writeQuery({
          query: GET_RESTAURANT_TEXT_BROADCASTS,
          data: { getRestaurantActiveTextBroadcast: updatedCurrentBroadcast, __typename: textBroadcastTypename },
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });
        // Update History Table cache
        const { getRestaurantTextBroadcasts: oldData }: any = cache.readQuery({
          query: GET_BROADCAST_HISTORY,
          variables: {
            input: {
              bizId: restaurantId,
              pageCursor: null,
              count: count
            }
          }
        });
        let broadcastsList: TextBroadcastHistory[] = (oldData && oldData.broadcasts) || [];
        let __typename = (oldData && oldData.__typename) || 'BroadcastConnection';
        broadcastsList = [updatedCurrentBroadcast, ...broadcastsList];
        cache.writeQuery({
          query: GET_BROADCAST_HISTORY,
          data: { getRestaurantTextBroadcasts: { broadcasts: broadcastsList, pageCursor: null, __typename: __typename } },
          variables: {
            input: {
              bizId: restaurantId,
              pageCursor: null,
              count: count
            }
          }
        });
      } catch (e) {}
    }
  });

  return {
    updateTextBroadcast,
    loading,
    data,
    error,
    restaurantId
  };
};

export const useCreateRestaurantTextBroadcast = () => {
  const { restaurantId } = useActiveRestaurant();

  const [createTextBroadcast, { loading, data, error }] = useMutationWithLoader<any, any>(createTextBroadcastMutation, {
    update: (cache, { data: { createTextBroadcast: newData } }) => {
      // Create a new Current Broadcast cache
      let textBroadcastTypename = (newData && newData.__typename) || 'TextBroadcast';
      cache.writeQuery({
        query: GET_RESTAURANT_TEXT_BROADCASTS,
        data: { getRestaurantActiveTextBroadcast: newData, __typename: textBroadcastTypename },
        variables: {
          input: {
            bizId: restaurantId
          }
        }
      });
      try {
        const { getRestaurantTextBroadcasts: oldData }: any = cache.readQuery({
          query: GET_BROADCAST_HISTORY,
          variables: {
            input: {
              bizId: restaurantId,
              pageCursor: null,
              count: count
            }
          }
        });
        let oldBroadcastsList: any[] = (oldData && oldData.broadcasts) || [];
        let __typename = (oldData && oldData.__typename) || 'BroadcastConnection';
        oldBroadcastsList = [newData, ...oldBroadcastsList];
        cache.writeQuery({
          query: GET_BROADCAST_HISTORY,
          data: { getRestaurantTextBroadcasts: { broadcasts: oldBroadcastsList, pageCursor: null, __typename: __typename } },
          variables: {
            input: {
              bizId: restaurantId,
              pageCursor: null,
              count: count
            }
          }
        });
      } catch (e) {}
    }
  });

  return {
    createTextBroadcast,
    loading,
    data,
    error,
    restaurantId
  };
};

export const useDeleteTextBroadcast = () => {
  const { restaurantId } = useActiveRestaurant();

  const [deleteTextBroadcast, { loading, data, error }] = useMutationWithLoader<any, any>(deleteTextBroadcastMutation, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_TEXT_BROADCASTS,
        variables: {
          input: {
            bizId: restaurantId
          }
        }
      },
      {
        query: GET_BROADCAST_HISTORY,
        variables: {
          input: {
            bizId: restaurantId,
            pageCursor: null,
            count: 10
          }
        }
      }
    ],
    update: (cache, { data: { deleteTextBroadcast } }) => {
      cache.writeQuery({
        variables: {
          input: {
            bizId: restaurantId
          }
        },
        query: GET_RESTAURANT_TEXT_BROADCASTS,
        data: { getRestaurantActiveTextBroadcast: null }
      });
    }
  });

  return {
    deleteTextBroadcast,
    loading,
    data,
    error,
    restaurantId
  };
};

export const useRequestTextMarketing = () => {
  const { restaurantId } = useActiveRestaurant();

  const [requestTextMarketing, { loading, data, error }] = useMutationWithLoader<any, any>(requestTextMarketingMutation, {
    refetchQueries: [
      {
        query: getRestaurantStatus,
        variables: {
          input: {
            id: restaurantId
          }
        }
      }
    ]
  });

  return {
    requestTextMarketing,
    loading,
    data,
    error,
    restaurantId
  };
};

export const useAddBroadcastPhoneNumbers = () => {
  const { restaurantId } = useActiveRestaurant();

  const [addBroadcastPhoneNumbers, { loading, data, error }] = useMutationWithLoader<any, any>(addBroadcastPhoneNumbersMutation, {
    refetchQueries: [
      {
        query: getTextBroadcastPhoneNumbersQuery,
        variables: {
          input: {
            bizId: restaurantId
          }
        }
      }
    ]
  });

  return {
    addBroadcastPhoneNumbers,
    loading,
    data,
    error
  };
};

export const useGetTextBroadcastPhoneNumbers = (bizId: string | number | null) => {
  const { loading, data } = useQueryWithLoader(GET_TEXT_BROADCASTS_TEXT_NUMBERS, {
    variables: {
      input: {
        bizId: bizId
      }
    }
  });

  if (data && data.getTextBroadcastPhoneNumbers) {
    const getTextBroadcastPhoneNumbers = data.getTextBroadcastPhoneNumbers;

    return {
      loading,
      getTextBroadcastPhoneNumbers
    };
  }

  return {
    loading,
    getTextBroadcastPhoneNumbers: null
  };
};

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    border: 'solid 1px ' + theme.palette.grey[300]
  },
  textField: {
    maxWidth: '100px'
  }
}));

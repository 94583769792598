import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  addIcon: {
    fontSize: 40,
    cursor: 'pointer'
  },
  divider: {
    margin: theme.spacing(1, 0),
    height: '2px'
  },
  emptyScreenContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  emptyScreenImage: {
    marginBottom: theme.spacing(1),
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  }
}));

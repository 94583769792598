import * as Yup from 'yup';
import Big from 'big.js';
import Moment from 'moment';

const regex = new RegExp(/^(?!0+$)[0-9]+(\.[0-9][0-9]?)?$/);
export const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Coupon code is required')
    .max(15),
  startDate: Yup.string()
    .nullable()
    .required('Start Date is required')
    .test({
      name: 'date-test',
      message: 'Start Date must be before End Date',
      test: function(startDate: string) {
        if (startDate && this.parent.endDate) {
          if (Moment(startDate).isAfter(Moment(this.parent.endDate))) {
            return false;
          }
        }
        return true;
      }
    }),
  endDate: Yup.string()
    .nullable()
    .required('End Date is required'),
  minSpending: Yup.string()
    .required('Min Spending is required')
    .test({
      name: 'equality-test2',
      message: 'Min Spending must be more than Max Discount',
      test: function(minSpending: string) {
        if (this.parent.discountType === 'DOLLAR' && minSpending && this.parent.discountValue && Big(minSpending).lte(this.parent.discountValue)) {
          return false;
        }
        return true;
      }
    })
    .test({
      name: 'regex-test',
      message: 'Invalid value',
      test: function(minSpending: string) {
        if (regex.test(minSpending)) {
          return true;
        }
        return false;
      }
    }),
  discountValue: Yup.string()
    .required('Discount is required')
    .matches(regex, 'Invalid Discount')
    .test({
      name: 'regex-test',
      message: 'Invalid value',
      test: function(discountValue: string) {
        if (regex.test(discountValue)) {
          return true;
        }
        return false;
      }
    }),
  maxDiscount: Yup.string().when('discountType', {
    is: (value: string) => value === 'PERCENT',
    then: Yup.string().test({
      name: 'regex-test',
      message: 'Invalid value',
      test: function(maxDiscount: number = 0) {
        if (maxDiscount.toString() === '') {
          return true;
        }
        if (regex.test(maxDiscount.toString())) {
          return true;
        }
        return false;
      }
    })
  }),

  couponCost: Yup.number()
    .required('Cost per promotion is required')
    .moreThan(1, 'Minimum value should be $2'),
  maxUse: Yup.string().required('Maximum # of promotion is required'),
  dayOfWeek: Yup.array()
    .of(Yup.number())
    .required('Days of week is required'),
  orderType: Yup.array()
    .of(Yup.string())
    .required('Order Type is required')
});

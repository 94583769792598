import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  DialogActions,
  Button,
  FormHelperText,
  Typography
} from '@material-ui/core';
import { ArrowBack, ArrowForward, Cancel, Save, AttachMoney } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSnackbar } from 'notistack';
import { useFormik, setNestedObjectValues } from 'formik';
import { useActiveRestaurant } from 'hooks/restaurant';
import CustomStepper from 'components/CustomStepper';
import DatePicker from 'components/DatePicker';
import { useStyles } from './style';
import { IInitialValueType } from './types';
import { Coupon } from 'generated/custom';
import Moment from 'moment';
import { chain, isEmpty, filter, isUndefined } from 'lodash';
import { step1, step2 } from './validation';
import { useCreateCoupon } from 'graphql/hooks/coupons.hooks';
import Big from 'big.js';
import moment from 'moment';

const daysofWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const orderTypes = ['TAKEOUT', 'DELIVERY', 'DINING'];

interface IAddCoupons {
  open: boolean;
  onClose: () => void;
  coupon: Coupon | null;
}

const AddEditCoupon: React.FC<IAddCoupons> = ({ open, onClose, coupon }) => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const { restaurantId } = useActiveRestaurant();

  const { createCoupon, loading, error, data } = useCreateCoupon();

  const [activeStep, setActiveStep] = useState(1);

  const [maxDiscount, setMaxDiscount] = useState<string | undefined>('');

  const steps = ['Name & Date', 'Discount & Type'];

  const initialValues: IInitialValueType = {
    code: '',
    ocassion: '',
    startDate: null,
    endDate: null,
    subtotalThreshold: '',
    taxMethod: 'POST_DISCOUNT',
    discountType: 'PERCENT',
    discountValue: '',
    maxDiscount: '',
    maxUse: '',
    useCnt: '',
    description: '',
    dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
    orderType: ['TAKEOUT', 'DELIVERY', 'DINING'],
    isPrivate: false,
    isSingleUse: false
  };

  const [intialFormValues, setInitialFormValues] = useState<IInitialValueType>(initialValues);

  /**
   * Transforming coupon data to form values
   * Operations like type conversion from number to string, currency from cents to USD, etc...
   */
  useEffect(() => {
    if (coupon) {
      //TODO: generate-types from codegen and use orignal coupon schema here for toFormValue type
      const toFormValue: any = {
        ...coupon,
        ocassion: coupon.description.split(',')[0],
        maxUse: coupon.maxUse ? `${coupon.maxUse}` : '',
        useCnt: coupon.useCnt ? `${coupon.useCnt}` : '',
        subtotalThreshold: coupon.subtotalThreshold
          ? Big(coupon.subtotalThreshold)
              .div(100)
              .toString()
          : '',
        maxDiscount: coupon.maxDiscount
          ? Big(coupon.maxDiscount)
              .div(100)
              .toString()
          : '',
        discountValue:
          coupon.discountType === 'DOLLAR'
            ? Big(coupon.discountValue)
                .div(100)
                .toString()
            : coupon.discountValue
      };

      setInitialFormValues(toFormValue);
    }
  }, [coupon]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'code') {
      const re = /^[a-zA-Z0-9]*$/;

      if (value.includes(' ') || !re.test(value)) {
        return false;
      }

      event.target.value = value.toUpperCase();
    }

    if (name === 'subtotalThreshold') {
      const re = /^[0-9]*$/;

      if (!re.test(value)) {
        return false;
      }
    }

    if (name === 'discountValue') {
      const re = /^(?!0+$)[0-9]*$/;

      if (!re.test(value)) {
        return false;
      }

      if (values.discountType === 'PERCENT' && value && Big(value).gte(100)) {
        return false;
      }
    }

    if (name === 'maxUse' || name === 'maxDiscount') {
      if (value && Big(value).lt(1)) {
        return false;
      }
    }

    handleChange(event);
  };

  const handleDateChange = (date: MaterialUiPickersDate, name: string) => {
    setFieldValue(name, date ? moment(date).format('yyyy-MM-DD') : null);
  };

  /**
   * Transform form data to mutation input type
   */
  const prepareInput = (data: any): Coupon => {
    const input = { ...data, name: data.ocassion, bizId: restaurantId };

    const toCents = ['subtotalThreshold', 'maxDiscount'];

    //Removing empty values
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        input[key] = toCents.includes(key)
          ? parseInt(
              Big(data[key])
                .mul(100)
                .toString()
            )
          : data[key];
      } else {
        delete input[key];
      }
    });

    if (input.discountType === 'DOLLAR') {
      input.discountValue = Big(input.discountValue)
        .mul(100)
        .toString();
    }

    if (input.discountType === 'PERCENT') {
      input.discountValue = Big(input.discountValue).toString();
    }

    delete input['ocassion'];

    return input;
  };

  const onSubmit = async (values: IInitialValueType) => {
    const input: Coupon = prepareInput(values);

    try {
      const response = await createCoupon({
        variables: {
          input
        }
      });

      if (response) {
        if (response.data) {
          const feedbackText = response.data && response.data.createCoupon ? 'Coupon saved successfully' : 'Coupon updated successfully';

          snackbar.enqueueSnackbar(feedbackText, {
            variant: 'success'
          });

          handleCancel();
        }

        if (response.errors?.length) {
          const errorText = response.errors[0].message;

          snackbar.enqueueSnackbar(errorText, {
            variant: 'error'
          });
        }
      } else {
        snackbar.enqueueSnackbar('Failed to save coupon', {
          variant: 'error'
        });
      }
    } catch (err) {
      snackbar.enqueueSnackbar('Failed to save coupon', {
        variant: 'error'
      });
    }
  };

  const handleCancel = () => {
    onClose();
    resetForm();
    setActiveStep(1);
    setInitialFormValues(initialValues);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: intialFormValues,
    validationSchema: activeStep === 1 ? step1 : step2,
    onSubmit
  });

  const { values, handleChange, handleBlur, resetForm, setTouched, submitForm, touched, errors, setFieldValue, validateForm } = formik;

  const readOnly = coupon ? true : false;

  const getUsedCount = () => {
    const color = !!values.maxUse && !!values.useCnt && Big(values.useCnt).gte(values.maxUse) ? 'error' : 'secondary';
    const useCount = !!values.useCnt ? values.useCnt : 0;

    if (readOnly) {
      return (
        <Typography variant="caption" color={color}>
          Used: {useCount}
        </Typography>
      );
    }

    return null;
  };

  /**
   * Setting description
   */
  useEffect(() => {
    const { ocassion, subtotalThreshold, discountType, discountValue, maxDiscount } = values;

    const discount = !!discountValue ? Big(discountValue).toString() : '';

    const value = !!discountValue && Big(discountValue).gt(0) ? ', Get ' + (discountType === 'PERCENT' ? `${discount}%` : `$${discount}`) + ' Off' : '';

    const minSpendThreshold = !!subtotalThreshold && !!discountValue && Big(subtotalThreshold).gt(0) ? ` on Purchase over $${subtotalThreshold}` : '';

    const maxDiscountThreshold = !!maxDiscount && !!discountValue && Big(maxDiscount).gt(0) ? `, Max Discount $${maxDiscount}` : '';

    const desc = !!ocassion ? `${ocassion}${value}${minSpendThreshold}${maxDiscountThreshold}` : '';

    setFieldValue('description', desc);
  }, [values.ocassion, values.subtotalThreshold, values.discountType, values.discountValue, values.maxDiscount, values, setFieldValue]);

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={onClose} onEscapeKeyDown={handleCancel} disableBackdropClick={true}>
      <DialogContent dividers>
        <Box padding={2}>
          <CustomStepper activeStep={activeStep} steps={steps} />
        </Box>
        <Box paddingX={5} paddingBottom={10}>
          {activeStep === 1 && (
            <>
              <Grid container spacing={5} className={classes.gridContainer}>
                <Grid item={true} xs={3}>
                  <TextField
                    fullWidth={true}
                    name="code"
                    type="text"
                    variant="outlined"
                    label="Coupon Code"
                    value={values.code}
                    placeholder="Upto 15 characters"
                    onChange={onChange}
                    inputProps={{
                      maxLength: 15,
                      minLength: 2
                    }}
                    InputProps={{
                      readOnly
                    }}
                    onBlur={handleBlur}
                    error={!!touched.code && !!errors.code}
                    helperText={!!touched.code && errors.code}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    fullWidth={true}
                    name="ocassion"
                    type="text"
                    variant="outlined"
                    label="Ocassion"
                    placeholder="eg: Birthday, Aniversary, Christmas Sale.."
                    value={values.ocassion}
                    onBlur={handleBlur}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 100
                    }}
                    InputProps={{
                      readOnly
                    }}
                    error={!!touched.ocassion && !!errors.ocassion}
                    helperText={!!touched.ocassion && errors.ocassion}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item={true} xs={3}>
                  <DatePicker
                    label="Start Date"
                    name="startDate"
                    value={values.startDate}
                    minDate={Moment()}
                    maxDate={values.endDate ? Moment(values.endDate) : Date.parse('2050-12-31')}
                    readOnly={readOnly}
                    onBlur={handleBlur}
                    onChange={handleDateChange}
                    errors={!!touched.startDate && !!errors.startDate ? errors.startDate : ''}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <DatePicker
                    label="End Date"
                    name="endDate"
                    value={values.endDate}
                    minDate={values.startDate ? Moment(values.startDate) : Moment()}
                    maxDate={Date.parse('2050-12-31')}
                    readOnly={readOnly}
                    onBlur={handleBlur}
                    onChange={handleDateChange}
                    errors={!!touched.endDate && !!errors.endDate ? errors.endDate : ''}
                  />
                </Grid>
                <Grid item={true} xs={3} className={classes.center}>
                  <FormControlLabel
                    className={classes.checkBox}
                    control={<Checkbox disabled={readOnly} name="isPrivate" onChange={onChange} checked={values.isPrivate} color="primary" />}
                    label="Private Coupon"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item={true} xs={3} className={classes.center}>
                  <FormControlLabel
                    className={classes.checkBox}
                    control={<Checkbox disabled={readOnly} name="isSingleUse" onChange={onChange} checked={values.isSingleUse} color="primary" />}
                    label="Single Use"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Grid container spacing={5} className={classes.gridContainer}>
                <Grid item={true} xs={3}>
                  <TextField
                    fullWidth={true}
                    name="subtotalThreshold"
                    type="text"
                    variant="outlined"
                    label="Min Spending ($)"
                    value={values.subtotalThreshold}
                    onBlur={handleBlur}
                    onChange={onChange}
                    InputProps={{
                      readOnly,
                      startAdornment: <AttachMoney />
                    }}
                    error={!!touched.subtotalThreshold && !!errors.subtotalThreshold}
                    helperText={!!touched.subtotalThreshold && errors.subtotalThreshold}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    fullWidth={true}
                    name="discountValue"
                    type="text"
                    variant="outlined"
                    label={`Discount (${values.discountType === 'DOLLAR' ? '$' : '%'})`}
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.discountValue}
                    InputProps={{
                      readOnly,
                      startAdornment: values.discountType === 'DOLLAR' ? <AttachMoney /> : '',
                      endAdornment: (
                        <FormControl className={classes.formControl} disabled={readOnly}>
                          <Select
                            native
                            value={values.discountType}
                            name="discountType"
                            onChange={(e: any) => {
                              handleChange(e);
                              if (e.target.value === 'DOLLAR') {
                                setMaxDiscount(values.maxDiscount);
                                setFieldValue('maxDiscount', '');
                              } else {
                                if (values.discountValue && Big(values.discountValue).gte(100)) {
                                  setFieldValue('discountValue', '');
                                }
                                setFieldValue('maxDiscount', maxDiscount);
                              }
                            }}>
                            <option value="PERCENT">%</option>
                            <option value="DOLLAR">$</option>
                          </Select>
                        </FormControl>
                      )
                    }}
                    error={!!touched.discountValue && !!errors.discountValue}
                    helperText={!!touched.discountValue && errors.discountValue}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    fullWidth={true}
                    name="maxDiscount"
                    type="text"
                    variant="outlined"
                    label="Max Discount ($)"
                    value={values.maxDiscount}
                    onBlur={handleBlur}
                    onChange={onChange}
                    disabled={values.discountType === 'DOLLAR'}
                    InputProps={{
                      readOnly,
                      startAdornment: <AttachMoney />
                    }}
                    error={!!touched.maxDiscount && !!errors.maxDiscount}
                    helperText={!!touched.maxDiscount && errors.maxDiscount}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    fullWidth={true}
                    name="maxUse"
                    type="number"
                    variant="outlined"
                    label="Maximum # of Coupons"
                    disabled={values.isSingleUse}
                    value={values.maxUse}
                    onBlur={handleBlur}
                    onChange={onChange}
                    InputProps={{
                      readOnly
                    }}
                    error={!!touched.maxUse && !!errors.maxUse}
                    helperText={!!touched.maxUse && errors.maxUse}
                  />
                  {getUsedCount()}
                </Grid>
              </Grid>
              <Grid container spacing={5} className={classes.gridContainer}>
                <Grid item={true} xs={4}>
                  <FormControl variant="outlined" style={{ width: '100%' }} error={!!touched.dayOfWeek && !!errors.dayOfWeek}>
                    <InputLabel>Days of Week</InputLabel>
                    <Select
                      readOnly={readOnly}
                      autoWidth
                      multiple
                      value={values.dayOfWeek}
                      renderValue={(selected: any) => {
                        if (selected.length >= 7) {
                          return 'Whole Week';
                        } else {
                          const days = chain(selected)
                            .orderBy(
                              [
                                (day: string) =>
                                  Moment()
                                    .day(day)
                                    .format('d')
                              ],
                              ['asc']
                            )
                            .map((day: string) =>
                              Moment()
                                .day(day)
                                .format('ddd')
                            )
                            .value();

                          return days.join(', ');
                        }
                      }}
                      onChange={(e: any) => {
                        setFieldValue(
                          'dayOfWeek',
                          filter(e.target.value, (a) => !isUndefined(a))
                        );
                      }}
                      label="Days of Week"
                      name="dayOfWeek"
                      inputProps={{
                        onBlur: handleBlur
                      }}>
                      <Box marginLeft={3.5}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.dayOfWeek.length >= 7}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setFieldValue('dayOfWeek', [0, 1, 2, 3, 4, 5, 6]);
                                } else {
                                  setFieldValue('dayOfWeek', []);
                                }
                              }}
                            />
                          }
                          label="Whole Week"
                        />
                      </Box>
                      {daysofWeek.map((day: string, index: number) => (
                        <MenuItem key={day} value={index}>
                          <Checkbox checked={values.dayOfWeek.includes(index)} />
                          <ListItemText primary={day} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{!!touched.dayOfWeek && errors.dayOfWeek}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControl variant="outlined" style={{ width: '100%' }} error={!!touched.orderType && !!errors.orderType}>
                    <InputLabel>Order Type</InputLabel>
                    <Select
                      readOnly={readOnly}
                      autoWidth
                      multiple
                      value={values.orderType}
                      renderValue={(selected: any) => {
                        return selected.join(', ');
                      }}
                      onChange={handleChange}
                      label="Order Type"
                      name="orderType"
                      inputProps={{
                        onBlur: handleBlur
                      }}>
                      {orderTypes.map((value) => (
                        <MenuItem key={value} value={value}>
                          <Checkbox checked={values.orderType.includes(value)} />
                          <ListItemText primary={value} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{!!touched.orderType && errors.orderType}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControl variant="outlined" style={{ width: '100%' }} error={!!touched.taxMethod && !!errors.taxMethod}>
                    <InputLabel>Tax Method</InputLabel>
                    <Select
                      readOnly={readOnly}
                      autoWidth
                      value={values.taxMethod}
                      renderValue={(selected: any) => {
                        return selected;
                      }}
                      onChange={handleChange}
                      label="Tax Method"
                      name="taxMethod"
                      inputProps={{
                        onBlur: handleBlur
                      }}>
                      {['POST_DISCOUNT', 'PRE_DISCOUNT'].map((value) => (
                        <MenuItem key={value} value={value}>
                          <ListItemText primary={value} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{!!touched.taxMethod && errors.taxMethod}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={5} className={classes.gridContainer}>
                <Grid item={true} xs={6}>
                  <TextField
                    fullWidth={true}
                    name="description"
                    type="text"
                    variant="outlined"
                    label="Description"
                    value={values.description}
                    InputProps={{
                      readOnly: true
                    }}
                    error={!!touched.description && !!errors.description}
                    helperText={!!touched.description && errors.description}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} className={classes.gridContainer}>
                <Grid item={true} xs={3}>
                  {/* <FormControlLabel
                    className={classes.checkBox}
                    control={<Checkbox disabled={readOnly} name="isPrivate" onChange={onChange} checked={values.isPrivate} color="primary" />}
                    label="Private Coupon"
                    labelPlacement="end"
                  /> */}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {activeStep === 2 && (
          <Button variant="contained" color="primary" onClick={() => setActiveStep(1)} startIcon={<ArrowBack />}>
            Back
          </Button>
        )}
        <Button variant="outlined" onClick={handleCancel} startIcon={<Cancel />}>
          Cancel
        </Button>
        {activeStep === 1 && (
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForward />}
            onClick={async () => {
              const errors = await validateForm();

              if (isEmpty(errors)) {
                setActiveStep(2);
              } else {
                setTouched(setNestedObjectValues(errors, true));
              }
            }}>
            Next
          </Button>
        )}
        {activeStep === 2 && !coupon && (
          <Button disabled={loading} variant="contained" color="primary" endIcon={<Save />} onClick={() => submitForm()}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCoupon;

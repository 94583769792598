import React, { useState, ChangeEvent, useContext } from 'react';
import { Button, Box, Typography, TextField } from '@material-ui/core';
import { AuthContext } from 'fbase/authContext';
import { FirebaseContext } from 'fbase';
import { useSnackbar } from 'notistack';
import { useStore } from 'store';

interface IProps {
  verificationId: string | null;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
}

const VerifyOtp: React.FC<IProps> = ({ activeStep, verificationId, setActiveStep }) => {
  /**
   * Get firebase reference
   */
  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const [value, setValue] = useState('');

  const snackbar = useSnackbar();

  const { dispatch } = useStore();

  const { currentUser } = useContext(AuthContext);

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };

  const stopLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };

  const verifyOTP = async () => {
    try {
      if (verificationId) {
        startLoader();

        const phoneCredential = auth.PhoneAuthProvider.credential(verificationId, value);

        if (currentUser) {
          await currentUser.updatePhoneNumber(phoneCredential);
          snackbar.enqueueSnackbar('Phone number updated succesfully.', { variant: 'success' });
          setActiveStep(1);
          stopLoader();
        } else {
          stopLoader();
          snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
        }
      }
    } catch (e) {
      if (e.code === 'auth/credential-already-in-use') {
        snackbar.enqueueSnackbar('Phone number is already in use by another account.', { variant: 'error' });
      } else if (e.code === 'auth/invalid-verification-code') {
        snackbar.enqueueSnackbar('Invalid OTP', { variant: 'error' });
      } else {
        snackbar.enqueueSnackbar(e.message, { variant: 'error' });
      }

      console.log('OTP VERIFICATION ERROR');
      stopLoader();
      setActiveStep(1);
    }
  };

  if (activeStep !== 2) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h5" paragraph={true}>
        Verify Mobile Number
      </Typography>
      <Typography variant="body1" paragraph={true}>
        OTP has been sent to your mobile number please enter in below
      </Typography>
      <TextField name="name" value={value} onChange={_onChange} label="Enter Your OTP" />
      <br />
      <br />
      <Button color="primary" variant="contained" onClick={verifyOTP}>
        Verify
      </Button>
    </Box>
  );
};

export default VerifyOtp;

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Button, Divider, Tabs, Tab, withStyles, useTheme, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useStyles } from './style';
import AddEditCoupon from './AddEditCoupon';
import { useGetCoupons } from 'graphql/hooks/coupons.hooks';
import CouponList from './CouponList';
import Moment from 'moment';
import { Coupon } from 'generated/custom';
import { useActiveRestaurant } from 'hooks/restaurant';
import Big from 'big.js';
import Tooltip from '@material-ui/core/Tooltip';

const Promotions: React.FC = () => {
  const classes = useStyles();

  const theme = useTheme();

  const { restaurantId } = useActiveRestaurant();

  const { data: couponsFromQuery, loading, getCoupons } = useGetCoupons();

  const [activeTab, setActiveTab] = useState(0);

  const [open, setOpen] = useState(false);

  const [couponData, setCouponData] = useState<Coupon | null>(null);

  const [activeCoupons, setActiveCoupons] = useState<Coupon[]>([]);

  const [inactiveCoupons, setInactiveCoupons] = useState<Coupon[]>([]);

  const _setActiveTab = (_event: React.ChangeEvent<{}>, index: number) => {
    setActiveTab(index);
  };

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const getCouponsQuery = useCallback(() => {
    if (restaurantId) {
      getCoupons({
        variables: {
          input: {
            bizId: restaurantId
          }
        }
      });
    }
  }, [getCoupons, restaurantId]);

  const setCoupon = (data: any) => {
    setCouponData(data);
    setOpen(true);
  };

  const isCouponActive = (coupon: Coupon) => {
    const { startDate, endDate, maxUse, useCnt } = coupon;

    const today = Moment().format('yyyy-MM-DD');

    const isBetween = Moment(today).isBetween(startDate, endDate, undefined, '[]');

    const isUnused = useCnt && maxUse && Big(useCnt).gte(maxUse) ? false : true;

    return isBetween && isUnused;
  };

  useEffect(() => {
    const activeCoupons: Coupon[] = [];

    const inactiveCoupons: Coupon[] = [];

    couponsFromQuery.forEach((coupon) => {
      if (isCouponActive(coupon)) {
        activeCoupons.push(coupon);
      } else {
        inactiveCoupons.push(coupon);
      }
    });

    setActiveCoupons(activeCoupons);

    setInactiveCoupons(inactiveCoupons);
  }, [couponsFromQuery]);

  useEffect(() => {
    getCouponsQuery();
  }, [getCouponsQuery]);

  return (
    <Box bgcolor="white" padding={1}>
      <Grid container={true}>
        <Grid item={true} xs={2}></Grid>
        <Grid item={true} xs={8}>
          <StyledTabs value={activeTab} onChange={_setActiveTab} centered>
            <Tab label="Active Coupons" id="0" />
            <Tab label="Inactive Coupons" id="1" />
          </StyledTabs>
        </Grid>
        <Grid item={true} xs={2}>
          <Box textAlign="center">
            <Tooltip title="Reload" placement="bottom">
              <IconButton aria-label="reload" className={classes.iconButton}>
                <RefreshIcon
                  fontSize="large"
                  fontWeight="bold"
                  onClick={() => {
                    getCouponsQuery();
                  }}
                />
              </IconButton>
            </Tooltip>

            <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
              Add Coupon
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box paddingBottom={2}>
        <Divider className={classes.divider} />
      </Box>

      {activeTab === 0 && <CouponList loading={loading} coupons={activeCoupons} setCoupon={setCoupon} />}

      {activeTab === 1 && <CouponList loading={loading} coupons={inactiveCoupons} setCoupon={setCoupon} />}

      <AddEditCoupon
        open={open}
        onClose={() => {
          setOpen(false);
          setCouponData(null);
        }}
        coupon={couponData}
      />
    </Box>
  );
};

export default Promotions;

import { gql } from 'apollo-boost';

export default gql`
  mutation($username: String!, $password: String!) {
    logInUser(username: $username, password: $password) {
      id
      sessionToken
      email
      username
      isOnline
    }
  }
`;

export const UPDATE_LOGGEDIN_USER_STATUS = gql`
  mutation updateUserLoggedInStatus($userDetails: String!) {
    updateUserLoggedInStatus(userDetails: $userDetails) @client
  }
`;

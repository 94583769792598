import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: (props: any) => ({
    backgroundColor: '#f3f3f3',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '35% !important',
    minWidth: theme.spacing(22.5),
    minHeight: theme.spacing(15),
    border: props.dragging ? 'dashed 2px black' : ''
  }),
  defaultImage: {
    transform: 'translateY(50%)',
    textAlign: 'center'
  },
  dragging: {
    border: 'dashed 2px black'
  },
  label: {
    width: '100%'
  },
  input: {
    display: 'none'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  cropperContainer: {
    margin: theme.spacing(1) + 'px auto',
    width: theme.spacing(60),
    height: theme.spacing(40),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(37.5),
      height: theme.spacing(25),
      margin: 'auto',
      padding: 0
    }
  },
  cropper: {
    width: '100%',
    height: '100%'
  },
  dialogContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important'
    }
  },
  btn: {
    display: 'block',
    margin: theme.spacing(2) + 'px auto',
    width: '80%'
  },
  closeButton: {
    textAlign: 'right',
    margin: theme.spacing(0.5),
    '& svg': {
      cursor: 'pointer'
    }
  }
}));

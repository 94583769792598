import { gql } from 'apollo-boost';
import { pizzaSize, pizzaCrusts, pizzaCheese, pizzaSauce, pizzaToppings, densityMultiplier } from 'graphql/fragments/pizza.fragments';

export const getPizzaPartsQuery = gql`
  query getPizzaParts($input: GetPizzaPartsInput!) {
    getPizzaParts(input: $input) {
      ...pizzaSize
      ...pizzaCrusts
      ...pizzaCheese
      ...pizzaSauce
      ...pizzaToppings
      ...densityMultiplier
    }
  }
  ${pizzaSize}
  ${pizzaCrusts}
  ${pizzaCheese}
  ${pizzaSauce}
  ${pizzaToppings}
  ${densityMultiplier}
`;

import { makeStyles, Theme } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    border: 'solid 1px ' + theme.palette.grey[300],
    borderCollapse: 'unset'
  },
  selectiveRow: {
    cursor: 'pointer'
  },
  selectedRow: {
    backgroundColor: blue[100]
  },
  densityContainerHeader: {
    borderBottom: 'solid 1px ' + theme.palette.grey[300]
  },
  densityContainer: {
    border: 'solid 1px ' + theme.palette.grey[300]
  },
  cell: {
    borderRight: 'solid 1px ' + theme.palette.grey[300]
  },
  btns: {
    margin: theme.spacing(0, 1)
  },
  editIcon: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  deleteIcon: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  successBtn: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  dragIcon: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    cursor: 'move',
    borderRadius: '4px',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid'
  },
  verticleAlignMiddle: {
    verticalAlign: 'middle'
  },
  verticleAlignTop: {
    verticalAlign: 'top'
  }
}));

import { createImageUploadUrlMutation } from 'graphql/mutations/image.mutation';
import { useMutationWithLoader } from 'hooks/loader';
import { useStore } from 'store';

export const useCreateImageUploader = () => {
  const [createImageUploadUrl, { data, loading, error }] = useMutationWithLoader(createImageUploadUrlMutation);

  const { dispatch } = useStore();

  const uploadImage = async (url: string, imageFiledata: string) => {
    try {
      dispatch({
        type: 'LOADER_INCREMENT_COUNT'
      });

      await fetch(url, {
        method: 'put',
        body: imageFiledata
      });

      dispatch({
        type: 'LOADER_DECREMENT_COUNT'
      });
    } catch (e) {
      dispatch({
        type: 'LOADER_DECREMENT_COUNT'
      });

      throw new Error(e);
    }
  };

  return {
    createImageUploadUrl,
    uploadImage,
    data,
    loading,
    error
  };
};

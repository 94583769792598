import { Box } from '@material-ui/core';
import { TextBroadcastHistory } from 'generated/custom';
import { useGetRestaurantActiveTextBroadcast } from 'graphql/hooks/textMarketing.hooks';
import React, { useState, useEffect } from 'react';
import { ITextBroadcastUI } from '../types';
import TextSchedulerForm from './TextSchedulerForm';

interface IProps {
  restaurantId: string | null;
  selectedBroadcast: TextBroadcastHistory | null;
  isBroadcastExist: boolean;
  setIsBroadcastExist: React.Dispatch<React.SetStateAction<boolean>>;
}

const smsHeading = '';
const TextScheduler: React.FC<IProps> = ({ restaurantId, selectedBroadcast, isBroadcastExist, setIsBroadcastExist }) => {
  const { getRestaurantActiveTextBroadcast } = useGetRestaurantActiveTextBroadcast(restaurantId);

  const [initialValues, setInitialValues] = useState<ITextBroadcastUI>({
    id: '',
    textHeading: smsHeading,
    textDescription: selectedBroadcast?.textBody || '',
    bizPhoneNumber: selectedBroadcast?.bizPhoneNumber || '',
    frequency: 'WEEKLY',
    dayOfWeek: selectedBroadcast?.dayOfWeek || 1,
    timeHour: selectedBroadcast?.timeHour || 0,
    timeMin: selectedBroadcast?.timeMin || 0,
    nextBroadcast: null,
    lastBroadcast: null
  });

  useEffect(() => {
    if (getRestaurantActiveTextBroadcast) {
      setIsBroadcastExist(true);
      const { id, bizPhoneNumber, frequency, nextBroadcast, dayOfWeek, timeHour, timeMin, textBody, lastBroadcast, status } = getRestaurantActiveTextBroadcast;
      setInitialValues({
        id: id,
        textDescription: textBody,
        bizPhoneNumber: bizPhoneNumber,
        frequency: frequency || 'WEEKLY',
        dayOfWeek: dayOfWeek - 1,
        timeHour: timeHour,
        timeMin: timeMin,
        status: status,
        nextBroadcast: nextBroadcast,
        lastBroadcast: lastBroadcast
      });
    } else {
      setIsBroadcastExist(false);
    }
  }, [getRestaurantActiveTextBroadcast, setIsBroadcastExist]);

  return (
    <Box display={'flex'} flexDirection="column" flex={1}>
      <Box marginBottom={2}>
        <TextSchedulerForm isBroadcastExist={isBroadcastExist} initialValues={initialValues} setIsBroadcastExist={setIsBroadcastExist} setInitialValues={setInitialValues} />
      </Box>
    </Box>
  );
};

export default TextScheduler;

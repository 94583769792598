import React, { useState, useImperativeHandle } from 'react';
import { Grid, Typography, Button, TextField, Box, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './style';
import * as _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ITable, ITableSortData } from '../types';
import { Dialog } from '@lokobee/lokobee-ui';
import { useFormik } from 'formik';
import validate from './validations';
import sortRows from './sort';
import TableCard from './TableCard';
import TableSort from './TableSort';
import StoreCard from './StoreCard';
import MenuCard from './MenuCard';

interface IProps {
  initialValues: ITable[];

  nextStep: () => void;
  addTableApi: (newRow: ITable) => Promise<any>;
  deleteTableApi: (tableName: string) => Promise<any>;
}

const TableSetup = React.forwardRef(({ nextStep, initialValues, addTableApi, deleteTableApi }: IProps, ref) => {
  // Defaut sort
  const [sortData, setSortData] = useState<ITableSortData>({
    orderBy: 'tableName',
    orderType: 'asc'
  });

  const formik = useFormik({
    initialValues: {
      tables: sortRows(initialValues, sortData)
    },
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });

  const handleFormSubmit = (formValues: any) => {
    nextStep();
  };

  const classes = useStyles();

  const pattern = new RegExp('^([A-Z][0-9][0-9]?|[0-9][0-9][0-9])$');

  const [showToolTip, setShowToolTip] = useState(false);

  const [newRow, setNewRow] = useState<ITable | null>(null);

  const [curRow, setCurRow] = useState<ITable | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Add Table

  const handleAddTableClick = () => {
    setNewRow({ tableName: '', seats: 1, cardURL: '' });
  };

  const addTable = async () => {
    const { tables: _tables } = formik.values;

    if (newRow && newRow.tableName.match(pattern) && newRow.seats > 0 && _.filter(_tables, (tb: ITable) => tb.tableName === newRow.tableName).length === 0) {
      const response = await addTableApi(newRow);
      if (!response) {
        console.log('Error in adding table');
      }
      setNewRow(null);
    }
  };

  // Delete Table

  const onDelete = (index: number, row: ITable) => {
    setCurRow(row);
    setOpenDeleteDialog(true);
  };

  const deleteTable = async () => {
    setOpenDeleteDialog(false);
    if (curRow) {
      const response = await deleteTableApi(curRow.tableName);
      if (!response) {
        console.log('Error in deleting table');
      }
      cancelDelete();
    }
  };

  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setCurRow(null);
  };

  useImperativeHandle(ref, () => {
    return {
      submitForm: () => {
        return formik.submitForm();
      }
    };
  });

  const { tables } = formik.values;

  const isDuplicate = newRow !== null && _.filter(tables, (tb: ITable) => tb.tableName === newRow.tableName).length > 0;
  const isTableNameError = newRow !== null && newRow.tableName !== '' && (!newRow.tableName.match(pattern) || isDuplicate);
  const errorMsg = isDuplicate ? 'Table No. Already Present' : 'Invalid Table No.';

  return (
    <Grid container={true} className={classes.tableContainer}>
      <Box padding={2} className={classes.tableHeader}>
        <Grid container={true}>
          <Grid item={true} xs={2} md={2}>
            <Typography variant="h6">
              Table
              <ClickAwayListener onClickAway={() => setShowToolTip(false)}>
                <Tooltip
                  placement="right"
                  PopperProps={{
                    disablePortal: true
                  }}
                  arrow={true}
                  onClose={() => setShowToolTip(false)}
                  open={showToolTip}
                  disableFocusListener={true}
                  disableHoverListener={true}
                  disableTouchListener={true}
                  title="Table No. should be either 3 numbers or 1 character followed by 1 or 2 Numbers. Eg. 123, A1, A12 ">
                  <InfoIcon fontSize="small" color="secondary" onClick={() => setShowToolTip(true)} />
                </Tooltip>
              </ClickAwayListener>
            </Typography>
          </Grid>
          <Grid item={true} xs={10} md={10}>
            <Box justifyContent="flex-end" display="flex" alignItems="center">
              {/* To be added in future */}
              {/* <Button className={classes.btns} size="small" variant="contained" color="primary" startIcon={<GetAppIcon />} onClick={downloadImages}>
                Download All Cards
              </Button> */}
              <Box marginRight={1}>
                <MenuCard />
              </Box>
              <Box marginRight={1}>
                <StoreCard />
              </Box>
              <Button className={classes.btns} size="small" variant="contained" color="primary" disableElevation={true} startIcon={<AddIcon />} onClick={handleAddTableClick}>
                Add Table
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableSort sortData={sortData} setSortData={setSortData} formik={formik} />
            <TableCell align="center" className={classes.equalWidth}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newRow && (
            <TableRow className={classes.verticleAlignTop}>
              <TableCell align="center" className={classes.equalWidth}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Table Number"
                  placeholder="eg. A1, A23, 234.."
                  value={newRow.tableName}
                  error={isTableNameError}
                  helperText={isTableNameError ? errorMsg : ''}
                  onChange={(e: any) => setNewRow({ ...newRow, tableName: e.target.value.toUpperCase() })}
                  inputProps={{
                    maxLength: 3,
                    autoCapitalize: 'none'
                  }}
                />
              </TableCell>
              <TableCell align="center" className={classes.equalWidth}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="Seats"
                  value={newRow.seats}
                  error={newRow.seats < 1}
                  onChange={(e: any) => {
                    setNewRow({ ...newRow, seats: e.target.value });
                  }}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </TableCell>
              <TableCell align="center" className={`${classes.equalWidth} ${classes.verticleAlignMiddle}`}>
                <Button className={classes.btns} size="small" variant="contained" color="secondary" startIcon={<AddIcon />} onClick={addTable}>
                  Add
                </Button>
                <Button className={classes.btns} size="small" variant="outlined" color="default" startIcon={<CloseIcon />} onClick={() => setNewRow(null)}>
                  Cancel
                </Button>
              </TableCell>
            </TableRow>
          )}
          {tables.length > 0 &&
            _.map(tables, (tb: ITable, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center" className={classes.equalWidth}>
                    {tb.tableName}
                  </TableCell>
                  <TableCell align="center" className={classes.equalWidth}>
                    {tb.seats}
                  </TableCell>
                  <TableCell align="center" className={classes.equalWidth}>
                    <Grid container={true}>
                      <TableCard table={tb} />

                      <DeleteIcon className={classes.deleteIcon} onClick={() => onDelete(index, tb)} />
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        {tables.length === 0 && (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <Typography variant="body1"> No Tables Added</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Dialog open={openDeleteDialog} setOpen={setOpenDeleteDialog}>
        <Dialog.DialogContent>
          <Typography variant="body1">Are you sure you want to delete this table ?</Typography>
        </Dialog.DialogContent>
        <Dialog.DialogActions saveBtnText="Yes" onSave={deleteTable} cancelBtnText="No" onCancel={cancelDelete} />
      </Dialog>
    </Grid>
  );
});

export default TableSetup;

import React, { useState, useEffect } from 'react';
import { useGetPizzaParts, useUpdatePizzaToppings } from 'graphql/hooks/pizza.hooks';
import { useStore } from 'store';
import { ISize, IDensityMultiplier, IPizzaToppingGroup } from '../types';
import { parseSizes, parseDensityMultipliers, getDefaulGroups, parseGroups } from '../utils';
import { Box, Typography } from '@material-ui/core';
import PizzaGroup from '../PizzaGroup';
import { filter, map, groupBy } from 'lodash';
import { useSnackbar } from 'notistack';

interface IProps {
  nextStep: () => void;
}

const defaultToppings = [
  {
    title: 'Veggie',
    items: [
      'Mushrooms',
      'Onions',
      'Green Onions',
      'Olives',
      'Paneer',
      'Green peppers',
      'Yellow peppers',
      'Red peppers',
      'Pineapple',
      'Spinach',
      'Pesto',
      'Artichoke hearts',
      'Tomatoes',
      'Basil',
      'Corn'
    ]
  },
  {
    title: 'Meat',
    items: ['Pepperoni', 'Sausage', 'Bacon', 'Chicken', 'Ham', 'Beef', 'Salami']
  }
];

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const data = useGetPizzaParts(restaurant);

  const { updatePizzaToppings } = useUpdatePizzaToppings();

  const [sizes, setSizes] = useState<ISize[] | null>(null);

  const [densityMultipliers, setDensityMultipliers] = useState<IDensityMultiplier[] | null>(null);

  const [toppings, setToppings] = useState<IPizzaToppingGroup[] | null>(null);

  const snackbar = useSnackbar();

  const [allowDelete, setAllowDelete] = useState(false);

  useEffect(() => {
    if (data) {
      const { sizes: pizzaSizes, densityMultipliers: pizzaDensityMultipliers, toppings: pizzaToppings } = data.getPizzaParts || {};

      if (pizzaSizes && pizzaSizes.length) {
        const parsedSizes = parseSizes(pizzaSizes);

        if (pizzaDensityMultipliers && pizzaDensityMultipliers.length) {
          const parsedMultipliers = filter(parseDensityMultipliers(pizzaDensityMultipliers), ({ densityAllowed }) => densityAllowed);

          if (pizzaToppings && pizzaToppings.length) {
            setSizes(parsedSizes);
            setDensityMultipliers(parsedMultipliers);
            setToppings(parseGroups(pizzaToppings, parsedSizes, parsedMultipliers));
          } else {
            setSizes(parsedSizes);
            setDensityMultipliers(parsedMultipliers);
            setToppings(getDefaulGroups(defaultToppings, parsedSizes, parsedMultipliers));
            setAllowDelete(true);
          }
        } else {
          setDensityMultipliers([]);
        }
      } else {
        setSizes([]);
      }
    }
  }, [data]);

  const updateAPI = async (updatedToppings: IPizzaToppingGroup[]) => {
    const input = {
      bizId: restaurant,
      toppings: map(updatedToppings, (topping) => {
        const { id, title, description, enableHalfHalf, items, groupPrices, densities } = topping;

        const allowedDensities = filter(densities, ({ densityAllowed }) => densityAllowed);

        const groupPricesConverted = map(groupPrices, (price) => {
          const { sizeId, regularPrice } = price;

          return {
            sizeId,
            densityPrices: map(allowedDensities, ({ density, multiplier }) => {
              return {
                density,
                price: {
                  currencyCode: 'usd',
                  strValue: parseFloat(regularPrice) ? (parseFloat(regularPrice) * multiplier).toFixed(2) : '0.00'
                }
              };
            })
          };
        });

        const commonPricesGroupedBySizeId = groupBy(groupPricesConverted, 'sizeId');

        console.log(sizes);

        console.log(commonPricesGroupedBySizeId);

        /*
         * We update all the changes in group prices then pass those prices
         * to each toppings as we also need to pass topping Ids
         * If there is new size added it will be there in group prices but not in topping prices
         * So we need to check that
         * We use size Ids to loop through the prices
         */

        return {
          id,
          title,
          description,
          enableHalfHalf,
          items: map(items, (item) => {
            const itemPricesGroupedBySizeId = groupBy(item.prices, 'sizeId');

            // If new item then price Ids will not be available so set groupPrices
            const itemPrices = item.prices.length
              ? map(sizes, (size) => {
                  if (size.id) {
                    const updatedPrice = commonPricesGroupedBySizeId[size.id][0];
                    const itemPriceForSelectedSize = itemPricesGroupedBySizeId[size.id];

                    return {
                      ...updatedPrice,
                      id: itemPriceForSelectedSize ? itemPriceForSelectedSize[0].id : undefined
                    };
                  }
                })
              : groupPricesConverted;

            return {
              ...item,
              prices: itemPrices
            };
          })
        };
      })
    };

    const response = await updatePizzaToppings({
      variables: {
        input
      }
    });

    if (response) {
      setAllowDelete(false);
      nextStep();
    } else {
      snackbar.enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };

  if (data) {
    if (sizes && sizes.length === 0) {
      return (
        <Box display="flex" justifyContent="center" padding={2}>
          <Typography variant="h4" color="error">
            Please configure pizza sizes.
          </Typography>
        </Box>
      );
    }

    if (densityMultipliers && densityMultipliers.length === 0) {
      return (
        <Box display="flex" justifyContent="center" padding={2}>
          <Typography variant="h4" color="error">
            Please configure Density Multipliers.
          </Typography>
        </Box>
      );
    }

    if (sizes && densityMultipliers && toppings) {
      const defaultPrices = map(sizes, (size) => {
        const { id: sizeId = '' } = size;
        return {
          sizeId,
          regularPrice: '0.00'
        };
      });

      const defaultDensities = densityMultipliers.map((multiplier) => ({ ...multiplier }));

      return (
        <PizzaGroup
          ref={ref}
          sizes={sizes}
          densityMultipliers={densityMultipliers}
          initialGroups={toppings}
          updateAPI={updateAPI}
          nextStep={nextStep}
          defaultDensities={defaultDensities}
          defaultPrices={defaultPrices}
          allowDelete={allowDelete}
        />
      );
    }
  }

  return null;
});

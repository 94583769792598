import { FormControl, FormHelperText, FormLabel, Grid, TextareaAutosize, TextField } from '@material-ui/core';
import { getIn } from 'formik';
import React from 'react';
import { useStyles } from './style';

const InfoInputField = ({ errorMsg, inputLabel, className, children, formik, required, isBigInput, customHandleBlur, label, rowsMin, ...props }: any) => {
  const classes = useStyles();

  const field: string = props.name;

  const isError = formik && !!getIn(formik.touched, field) && !!getIn(formik.errors, field);

  return (
    <Grid container={true} className={classes.inputContainer}>
      <Grid item={true} xs={12}>
        {children ? (
          children
        ) : isBigInput ? (
          <FormControl fullWidth className={className}>
            <FormLabel style={{ marginBottom: 8 }}>{label}</FormLabel>
            <TextareaAutosize
              style={{ widht: '100%', padding: '5px' }}
              placeholder={label}
              error={isError}
              className={'MuiInputBase-root'}
              rowsMin={rowsMin}
              helperText={isError ? getIn(formik.errors, field) : ''}
              value={formik.values[field] ? formik.values[field] : ''}
              onChange={formik.handleChange}
              onBlur={(e) => {
                if (customHandleBlur) {
                  customHandleBlur();
                }
                formik.handleBlur(e);
              }}
              {...props}
            />
            {isError && <FormHelperText>{getIn(formik.errors, field)}</FormHelperText>}
          </FormControl>
        ) : (
          <TextField
            className={classes.inputField}
            label={label}
            placeholder={label}
            error={isError}
            helperText={isError ? getIn(formik.errors, field) : ''}
            value={formik.values[field] ? formik.values[field] : ''}
            onChange={formik.handleChange}
            onBlur={(e) => {
              if (customHandleBlur) {
                customHandleBlur();
              }
              formik.handleBlur(e);
            }}
            {...props}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(InfoInputField);

import React, { useState, useEffect } from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { useStyles } from './style';

interface IProps {
  hour: number;
  minute: number;
  onChange: (value: string) => void;
  error?: boolean;
  readOnly?: boolean;
  className?: any;
}

const TimePicker = ({ hour, minute, onChange, error = false, readOnly, className }: IProps) => {
  const classes = useStyles();

  const [time, setTime] = useState<Date | string | null | undefined>(hour === -1 && minute === -1 ? null : new Date(0, 0, 0, hour, minute));

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (hour !== -1 && minute !== -1) {
      setTime(new Date(0, 0, 0, hour, minute));
    }
  }, [hour, minute]);

  return (
    <KeyboardTimePicker
      value={time}
      invalidDateMessage=""
      mask="--:-- -"
      readOnly={readOnly}
      disabled={readOnly}
      maskChar="-"
      inputVariant="outlined"
      InputAdornmentProps={
        className
          ? {
              style: { display: 'none' }
            }
          : undefined
      }
      error={(touched && hour === -1 && minute === -1) || error}
      className={classes.timeInput + ' ' + className}
      onChange={(date) => {
        setTouched(true);
        setTime(date ? date.toString() : '');

        if (!date || date.toString().toLowerCase() === 'invalid date') {
          onChange('');
        } else {
          onChange((date as any).getHours() + ':' + (date as any).getMinutes());
        }
      }}
    />
  );
};

export default TimePicker;

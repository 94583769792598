import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useGetDishQuery } from 'graphql/hooks/dish.hooks';
import { DISH_TYPES } from './RegularDish/types';
import RegularDishAddOrEditPage from './RegularDish';
import PizzaDishAddOrEditPage from './PizzaDish';

const DishAddOrEditPage = () => {
  const { dishId } = useParams() as any;

  const { state } = useLocation();

  const { dish, loading } = useGetDishQuery(dishId);

  const [dishType, setDishType] = useState<DISH_TYPES>(state && (state as any).dishType ? (state as any).dishType : 'REGULAR');

  useEffect(() => {
    if (dishId) {
      const { type } = dish || { type: 'REGULAR' };
      setDishType(type);
    }
  }, [dish, dishId]);

  if (!loading) {
    if (dishType === 'PIZZA') {
      return <PizzaDishAddOrEditPage dish={dish} />;
    } else {
      return <RegularDishAddOrEditPage dish={dish} dishType={dishType} />;
    }
  }
  return null;
};

export default DishAddOrEditPage;

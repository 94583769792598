import React, { useState } from 'react';
import { IPizzaToppingGroup, IPizzaToppingPrice, IDensityMultiplier } from '../../types';
import { Box, Button, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
import { map } from 'lodash';
import { useStyles } from '../style';
import Row from './Row';

interface IProps {
  groups: IPizzaToppingGroup[];
  selectedIndex: number;
  setSelected: (index: number) => void;
  setGroups: (groups: IPizzaToppingGroup[]) => void;
  defaultPrices: IPizzaToppingPrice[];
  defaultDensities: IDensityMultiplier[];
  allowDelete: boolean;
}

const GroupTable = ({ groups, setGroups, selectedIndex, setSelected, defaultPrices, defaultDensities, allowDelete }: IProps) => {
  const classes = useStyles();

  const newRow: IPizzaToppingGroup = {
    title: '',
    description: '',
    enableHalfHalf: true,
    items: [],
    densities: defaultDensities,
    groupPrices: defaultPrices
  };

  const [adding, setAdding] = useState(false);

  const onAdd = (newGroup: IPizzaToppingGroup) => {
    setGroups([...groups, newGroup]);
    setAdding(false);
  };
  const onEdit = (updatedGroup: IPizzaToppingGroup, index: number) => {
    const groupsCopy = [...groups];
    groupsCopy[index] = updatedGroup;
    setGroups(groupsCopy);
  };
  const onDelete = (index: number) => {
    const groupsCopy = [...groups];
    groupsCopy.splice(index, 1);
    setGroups(groupsCopy);
    if (index === selectedIndex) {
      setSelected(0);
    }
  };

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Group Name</TableCell>
          <TableCell>Half & Half supported</TableCell>
          <TableCell>
            <Button variant="contained" color="primary" disableElevation={true} size="small" onClick={() => setAdding(true)}>
              Add new group
            </Button>
          </TableCell>
        </TableRow>
      </TableHead>
      {groups.length === 0 ? (
        <TableBody>
          {adding && <Row allowDelete={allowDelete} key={''} rowData={newRow} rows={groups} action="new" onAdd={onAdd} selected={false} onClick={() => {}} onCancel={() => setAdding(false)} />}
          <TableRow>
            <TableCell colSpan={3}>No groups added</TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {adding && <Row allowDelete={allowDelete} key={''} rowData={newRow} rows={groups} action="new" onAdd={onAdd} selected={false} onClick={() => {}} onCancel={() => setAdding(false)} />}
          {map(groups, (group, index) => {
            return (
              <Row
                allowDelete={allowDelete}
                key={`${index}-${Date.now()}`}
                rowData={group}
                rows={groups}
                action="edit"
                selected={index === selectedIndex}
                onClick={() => setSelected(index)}
                onEdit={(updatedGroup: IPizzaToppingGroup) => onEdit(updatedGroup, index)}
                onDelete={() => onDelete(index)}
              />
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};

export default GroupTable;

import { Box, Divider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../style';
import Moment from 'moment';
import { map } from 'lodash';
import { convertPrice, convertPriceTo, convertToCCTokens } from 'util/number';
import Big from 'big.js';
import { calculatePizzaDishPrice, calculateServiceCharges } from '../utils';
import { useGetCCTokenValue } from 'graphql/hooks/coupons.hooks';

interface IProps {
  orderData: any;
}

const OrderDetails = ({ orderData }: IProps) => {
  const classes = useStyles();

  const { order, serviceCharges } = orderData;

  const { orderNumber, buyer, type, deliveryAddress, createdAt, expectTime, items, coupon, deliveryProvider, status, paymentType } = order;

  const { getCCTokenValue, data: ccTokenData } = useGetCCTokenValue();

  const [CCTokensEarned, setCCTokensEarned] = useState('');

  useEffect(() => {
    if (order.couponDiscount && status !== 'CANCELLED' && status !== 'REJECTED') {
      if (serviceCharges && serviceCharges.couponCost) {
        const cost = serviceCharges.couponCost;
        getCCTokenValue({
          variables: {
            input: {
              cents: cost.intValue,
              rewardType: 'BIZ_REWARD'
            }
          }
        });
      }
    }
  }, [getCCTokenValue, order.couponDiscount, serviceCharges, status]);

  useEffect(() => {
    if (ccTokenData && ccTokenData.getCCTokenValue && ccTokenData.getCCTokenValue.tokens && ccTokenData.getCCTokenValue.cryptoUnit) {
      setCCTokensEarned(`${convertToCCTokens(ccTokenData.getCCTokenValue.tokens, ccTokenData.getCCTokenValue.cryptoUnit)}`);
    }
  }, [ccTokenData]);

  const { displayName, phoneNumber } = buyer || {};

  const { text: addressText } = deliveryAddress || {};

  const couponCode = coupon ? coupon.code || '' : '';

  const calculatedCharges = calculateServiceCharges(order, serviceCharges);

  const { subtotal, couponDiscount, tax, bizCharge, stripeFee, tip, earnings, freeDelivery, couponCost } = calculatedCharges;

  const Row = ({ left, right }: any) => {
    return (
      <Box display={'flex'}>
        <Box flexGrow={1} paddingRight={1}>
          <Typography variant="body2" className={classes.boldText}>
            {left}
          </Typography>
        </Box>

        <Typography variant="body1" className={classes.boldText} noWrap={true} align="right">
          {right}
        </Typography>
      </Box>
    );
  };

  const types: any = {
    TAKEOUT: 'Takeout',
    DELIVERY: 'Delivery',
    DINING: 'Dining'
  };

  return (
    <Box minWidth={'500px'} height={'100%'} display={'flex'} flexDirection={'column'}>
      <Box padding={3} flexGrow={1}>
        <Box display={'flex'} justifyContent={'space-between'} paddingY={1}>
          <Box>
            <Typography variant="h6">#{orderNumber}</Typography>
            <Typography variant="subtitle1">{types[type]}</Typography>
          </Box>
          {(status === 'CANCELLED' || status === 'REJECTED') && <Typography variant="subtitle1" color="error">{`ORDER ${status}`}</Typography>}
        </Box>
        <Divider className={classes.divider} />
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h6">{displayName}</Typography>
          {paymentType === 'ONSITE' && <Typography variant="subtitle1">OFFLINE PAYMENT</Typography>}
          {paymentType === 'ONLINE_STRIPE' && (
            <Typography variant="subtitle1">
              Paid by <b>Stripe</b>
            </Typography>
          )}
        </Box>

        <Typography variant="subtitle1">{phoneNumber}</Typography>
        {type === 'DELIVERY' && <Typography variant="h6">Address</Typography>}

        {type === 'DELIVERY' && addressText && <Typography variant="subtitle1">{addressText}</Typography>}

        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
            <Typography variant="h6">Date</Typography>
            <Typography variant="subtitle1">{Moment(createdAt).format('MM/DD/yyyy')}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Time</Typography>
            <Typography variant="subtitle1">
              REC: {Moment(createdAt).format('HH:mm A')} | ETA: {Moment(expectTime).format('HH:mm A')}
            </Typography>
          </Box>
        </Box>

        <Divider className={classes.divider} />
        <Box paddingY={0.5}>
          <Typography variant="h6">Dish details</Typography>
        </Box>
        <Box>
          {map(items, (item: any, index: number) => {
            const dishType = item.dishType || 'REGULAR';

            if (dishType === 'REGULAR') {
              const { dishTitle, count, isReward, points, dishPrice, isDiscounted } = item;

              const {
                price: { intValue, shift },
                discountAmount
              } = dishPrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

              const [{ text: _title }] = dishTitle || [{ text: '' }];

              const [{ text: _dishSize }] = dishPrice ? dishPrice.size : [{ text: '' }];

              let finalPrice = Big(convertPrice(intValue, shift));
              if (!!discountAmount && isDiscounted) {
                finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
              }

              return <Row key={index} left={`${count}x ${_title} (${_dishSize})`} right={isReward ? `${count * points} PTS` : `$${finalPrice.mul(count).toFixed(2)}`} />;
            }

            if (dishType === 'PIZZA') {
              const { count, dishTitle, pizzaBasePrice, dishPrice } = calculatePizzaDishPrice(item);

              const [{ text: _title }] = dishTitle || [{ text: '' }];

              const { title: _dishSize } = pizzaBasePrice && pizzaBasePrice.size ? pizzaBasePrice.size : { title: '' };

              return (
                <Row
                  key={index}
                  left={`${count}x ${_title} (${_dishSize})`}
                  right={`$${Big(dishPrice)
                    .mul(count)
                    .toFixed(2)}`}
                />
              );
            }
          })}
        </Box>
        <Divider className={classes.divider} />

        <Box>
          {subtotal && <Row left="Subtotal" right={`$${subtotal}`} />}
          {status !== 'CANCELLED' && status !== 'REJECTED' && (
            <>
              {tax && <Row left="Tax" right={`$${tax}`} />}
              {type === 'DELIVERY' && deliveryProvider !== 'DOORDASH_CLASSIC' && tip && <Row left="Tip" right={`$${tip}`} />}
              {type !== 'DELIVERY' && tip && <Row left="Tip" right={`$${tip}`} />}
              {type === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC' && freeDelivery && freeDelivery !== '' && <Row left="Delivery discount" right={`- $${freeDelivery}`} />}
              {couponDiscount && couponDiscount !== '0.00' && <Row left={`Coupon Discount ${couponCode !== '' ? `( ${couponCode} )` : ''}`} right={`- $${couponDiscount}`} />}
              {bizCharge && bizCharge !== '0.00' && <Row left="Lokobee Service Fee" right={`- $${bizCharge}`} />}
              {couponCost && <Row left="CBT promotion fee" right={`- $${couponCost}`} />}
              {stripeFee && stripeFee !== '0.00' && <Row left="Stripe charges" right={`- $${stripeFee}`} />}
            </>
          )}
        </Box>
        {status !== 'CANCELLED' && status !== 'REJECTED' && (
          <>
            {earnings && <Divider className={classes.divider} />}
            <Box>{earnings && <Row left="Total in your Pocket" right={`$${earnings}`} />}</Box>
          </>
        )}
      </Box>

      {CCTokensEarned && (
        <Box className={classes.gradient} paddingX={2} paddingY={2}>
          <Row left="Cashback Token earned" right={`${CCTokensEarned}`} />
        </Box>
      )}
    </Box>
  );
};

export default OrderDetails;

import React, { useState } from 'react';
import { Dialog } from '@lokobee/lokobee-ui';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

interface IProps {
  className?: string;
  restaurantId: string;
  touched: boolean;
}

const CancelBtn = ({ className, restaurantId, touched }: IProps) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const onClick = () => {
    if (touched) {
      setOpen(true);
    } else {
      history.goBack();
    }
  };
  const onSave = () => {
    history.goBack();
  };
  return (
    <>
      <Button className={className ? className : ''} variant="outlined" color="default" onClick={onClick}>
        Cancel
      </Button>
      <Dialog open={open} setOpen={setOpen}>
        <Dialog.DialogContent>
          <Typography variant="body2">Are you sure you don't want to save the changes you made ?</Typography>
        </Dialog.DialogContent>
        <Dialog.DialogActions saveBtnText="Yes" cancelBtnText="No" onSave={onSave} onCancel={() => setOpen(false)} />
      </Dialog>
    </>
  );
};

export default CancelBtn;

import * as Yup from 'yup';

export default Yup.object().shape({
  bizHours: Yup.array()
    .of(
      Yup.object().shape({
        serviceType: Yup.string()
          .oneOf(['RESTAURANT_PICK_UP', 'RESTAURANT_DINING', 'RESTAURANT_DELIVERY'], 'Invalid service type value.')
          .required('Business Hours error'),
        serviceName: Yup.string().required('Business Hours error'),
        hours: Yup.array()
          .of(
            Yup.object().shape({
              openDay: Yup.number().required('Business Hours error'),
              closeDay: Yup.number().required('Business Hours error'),
              openHour: Yup.number().required('Business Hours error'),
              openMinute: Yup.number().required('Business Hours error'),
              closeHour: Yup.number().required('Business Hours error'),
              closeMinute: Yup.number().required('Business Hours error')
            })
          )
          .min(1, 'Business Hours error')
      })
    )
    .min(1, 'Business Hours error')
});

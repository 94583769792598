import { useState, useEffect } from 'react';
import { GET_RESTAURANT_BY_OWNER } from 'graphql/query';
import { useQueryWithLoader } from 'hooks/loader';
import { map } from 'lodash';
const useRestaurantList = () => {
  const [_data, setData] = useState<any>(undefined);

  const { loading, data, error } = useQueryWithLoader(GET_RESTAURANT_BY_OWNER);

  useEffect(() => {
    if (data && data.getRestaurantsByOwner) {
      const { edges } = data.getRestaurantsByOwner;

      setData(
        map(edges, (edge: any) => {
          return edge.node;
        })
      );
    }
  }, [data]);

  return {
    loading,
    data: _data,
    error
  };
};

export default useRestaurantList;

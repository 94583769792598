import * as Yup from 'yup';
import { DISH_TYPES_VALUES } from './types';
const regex = new RegExp(/^[0-9]+(\.[0-9][0-9]?)?$/);

export default Yup.object().shape({
  dishName: Yup.string().required('Please enter Dish name'),
  category: Yup.string().required('Please enter category'),
  visible: Yup.boolean().required('Please enter visible'),
  popular: Yup.boolean().required('Please enter popular'),
  pizzaDefaultCrustId: Yup.string().required('Please select default crust'),
  dishType: Yup.string()
    .required('Please enter Dish tyoe')
    .oneOf(DISH_TYPES_VALUES, 'Invalid dish type'),
  pizzaCrustIds: Yup.array()
    .of(Yup.string())
    .required('Crust is required.')
    .min(1),
  pizzaBasePrice: Yup.array()
    .of(
      Yup.object().shape({
        sizeId: Yup.string().required('Please enter size'),
        price: Yup.string()
          .required('Please enter price')
          .matches(regex, 'Invalid price value.')
      })
    )
    .min(1),
  pizzaSauce: Yup.array().of(
    Yup.object().shape({
      groupId: Yup.string().required('Please enter groupId'),
      items: Yup.array().of(
        Yup.object().shape({
          partId: Yup.string().required('Please enter partID'),
          preset: Yup.boolean().required('Please enter preset')
        })
      ),
      maxSelect: Yup.number().required('Please enter max select')
    })
  ),
  pizzaCheese: Yup.array().of(
    Yup.object().shape({
      groupId: Yup.string().required('Please enter groupId'),
      items: Yup.array().of(
        Yup.object().shape({
          partId: Yup.string().required('Please enter partID'),
          preset: Yup.boolean().required('Please enter preset')
        })
      ),
      maxSelect: Yup.number().required('Please enter max select')
    })
  ),
  pizzaToppings: Yup.array().of(
    Yup.object().shape({
      groupId: Yup.string().required('Please enter groupId'),
      items: Yup.array().of(
        Yup.object().shape({
          partId: Yup.string().required('Please enter partID'),
          preset: Yup.boolean().required('Please enter preset')
        })
      ),
      maxSelect: Yup.number().required('Please enter max select')
    })
  )
});

import React, { useState, useImperativeHandle } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from './style';
import { Typography, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as _ from 'lodash';
import IAddress from '../types';

const InputField = React.forwardRef(
  ({ defaultValue, mapSelection = true, validationError, errorMsg, onAddressSelect, onChange, onBlur, setMapAddress, setOpenAddressMapDialog, ...props }: any, ref) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [addressValue, setAddressValue] = useState('');

    const isError = validationError || false;

    const googleMapOption: IAddress = {
      text: 'Set In Google Map',
      country: '',
      city: '',
      state: '',
      zipcode: '',
      lat: 0,
      lng: 0,
      street: ''
    };

    const [options, setOptions] = useState<IAddress[]>(mapSelection ? [googleMapOption] : []);

    const fetch = React.useMemo(
      () =>
        _.throttle((input: string, callback: any) => {
          let promises: any = [];
          geocodeByAddress(input)
            .then((results: any) => {
              results.forEach((res: any) => {
                promises.push(
                  getLatLng(res).then((latlong: any) => {
                    return { ...res, ...latlong };
                  })
                );
              });

              Promise.all(promises)
                .then((res: any) => {
                  callback(res);
                })
                .catch((err: any) => {
                  callback({ error: err });
                });
            })
            .catch((err: any) => {
              callback({ error: err });
            });
        }, 3000),
      []
    );

    const onAddressChange = (val: any, selected: boolean) => {
      if (val === null || val === undefined || (typeof val === 'string' && val.trim() === '')) {
        onChange('');
        setAddressValue('');
        setOptions([]);
        return;
      }
      if (selected) {
        onSelect(val);
      } else {
        setLoading(true);
        onChange(val);
        setAddressValue(val);
        fetch(val, (results: any) => {
          if (!results.error) {
            const formattedResults = _.map(results, (res: any) => {
              const { address_components } = res;

              const { long_name: country } = _.filter(address_components, (obj: any) => obj.types[0] === 'country')[0] || {};
              const { long_name: city } = _.filter(address_components, (obj: any) => obj.types[0] === 'locality')[0] || {};
              const { long_name: state } = _.filter(address_components, (obj: any) => obj.types[0] === 'administrative_area_level_1')[0] || {};
              const { long_name: postalCode } = _.filter(address_components, (obj: any) => obj.types[0] === 'postal_code')[0] || {};

              const { long_name: street_number } = _.filter(address_components, (obj: any) => obj.types[0] === 'street_number')[0] || {};
              const { long_name: route } = _.filter(address_components, (obj: any) => obj.types[0] === 'route')[0] || {};

              return { text: res.formatted_address, lat: res.lat, lng: res.lng, country, city, state, zipcode: postalCode, street: street_number + ' ' + route };
            });

            if (mapSelection) {
              setOptions([...formattedResults, googleMapOption]);
            } else {
              setOptions(formattedResults);
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    };

    const onSelect = (val: IAddress) => {
      setAddressValue(val.text);
      onAddressSelect(val);
      setOptions(mapSelection ? [googleMapOption] : []);
      setLoading(false);
    };

    const openMapDialog = (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setMapAddress(options.length > 1 ? options[0] : null);
      setOpenAddressMapDialog(true);
    };

    useImperativeHandle(ref, () => {
      return {
        onSelect
      };
    });

    React.useEffect(() => {
      setAddressValue(defaultValue);
    }, [defaultValue]);

    return (
      <React.Fragment>
        <Autocomplete
          id="asynchronous-demo"
          options={options}
          loading={loading}
          inputValue={addressValue}
          onChange={(_e: any, value: IAddress | null) => onAddressChange(value, true)}
          filterOptions={(x) => x}
          getOptionLabel={(option) => option.text}
          selectOnFocus={false}
          renderOption={(option) => {
            if (option.text === googleMapOption.text) {
              return (
                <Typography variant="caption" onClick={openMapDialog} className={classes.googleMapOption}>
                  {googleMapOption.text}
                </Typography>
              );
            } else {
              return <Typography variant="caption">{option.text}</Typography>;
            }
          }}
          renderInput={(params) => (
            <TextField
              error={isError}
              {...params}
              fullWidth={true}
              value={addressValue}
              helperText={isError ? errorMsg : ''}
              onChange={(e) => onAddressChange(e.target.value, false)}
              onBlur={onBlur}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
              {...props}
            />
          )}
        />
      </React.Fragment>
    );
  }
);

export default InputField;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(1)
  },
  gridContainer: {
    marginTop: theme.spacing(2),
    maxWidth: "85%",
    
    paddingBottom:theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}));

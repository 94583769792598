export const restaurantInitialState = {
  restaurant: null
};

export const restaurantActions = {
  SET_RESTAURANT: (state: any, payload: any) => ({ ...state, restaurant: payload }),
  RESET_RESTAURANT: (state: any) => ({ ...state, restaurant: null })
};

export default {};

import gql from 'graphql-tag';

export const Dish = gql`
  fragment Dish on Dish {
    id
    title {
      text
    }
    category {
      id
      title {
        text
      }
    }
    status
    isReward
    rewardPoints
    isDiscounted
    description {
      text
    }
    type
    pizzaBasePrice {
      id

      price {
        intValue
        shift
      }
    }
    price {
      id
      size {
        text
      }
      isDefault
      price {
        currencyCode
        intValue
        shift
      }
    }
    images {
      id
      md {
        url
      }
    }
  }
`;

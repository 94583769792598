import { convertPrice, convertPriceTo } from 'util/number';
import { map } from 'lodash';
import { IDeliverySetting } from './types';

export const setInitialValues = (paymentSettings: any) => {
  const { onlinePaymentTypes, onsitePaymentTypes, tipPercentOptions, tipDefaultPercent } = paymentSettings;

  let tipDefaultIndex = 2;
  if (tipPercentOptions) {
    tipDefaultIndex = tipPercentOptions.indexOf(tipDefaultPercent) < 0 ? 2 : tipPercentOptions.indexOf(tipDefaultPercent);
  }

  return {
    onlinePaymentTypes: onlinePaymentTypes ? onlinePaymentTypes : [],
    onsitePaymentTypes: onsitePaymentTypes ? onsitePaymentTypes : [],
    tipPercentOptions: tipPercentOptions ? tipPercentOptions : [0, 10, 15, 20],
    tipDefaultPercentIndex: tipDefaultIndex
  };
};

export const setInitialDeliverySettings = (deliverySetting: IDeliverySetting) => {
  const { waitMinutes, minDeliveryAmount, freeDeliveryAmount, fees } = deliverySetting;

  return {
    waitMinutes: waitMinutes,
    freeDeliveryAmount: freeDeliveryAmount ? convertPriceTo(freeDeliveryAmount, 'DOLLAR') : '100.00',
    minDeliveryAmount: minDeliveryAmount ? convertPriceTo(minDeliveryAmount, 'DOLLAR') : '25.00',

    fees: fees
      ? map(fees, ({ mile, fee }) => {
          return {
            mile,
            fee: convertPriceTo(fee, 'DOLLAR')
          };
        })
      : [
          {
            mile: 3,
            fee: '4.50'
          },
          {
            mile: 5,
            fee: '4.50'
          },
          {
            mile: 6,
            fee: '4.50'
          },
          {
            mile: 7,
            fee: '4.50'
          }
        ]
  };
};

import React, { useState, useEffect } from 'react';
import EmployeeManagement from './EmployeeManagement';
import { useGetEmployees, useCreateEmployeeMutation, useUpdateEmployeeMutation, useDeleteEmployeeMutation } from 'graphql/hooks/employee.hooks';
import { useStore } from 'store';
import { IEmployee } from './types';

export default () => {
  const {
    state: { restaurant }
  } = useStore();

  const { data } = useGetEmployees(restaurant);

  const { createEmployee: createEmployeeApi } = useCreateEmployeeMutation();

  const { updateEmployee: updateEmployeeApi } = useUpdateEmployeeMutation();

  const { deleteEmployee: deleteEmployeeApi } = useDeleteEmployeeMutation();

  const [initialValues, setinitialValues] = useState([]);

  useEffect(() => {
    if (data) {
      setinitialValues(data.getBizEmployees ? data.getBizEmployees : []);
    }
  }, [data]);

  const createEmployee = async (newEmployee: IEmployee) => {
    return await createEmployeeApi({
      variables: {
        input: {
          userName: newEmployee.userName.replace(/\s/g, ''),
          displayName: newEmployee.displayName,
          password: newEmployee.password,
          bizId: restaurant
        }
      }
    });
  };

  const updateEmployee = async (editedEmployee: IEmployee) => {
    return await updateEmployeeApi({
      variables: {
        input: {
          userName: editedEmployee.userName.replace(/\s/g, ''),
          displayName: editedEmployee.displayName,
          uid: editedEmployee.uid,
          password: editedEmployee.password,
          bizId: restaurant
        }
      }
    });
  };

  const deleteEmployee = async (uid: string) => {
    return await deleteEmployeeApi(uid, {
      variables: {
        input: {
          uid,
          bizId: restaurant
        }
      }
    });
  };

  if (data) {
    return <EmployeeManagement initialEmployees={initialValues} createEmployee={createEmployee} updateEmployee={updateEmployee} deleteEmployee={deleteEmployee} />;
  }

  return null;
};

import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { useActiveRestaurant } from 'hooks/restaurant';
import { Box } from '@material-ui/core';
import DeleteCoupon from '../DeleteCoupon';
import { useStyle } from './style';
import { Coupon } from 'generated/custom';
import Moment from 'moment';

interface IProps {
  loading: boolean;
  coupons: Coupon[];
  setCoupon: (data: any) => void;
}

const CouponList: React.FC<IProps> = ({ loading, coupons, setCoupon }) => {
  const classes = useStyle();

  const { restaurantId } = useActiveRestaurant();

  const [couponList, setCouponList] = React.useState<Coupon[]>([]);

  React.useEffect(() => {
    setCouponList(coupons);
  }, [coupons]);

  const handleOnClick = (coupon: any) => {
    setCoupon(coupon);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="coupon data table">
          <TableHead>
            <TableRow>
              <TableCell>Coupon Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Max # of Coupons</TableCell>
              <TableCell>Used</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {couponList.length > 0 &&
              couponList.map((coupon) => {
                const { code, description, startDate, endDate, maxUse, useCnt } = coupon;
                return (
                  <TableRow hover tabIndex={-1} key={code} className={classes.tableRow}>
                    <TableCell onClick={() => handleOnClick(coupon)}>{code}</TableCell>
                    <TableCell onClick={() => handleOnClick(coupon)}>{description}</TableCell>
                    <TableCell onClick={() => handleOnClick(coupon)}>{maxUse ? maxUse : ''}</TableCell>
                    <TableCell onClick={() => handleOnClick(coupon)}>{useCnt ? useCnt : ''}</TableCell>
                    <TableCell onClick={() => handleOnClick(coupon)}>{Moment(startDate).format('MM/DD/yyyy')}</TableCell>
                    <TableCell onClick={() => handleOnClick(coupon)}>{Moment(endDate).format('MM/DD/yyyy')}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-evenly">
                        {!!restaurantId && !!code && <DeleteCoupon bizId={restaurantId} code={code} />}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            {!loading && couponList.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Typography variant="body1">No Coupons Found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CouponList;

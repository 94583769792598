import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_GROCERY_STORE_BY_ID, GET_RESTAURANT_BY_ID } from 'graphql/query';
import { setLocalStorage } from 'util/storage';

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const { currentUser } = useContext(AuthContext);

  return <Route {...rest} render={(routeProps) => (!!currentUser ? <RouteComponent {...routeProps} /> : <Redirect to={'/'} />)} />;
};

export const RestaurantAuthContext = React.createContext({ restaurantId: null });
export const GroceryStoreAuthContext = React.createContext({ groceryStoreId: null });

const ParantAuth: React.FC = ({ children }) => {
  const { restaurantId } = useParams();

  const [getRestaurant, { loading, data, called }] = useLazyQuery(GET_RESTAURANT_BY_ID);

  const [restId, setResId] = useState(null);

  useEffect(() => {
    if (restId && restId === restaurantId) {
      return;
    } else {
      getRestaurant({
        variables: {
          input: {
            id: restaurantId
          }
        }
      });
    }
  }, [children, getRestaurant, restId, restaurantId]);

  useEffect(() => {
    if (called && data && data.ownerGetRestaurantById) {
      const { id } = data.ownerGetRestaurantById;
      setResId(id);
      setLocalStorage('groceryStoreId', '');
      setLocalStorage('restaurantId', id);
    }
  }, [called, data]);

  if (called && loading) {
    return null;
  } else {
    return <RestaurantAuthContext.Provider value={{ restaurantId: restId }}>{children}</RestaurantAuthContext.Provider>;
  }
};

export const PrivateRestaurantDashboard = ({ component: RouteComponent, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <ParantAuth>
          <RouteComponent {...routeProps} />
        </ParantAuth>
      )}
    />
  );
};

const ParantGroceryAuth: React.FC = ({ children }) => {
  const { groceryStoreId } = useParams();

  const [getGroceryStore, { loading, data, called }] = useLazyQuery(GET_GROCERY_STORE_BY_ID);

  const [storeId, setStoreId] = useState(null);

  useEffect(() => {
    if (storeId && storeId === groceryStoreId) {
      return;
    } else {
      getGroceryStore({
        variables: {
          input: {
            storeId: groceryStoreId
          }
        }
      });
    }
  }, [children, getGroceryStore, storeId, groceryStoreId]);

  useEffect(() => {
    if (called && data && data.ownerGetGroceryStoreById) {
      const { id } = data.ownerGetGroceryStoreById;
      setStoreId(id);
      setLocalStorage('restaurantId', '');
      setLocalStorage('groceryStoreId', id);
    }
  }, [called, data]);

  if (called && loading) {
    return null;
  } else {
    return <GroceryStoreAuthContext.Provider value={{ groceryStoreId: storeId }}>{children}</GroceryStoreAuthContext.Provider>;
  }
};

export const PrivateGroceryStoreDashboard = ({ component: RouteComponent, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <ParantGroceryAuth>
          <RouteComponent {...routeProps} />
        </ParantGroceryAuth>
      )}
    />
  );
};

export default PrivateRoute;

import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import menu_empty_screen from 'assets/img/menu_empty_screen.png';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStyles } from './style';

interface IProps {
  onAddDish: () => void;
  onAddPizzaDish: () => void;
}

const MenuEmptyScreen = ({ onAddDish, onAddPizzaDish }: IProps) => {
  const classes = useStyles();

  return (
    <>
      <Box padding={2}>
        <Typography variant="h5">Menu</Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.emptyScreenContainer} display="flex" flexDirection="column" alignItems="center">
        <img src={menu_empty_screen} className={classes.emptyScreenImage} alt="" />
        <Box display="flex" alignItems="center" padding={1}>
          <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
            <AddCircleIcon color="disabled" className={classes.addIcon} onClick={onAddDish} />
            <Typography variant="h6">Add New Dish</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
            <AddCircleIcon color="disabled" className={classes.addIcon} onClick={onAddPizzaDish} />
            <Typography variant="h6">Add New pizza Dish</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MenuEmptyScreen;

import React, { useState } from 'react';
import { ISize, ICrust } from '../../types';
import { Box, TextField, TableCell, TableRow, Typography, Checkbox } from '@material-ui/core';
import { map, filter } from 'lodash';
import { useStyles } from '../style';
import { useFormik, getIn } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import validate from './validations';
import { ConfirmDialog } from '@lokobee/lokobee-ui';
import { DragHandleWrapper } from 'components/DragDrop';
import { CurrencyInputField } from '@lokobee/lokobee-ui';

interface IProps {
  action: 'edit' | 'new';
  rows: ICrust[];
  sizes: ISize[];
  rowData: ICrust;
  onAdd?: (newSize: ICrust) => void;
  onEdit?: (updatedSize: ICrust) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  allowDelete: boolean;
}

const Row = ({ action, sizes, rowData, onAdd, onEdit, onDelete, onCancel, rows, allowDelete }: IProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);

  const formik = useFormik({
    initialValues: { ...rowData },
    onSubmit: (formValues) => handleFormSubmit(formValues),
    validate: (values: ICrust) => {
      const errors: any = {};

      const crustPresent = filter(rows, ({ title }) => title.toLowerCase().replaceAll(' ', '') === values.title.toLowerCase().replaceAll(' ', ''));

      if (action === 'new' && crustPresent.length) {
        errors.title = 'Duplicate crust';
      }

      return errors;
    }
  });

  const handleFormSubmit = (formValues: ICrust) => {
    if (action === 'new') {
      if (onAdd) {
        onAdd(formValues);
      }
    }
    if (action === 'edit') {
      if (onEdit) {
        setEditing(false);
        onEdit(formValues);
      }
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    setEditing(false);
    if (onCancel) {
      onCancel();
    }
  };

  const onCheckChange = (id: string, checkAction: 'remove' | 'add') => {
    const { values } = formik;
    if (checkAction === 'add') {
      formik.setValues({
        ...values,
        prices: [
          ...prices,
          {
            sizeId: id,
            price: '0.00'
          }
        ]
      });
    }
    if (checkAction === 'remove') {
      formik.setValues({
        ...values,
        prices: filter(values.prices, ({ sizeId }) => sizeId !== id)
      });
    }
  };

  const { handleChange, handleBlur, errors, touched } = formik;

  const { title, prices } = formik.values;

  const editForm = (
    <TableRow>
      <TableCell align="center" className={classes.cell}>
        <TextField
          name="title"
          variant="outlined"
          fullWidth={true}
          value={title}
          placeholder="Size"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!getIn(touched, 'title') && !!getIn(errors, 'title')}
          helperText={!!getIn(touched, 'title') && !!getIn(errors, 'title') ? errors.title : ''}
        />
      </TableCell>
      {map(sizes, ({ id }, index) => {
        let priceIndex = null;
        const price = filter(prices, ({ sizeId }, index) => {
          if (sizeId === id) {
            priceIndex = index;
            return true;
          } else {
            return false;
          }
        });

        if (price.length) {
          return (
            <TableCell key={index} align="center">
              <CurrencyInputField
                name={`prices[${priceIndex}].price`}
                variant="outlined"
                fullWidth={true}
                value={price[0].price}
                placeholder="price"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!getIn(touched, `prices[${priceIndex}].price`) && !!getIn(errors, `prices[${priceIndex}].price`)}
                InputProps={{
                  className: classes.currencyInputField,
                  endAdornment: <Checkbox checked={true} color="primary" onChange={() => onCheckChange(id ? id : '', 'remove')} />
                }}
              />
            </TableCell>
          );
        } else {
          return (
            <TableCell key={index} align="center">
              <TextField
                variant="outlined"
                fullWidth={true}
                value="NA"
                placeholder="price"
                InputProps={{
                  readOnly: true,
                  endAdornment: <Checkbox checked={false} color="primary" onChange={() => onCheckChange(id ? id : '', 'add')} />
                }}
              />
            </TableCell>
          );
        }
      })}
      <TableCell className={classes.verticleAlignMiddle} align="center">
        <CheckIcon fontSize="small" className={classes.successBtn} onClick={formik.submitForm} />
        <ClearIcon fontSize="small" className={classes.deleteIcon} onClick={handleCancel} />
      </TableCell>
    </TableRow>
  );

  if (action === 'new' || editing) {
    return editForm;
  } else {
    return (
      <TableRow>
        <TableCell className={classes.cell} style={{ width: '10%' }}>
          {title}
        </TableCell>
        {map(sizes, ({ id }, index) => {
          const price = filter(prices, ({ sizeId }) => sizeId === id);

          if (price.length) {
            return (
              <TableCell key={index} align="center" style={{ width: `${70 / sizes.length}%` }}>
                <Typography variant="body2">{price[0].price}</Typography>
              </TableCell>
            );
          } else {
            return (
              <TableCell key={index} align="center" style={{ width: `${70 / sizes.length}%` }}>
                <Typography variant="body2">NA</Typography>
              </TableCell>
            );
          }
        })}
        <TableCell align="center" style={{ width: '20%' }}>
          <EditIcon fontSize="small" className={classes.editIcon} onClick={() => setEditing(true)} />
          {allowDelete && (
            <ConfirmDialog
              onYes={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
              message="Are you sure want to close this row?">
              <DeleteIcon fontSize="small" className={classes.deleteIcon} />
            </ConfirmDialog>
          )}
          <DragHandleWrapper>
            <DragHandleIcon fontSize="small" className={classes.dragIcon} />
          </DragHandleWrapper>
        </TableCell>
      </TableRow>
    );
  }
};

export default Row;

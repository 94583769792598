export interface ISortableData {
  id: string;
  title: string;
}

export interface Coupon {
  bizId: string;
  code: string;
  name: string;
  description: string;
  discountType: string;
  discountValue: string;
  maxDiscount?: number;
  startDate: string;
  endDate: string;
  subtotalThreshold: number;
  taxMethod: 'PRE_DISCOUNT' | 'POST_DISCOUNT';
  maxUse?: number;
  useCnt?: number;
  isPrivate?: boolean;
  orderType: string[];
  dayOfWeek: number[];
}
export enum CryptoUnit {
  Cc = 'CC',
  Gwei = 'GWEI',
  Qwei = 'QWEI',
  Twei = 'TWEI',
  Wei = 'WEI'
}

export type CcTokenOutput = {
  __typename?: 'CCTokenOutput';
  tokens: number;
  cryptoUnit: CryptoUnit;
};

export enum BroadcastStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  SCHEDULED = 'SCHEDULED'
}

export type TextBroadcastHistory = {
  bizId: string;
  id: string;
  bizPhoneNumber: string;
  dayOfWeek: number;
  timeHour: number;
  timeMin: number;
  textBody: string;
  lastBroadcast: string;
  createdAt: string;
  nextBroadcast: string;
  status: BroadcastStatus;
};

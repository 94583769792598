import React from 'react';
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import DashboardRoutes from './routes';
import Header from 'components/Header';
import Footer from 'components/Footer';
import useDownloadImage from 'hooks/useDownloadImage';

const Dashboard = () => {
  useDownloadImage();
  return (
    <>
      <Header />

      <DashboardRoutes />

      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default Dashboard;

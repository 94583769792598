import { gql } from 'apollo-boost';
import { CryptoCoupon } from 'graphql/fragments/cryptoCoupon.fragments';

export default gql`
  query getCryptoCouponHistory($input: GetCryptoCouponHistoryInput!) {
    getCryptoCouponHistory(input: $input) {
      coupons {
        ...CryptoCoupon
      }
      pageCursor
    }
  }
  ${CryptoCoupon}
`;

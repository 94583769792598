import { gql } from 'apollo-boost';

export default gql`
  query getBizReviews($input: GetBizReviewsInput!) {
    getBizReviews(input: $input) {
      edges {
        node {
          order {
            orderPhoneNumber
          }
          buyer {
            displayName
            phoneNumber
            email
          }
          createdAt
          rateScore
          rateText
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useGetWebsiteBlogs } from 'graphql/hooks/websiteAutomation.hooks';
import { Articles } from 'generated/types';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import DeleteBlogArticle from '../DeleteBlogArticle';
import S3Image from 'components/S3Image';
import blogDefault from 'assets/img/blog-default.svg';
import { useStyles } from 'pages/WebsiteAutomation/WebAutomationForm/styles';
const BlogsList: React.FC = () => {
  const { restaurantId } = useActiveRestaurant();
  const classes = useStyles();
  const history = useHistory();
  const data = useGetWebsiteBlogs(restaurantId);

  const [openDelete, setOpenDelete] = useState(false);
  const [blogsData, setBlogsData] = useState<Articles[]>([]);
  const [itemToDelete, setItemToDelete] = useState<Articles | null>(null);
  useEffect(() => {
    if (data && data.getAllBlogsByBizId) {
      setBlogsData(data.getAllBlogsByBizId);
    }
  }, [data, setBlogsData]);
  const openArticle = (blogId: string | null | undefined) => {
    if (blogId) {
      history.push(`/home/${restaurantId}/blogs/edit/${blogId}`);
    }
  };
  const startNewArticle = () => {
    history.push(`/home/${restaurantId}/blogs/add-new-article`);
  };
  return (
    <>
      <Box>
        <Box display={'flex'} justifyContent="space-between" width={'100%'} marginY={3}>
          <Typography variant="h4">Blogs</Typography>
          <Button variant="contained" color="primary" onClick={startNewArticle}>
            {' '}
            Add a New Article!{' '}
          </Button>
        </Box>
        <Box paddingY={2}>
          <Grid container={true} spacing={2}>
            {blogsData &&
              blogsData.map((article, index) => (
                <Grid key={article?.id || index} item={true} xs={12} sm={6} md={4}>
                  <Box borderRadius={5} boxShadow="rgb(185 185 185) 0px 0px 3px 0px" overflow={'hidden'} height="100%">
                    <Box>{article.picture && article.picture.id && article.picture.url && <S3Image src={article.picture.url} defaultImage={blogDefault} className={classes.fullImageWidth} />}</Box>
                    <Box padding={2}>
                      <Box marginBottom={1}>
                        <Typography gutterBottom variant="h6" className="line-clamp-2">
                          {article.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className="line-clamp-3">
                          {article.text}
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          style={{ marginRight: 8 }}
                          type="button"
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            setItemToDelete(article);
                            setOpenDelete(true);
                          }}>
                          Remove
                        </Button>
                        <Button type="button" color="primary" variant="contained" onClick={() => openArticle(article.id)}>
                          Edit
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
      {itemToDelete && (
        <DeleteBlogArticle
          bizId={restaurantId || ''}
          setItemToDelete={setItemToDelete}
          blogId={itemToDelete.id || ''}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          version={itemToDelete.version}
        />
      )}
    </>
  );
};

export default BlogsList;

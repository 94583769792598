import { gql } from 'apollo-boost';

export default gql`
  mutation createWebsiteBlog($input: CreateWebsiteBlogInput!) {
    createWebsiteBlog(input: $input) {
      id
      bizId
      version
      createdAt
      updatedAt
      title
      text
      picture {
        id
        url
      }
      headline1
      headline2
      headline1Image {
        url
      }
      headline2Image {
        url
      }
    }
  }
`;

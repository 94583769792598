import React, { useState, useEffect } from 'react';
import { ISize, ICrust } from '../types';
import { Box, Typography } from '@material-ui/core';
import { map } from 'lodash';
import Crust from './Crust';
import { useUpdatePizzaCrusts, useGetPizzaParts } from 'graphql/hooks/pizza.hooks';
import { useStore } from 'store';
import { getDefaultCrusts, parseCrusts } from '../utils';
import { useSnackbar } from 'notistack';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const data = useGetPizzaParts(restaurant);

  const [sizes, setSizes] = useState<ISize[] | null>(null);

  const [crusts, setCrusts] = useState<ICrust[]>([]);

  const { updatePizzaCrust } = useUpdatePizzaCrusts();

  const snackbar = useSnackbar();

  const [allowDelete, setAllowDelete] = useState(false);

  useEffect(() => {
    if (data) {
      const { sizes: pizzaSizes, crusts } = data.getPizzaParts || {};

      if (pizzaSizes && pizzaSizes.length) {
        setSizes(
          map(pizzaSizes, ({ id, title, description, enableHalfHalf }) => {
            return { id, title, description, enableHalfHalf };
          })
        );

        if (crusts && crusts.length) {
          const parsedData: ICrust[] = parseCrusts(crusts);
          setCrusts(parsedData);
        } else {
          const defaultCrusts: ICrust[] = getDefaultCrusts(pizzaSizes);
          setCrusts(defaultCrusts);
          setAllowDelete(true);
        }
      } else {
        setSizes([]);
      }
    }
  }, [data]);

  const updateAPI = async (updatedCrusts: ICrust[]) => {
    const response = await updatePizzaCrust({
      variables: {
        input: {
          bizId: restaurant,
          crusts: map(updatedCrusts, ({ id, title, description, prices }: ICrust) => {
            return {
              id,
              title,
              description,
              prices: map(prices, ({ sizeId, price }) => {
                return {
                  sizeId,
                  price: {
                    currencyCode: 'usd',
                    strValue: price
                  }
                };
              })
            };
          })
        }
      }
    });

    if (response) {
      setAllowDelete(false);
      nextStep();
    } else {
      snackbar.enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };

  if (data) {
    if (sizes && sizes.length === 0) {
      return (
        <Box display="flex" justifyContent="center" padding={2}>
          <Typography variant="h4" color="error">
            Please configure pizza sizes.
          </Typography>
        </Box>
      );
    } else {
      if (sizes) {
        return <Crust ref={ref} sizes={sizes} initialCrusts={crusts} allowDelete={allowDelete} updateAPI={updateAPI} />;
      }
    }
  }

  return null;
});

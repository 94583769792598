import gql from 'graphql-tag';
import { BizCard } from 'graphql/fragments/cards.fregments';
import { Profile, BusinessHours, Table, Announcement, RewardSetting, Gallery, PaymentSettings, Status } from 'graphql/fragments/restaurant.fragments';

export const createRestaurant = gql`
  mutation createRestaurant($input: CreateRestaurantInput!) {
    createRestaurant(input: $input) {
      id
    }
  }
`;

export const deleteRestaurant = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
    }
  }
`;

export const createTable = gql`
  mutation createTable($input: CreateTableInput!) {
    createTable(input: $input) {
      tableName
      seats
      cardImg {
        id
        url
      }
    }
  }
`;

export const deleteTable = gql`
  mutation deleteTable($input: DeleteTableInput!) {
    deleteTable(input: $input)
  }
`;

export const updateRestaurantProfile = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...Profile
    }
  }
  ${Profile}
`;

export const updateRestaurantBusinessHours = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...BusinessHours
    }
  }
  ${BusinessHours}
`;

export const updateRestaurantTable = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...Table
    }
  }
  ${Table}
`;

export const updateRestaurantGallery = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...Gallery
    }
  }
  ${Gallery}
`;

export const updateRestaurantAnnouncement = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...Announcement
    }
  }
  ${Announcement}
`;

export const updateRestaurantRewardSetting = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...RewardSetting
    }
  }
  ${RewardSetting}
`;

export const updateRestaurantPaymentSettings = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...PaymentSettings
      ...BusinessHours
    }
  }
  ${PaymentSettings}
  ${BusinessHours}
`;

export const updateRestaurantStatus = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...Status
    }
  }
  ${Status}
`;

export const activateRestaurant = gql`
  mutation activateRestaurant($input: ActivateRestaurantInput!) {
    activateRestaurant(input: $input)
  }
`;

export const updateBizCardBackSide = gql`
  mutation updateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      id
      ...BizCard
    }
  }
  ${BizCard}
`;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  sorting: {
    zIndex: theme.zIndex.modal + 100,
  },
  listItem: {
    whiteSpace: 'nowrap',
    cursor: 'default'
  },
  dialogContent: {
    padding: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  },
  dragActions: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.grey[300],
    cursor: 'pointer'
  }
}));

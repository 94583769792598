import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 50
  },
  gridContainer: {
    paddingBottom: theme.spacing(2)
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  checkBox: {
    '& .MuiCheckbox-colorPrimary.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.primary.main
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#000'
    }
  },
  center: {
    display: 'flex'
  }
}));

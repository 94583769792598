import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  formControls: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: '2px'
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      height: '30px',
      width: '30px'
    }
  },
  table: {
    '& th,td': {
      padding: theme.spacing(1, 2)
    }
  },
  tableCheckBox: {
    padding: 0
  }
}));

import { Button, Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { BroadcastStatus, TextBroadcastHistory } from 'generated/custom';
import { useGetTextBroadcastHistory } from 'graphql/hooks/textMarketing.hooks';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useStyle } from '../style';

interface IProps {
  restaurantId: string | null;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setSelectedBroadcast: React.Dispatch<React.SetStateAction<TextBroadcastHistory | null>>;
  isBroadcastExist: boolean;
}
const BroadcastHistory: React.FC<IProps> = ({ restaurantId, setActiveTab, setSelectedBroadcast, isBroadcastExist }) => {
  const classes = useStyle();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednsday', 'Thursday', 'Friday', 'Saturday'];
  const { textBroadcasts, fetchBroadcastHistory, fetchMoreData, fetching, hasMore } = useGetTextBroadcastHistory(restaurantId);
  useEffect(() => {
    fetchBroadcastHistory();
  }, [fetchBroadcastHistory]);
  const addZero = (digit: number) => {
    if (digit.toString().length === 1) {
      return '0' + digit;
    } else {
      return digit;
    }
  };
  return (
    <Box className={classes.root}>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="text-history">
            <TableHead>
              <TableRow>
                <TableCell>Text Body</TableCell>
                <TableCell>Scheduled day and time</TableCell>
                <TableCell>Created At</TableCell>
                {!isBroadcastExist && <TableCell>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {textBroadcasts?.map((broadcastItem: TextBroadcastHistory) => {
                return (
                  <TableRow key={broadcastItem.id}>
                    <TableCell style={{ maxWidth: 300 }}>{broadcastItem.textBody}</TableCell>
                    <TableCell>
                      {days[broadcastItem.dayOfWeek - 1]}, {addZero(broadcastItem.timeHour)}:{addZero(broadcastItem.timeMin)}
                    </TableCell>
                    <TableCell>{moment(broadcastItem.createdAt).format('YYYY-MM-DD, hh:mm A')}</TableCell>
                    {!isBroadcastExist && (
                      <TableCell key={broadcastItem.id + broadcastItem.status}>
                        {broadcastItem.status === BroadcastStatus.DISABLED && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setSelectedBroadcast(broadcastItem);
                              setActiveTab(0);
                            }}>
                            Schedule Again
                          </Button>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {textBroadcasts && textBroadcasts.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography variant="body1">No Broadcast History</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {hasMore && (
        <Box display={'flex'} justifyContent="center">
          <Button variant="contained" color="primary" disabled={fetching} onClick={fetchMoreData} className={classes.loadMoreButton}>
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BroadcastHistory;

import React from 'react';
import { useStyles } from '../style';
import { TableCell } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { ITableSortData } from '../../types';
import sortRows from '../sort';
const TableSort = ({ sortData, setSortData, formik }: any) => {
  const classes = useStyles();

  const handleSort = (sortBy: 'tableName' | 'seats') => {
    const { tables: _tables } = formik.values;

    setSortData((oldval: ITableSortData) => {
      const isAsc = oldval.orderBy === sortBy && oldval.orderType === 'asc';

      formik.setValues({
        tables: sortRows(_tables, {
          orderBy: sortBy,
          orderType: isAsc ? 'desc' : 'asc'
        })
      });

      return {
        orderBy: sortBy,
        orderType: isAsc ? 'desc' : 'asc'
      };
    });
  };

  return (
    <>
      <TableCell align="center" className={classes.equalWidth}>
        <TableSortLabel active={sortData.orderBy === 'tableName'} direction={sortData.orderType} onClick={() => handleSort('tableName')}>
          Table No.
          <span className={classes.visuallyHidden}>{'sorted ascending'}</span>
        </TableSortLabel>
      </TableCell>
      <TableCell align="center" className={classes.equalWidth}>
        <TableSortLabel active={sortData.orderBy === 'seats'} direction={sortData.orderType} onClick={() => handleSort('seats')}>
          Seats
          <span className={classes.visuallyHidden}></span>
        </TableSortLabel>
      </TableCell>
    </>
  );
};

export default TableSort;

import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Divider, TextField, Switch, FormControlLabel, Tabs, Tab, withStyles, useTheme } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useSnackbar } from 'notistack';
import { maxBy, minBy } from 'lodash';
import { useFormik, getIn } from 'formik';
import { useStyles } from './style';
import validate from './validations';
import { useStore } from 'store';
import { useGetRestaurantById, useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { useGetRewardItemsQuery } from 'graphql/hooks/dish.hooks';
import DishList from '../RestaurantHome/DishList';
import { useActiveRestaurant } from 'hooks/restaurant';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import {
  MIN_POINTS_REQUIRED_LABEL,
  MAX_REDEEMABLE_LABEL,
  MIN_PURCHASE_REQUIRED,
  POINT_EXCHANGE_RATIO,
  MIN_START_POINTS,
  MAX_REDEEM_POINTS_PER_USAGE,
  MIN_REDEEEM_PURCHASE,
  PRICE_POINT_RATIO
} from '../constants';

interface IInitialValueType {
  minStartPoints: number;
  maxRedeemPointsPerUsage: number;
  minRedeemPurchase: string;
  pricePointRatio: string;
  issueRewardPoint: boolean;
}

/**
 * Rewards page
 *
 * This screen allows user to configure rewards settings for the restaurant.
 */
const Reward: React.FC = () => {
  const classes = useStyles();

  const theme = useTheme();

  const snackbar = useSnackbar();

  const {
    state: { restaurant }
  } = useStore();

  const [dishMinMaxPoints, setDishMinMaxPoints] = useState<{ minDishPoints: null; maxDishPoints: null }>({ minDishPoints: null, maxDishPoints: null });

  const { restaurantId } = useActiveRestaurant();

  const { data } = useGetRestaurantById(restaurant, 'REWARD_SETTING');

  const { data: rewardDishes } = useGetRewardItemsQuery({ restaurantId });

  const { updateRestaurant } = useUpdateRestaurantMutation('REWARD_SETTING');

  const { rewardSetting = {}, issueRewardPoint = false } = data ? data.ownerGetRestaurantById : {};

  const { minStartPoints = MIN_START_POINTS, maxRedeemPointsPerUsage = MAX_REDEEM_POINTS_PER_USAGE, minRedeemPurchase = MIN_REDEEEM_PURCHASE, pricePointRatio = PRICE_POINT_RATIO } =
    rewardSetting || {};

  const minRedeemPurchaseFormatted = minRedeemPurchase.toString() + '.00';

  const initialValues: IInitialValueType = {
    minStartPoints,
    maxRedeemPointsPerUsage,
    minRedeemPurchase: minRedeemPurchaseFormatted,
    pricePointRatio,
    issueRewardPoint
  };

  React.useEffect(() => {
    if (rewardDishes && rewardDishes.length) {
      const minDishPoints = minBy(rewardDishes, (dish: any) => {
        return dish.rewardPoints ? dish.rewardPoints : null;
      }).rewardPoints;

      const maxDishPoints = maxBy(rewardDishes, (dish: any) => {
        return dish.rewardPoints ? dish.rewardPoints : null;
      }).rewardPoints;

      setDishMinMaxPoints({ maxDishPoints, minDishPoints });
    }
  }, [rewardDishes]);

  const onSubmit = async (values: IInitialValueType) => {
    setDisableButton(true);
    const response = await updateRestaurant({
      variables: {
        input: {
          id: restaurant,
          issueRewardPoint: values.issueRewardPoint,
          rewardSetting: {
            minStartPoints: values.minStartPoints,
            maxRedeemPointsPerUsage: values.maxRedeemPointsPerUsage,
            minRedeemPurchase: parseInt(values.minRedeemPurchase),
            pricePointRatio: parseInt(values.pricePointRatio)
          }
        }
      }
    });
    if (!response.errors) {
      snackbar.enqueueSnackbar('Rewards configurations updated successfully', {
        variant: 'success'
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate
  });

  const [disableButton, setDisableButton] = useState(true);

  const { values, handleChange, handleSubmit, errors } = formik;

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);

    setDisableButton(false);
  };

  const [activeTab, setActiveTab] = useState(0);

  const _setActiveTab = (_event: React.ChangeEvent<{}>, index: number) => {
    setActiveTab(index);
  };

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  if (!data || !data.ownerGetRestaurantById) {
    return null;
  }

  const renderConfigurations = (readOnly = false) => {
    if (!values.issueRewardPoint) {
      return (
        <Box textAlign="center">
          <Typography variant="h6" color="secondary">
            This feature is not enabled for your restaurant. Contact Lokobee for customer loyalty reward program.
          </Typography>
        </Box>
      );
    }
    return (
      <>
        <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
          <Grid container={true} alignItems="center" spacing={2}>
            <Grid item={true} xs={12} sm={10}>
              <Typography variant="h6">Min Points Required</Typography>
              <Typography variant="body1" align="justify">
                {MIN_POINTS_REQUIRED_LABEL}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={2}>
              <TextField
                name="minStartPoints"
                type="number"
                value={values.minStartPoints}
                variant="outlined"
                fullWidth={true}
                onChange={_onChange}
                error={errors.minStartPoints ? true : false}
                helperText={errors.minStartPoints}
                inputProps={{
                  min: 0
                }}
                InputProps={{
                  readOnly: readOnly
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
          <Grid container={true} alignItems="center" spacing={2}>
            <Grid item={true} xs={12} sm={10}>
              <Typography variant="h6">Max Redeemable Points Per Usage</Typography>
              <Typography variant="body1" align="justify">
                {MAX_REDEEMABLE_LABEL}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={2}>
              <TextField
                name="maxRedeemPointsPerUsage"
                type="number"
                value={values.maxRedeemPointsPerUsage}
                variant="outlined"
                fullWidth={true}
                onChange={_onChange}
                error={errors.maxRedeemPointsPerUsage ? true : false}
                helperText={errors.maxRedeemPointsPerUsage}
                inputProps={{
                  min: 0
                }}
                InputProps={{
                  readOnly: readOnly
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
          <Grid container={true} alignItems="center" spacing={2}>
            <Grid item={true} xs={12} sm={10}>
              <Typography variant="h6">Min Purchase Required</Typography>
              <Typography variant="body1" align="justify">
                {MIN_PURCHASE_REQUIRED}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={2}>
              <CurrencyInputField
                style={{ textAlign: 'right' }}
                name="minRedeemPurchase"
                variant="outlined"
                value={values.minRedeemPurchase}
                onChange={_onChange}
                error={errors.minRedeemPurchase ? true : false}
                helperText={errors.minRedeemPurchase}
                InputProps={{
                  readOnly: readOnly,
                  startAdornment: <AttachMoneyIcon fontSize="small" />
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" maxWidth={1000} margin="0 auto" marginBottom={5} alignItems="center">
          <Grid container={true} alignItems="center" spacing={2}>
            <Grid item={true} xs={12} sm={10}>
              <Typography variant="h6">Point Exchange Ratio</Typography>
              <Typography variant="body1" align="justify">
                {POINT_EXCHANGE_RATIO}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={2}>
              <TextField
                name="pricePointRatio"
                type="number"
                value={values.pricePointRatio}
                variant="outlined"
                fullWidth={true}
                onChange={_onChange}
                error={errors.pricePointRatio ? true : false}
                helperText={errors.pricePointRatio}
                inputProps={{
                  min: 0
                }}
                InputProps={{
                  readOnly: false
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <Box bgcolor="white" padding={1}>
      <form onSubmit={handleSubmit}>
        <Grid container={true} alignItems="center">
          <Grid item={true} xs={4}>
            <Typography variant="h5">Reward</Typography>
          </Grid>
          <Grid item={true} xs={8}>
            <Box>
              <StyledTabs value={activeTab} onChange={_setActiveTab}>
                <Tab label="Configuration" id="0" />
                <Tab label="Dishes" id="1" />
              </StyledTabs>
            </Box>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        {activeTab === 0 && (
          <>
            <Box paddingBottom={5} textAlign="right">
              {values.issueRewardPoint ? (
                <Typography variant="body1" className={classes.success}>
                  Customer loyalty reward program enabled
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.error}>
                  Customer loyalty reward program disabled
                </Typography>
              )}
              {/* <FormControlLabel
                control={
                  <Switch
                    aria-label="Issue rewards:"
                    color="primary"
                    checked={values.issueRewardPoint}
                    style={{ cursor: 'not-allowed' }}
                    onChange={(e: any, checked) => {
                      formik.setFieldValue('issueRewardPoint', checked);
                      setDisableButton(false);
                    }}
                  />
                }
                label="Customer Loyalty Reward Program Enabled"
                labelPlacement="start"
              /> */}

              {values.issueRewardPoint && (
                <Button className={classes.formControls} type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} disabled={disableButton && rewardSetting !== null}>
                  Save
                </Button>
              )}
            </Box>

            {renderConfigurations()}
          </>
        )}

        {activeTab === 1 && (
          <Box justifyContent="center" display="flex" maxWidth={1000} margin="0 auto">
            {rewardDishes && rewardDishes.length ? <DishList dishes={rewardDishes} showActions={false} statusIcon={true} /> : <Typography>No rewards added.</Typography>}
          </Box>
        )}
      </form>
    </Box>
  );
};

export default Reward;

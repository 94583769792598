import gql from 'graphql-tag';

export const CryptoCoupon = gql`
  fragment CryptoCoupon on CryptoCoupon {
    bizId
    code
    id
    createdAt
    name
    description
    discountType
    discountValue
    maxDiscount
    startDate
    endDate
    minSpending
    maxUse
    isPrivate
    isSingleUse
    orderType
    dayOfWeek
    used
    totalSales
    couponCost
    cryptoUnit
    cryptoEarned
  }
`;

import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, TextField, Box, Table, TableRow, TableCell, TableHead, TableBody, Divider } from '@material-ui/core';
import { useStyles } from './style';
import * as _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { IEmployee } from './types';
import PasswordField from 'components/PasswordField';
import { useFormik } from 'formik';
import { Dialog } from '@lokobee/lokobee-ui';
import { useSnackbar } from 'notistack';

interface IProps {
  initialEmployees: IEmployee[];
  createEmployee: (newEmp: IEmployee) => Promise<any>;
  updateEmployee: (newEmp: IEmployee) => Promise<any>;
  deleteEmployee: (uid: string) => Promise<any>;
}

const EmployeeManagement = ({ initialEmployees, createEmployee, updateEmployee, deleteEmployee }: IProps) => {
  const classes = useStyles({ colLength: 3 });

  const [employees, setEmployees] = useState<IEmployee[]>(initialEmployees);

  useEffect(() => {
    setEmployees(initialEmployees);
  }, [initialEmployees]);

  const [curIndex, setCurIndex] = useState<number>(-1);

  const [deleteIndex, setDeleteIndex] = useState<number>(-1);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const snackbar = useSnackbar();

  const onAdd = () => {
    formik.resetForm();
    setCurIndex(-2);
  };

  const onEdit = (index: number) => {
    formik.setValues({
      ...employees[index],
      password: ''
    });
    setCurIndex(index);
  };

  const onDelete = (index: number) => {
    setDeleteIndex(index);
    setOpenDeleteDialog(true);
  };

  const addEmployee = async (values: IEmployee) => {
    const response = await createEmployee(values);
    if (response) {
      notify('success', 'Employee added successfully.');
      cancel();
    } else {
      notify('error', 'Error in adding employee.');
      cancel();
    }
  };

  const editEmployee = async (values: IEmployee) => {
    const response = await updateEmployee(values);

    if (response) {
      notify('success', 'Employee updated successfully.');
      cancel();
    } else {
      notify('error', 'Error in editing employee.');
      cancel();
    }
  };

  const _deleteEmployee = async () => {
    const emp = employees[deleteIndex];
    const response = await deleteEmployee(emp.uid);

    if (response) {
      notify('success', 'Employee deleted successfully.');
      cancel();
    } else {
      notify('error', 'Error in deleting employee.');
      cancel();
    }
  };

  const cancel = () => {
    formik.resetForm();
    setOpenDeleteDialog(false);
    setCurIndex(-1);
    setDeleteIndex(-1);
  };

  const notify = (variant: 'error' | 'success', msg: string) => {
    snackbar.enqueueSnackbar(msg, {
      variant
    });
  };

  const initialValues: IEmployee = {
    uid: '',
    displayName: '',
    userName: '',
    password: ''
  };

  const validate = (values: IEmployee) => {
    const errors: any = {};

    if (!values.displayName || values.displayName.trim() === '') errors.displayName = 'Required.';

    if (!values.userName || values.userName.trim() === '') errors.userName = 'Required.';
    if (_.filter(employees, (emp: IEmployee, index: number) => index !== curIndex && emp.userName === values.userName).length > 0) errors.userName = 'Duplicate Username.';

    if (curIndex === -2 && (!values.password || values.password.trim() === '')) errors.password = 'Required';
    if (values.password && values.password.length > 0 && values.password.length < 6) errors.password = 'Min 6 characters.';

    return errors;
  };

  const onSubmit = (values: IEmployee) => {
    if (curIndex === -2) {
      addEmployee(values);
    } else {
      editEmployee(values);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  });

  const editForm = (
    <TableRow className={classes.verticleAlignTop}>
      <TableCell align="center" className={classes.equalWidth}>
        <TextField
          variant="outlined"
          label="name"
          name="displayName"
          value={formik.values.displayName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.displayName && formik.errors.displayName ? true : false}
          helperText={formik.touched.displayName ? formik.errors.displayName : ''}
        />
      </TableCell>
      <TableCell align="center" className={classes.equalWidth}>
        <TextField
          variant="outlined"
          label="username"
          name="userName"
          value={formik.values.userName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.replace(/\s/g, '');
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.userName && formik.errors.userName ? true : false}
          helperText={formik.touched.userName ? formik.errors.userName : ''}
        />
      </TableCell>
      <TableCell align="center" className={classes.equalWidth}>
        {formik.values.password !== undefined && (
          <PasswordField
            component="input"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password ? true : false}
            errorMsg={formik.touched.password ? formik.errors.password : ''}
          />
        )}
      </TableCell>
      <TableCell align="center" className={`${classes.equalWidth} ${classes.verticleAlignMiddle}`}>
        <CheckIcon className={classes.successBtn} onClick={formik.submitForm} />
        <ClearIcon className={classes.deleteIcon} onClick={() => cancel()} />
      </TableCell>
    </TableRow>
  );

  return (
    <Box width="100%">
      <Grid container={true} alignItems="center">
        <Grid item={true} sm={6}>
          <Typography variant="h5">Employee Management</Typography>
        </Grid>
        <Grid item={true} sm={6}>
          <Box textAlign="right">
            <Button type="submit" variant="contained" color="primary" onClick={onAdd}>
              Add Employee
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container={true} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.equalWidth} align="center">
                Employee Name
              </TableCell>
              <TableCell className={classes.equalWidth} align="center">
                Username
              </TableCell>
              <TableCell className={classes.equalWidth} align="center">
                Password
              </TableCell>
              <TableCell className={classes.equalWidth} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {employees.length > 0 && (
            <TableBody>
              {curIndex === -2 && editForm}
              {_.map(employees, (emp: any, index: number) => {
                if (index === curIndex) {
                  return <React.Fragment key={index}>{editForm}</React.Fragment>;
                } else {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center" className={classes.equalWidth}>
                        {emp.displayName}
                      </TableCell>
                      <TableCell align="center" className={classes.equalWidth}>
                        {emp.userName}
                      </TableCell>
                      <TableCell align="center" className={classes.equalWidth}>
                        <PasswordField value={emp.password} component="div" />
                      </TableCell>
                      <TableCell align="center" className={classes.equalWidth}>
                        <EditIcon className={classes.editIcon} onClick={() => onEdit(index)} />
                        <DeleteIcon className={classes.deleteIcon} onClick={() => onDelete(index)} />
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          )}
          {employees.length === 0 && (
            <TableBody>
              {curIndex === -2 && editForm}
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <Typography variant="body1"> No Employees Added</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Grid>
      <Dialog open={openDeleteDialog} setOpen={setOpenDeleteDialog}>
        <Dialog.DialogContent>
          <Typography variant="body1">Are you sure you want to delete this employee ?</Typography>
        </Dialog.DialogContent>
        <Dialog.DialogActions saveBtnText="Yes" onSave={_deleteEmployee} cancelBtnText="No" onCancel={cancel} />
      </Dialog>
    </Box>
  );
};

export default EmployeeManagement;

import { FormikProps, getIn, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, Grid, InputAdornment, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkIcon from '@material-ui/icons/Link';
import PublicIcon from '@material-ui/icons/Public';
import Rating from '@material-ui/lab/Rating';
import UploadImage from 'components/UploadImage';
import { useCheckWebsiteUrl, useCreateWebsiteData, useUpdateWebsiteDataMutation } from 'graphql/hooks/websiteAutomation.hooks';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import EditControlledInputField from './EditControlledInputField';
import InfoInputField from './InfoInputField';
import { useStyles } from './styles';
import { WebsiteFormValues } from './types';
import validationSchema from './validations';

interface IProps {
  initialValues: WebsiteFormValues;
  bizId: string | null;

  getUploadId: (filename: string, imageData: string) => Promise<string | null>;
  createData: boolean;
}

const WebAuotmationForm = ({ initialValues, getUploadId, bizId, createData }: IProps) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const history = useHistory();

  let link1 = '';

  if (process.env.NODE_ENV === 'production') {
    link1 = 'https://www.lokosite.com/biz/<Website Domain Name>';
  } else {
    link1 = 'https://www.lokowoo.im/biz/<Website Domain Name>';
  }

  const { createWebsite, error: createError } = useCreateWebsiteData(bizId);

  const { updateWebsite, error: updateError } = useUpdateWebsiteDataMutation(bizId);

  const [urlCheckLoader, seturlCheckLoader] = useState(false);

  const { refetch } = useCheckWebsiteUrl('');

  const formik: FormikProps<WebsiteFormValues> = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });

  const handleFormSubmit = async (formValues: WebsiteFormValues) => {
    try {
      formik.setSubmitting(true);
      let displayImageId;
      let menuUploadId;

      if (createData) {
        const res = await refetch({
          webisteUrl: formValues.websiteUrl
        });

        if (res && res.data) {
          if (res.data.checkWebsiteUrlExits) {
            throw Error('URL already exists !');
          }
        } else {
          throw Error('Request failed');
        }
      }

      const { displayImageData, menuImageData, about } = formValues;

      if (displayImageData) {
        displayImageId = await getUploadId(displayImageData.filename, displayImageData.imageFiledata);
      } else {
        displayImageId = formValues.displayImage ? formValues.displayImage.id : '';
      }

      if (!displayImageId || displayImageId === '') {
        throw Error('Please upload home page image');
      }

      const aboutData = await Promise.all(
        map(about, (aboutSection) => {
          return new Promise(async (resolve, reject) => {
            if (aboutSection.imageData) {
              const imageId = await getUploadId(aboutSection.imageData.filename, aboutSection.imageData.imageFiledata);
              if (!imageId) {
                reject('About section image upload failed');
              } else {
                resolve({
                  picture: aboutSection.imageData.filename ? imageId : null,
                  title: aboutSection.heading,
                  description: aboutSection.description
                });
              }
            } else {
              resolve({
                picture: aboutSection.image ? aboutSection.image.id : undefined,
                title: aboutSection.heading,
                description: aboutSection.description
              });
            }
          });
        })
      ).catch((e) => {
        throw Error('Failed to upload about section image');
      });

      //menuImageUpload

      if (menuImageData) {
        menuUploadId = await getUploadId(menuImageData.menuFilename, menuImageData.menuImageFiledata);
      } else {
        menuUploadId = formValues.menuImage ? formValues.menuImage.id : '';
      }

      if (!createData) {
        const response = await updateWebsite({
          variables: {
            input: {
              url: formValues.websiteUrl,
              bizId: bizId,
              keywords: formValues.keywords,
              displayImage: displayImageId,
              headline1: formValues.headline1,
              googleAnalyticsTrackingId: formValues.googleAnalyticsTrackingId,
              googleAdsId: formValues.googleAdsId,
              about: aboutData,
              menuPicture: !menuUploadId || menuUploadId === '' ? undefined : menuUploadId,
              menuHeadline: formValues.menuDescription,
              testimonials: formValues.testimonials,
              orderPlatform: formValues.orderPlatform,
              seoPageTitle: formValues.seoPageTitle,
              seoPageDescription: formValues.seoPageDescription,
              googleSiteVerification: formValues.googleSiteVerification,
              legalEntityName: formValues.legalEntityName,
              version: formValues.version,
              fbLink: formValues.facebookLink,
              instaLink: formValues.instagramLink,
              yelpLink: formValues.yelpLink,
              tikTokLink: formValues.tikTokLink,
              blogspostLink: formValues.blogspostLink,
              googleReviewsLink: formValues.googleReviewsLink,
              yelpWriteReviewLink: formValues.yelpWriteReviewLink,
              googleWriteReviewLink: formValues.googleWriteReviewLink
            }
          }
        });

        formik.setSubmitting(false);
        if (response) {
          snackbar.enqueueSnackbar('Website updated Successfully !', {
            variant: 'success'
          });
        }
      } else {
        const response = await createWebsite({
          variables: {
            input: {
              url: formValues.websiteUrl,
              bizId: bizId,
              displayImage: displayImageId,
              headline1: formValues.headline1,
              googleAnalyticsTrackingId: formValues.googleAnalyticsTrackingId,
              googleAdsId: formValues.googleAdsId,
              about: aboutData,
              menuPicture: !menuUploadId || menuUploadId === '' ? undefined : menuUploadId,
              menuHeadline: formValues.menuDescription,
              testimonials: formValues.testimonials,
              orderPlatform: formValues.orderPlatform,
              seoPageTitle: formValues.seoPageTitle,
              seoPageDescription: formValues.seoPageDescription,
              googleSiteVerification: formValues.googleSiteVerification,
              legalEntityName: formValues.legalEntityName,
              fbLink: formValues.facebookLink,
              instaLink: formValues.instagramLink,
              yelpLink: formValues.yelpLink,
              tikTokLink: formValues.tikTokLink,
              blogspostLink: formValues.blogspostLink,
              googleReviewsLink: formValues.googleReviewsLink,
              yelpWriteReviewLink: formValues.yelpWriteReviewLink,
              googleWriteReviewLink: formValues.googleWriteReviewLink
            }
          }
        });
        formik.setSubmitting(false);
        if (response) {
          snackbar.enqueueSnackbar('Webiste Created Sucessfully !', {
            variant: 'success'
          });
        }
      }
    } catch (e) {
      formik.setSubmitting(false);
      console.log(e);
      snackbar.enqueueSnackbar((e as any).message, {
        variant: 'error'
      });
    }
  };

  const onDisplayImageCrop = (filename: string, imageFiledata: string) => {
    formik.setValues({
      ...formik.values,
      displayImageData: {
        filename,
        imageFiledata
      }
    });
  };

  const onAboutSectionCrop = (filename: string, fileData: string, index: number) => {
    const currentAboutVal = formik.values.about;

    currentAboutVal[index].imageData = {
      filename,
      imageFiledata: fileData
    };

    formik.setValues({
      ...formik.values,
      about: currentAboutVal
    });
  };

  const checkUrl = async () => {
    if (formik.values.websiteUrl.trim() !== '') {
      seturlCheckLoader(true);
      try {
        const res = await refetch({
          webisteUrl: formik.values.websiteUrl
        });
        seturlCheckLoader(false);

        if (res && res.data && res.data.checkWebsiteUrlExits) {
          snackbar.enqueueSnackbar('Url already Exist !', {
            variant: 'error'
          });
        } else {
          snackbar.enqueueSnackbar('Url avalaible !', {
            variant: 'success'
          });
        }
      } catch (e) {
        seturlCheckLoader(false);
        snackbar.enqueueSnackbar('Request failed !', {
          variant: 'error'
        });
      }
    }
  };
  const { displayImage, about } = formik.values;

  const redirectToWebsite = (url: string | undefined) => {
    window.open('https://' + url, '_blank');
  };
  useEffect(() => {
    if (updateError && updateError.graphQLErrors && updateError.graphQLErrors.length > 0) {
      updateError.graphQLErrors.forEach((gqlError) => {
        snackbar.enqueueSnackbar(gqlError.message, {
          variant: 'error'
        });
      });
    }
    if (createError && createError.graphQLErrors && createError.graphQLErrors.length > 0) {
      createError.graphQLErrors.forEach((gqlError) => {
        snackbar.enqueueSnackbar(gqlError.message, {
          variant: 'error'
        });
      });
    }
  }, [snackbar, updateError, createError]);

  return (
    <Box paddingBottom={3} className={classes.root}>
      <Typography variant="h5">Website Form</Typography>

      <Box className={classes.wrapper}>
        <form className={classes.formWrapper}>
          {formik.values.customDomain ? (
            <Box padding={1.5}>
              <Box marginBottom={1.5}>
                <Typography variant="h6">Website Domain Preview </Typography>
              </Box>
              <Box>
                <Button color="primary" onClick={() => redirectToWebsite(formik.values.customDomain)} size="small" variant="contained" disableElevation={true} disabled={urlCheckLoader}>
                  <PublicIcon style={{ marginRight: '8px' }} /> {formik.values.customDomain}
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box padding={1.5}>
                <Typography variant="h6">Website Domain Name </Typography>
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  <Box width="100%">
                    <InfoInputField
                      name="websiteUrl"
                      variant="outlined"
                      formik={formik}
                      style={{ width: '100%' }}
                      label="Enter Website Domain Name"
                      InputProps={{
                        readOnly: !createData,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LinkIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // website url should not have space or speacial characters or capital letters
                        formik.setFieldValue(
                          'websiteUrl',
                          e.target.value
                            .replace(/[^\w]/gi, '')
                            .replaceAll('_', '')
                            .toLowerCase()
                        );
                      }}
                    />
                  </Box>
                  {createData && (
                    <Box ml={2} width="100%" display="flex">
                      <Button color="primary" onClick={checkUrl} size="small" variant="contained" disableElevation={true} disabled={urlCheckLoader}>
                        Check url availability
                      </Button>
                      {urlCheckLoader && (
                        <Box paddingX={1}>
                          <CircularProgress />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
                <Box paddingX={1}>
                  <Typography variant="body1">Suggestions : {link1}</Typography>
                </Box>
              </Box>
            </>
          )}
          <Box padding={1.5}>
            <Typography variant="h6">Header section</Typography>
          </Box>
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="facebookLink"
            label="Facebook Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookIcon />
                </InputAdornment>
              )
            }}
          />
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="instagramLink"
            label="Instagram Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InstagramIcon />
                </InputAdornment>
              )
            }}
          />
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="yelpLink"
            label="Yelp Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={'1.6rem'} height={'1.3rem'}>
                    <path d="M42.9 240.3l99.6 48.6c19.2 9.4 16.2 37.5-4.5 42.7L30.5 358.5a22.8 22.8 0 0 1 -28.2-19.6 197.2 197.2 0 0 1 9-85.3 22.8 22.8 0 0 1 31.6-13.2zm44 239.3a199.5 199.5 0 0 0 79.4 32.1A22.8 22.8 0 0 0 192.9 490l3.9-110.8c.7-21.3-25.5-31.9-39.8-16.1l-74.2 82.4a22.8 22.8 0 0 0 4.1 34.1zm145.3-109.9l58.8 94a22.9 22.9 0 0 0 34 5.5 198.4 198.4 0 0 0 52.7-67.6A23 23 0 0 0 364.2 370l-105.4-34.3c-20.3-6.5-37.8 15.8-26.5 33.9zm148.3-132.2a197.4 197.4 0 0 0 -50.4-69.3 22.9 22.9 0 0 0 -34 4.4l-62 91.9c-11.9 17.7 4.7 40.6 25.2 34.7L366 268.6a23 23 0 0 0 14.6-31.2zM62.1 30.2a22.9 22.9 0 0 0 -9.9 32l104.1 180.4c11.7 20.2 42.6 11.9 42.6-11.4V22.9a22.7 22.7 0 0 0 -24.5-22.8 320.4 320.4 0 0 0 -112.3 30.1z" />
                  </svg>
                </InputAdornment>
              )
            }}
          />
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="tikTokLink"
            label="Tiktok Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <svg fill="#000000" width={'1.6rem'} height={'1.3rem'} viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg">
                    <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                  </svg>
                </InputAdornment>
              )
            }}
          />
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="blogspostLink"
            label="Blogspot Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <svg fill="#000000" width={'1.6rem'} height={'1.3rem'} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <g id="7935ec95c421cee6d86eb22ecd116f2d">
                      <path
                        d="M509.023,212.71l-4.267-8.883l-7.173-5.626
		c-9.382-7.348-56.853,0.511-69.652-11.091c-9.044-8.371-10.416-23.404-13.161-43.714c-5.114-39.436-8.358-41.494-14.496-54.805
		c-22.37-47.47-82.977-83.15-124.632-88.09H162.782C74,0.5,1.441,73.232,1.441,162.009v188.307C1.441,438.93,74,511.5,162.782,511.5
		h185.419c88.776,0,160.822-72.57,161.334-161.185l1.023-130.433L509.023,212.71z M164.828,132.467h89.463
		c17.072,0,30.908,13.835,30.908,30.74c0,16.892-13.836,30.89-30.908,30.89h-89.463c-17.067,0-30.901-13.998-30.901-30.89
		C133.926,146.302,147.761,132.467,164.828,132.467z M346.654,378.66H164.828c-17.067,0-30.901-13.998-30.901-30.728
		c0-16.904,13.834-30.728,30.901-30.728h181.827c16.917,0,30.74,13.823,30.74,30.728C377.395,364.662,363.571,378.66,346.654,378.66
		z"></path>
                    </g>
                  </svg>
                </InputAdornment>
              )
            }}
          />
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="googleReviewsLink"
            label="Google Reviews Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width={'1.6rem'} height={'1.3rem'}>
                    <path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z" />
                  </svg>
                </InputAdornment>
              )
            }}
          />
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="yelpWriteReviewLink"
            label="Yelp Write Review Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={'1.6rem'} height={'1.3rem'}>
                    <path d="M42.9 240.3l99.6 48.6c19.2 9.4 16.2 37.5-4.5 42.7L30.5 358.5a22.8 22.8 0 0 1 -28.2-19.6 197.2 197.2 0 0 1 9-85.3 22.8 22.8 0 0 1 31.6-13.2zm44 239.3a199.5 199.5 0 0 0 79.4 32.1A22.8 22.8 0 0 0 192.9 490l3.9-110.8c.7-21.3-25.5-31.9-39.8-16.1l-74.2 82.4a22.8 22.8 0 0 0 4.1 34.1zm145.3-109.9l58.8 94a22.9 22.9 0 0 0 34 5.5 198.4 198.4 0 0 0 52.7-67.6A23 23 0 0 0 364.2 370l-105.4-34.3c-20.3-6.5-37.8 15.8-26.5 33.9zm148.3-132.2a197.4 197.4 0 0 0 -50.4-69.3 22.9 22.9 0 0 0 -34 4.4l-62 91.9c-11.9 17.7 4.7 40.6 25.2 34.7L366 268.6a23 23 0 0 0 14.6-31.2zM62.1 30.2a22.9 22.9 0 0 0 -9.9 32l104.1 180.4c11.7 20.2 42.6 11.9 42.6-11.4V22.9a22.7 22.7 0 0 0 -24.5-22.8 320.4 320.4 0 0 0 -112.3 30.1z" />
                  </svg>
                </InputAdornment>
              )
            }}
          />
          <InfoInputField
            className={classes.textfield}
            variant="outlined"
            formik={formik}
            name="googleWriteReviewLink"
            label="Google Write Review Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width={'1.6rem'} height={'1.3rem'}>
                    <path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z" />
                  </svg>
                </InputAdornment>
              )
            }}
          />
          <Box padding={1.5}>
            <Typography variant="h6">SEO Values</Typography>
          </Box>
          <EditControlledInputField className={classes.textfield} variant="outlined" formik={formik} name="seoPageTitle" label="SEO Page Title" />
          <InfoInputField className={classes.textfield} variant="outlined" formik={formik} name="seoPageDescription" label="SEO Page Description" />
          <InfoInputField className={classes.textfield} variant="outlined" formik={formik} name="keywords" label="Keywords" helperText="Enter Keywords saperated by coma " />
          <EditControlledInputField className={classes.textfield} variant="outlined" formik={formik} name="googleAnalyticsTrackingId" label="Google Analytics Tracking ID" />
          <EditControlledInputField className={classes.textfield} variant="outlined" formik={formik} name="googleAdsId" label="Google Ads ID" />
          <EditControlledInputField className={classes.textfield} variant="outlined" formik={formik} name="googleSiteVerification" label="Google Site Verification Code (for Google Search Console)" />
          <InfoInputField className={classes.textfield} variant="outlined" formik={formik} name="legalEntityName" label="Restaurant Legal Entity Name" />
          <Box padding={1.5}>
            <Typography variant="h6">Home page section</Typography>
          </Box>
          <Box display="flex" mb={2}>
            <Box>
              <UploadImage id="displayImage" width={300} height={200} onCrop={onDisplayImageCrop} initialValue={displayImage ? displayImage.url : undefined} />
              {formik.values.displayImageData === null && formik.values.displayImageData === null && !!getIn(formik.errors, 'displayImageData') ? (
                <Box ml={4} mt={0.5}>
                  <Typography variant="caption" color="error">
                    Home page image upload is mandatory.
                  </Typography>
                </Box>
              ) : (
                ''
              )}
            </Box>
            <Box width="100%">
              <InfoInputField formik={formik} className={classes.textfield} name="headline1" variant="outlined" type="text" label="Headline 1" />
              {/* <InfoInputField formik={formik} className={classes.textfield} name="headline2" variant="outlined" type="text" label="Headline 2" /> */}
            </Box>
          </Box>

          <Box padding={1.5}>
            <Typography variant="h6">About page section</Typography>
          </Box>

          {map(about, (aboutSection, index) => {
            return (
              <Box mb={2} display="flex" key={index}>
                <Box mr={1}>
                  <UploadImage
                    id={`about${index}`}
                    width={300}
                    height={200}
                    onCrop={(filename, imgData) => onAboutSectionCrop(filename, imgData, index)}
                    initialValue={aboutSection.image ? aboutSection.image.url : undefined}
                  />
                </Box>
                <Box width="100%">
                  <InfoInputField className={classes.textfield} formik={formik} value={aboutSection.heading} variant="outlined" name={`about.${index}.heading`} type="text" label="Heading" />
                  <Box>
                    <InfoInputField
                      isBigInput={true}
                      variant="outlined"
                      rowsMin={5}
                      value={aboutSection.description}
                      formik={formik}
                      name={`about.${index}.description`}
                      type="text"
                      label="Description"
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}

          <Box padding={1.5}>
            <Typography variant="h6">Menu section</Typography>
          </Box>
          <Box mb={1} display="flex">
            <Box width="100%">
              <InfoInputField isBigInput={true} variant="outlined" rowsMin={10} formik={formik} name="menuDescription" type="text" label="Menu Description" />
            </Box>
          </Box>

          <Box padding={1.5}>
            <Typography variant="h6">Testimonials section</Typography>
          </Box>
          <Box>
            <Grid container={true}>
              {formik.values.testimonials.length > 0 &&
                formik.values.testimonials.map((testimonial, index) => (
                  <Grid key={index} item={true} xs={12} sm={6} md={4}>
                    <Box marginY={4}>
                      <InfoInputField variant="outlined" formik={formik} name={`testimonials.${index}.name`} value={testimonial.name} type="text" label="Full Name" />
                      <InfoInputField variant="outlined" name={`testimonials.${index}.text`} formik={formik} value={testimonial.text} type="text" multiline={true} rows={3} label="Enter Reviews" />

                      <Box padding={1}>
                        <Rating
                          value={testimonial.rating}
                          name={`testimonials.${index}.rating`}
                          precision={0.5}
                          onChange={(event: any, val: number | null) => {
                            if (val) {
                              formik.setFieldValue(`testimonials.${index}.rating`, val);
                            }
                          }}
                        />
                        <Box>
                          {!!getIn(formik.touched, `testimonials.${index}.rating`) && !!getIn(formik.errors, `testimonials.${index}.rating`) && (
                            <Typography variant="caption" color="error">
                              {getIn(formik.errors, `testimonials.${index}.rating`)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box paddingX={1}>
                        <Button
                          type="button"
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            const testimonialsCopy = [...formik.values.testimonials];
                            testimonialsCopy.splice(index, 1);
                            formik.setValues({
                              ...formik.values,
                              testimonials: testimonialsCopy
                            });
                          }}>
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
            <Box paddingY={1}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  formik.setValues({
                    ...formik.values,
                    testimonials: [
                      ...formik.values.testimonials,
                      {
                        name: '',
                        text: '',
                        rating: 0
                      }
                    ]
                  });
                }}>
                Add Testionial
              </Button>
            </Box>
          </Box>
          <Box padding={1.5}>
            <Typography variant="h6">Blog section</Typography>
            <Button variant="contained" color="primary" onClick={() => history.push(`/home/${bizId}/blogs`)}>
              Go to Blogs
            </Button>
          </Box>
          <Box padding={1.5}>
            <Typography variant="h6">New Platform Section</Typography>
          </Box>
          <Box>
            <Box paddingY={1}>
              <Grid container={true}>
                {formik.values.orderPlatform.length > 0 &&
                  formik.values.orderPlatform.map((platform, index) => (
                    <Grid key={platform.id} item={true} xs={12} sm={6} md={4}>
                      <Box marginY={3}>
                        <InfoInputField variant="outlined" formik={formik} name={`orderPlatform.${index}.name`} value={platform.name} type="text" label="Enter Platform title" />
                        <InfoInputField variant="outlined" name={`orderPlatform.${index}.url`} formik={formik} value={platform.url} type="text" multiline={true} rows={3} label="Enter Platform url" />

                        <Box paddingX={1}>
                          <Button
                            type="button"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                              const platformCopy = [...formik.values.orderPlatform];
                              platformCopy.splice(index, 1);
                              formik.setValues({
                                ...formik.values,
                                orderPlatform: platformCopy
                              });
                            }}>
                            Remove
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  if (formik.values.orderPlatform.length >= 5) {
                    snackbar.enqueueSnackbar('You can add only 5 platforms', {
                      variant: 'error'
                    });
                    return;
                  }
                  formik.setValues({
                    ...formik.values,
                    orderPlatform: [
                      ...formik.values.orderPlatform,
                      {
                        id: '',
                        name: '',
                        url: ''
                      }
                    ]
                  });
                }}>
                Add new Platform
              </Button>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button color="primary" size="large" variant="contained" onClick={formik.submitForm} disabled={formik.isSubmitting}>
              Submit
            </Button>
            {formik.isSubmitting && (
              <Box paddingX={1}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default React.memo(WebAuotmationForm);

import { useState, useCallback } from 'react';
import API from './core';
import { useStore } from 'store';

type TMethod =
  | 'get' | 'GET'
  | 'delete' | 'DELETE'
  | 'head' | 'HEAD'
  | 'options' | 'OPTIONS'
  | 'post' | 'POST'
  | 'put' | 'PUT'
  | 'patch' | 'PATCH'
  | 'purge' | 'PURGE'
  | 'link' | 'LINK'
  | 'unlink' | 'UNLINK'


export const useRequest = (endpoint: string, method: TMethod, loader = true) => {
  const { dispatch } = useStore();
  
  const [data, setData] = useState<null | any>(null);

  const [error, setError] = useState<null | any>(null);

  const[loading, setLoading] = useState(true);

  const muteLoader = useCallback(() => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });

  }, [dispatch]);
  
  const sendRequest = useCallback(async (payload?: any) => {
    setLoading(true);

    if (loader) {
      dispatch({
        type: 'LOADER_INCREMENT_COUNT'
      });
    }

    try {
      const result = await API.request({
        url: endpoint,
        method,
        data: payload
      })
      setData(result.data);
      return result.data;
    } catch(err) {
      if (err && err.data) {
        setError(err.data);
      }
    } finally {
      muteLoader();
      setLoading(false);
    }
  }, [dispatch, endpoint, loader, method, muteLoader]);


  return { sendRequest, data, error, loading }
}

export const usePostRequest = (endpoint: string, loader = true) => {
  const method: TMethod = "POST"
  return useRequest(endpoint, method, loader);
}
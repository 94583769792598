import React, { useEffect } from 'react';
import { useStyles } from './style';
import stripe_button from 'assets/img/stripe_button.png';
import { useStore } from 'store';
import { Dialog } from '@lokobee/lokobee-ui';
import { Typography } from '@material-ui/core';
import { useGetStripeConnectUrlForGroceryStore } from 'graphql/hooks/grocery.hooks';

const StripeConnect = () => {
  const classes = useStyles();

  const {
    state: { groceryStoreId }
  } = useStore();

  const { data, getStripeUrl } = useGetStripeConnectUrlForGroceryStore();

  const { getStripeConnectUrlForGrocery: link } = data || { getStripeConnectUrlForGrocery: '' };

  useEffect(() => {
    console.log(data);
    if (data) {
      const { getStripeConnectUrlForGrocery: link } = data || { getStripeConnectUrlForGrocery: '' };

      window.open(link, '_self');
    }
  }, [data]);

  const redirectToStripeConnect = async () => {
    getStripeUrl({
      variables: {
        input: {
          bizId: groceryStoreId
        }
      }
    });
  };

  return (
    <Dialog>
      <img src={stripe_button} height="35px" className={classes.stripeBtn} alt="" />
      <Dialog.DialogContent>
        <Typography variant="body2">Going to a third part site: Stripe for account setup</Typography>
      </Dialog.DialogContent>
      <Dialog.DialogActions saveBtnText="OK" onSave={redirectToStripeConnect} />
    </Dialog>
  );
};

export default StripeConnect;

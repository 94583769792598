import React, { useRef, useState } from 'react';
import InputField from './InputField';
import AddressMapDialog from './AddressMapDialog';
import IAddress from './types';

const AddressInputField = ({ defaultValue, mapSelection = true, validationError, errorMsg, onAddressSelect, onChange, onBlur, ...props }: any) => {
  const [openAddressMapDialog, setOpenAddressMapDialog] = useState(false);

  const [mapAddress, setMapAddress] = useState<IAddress | null>(null);

  const inputFieldref: any = useRef(null);

  const onConfirm = (address: IAddress) => {
    const { onSelect } = inputFieldref.current;
    if (onSelect) {
      onSelect(address);
      setOpenAddressMapDialog(false);
    }
  };

  return (
    <>
      <InputField
        ref={inputFieldref}
        defaultValue={defaultValue}
        mapSelection={mapSelection}
        validationError={validationError}
        errorMsg={errorMsg}
        onAddressSelect={onAddressSelect}
        onChange={onChange}
        onBlur={onBlur}
        setMapAddress={setMapAddress}
        setOpenAddressMapDialog={setOpenAddressMapDialog}
        {...props}
      />

      {openAddressMapDialog && window.google !== null && (
        <AddressMapDialog open={openAddressMapDialog} setOpen={setOpenAddressMapDialog} address={mapAddress} onConfirm={onConfirm} onCancel={() => setOpenAddressMapDialog(false)} />
      )}
    </>
  );
};

export default AddressInputField;

export type DISH_TYPES = 'REGULAR' | 'TABLE_SERVICE' | 'PIZZA' | 'COMBO_PIZZA';
export const DISH_TYPES_VALUES = ['REGULAR', 'TABLE_SERVICE', 'PIZZA', 'COMBO_PIZZA'];

export interface IPizzaBasePrice {
  sizeId: string;
  price: string;
}

export interface IPizzaToppingItem {
  partId: string;
  preset: boolean;
}

export interface IPizzaToppingGroup {
  groupId: string;
  items: IPizzaToppingItem[];
  maxSelect: number;
}

export interface IPizzaDish {
  dishName: string;
  dishType: DISH_TYPES;
  visible: boolean;
  popular: boolean;
  category: string;
  description: string;
  pizzaBasePrice: IPizzaBasePrice[];
  pizzaDefaultCrustId: string;
  pizzaCrustIds: string[];
  pizzaSauce: IPizzaToppingGroup[];
  pizzaCheese: IPizzaToppingGroup[];
  pizzaToppings: IPizzaToppingGroup[];
}

import * as _ from 'lodash';

import { IBizHourSlot } from 'pages/Home/RestaurantDashboard/RestaurantInfo/types';

export const sortBy = (arr: [any]) => {
  for (let i = 0; i < arr.length - 1; i += 1) {
    for (let j = i + 1; j < arr.length; j += 1) {
      const temp = arr[i];

      // If next service starts with pickup
      const isNextTrue = _.chain(arr[j].service)
        .lowerCase()
        .replace(' ', '')
        .startsWith('pickup')
        .value();

      // If current servicee starts with pickup
      const isCurrentTrue = _.chain(arr[i].service)
        .lowerCase()
        .replace(' ', '')
        .startsWith('pickup')
        .value();

      if (isNextTrue && !isCurrentTrue) {
        arr[i] = arr[j];
        arr[j] = temp;
      }
    }
  }

  return arr;
};

export const getCurrentDayTimeInterval: any = (businessHours: any, currentDay: any) => {
  const _currentDay = currentDay.day() === 0 ? 7 : currentDay.day();
  const timings = [];
  if (businessHours) {
    for (const businessHour of businessHours) {
      const isPickUpTime = _.chain(businessHour.service)
        .lowerCase()
        .replace(' ', '')
        .startsWith('pickup')
        .value();

      if (isPickUpTime) {
        if (_.includes(businessHour.daysOfWeek, _currentDay)) {
          const { openHour, openMinute, closeHour, closeMinute } = businessHour;
          timings.push({
            openHour,
            openMinute,
            closeHour,
            closeMinute
          });
        }
      }
    }
  }

  return timings;
};

export const combineDaysOfWeek = (businessHours: any) => {
  let arr: any = [];
  let result: any = [];

  // remove rows with hours data not set
  arr = _.filter(_.sortBy(businessHours, 'openDay'), (obj) => {
    return obj.openHour !== undefined && obj.openMinute !== undefined && obj.closeHour !== undefined && obj.closeMinute !== undefined;
  });

  // group rows with same hours
  arr = _.groupBy(arr, (value) => {
    return value.openHour.toString() + value.openMinute.toString() + value.closeHour.toString() + value.closeMinute.toString();
  });

  for (const el in arr) {
    let count = 0;
    const daysofweek: any = [];
    arr[el].forEach((d: any) => {
      if (daysofweek[count] === undefined) daysofweek.push([d.openDay]);
      else {
        // maintains squence of days
        if (daysofweek[count][daysofweek[count].length - 1] === d.openDay - 1) daysofweek[count].push(d.openDay);
        else {
          // create new sequence if in between days different timing is there
          daysofweek.push([d.openDay]);
          count++;
        }
      }
    });

    // eslint-disable-next-line no-loop-func
    daysofweek.forEach((d: any) => {
      result.push({
        ...arr[el][0],
        openDay: d,
        closeDay: d
      });
    });

    result = _.sortBy(result, 'openDay[0]');
  }
  return result;
};

export const manageRange = (businessHour: IBizHourSlot, newHour: number, newMinute: number, timeType: string) => {
  const newTimeDate = new Date(0, 0, 0, newHour, newMinute, 0);

  const arr = { ...businessHour };
  let newTimeObj: any = {};
  if (timeType === 'open') {
    newTimeObj = {
      openHour: newTimeDate.getHours(),
      openMinute: newTimeDate.getMinutes()
    };
    const closeHour = arr.closeHour;
    const closeMinute = arr.closeMinute;

    if (closeHour !== -1 && closeMinute !== -1) {
      let maxTime = null;
      // if endtime is 00:00  then add new day e.g 5 pm to 12 am
      if (closeHour === 0 && closeMinute === 0) {
        maxTime = new Date(0, 0, newTimeDate.getDate() + 1, closeHour, closeMinute);
      } else {
        maxTime = new Date(0, 0, 0, closeHour, closeMinute);
      }

      if (!(newTimeDate < maxTime)) {
        return false;
      }
    }
  } else if (timeType === 'close') {
    newTimeObj = {
      closeHour: newTimeDate.getHours(),
      closeMinute: newTimeDate.getMinutes()
    };

    const openHour = arr.openHour;
    const openMinute = arr.openMinute;
    if (openHour !== -1 && openMinute !== -1) {
      let minTime = null;

      // if setting endtime as 00:00 then reduce one day from min
      if ((newTimeObj.closeHour === 0 || newTimeObj.closeHour === '00') && (newTimeObj.closeMinute === 0 || newTimeObj.closeMinute === '00')) {
        minTime = new Date(newTimeDate.getFullYear(), newTimeDate.getMonth(), newTimeDate.getDate() - 1, openHour, openMinute);
      } else {
        minTime = new Date(0, 0, 0, openHour, openMinute);
      }

      if (!(newTimeDate > minTime)) {
        return false;
      }
    }
  }

  return newTimeObj;
};

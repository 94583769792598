import moment from 'moment-timezone';
import * as _ from 'lodash';

interface ITimeZone {
  name: string;
  link: string;
}

const timeZones: ITimeZone[] = _.uniqBy(
  _.map(
    moment.tz.names().filter((obj: string) => obj.indexOf('US') > -1),
    (name: string) => {
      return {
        name,
        link: moment.tz._links[name.toLowerCase().replace('/', '_')]
      };
    }
  ),
  'link'
);
let _currentTimeZone = '';
_currentTimeZone = moment.tz
  .guess(true)
  .toLowerCase()
  .replace('/', '_');

const selectedTimeZone = _.filter(timeZones, (obj) => obj.link === _currentTimeZone);
// if current timezone available in list of time zones
if (selectedTimeZone.length > 0) {
  _currentTimeZone = selectedTimeZone[0].name;
} else {
  _currentTimeZone = timeZones[0].name;
}

export const currentTimeZone = _currentTimeZone;

export default timeZones;

import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

export const DragContainerWrapper = SortableContainer(({ children }: any) => {
  return <>{children}</>;
});

export const DragElementWrapper = SortableElement(({ children }: any) => {
  return <>{children}</>;
});

export const DragHandleWrapper = SortableHandle(({ children }: any) => {
  return <>{children}</>;
});

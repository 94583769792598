import * as Yup from 'yup';

const regex = new RegExp(/^[0-9]+(\.[0-9][0-9]?)?$/);

export default Yup.object().shape({
  title: Yup.string().required('Please enter title'),
  items: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Please enter title'),
      price: Yup.string()
        .required('Please enter price')
        .matches(regex, 'Invalid price value.')
    })
  ),
  min: Yup.number().required('Please enter valid email'),
  max: Yup.number().when('isOptional', {
    is: 'false',
    then: Yup.number()
      .required()
      .when('min', (min: number, schema: any) =>
        schema.test({
          test: (max: number) => max >= min,
          message: 'Max should be more than min'
        })
      )
  })
});

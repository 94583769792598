import React, { useEffect } from 'react';
import { Dialog } from '@lokobee/lokobee-ui';
import { Box, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDeleteBlogMutation } from 'graphql/hooks/websiteAutomation.hooks';
import { Articles } from 'generated/types';

interface IProps {
  bizId: string;
  blogId: string;
  version: number | null | undefined;
  openDelete: boolean;
  setItemToDelete?: React.Dispatch<React.SetStateAction<Articles | null>>;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteSucess?: () => void;
}

const DeleteBlogArticle: React.FC<IProps> = ({ bizId, blogId, version, openDelete, setOpenDelete, setItemToDelete, onDeleteSucess }) => {
  const { deleteWebsiteBlog, error: deleteError } = useDeleteBlogMutation(bizId, blogId, version);

  const snackbar = useSnackbar();

  const onClick = async () => {
    if (setItemToDelete) {
      setItemToDelete(null);
    }
    try {
      const { data } = await deleteWebsiteBlog();

      if (data && data.deleteWebsiteBlog) {
        snackbar.enqueueSnackbar('Blog Article deleted successfully !', {
          variant: 'success'
        });
        setOpenDelete(false);
        if (onDeleteSucess) {
          onDeleteSucess();
        }
      } else {
        snackbar.enqueueSnackbar('Delete request failed.', {
          variant: 'error'
        });
      }
    } catch (err) {
      console.log('err', err);
      snackbar.enqueueSnackbar('Delete request failed.', {
        variant: 'error'
      });
    }
  };
  useEffect(() => {
    if (deleteError && deleteError.graphQLErrors && deleteError.graphQLErrors.length > 0) {
      deleteError.graphQLErrors.forEach((gqlError) => {
        snackbar.enqueueSnackbar(gqlError.message, {
          variant: 'error'
        });
      });
    }
  }, [snackbar, deleteError]);
  return (
    <Box paddingX={1}>
      <Dialog open={openDelete} setOpen={setOpenDelete} fullWidth>
        <Dialog.DialogContent>
          <Typography variant="h6">Are you sure you want to delete this Blog Article? </Typography>
        </Dialog.DialogContent>
        <Dialog.DialogActions
          saveBtnText="Yes"
          cancelBtnText="No"
          onSave={onClick}
          onCancel={() => {
            if (setItemToDelete) {
              setItemToDelete(null);
            }
          }}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteBlogArticle;

import React, { useContext } from 'react';
import { Formik, getIn } from 'formik';
import 'react-phone-input-2/lib/material.css';
import { Button, Box, TextField, Typography, InputAdornment, Grid } from '@material-ui/core';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { useStyles } from './style';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { FirebaseContext } from 'fbase';
import validations from './validations';
import { useSnackbar } from 'notistack';
import { useStore } from 'store';
interface IProps extends RouteComponentProps<{}> {}

/**
 * @type {React.Component} - Login form
 */
const LoginForm: React.FC<IProps> = ({ history }) => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const { dispatch } = useStore();

  const { firebase } = useContext(FirebaseContext);

  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };

  const stopLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };

  const _handleSubmit = async (values: any, actions: any) => {
    const { email, password } = values;

    if (firebase) {
      startLoader();
      try {
        await firebase.signInWithEmailAndPassword(email, password);
        stopLoader();
      } catch (e) {
        if (e.code === 'auth/user-not-found') {
          snackbar.enqueueSnackbar('User not found', { variant: 'error' });
        } else if (e.code === 'auth/wrong-password') {
          snackbar.enqueueSnackbar('Invalid Password.', { variant: 'error' });
        } else {
          snackbar.enqueueSnackbar(e.message, { variant: 'error' });
        }
        stopLoader();
        console.log('ERROR IN SIGNING IN');
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={_handleSubmit}
      initialStatus={null}
      validationSchema={validations}>
      {({ handleSubmit, values, handleChange, handleBlur, errors, touched, setFieldValue }) => {
        return (
          <Box className={classes.root}>
            <Typography variant="h5" paragraph={true}>
              Welcome
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Sign in to Digital Restaurant
            </Typography>
            <Box className={classes.wrapper}>
              <form className={classes.formWrapper} onSubmit={handleSubmit}>
                <TextField
                  className={classes.textfield}
                  name="email"
                  value={values.email}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setFieldValue('email', e.target.value.trim());
                  }}
                  onBlur={handleBlur}
                  label="Email"
                  fullWidth={true}
                  error={!!getIn(touched, 'email') && !!getIn(errors, 'email')}
                  helperText={getIn(errors, 'email')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  className={classes.textfield}
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Password"
                  fullWidth={true}
                  error={!!getIn(touched, 'password') && !!getIn(errors, 'password')}
                  helperText={getIn(errors, 'password')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <Box marginBottom={3} width="100%">
                  <Grid container={true} alignItems="center">
                    <Grid item={true} lg={6}>
                      <Typography variant="subtitle2" color="textPrimary">
                        <Link to="/forgotpassword">Forgot Password</Link>
                      </Typography>
                    </Grid>
                    <Grid item={true} lg={6}>
                      <Typography variant="subtitle2" color="textPrimary" align="right">
                        Don't have an account? <Link to="/register">Sign up</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Button color="primary" type="submit" size="large" variant="contained">
                  Login
                </Button>
              </form>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};

export default withRouter(LoginForm);

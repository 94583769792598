import React from 'react';
import { useStore } from 'store';
import { useGetRestaurantById, useActivateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { Dialog } from '@lokobee/lokobee-ui';
import { useSnackbar } from 'notistack';

const RestaurantStatus = () => {
  const {
    state: { restaurant }
  } = useStore();

  const { data } = useGetRestaurantById(restaurant, 'STATUS');

  const { activateRestaurant } = useActivateRestaurantMutation();

  const snackbar = useSnackbar();

  const publishRestaurant = async () => {
    const response = await activateRestaurant({
      variables: {
        input: {
          id: restaurant
        }
      }
    });

    if (!response) {
      snackbar.enqueueSnackbar('Restaurant configuration is not completed.', { variant: 'error' });
    } else {
      snackbar.enqueueSnackbar('Restaurant published successfully.', { variant: 'success' });
    }
  };

  if (data && data.ownerGetRestaurantById) {
    const { onlineStatus, isLokobeePartner } = data.ownerGetRestaurantById;

    if (onlineStatus !== 'ACTIVE' && isLokobeePartner) {
      return (
        <Box>
          <Dialog>
            <Button variant="contained" color="primary" disableElevation={true} size="small">
              Publish
            </Button>
            <Dialog.DialogContent>
              <Typography>Are you sure you want to publish your restaurant ?</Typography>
            </Dialog.DialogContent>
            <Dialog.DialogActions onSave={publishRestaurant} saveBtnText="Yes" cancelBtnText="No" />
          </Dialog>
        </Box>
      );
    }
  }

  return null;
};

export default RestaurantStatus;

import React from 'react';
import { ITabOptions, PaymentOptions, IInitialValueType } from '../types';
import StripeConnect from './StripeConnect';
import PaymentOption from './PaymentOption';
import { Box, Radio, TextField, Typography, Button, Divider } from '@material-ui/core';
import { map, isUndefined, filter } from 'lodash';
import { getIn, FormikProps } from 'formik';
import { useStyles } from '../style';
import visa_icon from 'assets/img/visa_icon.png';
import apple_pay from 'assets/img/apple_pay.svg';
import discover_icon from 'assets/img/discover_icon.svg';
import mastercard_icon from 'assets/img/mastercard_icon.png';
import american_express_icon from 'assets/img/american_express_icon.png';
import google_pay_icon from 'assets/img/google_pay_icon.png';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import AddIcon from '@material-ui/icons/Add';
import { grey } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';

interface IProps {
  formik: FormikProps<IInitialValueType>;
  field: ITabOptions;
}

const Setup = ({ formik, field }: IProps) => {
  const classes = useStyles();

  const { values, touched, errors, handleChange, handleBlur } = formik;

  const { hasStripeAccount, deliverySetting, takeoutWaitMinutes, stripeInProgress } = values;

  const { onlinePaymentTypes, onsitePaymentTypes, tipDefaultPercentIndex, tipPercentOptions } = values[field];

  const { waitMinutes, fees, minDeliveryAmount, freeDeliveryAmount } = deliverySetting;

  const onOnlinePaymentTypeChange = (paymentOption: PaymentOptions) => {
    formik.setValues({
      ...values,
      [field]: {
        ...values[field],
        onlinePaymentTypes:
          onlinePaymentTypes.indexOf(paymentOption) < 0 ? [...onlinePaymentTypes, paymentOption] : filter(onlinePaymentTypes, (payOption: PaymentOptions) => payOption !== paymentOption)
      }
    });
  };

  const onOnsitePaymentTypeChange = (paymentOption: PaymentOptions) => {
    formik.setValues({
      ...values,
      [field]: {
        ...values[field],
        onsitePaymentTypes:
          onsitePaymentTypes.indexOf(paymentOption) < 0 ? [...onsitePaymentTypes, paymentOption] : filter(onsitePaymentTypes, (payOption: PaymentOptions) => payOption !== paymentOption)
      }
    });
  };

  const onDefaultTipChange = (index: number) => {
    formik.setValues({
      ...values,
      [field]: {
        ...values[field],
        tipDefaultPercentIndex: index
      }
    });
  };

  const onDeliveryChargeChange = (newFees: { mile: number; fee: string }[]) => {
    formik.setValues({
      ...values,
      deliverySetting: {
        ...deliverySetting,
        fees: newFees
      }
    });
  };

  return (
    <Box paddingY={2}>
      <Box>
        <Typography variant="h6">Online</Typography>
        <Box paddingY={1}>
          {hasStripeAccount && !isUndefined(hasStripeAccount) ? (
            <Box marginLeft={-3}>
              <PaymentOption custom={true} checked={onlinePaymentTypes.indexOf('ONLINE_STRIPE') > -1} onChange={() => onOnlinePaymentTypeChange('ONLINE_STRIPE')}>
                <Button variant="outlined" color="default" className={classes.successBtn}>
                  Stripe Account Connected
                </Button>
              </PaymentOption>
            </Box>
          ) : stripeInProgress ? (
            <Box>
              <Typography variant="body2">Stripe setup in progress. Please wait for 24 hours and check again. If it stil doesn't work then restart the process again.</Typography>
              <Box paddingY={1}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disableElevation={true}
                  onClick={() => {
                    formik.setValues({
                      ...formik.values,
                      stripeInProgress: false
                    });
                  }}>
                  Restart setup
                </Button>
              </Box>
            </Box>
          ) : (
            <StripeConnect />
          )}
        </Box>
        {/* <Box display="flex" flexWrap="wrap">
          <Box>
            <Box display="flex">
              <Typography variant="h6">Online payment fee</Typography>
              <Switch checked={addOnlinePaymentFee} name="addOnlinePaymentFee" onChange={(_e: any, checked: boolean) => onChange('addOnlinePaymentFee', checked)} color="secondary" />
            </Box>
            {addOnlinePaymentFee && (
              <Box display="flex" paddingY={2}>
                <Box width="150px" marginX={1}>
                  <CurrencyInputField
                    name={`${field}.onlinePaymentFeeThreshold`}
                    label="Threshold"
                    value={onlinePaymentFeeThreshold}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    touched={!!getIn(touched, `${field}.onlinePaymentFeeAmount`)}
                    error={!!getIn(touched, `${field}.onlinePaymentFeeThreshold`) && !!getIn(errors, `${field}.onlinePaymentFeeThreshold`)}
                  />
                </Box>
                <Box width="150px">
                  <CurrencyInputField
                    name={`${field}.onlinePaymentFeeAmount`}
                    label="Fee"
                    value={onlinePaymentFeeAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    touched={!!getIn(touched, `${field}.onlinePaymentFeeAmount`)}
                    error={!!getIn(touched, `${field}.onlinePaymentFeeAmount`) && !!getIn(errors, `${field}.onlinePaymentFeeAmount`)}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box> */}
      </Box>
      <Box>
        <Typography variant="h6">Onsite</Typography>
        <Box marginLeft={-3} display="flex" flexDirection="row" flexWrap="wrap">
          {/* <PaymentOption custom={true} checked={onsitePaymentTypes.indexOf('ONSITE_CASH') > -1} onChange={() => onOnsitePaymentTypeChange('ONSITE_CASH')}>
            <>
              <AttachMoneyIcon />
              <Typography variant="body2">Pay Cash</Typography>
            </>
          </PaymentOption> */}

          <PaymentOption imgSrc={visa_icon} checked={onsitePaymentTypes.indexOf('ONSITE_VISA_CARD') > -1} onChange={() => onOnsitePaymentTypeChange('ONSITE_VISA_CARD')} />
          <PaymentOption imgSrc={mastercard_icon} checked={onsitePaymentTypes.indexOf('ONSITE_MASTER_CARD') > -1} onChange={() => onOnsitePaymentTypeChange('ONSITE_MASTER_CARD')} />
          <PaymentOption
            imgSrc={american_express_icon}
            checked={onsitePaymentTypes.indexOf('ONSITE_AMERICAN_EXPRESS_CARD') > -1}
            onChange={() => onOnsitePaymentTypeChange('ONSITE_AMERICAN_EXPRESS_CARD')}
          />
          <PaymentOption imgSrc={apple_pay} checked={onsitePaymentTypes.indexOf('ONSITE_APPLE_PAY') > -1} onChange={() => onOnsitePaymentTypeChange('ONSITE_APPLE_PAY')} />
          <PaymentOption imgSrc={google_pay_icon} checked={onsitePaymentTypes.indexOf('ONSITE_GOOGLE_PAY') > -1} onChange={() => onOnsitePaymentTypeChange('ONSITE_GOOGLE_PAY')} />
          <PaymentOption imgSrc={discover_icon} checked={onsitePaymentTypes.indexOf('ONSITE_DISCOVER_CARD') > -1} onChange={() => onOnsitePaymentTypeChange('ONSITE_DISCOVER_CARD')} imgHeight="25px" />
        </Box>
        <Divider className={classes.divider} />
      </Box>
      <Box>
        <Typography variant="h6">Tip Options</Typography>
        <Box display="flex" flexWrap="wrap" paddingY={2}>
          {map(tipPercentOptions, (tipOption, index: number) => {
            return (
              <Box width="150px" display="flex" alignItems="center" marginY={1} marginX={index === 0 ? 0 : 1} key={index}>
                <Radio checked={tipDefaultPercentIndex === index} onChange={() => onDefaultTipChange(index)} />
                <TextField
                  value={tipOption}
                  type="number"
                  name={`${field}.tipPercentOptions[${index}]`}
                  error={!!getIn(touched, `${field}.tipPercentOptions[${index}]`) && !!getIn(errors, `${field}.tipPercentOptions[${index}]`)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <Box paddingLeft={1}>
                        <h3>%</h3>
                      </Box>
                    )
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      {field === 'deliveryPaymentSetting' && (
        <>
          <Divider className={classes.divider} />
          <Box>
            <Typography variant="h6">Delivery Settings</Typography>

            <Box display="flex" flexWrap="wrap" paddingY={2}>
              <Box width="200px" margin={1}>
                <TextField
                  type="number"
                  name={`deliverySetting.waitMinutes`}
                  label="Delivery Time (mins)"
                  value={waitMinutes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  error={!!getIn(touched, `deliverySetting.waitMinutes`) && !!getIn(errors, `deliverySetting.waitMinutes`)}
                />
              </Box>
              <Box width="200px" margin={1}>
                <CurrencyInputField
                  name={`deliverySetting.freeDeliveryAmount`}
                  label="Free Delivery Amount"
                  value={freeDeliveryAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  touched={!!getIn(touched, `deliverySetting.freeDeliveryAmount`)}
                  error={!!getIn(touched, `deliverySetting.freeDeliveryAmount`) && !!getIn(errors, `deliverySetting.freeDeliveryAmount`)}
                />
              </Box>
              <Box width="200px" margin={1}>
                <CurrencyInputField
                  name={`deliverySetting.minDeliveryAmount`}
                  label="Min Delivery Amount"
                  value={minDeliveryAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  touched={!!getIn(touched, `deliverySetting.minDeliveryAmount`)}
                  error={!!getIn(touched, `deliverySetting.minDeliveryAmount`) && !!getIn(errors, `deliverySetting.minDeliveryAmount`)}
                />
              </Box>
            </Box>

            <Box width="fit-content">
              <Box width="100%" bgcolor={grey[100]} className={classes.tableHeaderContainer}>
                <Box display="flex" padding={2}>
                  <Box flexGrow={1}>
                    <Typography variant="h6">Delivery Charge</Typography>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={() =>
                      onDeliveryChargeChange([
                        ...fees,
                        {
                          mile: 0,
                          fee: '0.00'
                        }
                      ])
                    }>
                    Add
                  </Button>
                </Box>
              </Box>
              <Box padding={1} display="flex" alignItems="center" borderColor={grey[500]} border={1} borderBottom={0}>
                <Box paddingX={1} flexGrow={1}>
                  <Typography variant="body2">Delivery Range (Miles)</Typography>
                </Box>
                {map(fees, ({ mile }, index) => (
                  <Box display="flex" alignItems="center" key={index}>
                    <Box width="125px" margin={1}>
                      <TextField
                        type="number"
                        name={`deliverySetting.fees[${index}].mile`}
                        value={mile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={
                          (!!getIn(touched, `deliverySetting.fees[${index}].mile`) && !!getIn(errors, `deliverySetting.fees[${index}].mile`)) ||
                          (!!getIn(touched, `deliverySetting.fees[${index}].mile`) && typeof getIn(errors, `deliverySetting.fees`) === 'string' && !!getIn(errors, `deliverySetting.fees`))
                        }
                        InputProps={{
                          endAdornment: (
                            <CancelIcon
                              className={classes.cancelIcon}
                              onClick={() => {
                                const _fees = [...fees];
                                _fees.splice(index, 1);
                                onDeliveryChargeChange(_fees);
                              }}
                            />
                          )
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box padding={1} display="flex" alignItems="center" borderColor={grey[500]} border={1} className={classes.tableBottomContainer}>
                <Box paddingX={1} flexGrow={1}>
                  <Typography variant="body2">Delivery Charge</Typography>
                </Box>
                {map(fees, ({ fee }, index) => (
                  <Box width="125px" margin={1} key={index}>
                    <CurrencyInputField
                      name={`deliverySetting.fees[${index}].fee`}
                      value={fee}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      touched={!!getIn(touched, `deliverySetting.fees[${index}].fee`)}
                      error={!!getIn(touched, `deliverySetting.fees[${index}].fee`) && !!getIn(errors, `deliverySetting.fees[${index}].fee`)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {field === 'takeoutPaymentSetting' && (
        <>
          <Divider className={classes.divider} />
          <Box>
            <Typography variant="h6">Takeout Settings</Typography>
          </Box>
          <Box width="200px" marginX={1} marginY={2}>
            <TextField
              type="number"
              name={`takeoutWaitMinutes`}
              label="Waiting Time (mins)"
              value={takeoutWaitMinutes}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              error={!!getIn(touched, `takeoutWaitMinutes`) && !!getIn(errors, `takeoutWaitMinutes`)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Setup;

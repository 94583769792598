import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  wrapper: { width: '100%' },

  formWrapper: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 8)
  },
  textfield: {
    // marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    width: '100%'
  },
  fullImageWidth: {
    width: '100%'
  }
}));

import firebase from 'firebase/app';
import 'firebase/auth';
import { config } from './config';
import 'firebase/database';

interface IFirebase {
  getAuth: () => any;
}

class Firebase implements IFirebase {
  constructor() {
    try {
      if (!firebase.apps.length) {
        firebase.initializeApp(config);
      }
    } catch (e) {
      console.log(e);
    }
  }

  getAuth = () => {
    return firebase.auth;
  };

  confirmOtp = async (code: string) => {
    try {
      if ((window as any).confirmationResult) {
        const result = await (window as any).confirmationResult.confirm(code);
        const { user } = result;

        return user;
      } else {
        throw Error();
      }
    } catch (e) {
      throw Error('Confirmation Error');
    }
  };

  /**
   * Render recaptch
   */
  initializeRecatpcha = () => {
    const { auth } = firebase;

    if (auth) {
      (window as any).recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha', {
        size: 'normal',
        callback: () => {},
        'expired-callback': () => {}
      });

      if (window) {
        (window as any).recaptchaVerifier.render().then((widgetId: any) => {
          (window as any).recaptchaWidgetId = widgetId;
        });
      }
    }
  };

  signInWithEmailAndPassword = (email: string, password: string) => {
    const { auth } = firebase;

    return auth().signInWithEmailAndPassword(email, password);
  };

  /**
   * This method signs in user in firebase using phone number.
   *
   * @param phone - Phone number
   */
  signInWithFirebase = (phone: string) => {
    const { auth } = firebase;

    return auth()
      .signInWithPhoneNumber(phone, (window as any).recaptchaVerifier)
      .then((confirmationResult: any) => {
        (window as any).confirmationResult = confirmationResult;
      });
  };

  /**
   * Sign out current user
   */
  signOut = () => {
    const { auth } = firebase;
    return auth().signOut();
  };

  getRealtimeDB = () => {
    return firebase.database();
  };
}

export default Firebase;

import { Box, Button, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useAddBroadcastPhoneNumbers, useGetTextBroadcastPhoneNumbers } from 'graphql/hooks/textMarketing.hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CSVReader from 'react-csv-reader';
import { useStyle } from '../style';
import { arrayEquals, parseCsv } from './utils';

interface IProps {
  restaurantId: string | null;
}
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_')
};
const UploadUsers: React.FC<IProps> = ({ restaurantId }) => {
  const snackbar = useSnackbar();

  const [defaultItems, setDefaultItems] = useState<string[]>([]);
  const [newlyAddedItems, setNewlyAddedItems] = useState<string[]>([]);
  const { addBroadcastPhoneNumbers } = useAddBroadcastPhoneNumbers();
  const { getTextBroadcastPhoneNumbers } = useGetTextBroadcastPhoneNumbers(restaurantId);
  const [isNewItems, setIsNewItems] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const onDataUpload = (uploadedData: string[]) => {
    const { items, errorRows } = parseCsv(uploadedData, defaultItems);
    if (items.length > 0 && arrayEquals(defaultItems, items) === false) {
      const newItems = items.filter((item) => !defaultItems.includes(item));
      setNewlyAddedItems(newItems);
      setIsNewItems(true);
      setDefaultItems(items);
    } else {
      setIsNewItems(false);
    }
    setErrors(errorRows);
  };

  useEffect(() => {
    if (getTextBroadcastPhoneNumbers && getTextBroadcastPhoneNumbers.length > 0) {
      setDefaultItems(getTextBroadcastPhoneNumbers);
    }
  }, [getTextBroadcastPhoneNumbers]);
  const handleUploadNumbers = async () => {
    const response = await addBroadcastPhoneNumbers({
      variables: {
        input: {
          bizId: restaurantId,
          phoneNumbers: defaultItems
        }
      }
    });

    if (!response.errors) {
      snackbar.enqueueSnackbar('Phone numbers uploaded successfully!', {
        variant: 'success'
      });
    } else {
      snackbar.enqueueSnackbar('Oops!, something went wrong!', {
        variant: 'error'
      });
    }
  };
  const classes = useStyle();
  return (
    <Box flexDirection={'column'} width={'100%'}>
      <Box display="flex" justifyContent={'space-between'}>
        <Box>
          <CSVReader cssClass="react-csv-input" onFileLoaded={onDataUpload} parserOptions={papaparseOptions} />

          <Typography variant="body2" align="center">
            Make sure there is 'phone_number' field in file
          </Typography>
        </Box>
        <Box>
          {defaultItems && defaultItems.length > 0 ? (
            <Button variant="contained" disabled={!isNewItems} onClick={handleUploadNumbers} color="primary">
              Submit
            </Button>
          ) : null}
        </Box>
      </Box>
      {errors.length > 0 && (
        <Box paddingY={1}>
          {errors.map((err, index) => (
            <Typography key={index} variant="body2" color="error">
              {err}
            </Typography>
          ))}
        </Box>
      )}
      {defaultItems && defaultItems.length > 0 && (
        <Box marginBottom={2}>
          {newlyAddedItems.length ? (
            <Typography variant="subtitle1">
              New entries : <strong>{newlyAddedItems.length}</strong>
            </Typography>
          ) : null}
          <Typography variant="subtitle1">
            Total phone numbers : <strong>{defaultItems.length}</strong>
          </Typography>
        </Box>
      )}
      <Divider className={classes.divider} />
      <Box width={'25%'}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Phone number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {defaultItems.map((singlePhoneNumber: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>{singlePhoneNumber}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default UploadUsers;

import { Box, Grid, Typography, Button, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../style';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as _ from 'lodash';
import { ITableSet } from '../types';
import { useDownloadTableCards, useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { useContext } from 'react';
import { FirebaseContext } from 'fbase';
import { ChangeEvent } from 'react';
import { useStore } from 'store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NoInfo from '../NoInfo';
interface IProps {
  restaurantId: string;
}
const TableCardMSP = ({ restaurantId }: IProps) => {
  const { dispatch } = useStore();

  const classes = useStyles();
  const { data } = useGetRestaurantById(restaurantId, 'TABLE');

  const { downloadTablesCard, tableCardZipUrl } = useDownloadTableCards();
  const { firebase } = useContext(FirebaseContext);
  const [initialValues, setinitialValues] = useState<ITableSet[]>([]);

  const [selectedTable, setSelectedTable] = useState<ITableSet | null>();

  const [selectedImages, setSelectedImages] = useState<Array<string>>([]);
  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { tables } = data.ownerGetRestaurantById;

      if (tables) {
        setinitialValues(
          _.map(tables, (table: any) => {
            return { tableName: table.tableName, seats: table.seats, cardURL: table.cardImg.url };
          })
        );
      }
    }
  }, [data]);
  useEffect(() => {
    setSelectedTable(initialValues[0]);
  }, [initialValues]);

  const handleSelectPreview = (e: any) => {
    setSelectedTable(null);
    let tempTable = initialValues.filter((tableItem: ITableSet) => tableItem.tableName === e);
    if (tempTable.length > 0) {
      setSelectedTable(tempTable[0]);
    }
  };
  const handleMultiDownload = () => {
    downloadTablesCard({
      variables: {
        input: {
          bizId: restaurantId,
          tableNames: selectedImages
        }
      }
    });
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };
  useEffect(() => {
    if (tableCardZipUrl && tableCardZipUrl.downloadTablesCard && tableCardZipUrl.downloadTablesCard.url && tableCardZipUrl.downloadTablesCard.id) {
      const path = `/download_table/${tableCardZipUrl.downloadTablesCard.id}`;
      firebase
        ?.getRealtimeDB()
        .ref(path)
        .on('value', (val) => {
          const dbStatusValue = val.val();
          if (dbStatusValue && dbStatusValue.status && dbStatusValue.status === 'done') {
            window.open(tableCardZipUrl.downloadTablesCard.url, '_self');
            setSelectedImages([]);
            dispatch({
              type: 'LOADER_DECREMENT_COUNT'
            });
            firebase
              ?.getRealtimeDB()
              .ref(path)
              .off('value');
          }
        });
    }
  }, [dispatch, firebase, tableCardZipUrl]);
  if (data && data.ownerGetRestaurantById) {
    return initialValues && initialValues.length > 0 ? (
      <Grid container>
        <Grid item xs={6} lg={6} md={6}>
          <Box paddingLeft={1} width={'60%'}>
            <Box paddingY={1}>
              <Typography variant="h6"> Tables </Typography>
            </Box>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.borderRight}>
                    Table No.
                  </TableCell>
                  <TableCell align="center" className={classes.borderRight}>
                    Preview
                  </TableCell>
                  <TableCell align="center">
                    Select cards
                    <Checkbox
                      onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        if (checked) {
                          let allTableNames: string[] = [];
                          initialValues.map((tableItem: ITableSet, index: number) => {
                            allTableNames.push(tableItem.tableName);
                          });
                          setSelectedImages(allTableNames);
                        } else {
                          setSelectedImages([]);
                        }
                      }}
                      name="checked"
                      color="primary"
                      checked={selectedImages.length === initialValues.length}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialValues.map((tableItem: ITableSet, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center" className={classes.borderRight + ' ' + (tableItem.tableName === selectedTable?.tableName ? classes.selectedTableCell : '')} size="small">
                        {tableItem.tableName}
                      </TableCell>
                      <TableCell size="small" align="center" className={classes.borderRight + ' ' + (tableItem.tableName === selectedTable?.tableName ? classes.selectedTableCell : '')}>
                        <IconButton
                          onClick={() => {
                            handleSelectPreview(tableItem.tableName);
                          }}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell size="small" align="center" className={tableItem.tableName === selectedTable?.tableName ? classes.selectedTableCell : ''}>
                        <Checkbox
                          onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            if (checked) {
                              if (!selectedImages.includes(tableItem.tableName)) {
                                let tempSelectedItems = [...selectedImages];
                                tempSelectedItems?.push(tableItem.tableName);
                                setSelectedImages(tempSelectedItems);
                              }
                            } else {
                              let tempSelectedItems = [...selectedImages];
                              const index = tempSelectedItems?.indexOf(tableItem.tableName);
                              if (selectedImages.includes(tableItem.tableName)) {
                                tempSelectedItems?.splice(index, 1);
                              }
                              setSelectedImages(tempSelectedItems);
                            }
                          }}
                          name="checked"
                          checked={selectedImages.includes(tableItem.tableName)}
                          color="primary"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box marginTop={2}>
              <Button variant="contained" disabled={!(selectedImages && selectedImages.length > 0)} color="secondary" disableElevation={true} onClick={handleMultiDownload} startIcon={<GetAppIcon />}>
                Download {selectedImages.length === initialValues.length ? 'All' : 'Selected'}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <Box display={'flex'} alignItems="center" justifyContent={'center'}>
            {selectedTable && (
              <Box className={classes.cardContainer} width="100%" height={selectedTable ? 'auto' : '500px'}>
                <img src={selectedTable.cardURL} className={classes.cardStyle} height="100%" width="100%" alt="StoreCard" />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    ) : (
      <NoInfo text="You need to add tables to generate Table cards" restaurantId={restaurantId} />
    );
  } else {
    return <></>;
  }
};
export default TableCardMSP;

export const menuPageInitialState = {
  restaurantMenu: {
    categories: [],
    activeCategory: 'All'
  }
};

export const menuPageActions = {
  SET_ACTIVE_CATEGORY: (state: any, payload: any) => ({ ...state, restaurantMenu: { ...state.restaurantMenu, activeCategory: payload } }),
  SET_CATEGORY_LIST: (state: any, payload: any) => ({
    ...state,
    restaurantMenu: {
      ...state.restaurantMenu,
      categories: payload
    }
  })
};

export default {};

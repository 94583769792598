import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  previewContainer: {
    justifyContent: 'center'
  },
  expansionPanel: {
    width: '100%',
    margin: theme.spacing(2, 0)
  },
  expansionPanelHeader: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '5px 5px 0 0',

    '& .MuiExpansionPanelSummary-content': {
      alignItems: 'center'
    }
  },
  expansionPanelDetails: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1)
    }
  },
  editBtn: {
    marginLeft: 'auto'
  },
  profileDetailsContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  profileDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
    flexWrap: 'wrap'
  },
  serviceTable: {
    padding: theme.spacing(1)
  },
  imageBox: {
    margin: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
    '&:hover': {
      boxShadow: theme.shadows[6]
    }
  },
  defaultImageBox: {
    border: 'dashed',
    margin: theme.spacing(1)
  },
  image: {
    width: '100%',
    height: '100%'
  },
  table: {
    width: '50%',
    margin: 'auto',
    border: 'solid 1px ' + theme.palette.grey[200],
    '& td': {
      padding: theme.spacing(1)
    },
    '& thead': {
      backgroundColor: theme.palette.grey[300]
    }
  }
}));

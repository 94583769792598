import { gql } from 'apollo-boost';
import { BizCard, BIZCARD_BACKSIDE_IMG, MenuCard } from 'graphql/fragments/cards.fregments';
import { Profile, BusinessHours, Table, Announcement, RewardSetting, Gallery, PaymentSettings } from 'graphql/fragments/restaurant.fragments';

export default gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      couponCredit
      rewardSetting {
        minStartPoints
        maxRedeemPointsPerUsage
        minRedeemPurchase
        pricePointRatio
      }
    }
  }
`;

export const getRestaurantProfile = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      couponCredit
      ...Profile
    }
  }
  ${Profile}
`;

export const getRestaurantBusinsessHours = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...BusinessHours
    }
  }
  ${BusinessHours}
`;

export const getRestaurantTables = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...Table
    }
  }
  ${Table}
`;

export const getCardUrl = gql`
  query getTable($input: GetTableInput!) {
    getTable(input: $input) {
      tableName
      cardImg {
        key
        bucket
      }
    }
  }
`;

export const getRestaurantGallery = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...Gallery
    }
  }
  ${Gallery}
`;

export const getRestaurantAnnouncement = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...Announcement
    }
  }
  ${Announcement}
`;

export const getRestaurantRewards = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...RewardSetting
    }
  }
  ${RewardSetting}
`;

export const getRestaurantPaymentSettings = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...PaymentSettings
      ...BusinessHours
    }
  }
  ${BusinessHours}
  ${PaymentSettings}
`;

export const getRestaurantStatus = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      onlineStatus
      isLokobeePartner
      isTextMarketingEnabled
      isTextMarketingRequested
      cryptoCouponEnabled
    }
  }
`;

export const getRestaurant = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...Profile
      ...BusinessHours
      ...Table
      ...Gallery
      ...BizCard
      ...BIZCARD_BACKSIDE_IMG
    }
  }
  ${Profile}
  ${BusinessHours}
  ${Table}
  ${Gallery}
  ${BizCard}
  ${BIZCARD_BACKSIDE_IMG}
`;

export const getRestaurantStoreCard = gql`
  query getStoreCard($input: GetStoreCardInput!) {
    getStoreCard(input: $input) {
      id
      cardImg {
        url
      }
    }
  }
`;

export const getMenuCard = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...MenuCard
    }
  }
  ${MenuCard}
`;

export const getBizCard = gql`
  query ownerGetRestaurantById($input: OwnerGetRestaurantByIdInput!) {
    ownerGetRestaurantById(input: $input) {
      id
      ...BizCard
    }
  }
  ${BizCard}
`;

import React, { useState, useEffect, useImperativeHandle } from 'react';
import { ISize, ICrust } from '../types';
import { Box, Button, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
import { map } from 'lodash';
import { useStyles } from './style';
import Row from './Row';
import { DragContainerWrapper, DragElementWrapper } from 'components/DragDrop';
import { SortEnd } from 'react-sortable-hoc';

interface IProps {
  sizes: ISize[];
  initialCrusts: ICrust[];
  allowDelete: boolean;
  updateAPI: (sizes: ICrust[]) => Promise<any>;
}

const newRow: ICrust = {
  title: '',
  description: '',
  prices: []
};

const Crust = React.forwardRef(({ sizes, initialCrusts, updateAPI, allowDelete }: IProps, ref) => {
  const classes = useStyles();

  const [adding, setAdding] = useState(false);

  const [crusts, setCrusts] = useState<ICrust[]>([]);

  useEffect(() => {
    setCrusts(initialCrusts);
  }, [initialCrusts]);

  const onAdd = (newCrust: ICrust) => {
    setCrusts([...crusts, newCrust]);
    setAdding(false);
  };
  const onEdit = (updatedCrust: ICrust, index: number) => {
    const crustsCopy = [...crusts];
    crustsCopy[index] = updatedCrust;
    setCrusts(crustsCopy);
  };
  const onDelete = (index: number) => {
    const crustsCopy = [...crusts];
    crustsCopy.splice(index, 1);
    setCrusts(crustsCopy);
  };

  const submitForm = async () => {
    if (crusts.length) {
      await updateAPI(crusts);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  const onDragEnd = (sortResult: SortEnd) => {
    const { oldIndex, newIndex } = sortResult;

    if (oldIndex === newIndex) {
      // No dragging
      return;
    }
    const crustsCopy = [...crusts];
    const value = { ...crusts[oldIndex] };

    if (newIndex < oldIndex) {
      /*
       * All indexes after newIndex incremented along with oldIndex
       * So add at newIndex
       * Remove at oldIndex + 1
       * */
      crustsCopy.splice(newIndex, 0, value);
      crustsCopy.splice(oldIndex + 1, 1);
    } else {
      /*
       * Indexes before newIndex should be decremented
       * So add at newIndex + 1
       * Remove at oldIndex
       * */
      crustsCopy.splice(newIndex + 1, 0, value);
      crustsCopy.splice(oldIndex, 1);
    }

    setCrusts(crustsCopy);
  };

  return (
    <DragContainerWrapper useDragHandle={true} onSortEnd={onDragEnd} axis="y">
      <Box display="flex" justifyContent="center" className={classes.pageContainer}>
        <Box className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2} align="center" className={classes.cell} style={{ width: '10%' }}>
                  Crust
                </TableCell>
                <TableCell colSpan={sizes.length + 1} align="center">
                  Sizes
                </TableCell>
              </TableRow>
              <TableRow>
                {map(sizes, ({ title }, index) => {
                  return (
                    <TableCell key={index} align="center" style={{ width: `${75 / sizes.length}%` }}>
                      {title}
                    </TableCell>
                  );
                })}
                <TableCell style={{ width: '15%' }}>
                  <Button variant="contained" color="primary" disableElevation={true} size="small" onClick={() => setAdding(true)}>
                    Add new crust
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adding && <Row allowDelete={allowDelete} sizes={sizes} rowData={newRow} rows={crusts} action="new" onAdd={onAdd} onCancel={() => setAdding(false)} />}
              {map(crusts, (crust, index) => {
                return (
                  <DragElementWrapper key={`${index}-${Date.now()}`} index={index}>
                    <Row allowDelete={allowDelete} sizes={sizes} rowData={crust} rows={crusts} action="edit" onEdit={(updatedCrust) => onEdit(updatedCrust, index)} onDelete={() => onDelete(index)} />
                  </DragElementWrapper>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </DragContainerWrapper>
  );
});

export default Crust;

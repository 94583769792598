import React from 'react';
import { Box, withStyles, useMediaQuery, Tabs, Tab, useTheme } from '@material-ui/core';
import { map, findIndex } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ISortableData } from 'generated/custom';
import DeleteCategory from './DeleteCategory';
import UpdateCategory from './UpdateCategory';
import { getUrlQuery } from 'pages/Home/RestaurantDashboard/RestaurantHome/util';

interface IProps {
  data: ISortableData[] | null;
}

const RestaurantCategories: React.FC<IProps> = ({ data }) => {
  const history = useHistory();

  const location = useLocation();

  const { restaurantId } = useParams<any>();

  const activeCategory = getUrlQuery(location, 'category') || 'All';

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const theme = useTheme();

  const _onClick = (category: string) => {
    history.push({ pathname: `/home/${restaurantId}`, search: `?category=${category}` });
  };

  const StyledTabs = withStyles({
    indicator: {
      display: 'none'
    }
  })((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

  const StyledTab = withStyles((theme) => ({
    root: {
      textAlign: 'left',

      [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
        flex: 1
      }
    },
    selected: {
      backgroundColor: theme.palette.primary.main
    },
    wrapper: {
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        textTransform: 'none'
      }
    }
  }))((props: any) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" bgcolor={props.selected ? theme.palette.primary.main : ''}>
        <Tab {...props} />
        {!!props.id && (
          <>
            <UpdateCategory categoryId={props.id ? props.id : ''} title={props.label} />
            <DeleteCategory categoryId={props.id ? props.id : ''} activeCategory={activeCategory} />
          </>
        )}
      </Box>
    );
  });

  if (data) {
    const activeCategoryIndex = findIndex(data, (category) => {
      return category.id === activeCategory;
    });

    return (
      <StyledTabs
        value={activeCategoryIndex < 0 ? 'All' : activeCategory}
        variant={isDesktop ? 'standard' : 'scrollable'}
        scrollButtons={isDesktop ? 'off' : 'on'}
        orientation={isDesktop ? 'vertical' : 'horizontal'}>
        <StyledTab label="All" value={'All'} selected={'All' === activeCategory} onClick={() => _onClick('All')} />
        {map(data, (category) => {
          return <StyledTab key={category.id} id={category.id} value={category.id} label={category.title} selected={category.id === activeCategory} onClick={() => _onClick(category.id)} />;
        })}
      </StyledTabs>
    );
  }

  return null;
};

export default RestaurantCategories;

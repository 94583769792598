import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  headerDivider: {
    margin: theme.spacing(2, 0),
    height: '2px'
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: 'black'
  },
  successBtn: {
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  tableHeaderContainer: {
    borderRadius: '5px 5px 0 0'
  },
  tableBottomContainer: {
    borderRadius: '0 0 5px 5px'
  },
  cancelIcon: {
    color: theme.palette.error.main
  }
}));

import { GET_ORDERS_REPORT, GET_ORDER_BY_ORDER_NUMBER, GET_ORDER_SERVICE_CHARGES, GET_REPORT_ORDER } from 'graphql/query';
import { useLazyQueryWithLoader, useQueryWithLoader } from 'hooks/loader';
import moment from 'moment';
import { useState, useEffect } from 'react';

export const useGetOrdersReports = (restaurantId: string | null) => {
  const [hasMore, setHasMore] = useState(false);

  const [cursor, setCursor] = useState(null);

  const [fetching, setFetching] = useState(true);

  const [fetchOrders, { data, error, fetchMore, variables }] = useLazyQueryWithLoader(GET_ORDERS_REPORT);

  useEffect(() => {
    if (data && data.getBizOrderReport && !error) {
      const { getBizOrderReport } = data;

      const { pageCursor } = getBizOrderReport;

      setHasMore(!!pageCursor);
      setCursor(pageCursor);

      setFetching(false);
    }
    if (error) {
      setFetching(false);
      console.log('ERROR: ' + error);
    }
  }, [data, error]);

  const fetchMoreData = async () => {
    if (hasMore) {
      try {
        setFetching(true);
        await fetchMore({
          query: GET_ORDERS_REPORT,
          variables: {
            input: {
              bizId: restaurantId,
              startDate: variables.input.startDate,
              endDate: variables.input.endDate,
              count: variables.input.count,
              pageCursor: cursor,
              orderType: variables.input.orderType
            }
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            const { pageCursor } = fetchMoreResult.getBizOrderReport;
            const newCursor = pageCursor;

            return {
              getBizOrderReport: {
                orders: [...previousResult.getBizOrderReport.orders, ...fetchMoreResult.getBizOrderReport.orders],
                pageCursor: newCursor,
                __typename: previousResult.getBizOrderReport.__typename
              }
            };
          }
        });
      } catch (e) {
        setFetching(false);
        console.log(e);
      }
    }
  };

  return {
    data,
    fetchOrders,
    fetchMoreData,
    fetching,
    hasMore,
    error
  };

  // variables: {
  //   input: {
  //     bizId: restaurantId,
  //     count: 10,
  //     startDate: moment()
  //       .subtract('days', 7)
  //       .hours(0)
  //       .minute(0)
  //       .second(0)
  //       .valueOf(),
  //     endDate: moment().valueOf()
  //   }
  // },
};

export const useFindOrder = () => {
  const [findOrder, { data, error, loading, called }] = useLazyQueryWithLoader(GET_ORDER_BY_ORDER_NUMBER, {
    fetchPolicy: 'network-only'
  });

  return {
    findOrder,
    data,
    loading,
    error,
    called
  };
};

export const useFindReportOrder = () => {
  const [findOrder, { data, error, loading, called }] = useLazyQueryWithLoader(GET_REPORT_ORDER, {
    fetchPolicy: 'network-only'
  });

  return {
    findOrder,
    data,
    loading,
    error,
    called
  };
};

export const useGetOrderServiceCharges = (bizId: string, orderId: string) => {
  const { loading, data } = useQueryWithLoader(GET_ORDER_SERVICE_CHARGES, {
    skip: !bizId || !orderId,
    variables: {
      input: {
        bizId,
        orderId
      }
    }
  });

  return {
    loading,
    data
  };
};

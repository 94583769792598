import {
  createGroceryStore as createGroceryStoreMutation,
  updateGroceryStoreProfile,
  updateGroceryStoreBusinessHours,
  updateGroceryStoreAnnouncement,
  updateGroceryStoreRewardSetting,
  updateGroceryStoreGallery,
  updateGroceryStorePaymentSettings,
  deleteGroceryStore as deleteGroceryStoreMutation,
  updateGroceryStoreItems as updateGroceryStoreItemsMutation,
  activateGroceryStoreMutation
} from 'graphql/mutations/grocery.mutation';
import getGroceryStoreById, {
  getGroceryStoreBusinsessHours,
  getGroceryStoreProfile,
  getGroceryStoreGallery,
  getGroceryStoreAnnouncement,
  getGroceryStoreRewards,
  getGroceryStore,
  getGroceryStorePaymentSettings,
  getGroceryStoreStatus
} from 'graphql/query/grocery/getGroceryStoreById.query';
import { Profile, BusinessHours, Announcement, RewardSetting, Gallery, PaymentSettings, Status } from 'graphql/fragments/groceryStore.fragments';
import { useLazyQueryWithLoader, useMutationWithLoader, useQueryWithLoader } from 'hooks/loader';
import { GET_GROCERY_STORE_BY_OWNER, GET_STRIPE_CONNECT_URL_GROCERY } from 'graphql/query';
import { useEffect, useState } from 'react';
import { getGroceryItemsByStoreId } from 'graphql/query/grocery/getGroceryItems.query';
import useActiveGroceryStore from 'hooks/grocery/useActiveGroceryStore';
import { MutationFunctionOptions } from '@apollo/react-common';
import { ExecutionResult } from 'apollo-boost';
import { getGroceryDefaultItems } from 'graphql/query/grocery/getGroceryDefaultItems.query';
type IGroceryStoreQueryOptions = 'ID' | 'PROFILE' | 'BUSINESS_HOURS' | 'GALLERY' | 'ANNOUNCEMENT' | 'REWARD_SETTING' | 'PAYMENT_SETTINGS' | 'ALL' | 'STATUS';

const getQueryMapper = (type: IGroceryStoreQueryOptions) => {
  const mapper = {
    ID: getGroceryStoreById,
    PROFILE: getGroceryStoreProfile,
    BUSINESS_HOURS: getGroceryStoreBusinsessHours,
    GALLERY: getGroceryStoreGallery,
    ANNOUNCEMENT: getGroceryStoreAnnouncement,
    REWARD_SETTING: getGroceryStoreRewards,
    ALL: getGroceryStore,
    PAYMENT_SETTINGS: getGroceryStorePaymentSettings,
    STATUS: getGroceryStoreStatus
  };

  return mapper[type];
};

const getMutationMapper = (type: IGroceryStoreQueryOptions) => {
  const mapper: any = {
    PROFILE: updateGroceryStoreProfile,
    BUSINESS_HOURS: updateGroceryStoreBusinessHours,
    ANNOUNCEMENT: updateGroceryStoreAnnouncement,
    REWARD_SETTING: updateGroceryStoreRewardSetting,
    GALLERY: updateGroceryStoreGallery,
    PAYMENT_SETTINGS: updateGroceryStorePaymentSettings
  };

  return mapper[type];
};

const getFragmentMapper = (type: IGroceryStoreQueryOptions) => {
  const mapper: any = {
    PROFILE: Profile,
    BUSINESS_HOURS: BusinessHours,
    ANNOUNCEMENT: Announcement,
    REWARD_SETTING: RewardSetting,
    GALLERY: Gallery,
    PAYMENT_SETTINGS: PaymentSettings,
    STATUS: Status
  };

  return mapper[type];
};

export const useCreateGroceryStoreMutation = () => {
  const [createGroceryStore, { data, loading, error }] = useMutationWithLoader(createGroceryStoreMutation, {
    update: (cache, { data: { createGroceryStore: newData } }) => {
      const { getGroceryStoresByOwner }: any = cache.readQuery({ query: GET_GROCERY_STORE_BY_OWNER });

      getGroceryStoresByOwner.push(newData);

      cache.writeQuery({
        query: GET_GROCERY_STORE_BY_OWNER,
        data: { getGroceryStoresByOwner }
      });
    }
  });

  return {
    createGroceryStore,
    data,
    loading,
    error
  };
};

export const useDeleteGroceryStoreMutation = () => {
  const [deleteGroceryStore, { data, loading, error }] = useMutationWithLoader(deleteGroceryStoreMutation, {
    refetchQueries: [{ query: GET_GROCERY_STORE_BY_OWNER }]
  });

  return {
    deleteGroceryStore,
    data,
    loading,
    error
  };
};

export const useGetGroceryStoreById = (id: string | null, type: IGroceryStoreQueryOptions = 'ID') => {
  const query = getQueryMapper(type);

  const data = useQueryWithLoader(query, {
    skip: !id,
    variables: {
      input: {
        storeId: id
      }
    }
  });
  return data;
};

export const useGroceryStoreList = () => {
  const [stores, setStores] = useState<any>(null);

  const { loading, data, error } = useQueryWithLoader(GET_GROCERY_STORE_BY_OWNER);

  useEffect(() => {
    if (data && data.getGroceryStoresByOwner) {
      const { getGroceryStoresByOwner } = data;

      setStores(getGroceryStoresByOwner);
    }
  }, [data]);

  return {
    loading,
    stores,
    error
  };
};

export const useGetGroceryStoreItems = (storeId: string | null) => {
  const [items, setItems] = useState<any>(null);

  const { loading, data, error } = useQueryWithLoader(getGroceryItemsByStoreId, {
    skip: !storeId,
    variables: {
      input: {
        storeId
      }
    }
  });

  useEffect(() => {
    if (data && data.getCategoriesWithItemsByStoreId) {
      const { getCategoriesWithItemsByStoreId } = data;

      setItems(getCategoriesWithItemsByStoreId);
    }
  }, [data]);

  return {
    loading,
    data: items,
    error
  };
};

export const useGetGroceryDefaultItems = () => {
  const [items, setItems] = useState<any>(null);

  const { loading, data, error } = useQueryWithLoader(getGroceryDefaultItems);

  useEffect(() => {
    if (data && data.getGroceryDefaultItems) {
      const { getGroceryDefaultItems } = data;

      setItems(getGroceryDefaultItems);
    }
  }, [data]);

  return {
    loading,
    data: items,
    error
  };
};

export const useUpdateGroceryStoreItems = (storeId: string | null) => {
  const [updateGroceryStoreItems, { data, loading, error }] = useMutationWithLoader(updateGroceryStoreItemsMutation, {
    refetchQueries: [
      {
        query: getGroceryItemsByStoreId,
        variables: {
          input: {
            storeId
          }
        }
      }
    ]
  });

  return {
    updateGroceryStoreItems,
    data,
    loading,
    error
  };
};

export const useUpdateGroceryStoreMutation = (type: IGroceryStoreQueryOptions = 'PROFILE', loader = true) => {
  const { groceryStoreId } = useActiveGroceryStore();

  const mutation = getMutationMapper(type);

  const [updateGroceryStoreCaller, { data, loading, error }] = useMutationWithLoader(mutation, {
    update: (cache, { data: { updateGroceryStore: newData } }) => {
      try {
        if (groceryStoreId) {
          const fragment = getFragmentMapper(type);

          const id = `GroceryStore:${groceryStoreId}`;

          cache.writeFragment({
            id,
            fragment,
            data: newData
          });
        }
      } catch {
        console.log('[FRAGMENT_UPDATE] Failed to update fragment');
      }
    }
  });

  const updateGroceryStore = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updateGroceryStoreCaller({
      ...options,
      variables: {
        ...options?.variables,
        input: { ...options?.variables?.input, id: groceryStoreId }
      }
    });
  };

  return {
    updateGroceryStore,
    data,
    loading,
    error
  };
};

export const useGetStripeConnectUrlForGroceryStore = () => {
  const [getStripeUrl, { data }] = useLazyQueryWithLoader(GET_STRIPE_CONNECT_URL_GROCERY);

  return { data, getStripeUrl };
};

export const useActivateGroceryMutation = () => {
  const { groceryStoreId } = useActiveGroceryStore();
  const [activateGroceryStore, { data, loading, error }] = useMutationWithLoader(activateGroceryStoreMutation, {
    update: (cache) => {
      if (groceryStoreId) {
        const id = `GroceryStore:${groceryStoreId}`;

        cache.writeFragment({
          id,
          fragment: getFragmentMapper('STATUS'),
          data: { id: groceryStoreId, onlineStatus: 'ACTIVE', __typename: 'GroceryStore' }
        });
      }
    }
  });
  return {
    activateGroceryStore,
    data,
    loading,
    error
  };
};

import React, { useEffect, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { useGetGroceryStoreItems, useGetGroceryStoreById, useUpdateGroceryStoreItems, useGetGroceryDefaultItems } from 'graphql/hooks/grocery.hooks';
import { GroceryStoreItem, ICategoryMapper } from '../types';
import GroceryEmptyScreen from 'components/EmptyScreen/GroceryEmptyScreen';
import { useStyles } from './style';
import Row from './Row';
import { map, cloneDeep } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { parseCsv, parseBEData, buildCategories, mergeWithCurrentRows } from './utils';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSnackbar } from 'notistack';
import UpdateDialog from './UpdateDialog';
import { convertPriceToPoints } from 'pages/Home/RestaurantDashboard/DishAddOrEditPage/RegularDish/util';
import useActiveGroceryStore from 'hooks/grocery/useActiveGroceryStore';
import CSVReader from 'react-csv-reader';
import { convertPriceTo } from 'util/number';

interface IProps {
  groceryStoreId: string | null;
  groceryStore: any;
}

const newItem: GroceryStoreItem = {
  upcCode: '',
  title: '',
  brand: '',
  size: '',
  category: '',
  subCategory: '',
  price: '',
  taxMode: undefined,
  itemIndex: 0
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_')
};

const StoreItemsTable = () => {
  const { groceryStoreId } = useActiveGroceryStore();

  const { data: groceryItemsData, loading } = useGetGroceryStoreItems(groceryStoreId);

  const { data: groceryDefaltItems } = useGetGroceryDefaultItems();

  const { data: groceryStoreData } = useGetGroceryStoreById(groceryStoreId, 'REWARD_SETTING');

  const { updateGroceryStoreItems } = useUpdateGroceryStoreItems(groceryStoreId);

  const { ownerGetGroceryStoreById: groceryStore } = groceryStoreData || {};

  const classes = useStyles();

  const taxModes: string[] = groceryStore && groceryStore.taxModes ? groceryStore.taxModes.map((mode: any) => mode.title) : [];

  const pricePointRatio: number = groceryStore && groceryStore.rewardSetting ? groceryStore.rewardSetting.pricePointRatio || 0 : 0;

  const [initialRows, setInitialRows] = useState<GroceryStoreItem[]>([]);

  const [rows, setRows] = useState<GroceryStoreItem[]>([]);

  const [defaultItems, setDefaultItems] = useState<GroceryStoreItem[]>([]);

  const [deletedRows, setDeletedRows] = useState<GroceryStoreItem[]>([]);

  const [categoryToIdMapper, setCategoryToIdMapper] = useState<ICategoryMapper>({});

  const [errors, setErrors] = useState<string[]>([]);

  const [openNewItemDialog, setOpenNewItemDialog] = useState(false);

  const snackbar = useSnackbar();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Using object so its easier to check if item present in selected rows
  const [selectedRows, setSelectedRows] = useState<{ [key: number]: string }>({});

  const onDataUpload = (uploadedData: any[]) => {
    const { items, errorRows } = parseCsv(uploadedData, defaultItems, pricePointRatio, taxModes);

    if (rows.length) {
      const { mergedRows, deletedRows } = mergeWithCurrentRows(rows, items);

      if (mergedRows.length) {
        setRows(mergedRows);
        setDeletedRows(deletedRows);
      }
    } else {
      if (items.length) {
        setRows(items);
        setInitialRows(items);
      }
    }

    setErrors(errorRows);
  };

  useEffect(() => {
    if (groceryItemsData && groceryItemsData.length) {
      const { items, categoryMapper, deletedItems } = parseBEData(groceryItemsData);

      setRows(items);
      setDeletedRows(deletedItems);
      setInitialRows(cloneDeep(items));
      setCategoryToIdMapper(categoryMapper);
    }
  }, [groceryItemsData]);

  useEffect(() => {
    if (groceryDefaltItems && groceryDefaltItems.length) {
      const { items } = parseBEData(groceryDefaltItems);

      setDefaultItems(items);
    }
  }, [groceryDefaltItems]);

  const onAdd = (newItem: GroceryStoreItem) => {
    const rowsCopy = [newItem, ...rows];
    Object.keys(selectedRows).forEach((i) => {
      rowsCopy[parseInt(i)] = {
        ...rowsCopy[parseInt(i)],
        category: newItem.category,
        subCategory: newItem.subCategory
      };
    });

    setRows(rowsCopy);
    setSelectedRows({});
  };

  const onEdit = (updatedItem: GroceryStoreItem, index: number) => {
    const rowsCopy = [...rows];
    rowsCopy[index] = updatedItem;

    Object.keys(selectedRows).forEach((i) => {
      rowsCopy[parseInt(i)] = {
        ...rowsCopy[parseInt(i)],
        category: updatedItem.category,
        subCategory: updatedItem.subCategory
      };
    });

    setRows(rowsCopy);
    setSelectedRows({});
  };

  const onDelete = (index: number) => {
    const rowsCopy = [...rows];

    setDeletedRows([
      ...deletedRows,
      {
        ...rowsCopy[index],
        status: 'DELETED'
      }
    ]);

    rowsCopy.splice(index, 1);

    setRows(rowsCopy);
  };

  const onReset = () => {
    setRows(cloneDeep(initialRows));
  };

  const onSubmit = async () => {
    const categories = buildCategories([...rows, ...deletedRows], categoryToIdMapper);

    const response = await updateGroceryStoreItems({
      variables: {
        input: { id: groceryStoreId, categories }
      }
    });

    if (response && response.data && response.data.updateGroceryStore) {
      snackbar.enqueueSnackbar('Items data updated successfully !', {
        variant: 'success'
      });
    } else {
      snackbar.enqueueSnackbar('Update request failed.', {
        variant: 'error'
      });
    }
  };

  const onRewardsChanged = (index: number, checked: boolean) => {
    const rowsCopy = [...rows];

    const item = rowsCopy[index];
    if (checked) {
      item.isReward = true;
      item.rewardPoints = convertPriceToPoints(item.price, pricePointRatio);
    } else {
      item.isReward = undefined;
      item.rewardPoints = undefined;
    }
    rowsCopy[index] = item;
    setRows(rowsCopy);
  };

  const onDiscountChanged = (index: number, checked: boolean) => {
    const rowsCopy = [...rows];

    const item = rowsCopy[index];
    if (checked) {
      item.isDiscounted = true;
    } else {
      item.isDiscounted = undefined;
    }
    rowsCopy[index] = item;
    setRows(rowsCopy);
  };

  if (groceryStore && rows && rows.length) {
    if (!taxModes.length) {
      return (
        <Typography variant="h6" align="center" color="error">
          Please update tax modes
        </Typography>
      );
    }

    return (
      <Box display="flex" justifyContent="center" paddingBottom={4}>
        <Box>
          <Box paddingY={1.5} display="flex" alignItems="flex-end">
            <Box marginRight={1}>
              <CSVReader cssInputClass={classes.csvReader} cssClass="react-csv-input" onFileLoaded={onDataUpload} parserOptions={papaparseOptions} />

              <Typography variant="body2" align="center">
                Make sure the column names are upc_code, price, description,tax_mode,reward,is_discounted
              </Typography>
            </Box>
            <Box flex={1} />

            <Box marginRight={1}>
              <Button variant="outlined" endIcon={<RefreshIcon fontSize="small" />} onClick={onReset}>
                Reset
              </Button>
            </Box>
            <Box>
              <Button variant="contained" className={classes.submitButton} onClick={onSubmit} disableElevation={true}>
                Submit
              </Button>
            </Box>
          </Box>
          {errors.length > 0 && (
            <Box paddingY={1}>
              {errors.map((err, index) => (
                <Typography key={index} variant="body2" color="error">
                  {err}
                </Typography>
              ))}
            </Box>
          )}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ width: '5%' }}>upccode</TableCell>
                <TableCell style={{ width: '15%' }}>Product name</TableCell>
                <TableCell style={{ width: '5%' }}>Brand</TableCell>
                <TableCell style={{ width: '8%' }}>Size</TableCell>
                <TableCell style={{ width: '10%' }}>Category</TableCell>
                <TableCell style={{ width: '10%' }}>Sub category</TableCell>
                <TableCell style={{ width: '5%' }}>Price</TableCell>
                <TableCell>Tax mode</TableCell>
                <TableCell style={{ width: '5%' }}>Reward</TableCell>
                <TableCell style={{ width: '5%' }}>Discounted</TableCell>

                <TableCell>Description</TableCell>
                {/* <TableCell align="right" style={{ width: '8%' }}>
                  <AddIcon className={classes.addIcon} onClick={() => setOpenNewItemDialog(true)} />
                  {openNewItemDialog && (
                    <UpdateDialog
                      open={openNewItemDialog}
                      rowData={newItem}
                      onSubmit={(item) => {
                        onAdd(item);
                        setOpenNewItemDialog(false);
                      }}
                      taxModes={taxModes}
                      onClose={() => setOpenNewItemDialog(false)}
                      pricePointRatio={pricePointRatio}
                    />
                  )}
                </TableCell> */}
              </TableRow>
            </TableHead>
            {rows.length > 0 && (
              <TableBody>
                {map(rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), (row, index) => {
                  const itemIndex = page * rowsPerPage + index;

                  if (row.status !== 'DELETED') {
                    return (
                      <Row
                        key={itemIndex}
                        rowData={row}
                        onEdit={(updatedItem) => onEdit(updatedItem, itemIndex)}
                        onDelete={() => onDelete(itemIndex)}
                        taxModes={taxModes}
                        pricePointRatio={pricePointRatio}
                        isSelected={selectedRows[itemIndex] !== undefined}
                        onSelect={(checked: boolean) => {
                          if (checked) {
                            setSelectedRows({
                              ...selectedRows,
                              [itemIndex]: ''
                            });
                          } else {
                            const selected = { ...selectedRows };
                            delete selected[itemIndex];
                            setSelectedRows(selected);
                          }
                        }}
                        rewardsEnabled={!!groceryStore.issueRewardPoint && !!groceryStore.rewardSetting}
                        onRewardsChanged={(checked: boolean) => onRewardsChanged(itemIndex, checked)}
                        onDiscountChanged={(checked: boolean) => onDiscountChanged(itemIndex, checked)}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            )}
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              count={rows.length}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Table>
        </Box>
      </Box>
    );
  }

  if (!loading && groceryItemsData && !groceryItemsData.length) {
    return (
      <>
        {errors.length > 0 && (
          <Box paddingY={1}>
            {errors.map((err, index) => (
              <Typography key={index} variant="body2" color="error">
                {err}
              </Typography>
            ))}
          </Box>
        )}
        <GroceryEmptyScreen onDataUpload={onDataUpload} />
      </>
    );
  }

  return null;
};

export default StoreItemsTable;

import { gql } from 'apollo-boost';

export default gql`
  query downloadTablesCard($input: DownloadTablesCardInput!) {
    downloadTablesCard(input: $input) {
      url
      id
    }
  }
`;

import axios, { AxiosRequestConfig } from 'axios';
import { Firebase } from 'fbase';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ANALYTICS_URI

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const auth = new Firebase().getAuth();

    const token = await auth().currentUser?.getIdToken();

    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete config.headers.common['Authorization'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //Handle error for diff status.codes

    return Promise.reject(error.response);
  }
);

export const setToken = async () => {
  const auth = new Firebase().getAuth();

  const token = await auth().currentUser?.getIdToken();

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export default axios;
export const MIN_POINTS_REQUIRED_LABEL: string = 'This is the minimum number of points at which user can start redeeming them for rewards. Default value is set to "0" points.';

export const MAX_REDEEMABLE_LABEL: string = 'This is the maximum number of Points that can be redeemed per receipt/transaction per day. Default value is set to "300" points.';

export const MIN_PURCHASE_REQUIRED: string = 'This is the minimum purchase amount (subtotal) required to enable "Redeem Button". Default value is set to $0';

export const POINT_EXCHANGE_RATIO: string = 'This factor is used to convert dish price into number of points. For example: A "Mago Smoothie" priced at $5.60 will convert to 60 points with "Point Exchange Ratio" of 10. Cusomter will need 60 points to get this reward item.'

export const MIN_START_POINTS: number = 0;

export const MAX_REDEEM_POINTS_PER_USAGE: number = 300;

export const MIN_REDEEEM_PURCHASE: number = 0;

export const PRICE_POINT_RATIO: number = 10;
import { convertPriceTo } from 'util/number';
import { GroceryStoreItem, ICategoryMapper } from '../types';
import { groupBy, map, sortBy } from 'lodash';
import { convertPriceToPoints } from 'pages/Home/RestaurantDashboard/DishAddOrEditPage/RegularDish/util';

export const parseCsv = (uploadedData: any[], defaultItems: GroceryStoreItem[], pricePointRatio: number, taxModes: string[]) => {
  const errorRows: string[] = [];
  let count = 0;
  let items: GroceryStoreItem[] = [];

  console.log(uploadedData);

  if (uploadedData && uploadedData.length) {
    const defaultItemsGroupedByUPC = groupBy(defaultItems, 'upcCode');

    uploadedData.forEach((csvRow, index) => {
      const upcCode = csvRow['upc_code'] ? csvRow['upc_code'].toString().trim() : '';

      const description = csvRow['description'] ? csvRow['description'].toString().trim() : undefined;
      const price = csvRow['price'] ? csvRow['price'] : 0;
      const taxMode = csvRow['tax_mode'] ? csvRow['tax_mode'] : undefined;
      const isReward = csvRow['reward'] ? true : undefined;
      const isDiscounted = csvRow['is_discounted'] ? true : undefined;

      if (upcCode === '') {
        errorRows.push(`row : ${index + 2} ignored because of missing upcCode`);
      } else if (upcCode && (upcCode.length < 11 || upcCode.length > 13)) {
        errorRows.push(`row : ${index + 2} ignored because of invalid upcCode`);
      } else if (!price) {
        errorRows.push(`row : ${index + 2} ignored because of missing price`);
      } else if (taxMode === '') {
        errorRows.push(`row : ${index + 2} ignored because of missing taxMode`);
      } else if (taxMode && taxModes.indexOf(taxMode) === -1) {
        errorRows.push(`row : ${index + 2} ignored because of invalid taxMode`);
      } else {
        if (defaultItemsGroupedByUPC[upcCode] && defaultItemsGroupedByUPC[upcCode].length) {
          items.push({
            ...defaultItemsGroupedByUPC[upcCode][0],
            upcCode,
            description,
            price,
            taxMode,
            itemIndex: count++,
            isReward,
            rewardPoints: isReward ? convertPriceToPoints(price, pricePointRatio) : undefined,
            status: 'ACTIVE',
            categoryId: undefined,
            subCategoryId: undefined,
            id: undefined,
            isDiscounted
          });
        } else {
          errorRows.push(`Data not found for upccode : ${upcCode}`);
        }
      }
    });
  }

  items = sortBy(items, 'category');

  return {
    items,
    errorRows
  };
};

export const parseCsvForDefaultItems = (uploadedData: any[]) => {
  const errorRows: string[] = [];
  let count = 0;
  let items: GroceryStoreItem[] = [];

  console.log(uploadedData);

  if (uploadedData && uploadedData.length) {
    uploadedData.forEach((csvRow, index) => {
      const upcCode = csvRow['upc_code'] ? csvRow['upc_code'].toString().trim() : '';

      const description = csvRow['description'] ? csvRow['description'].toString().trim() : undefined;
      const price = csvRow['price'] ? csvRow['price'] : 0;
      const taxMode = csvRow['tax_mode'] ? csvRow['tax_mode'] : undefined;
      const brand = csvRow['brand'] ? csvRow['brand'].toString().trim() : '';
      const category = csvRow['category'] ? csvRow['category'].toString().trim() : '';
      const subCategory = csvRow['sub_category'] ? csvRow['sub_category'].toString().trim() : undefined;
      const title = csvRow['product_name'] ? csvRow['product_name'].toString().trim() : '';
      const size = csvRow['size'] ? csvRow['size'].toString().trim() : '';

      if (upcCode !== '' && brand !== '' && category !== '' && title !== '' && size !== '') {
        items.push({
          upcCode,
          brand,
          category,
          subCategory,
          title,
          size,
          description,
          price,
          taxMode,
          itemIndex: count++
        });
      } else {
        errorRows.push(`row : ${index + 1} ignored because of missing data`);
      }
    });
  }

  items = sortBy(items, 'category');

  return {
    items,
    errorRows
  };
};

export const parseBEData = (groceryItemsData: any[]) => {
  let items: GroceryStoreItem[] = [];
  let deletedItems: GroceryStoreItem[] = [];

  const categoryToIdMapper: ICategoryMapper = {};
  let count = 0;

  groceryItemsData.forEach((category: any) => {
    const categoryTitle = category.title;

    const categoryId = category.id;

    categoryToIdMapper[categoryTitle] = {
      id: categoryId,
      subCategoryMapper: {}
    };

    const subCategories: any = {};

    if (category.subCategories) {
      category.subCategories.forEach((subCategory: any) => {
        subCategories[subCategory.id] = subCategory;
        categoryToIdMapper[categoryTitle].subCategoryMapper[subCategory.title] = subCategory.id;
      });
    }

    if (category.items && category.items.length) {
      category.items.forEach((item: any) => {
        const { upcCode, brand, title, size, subCategoryId, description, price, id, categoryId, taxMode, status, isReward, rewardPoints, isDiscounted } = item;

        if (status === 'DELETED') {
          deletedItems.push({
            upcCode: upcCode,
            brand: brand,
            title: title,
            size: size,
            category: categoryTitle,
            subCategory: subCategoryId ? subCategories[subCategoryId].title : undefined,
            description: description,
            price: convertPriceTo(price, 'DOLLAR'), // converting cents to dollar
            id: id,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            taxMode: taxMode,
            status: status,
            isReward: isReward,
            rewardPoints: rewardPoints,
            itemIndex: count++,
            isDiscounted
          });
        } else {
          items.push({
            upcCode: upcCode,
            brand: brand,
            title: title,
            size: size,
            category: categoryTitle,
            subCategory: subCategoryId ? subCategories[subCategoryId].title : undefined,
            description: description,
            price: convertPriceTo(price, 'DOLLAR'), // converting cents to dollar
            id: id,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            taxMode: taxMode,
            status: status,
            isReward: isReward,
            rewardPoints: rewardPoints,
            itemIndex: count++,
            isDiscounted
          });
        }
      });
    }
  });

  items = sortBy(items, 'category');

  return { items, categoryMapper: categoryToIdMapper, deletedItems };
};

export const buildCategories = (rows: GroceryStoreItem[], categoryToIdMapper: ICategoryMapper) => {
  const categories: any[] = [];

  if (rows && rows.length > 0) {
    const itemsGroupedByCategories = groupBy(rows, 'category');

    for (const categoryTitle in itemsGroupedByCategories) {
      let categoryId: string | undefined = categoryToIdMapper[categoryTitle]?.id;

      const subCategories: any = [];

      const itemsWithSameCategory: GroceryStoreItem[] = itemsGroupedByCategories[categoryTitle];

      const itemsWithSubCategory: GroceryStoreItem[] = itemsWithSameCategory.filter((item: GroceryStoreItem) => item.subCategory);

      const itemsWithoutSubCategory: GroceryStoreItem[] = itemsWithSameCategory.filter((item: GroceryStoreItem) => !item.subCategory);

      const itemsWithSubCategoryGroupedBySubCategory = groupBy(itemsWithSubCategory, 'subCategory');

      if (itemsWithSubCategory.length) {
        for (const subCategoryTitle in itemsWithSubCategoryGroupedBySubCategory) {
          if (subCategoryTitle.trim() !== '') {
            let subCategoryId: string | undefined = categoryToIdMapper[categoryTitle]?.subCategoryMapper[subCategoryTitle];

            subCategories.push({
              title: subCategoryTitle,
              id: subCategoryId ? subCategoryId : undefined,
              items: map(itemsWithSubCategoryGroupedBySubCategory[subCategoryTitle], (item: GroceryStoreItem) => {
                return {
                  upcCode: item.upcCode,
                  brand: item.brand,
                  title: item.title,
                  size: item.size,
                  description: item.description ? item.description : undefined,
                  price: parseInt(convertPriceTo(item.price, 'CENT')) || 0,
                  id: item.id ? item.id : undefined,
                  status: item.status ? item.status : undefined,
                  taxMode: item.taxMode,
                  isReward: item.isReward,
                  isDiscounted: item.isDiscounted
                };
              })
            });
          }
        }
      }

      categories.push({
        title: categoryTitle,
        id: categoryId ? categoryId : undefined,
        items: itemsWithoutSubCategory.length
          ? map(itemsWithoutSubCategory, (item: GroceryStoreItem) => {
              return {
                upcCode: item.upcCode,
                brand: item.brand,
                title: item.title,
                size: item.size,
                description: item.description ? item.description : undefined,
                price: parseInt(convertPriceTo(item.price, 'CENT')) || 0,
                id: item.id ? item.id : undefined,
                status: item.status ? item.status : undefined,
                taxMode: item.taxMode,
                isReward: item.isReward,
                isDiscounted: item.isDiscounted
              };
            })
          : undefined,
        subCategories: subCategories.length ? subCategories : undefined
      });

      delete categoryToIdMapper[categoryTitle];
    }
  }

  /*
   * If this category not used hat means items are moved to another categories
   * So this category have 0 items and need to be deleted
   *
   **/

  for (const deleteCategoryTitle in categoryToIdMapper) {
    const deletedCategory = categoryToIdMapper[deleteCategoryTitle];

    let categoryId: string | undefined = deletedCategory.id;

    const subCategories: any = [];

    for (const deletedSubCategory in deletedCategory.subCategoryMapper) {
      subCategories.push({
        title: deletedSubCategory,
        id: deletedCategory.subCategoryMapper[deletedSubCategory] ? deletedCategory.subCategoryMapper[deletedSubCategory] : undefined,
        items: undefined
      });
    }

    categories.push({
      title: deleteCategoryTitle,
      id: categoryId ? categoryId : undefined,
      items: undefined,
      subCategories: subCategories.length ? subCategories : undefined,
      status: 'DELETED'
    });
  }

  return categories;
};

export const mergeWithCurrentRows = (curRows: GroceryStoreItem[], newRows: GroceryStoreItem[]) => {
  const mergedRows: GroceryStoreItem[] = [];
  const deletedRows: GroceryStoreItem[] = [];

  const curRowsGroupedByUPC = groupBy(curRows, 'upcCode');
  const newRowsGroupedByUPC = groupBy(newRows, 'upcCode');

  for (const upcCode in curRowsGroupedByUPC) {
    if (newRowsGroupedByUPC[upcCode]) {
      /**
       * Merge rows present in both
       * Get ids from old row data and updates from new row data
       * new status will be ACTIVE
       */

      mergedRows.push({
        ...curRowsGroupedByUPC[upcCode][0],
        ...newRowsGroupedByUPC[upcCode][0],
        categoryId: curRowsGroupedByUPC[upcCode][0].categoryId,
        subCategoryId: curRowsGroupedByUPC[upcCode][0].subCategoryId,
        id: curRowsGroupedByUPC[upcCode][0].id
      });

      delete newRowsGroupedByUPC[upcCode];
    } else {
      /**
       * Rows not present in new sheet
       * Mark them as DELETED
       */

      deletedRows.push({
        ...curRowsGroupedByUPC[upcCode][0],
        status: 'DELETED'
      });
    }

    delete curRowsGroupedByUPC[upcCode];
  }

  for (const upcCode in newRowsGroupedByUPC) {
    /**
     * Rows present in new sheet but not present in old data
     */
    mergedRows.push({
      ...newRowsGroupedByUPC[upcCode][0]
    });
  }

  return { mergedRows, deletedRows };
};

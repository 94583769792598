import gql from 'graphql-tag';

export const updatePizzaSizeMutation = gql`
  mutation updateRestaurantPizzaSizes($input: UpdateRestaurantPizzaSizesInput!) {
    updateRestaurantPizzaSizes(input: $input) {
      id
      title
      description
      enableHalfHalf
    }
  }
`;

export const updatePizzaCrustMutation = gql`
  mutation updateRestaurantPizzaCrusts($input: UpdateRestaurantPizzaCrustsInput!) {
    updateRestaurantPizzaCrusts(input: $input) {
      id
      title
      description
      prices {
        size {
          id
        }
        price {
          intValue
          shift
        }
      }
    }
  }
`;

export const updateRestaurantPizzaDensityMultipliers = gql`
  mutation updateRestaurantPizzaDensityMultipliers($input: UpdateRestaurantPizzaDensityMultipliersInput!) {
    updateRestaurantPizzaDensityMultipliers(input: $input) {
      density
      multiplier
    }
  }
`;

export const updatePizzaSauceMutation = gql`
  mutation updateRestaurantPizzaSauces($input: UpdateRestaurantPizzaSaucesInput!) {
    updateRestaurantPizzaSauces(input: $input) {
      id
      title
      description
      enableHalfHalf
      maxSelect
      items {
        id
        title
        description
        preset
        prices {
          id
          size {
            id
          }
          densities
          densityPrices {
            density
            price {
              intValue
              shift
            }
          }
        }
      }
    }
  }
`;

export const updatePizzaCheeseMutation = gql`
  mutation updateRestaurantPizzaCheeses($input: UpdateRestaurantPizzaCheesesInput!) {
    updateRestaurantPizzaCheeses(input: $input) {
      id
      title
      description
      enableHalfHalf
      maxSelect
      items {
        id
        title
        description
        preset
        prices {
          id
          size {
            id
          }
          densities
          densityPrices {
            density
            price {
              intValue
              shift
            }
          }
        }
      }
    }
  }
`;

export const updatePizzaToppingMutation = gql`
  mutation updateRestaurantPizzaToppings($input: UpdateRestaurantPizzaToppingsInput!) {
    updateRestaurantPizzaToppings(input: $input) {
      id
      title
      description
      enableHalfHalf
      maxSelect
      items {
        id
        title
        description
        preset
        prices {
          id
          size {
            id
          }
          densities
          densityPrices {
            density
            price {
              intValue
              shift
            }
          }
        }
      }
    }
  }
`;

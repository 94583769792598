import React, { useState } from 'react';
import { Grid, Box, Typography, Button, Divider } from '@material-ui/core';
import { useStyles } from './style';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomStepper from 'components/CustomStepper';
import RestaurantProfile from './RestaurantProfile';
import BusinessHours from './BusinessHours';
import Gallery from './Gallery';
import RestaurantInfoPreview from './RestaurantInfoPreview';
import { useHistory } from 'react-router-dom';
import TableSetup from './TableSetup';
import { useActiveRestaurant } from 'hooks/restaurant';
import CancelBtn from 'components/CancelBtn';

const RestaurantInfo = () => {
  const classes = useStyles();

  const history = useHistory();

  const [activeStep, setActiveStep] = useState(4);

  const [editing, setEditing] = useState(false);

  const steps = ['Profile', 'Business Hours', 'Table Setup', 'Gallery'];

  const restaurantProfileRef: any = React.useRef(null);

  const businessHoursRef: any = React.useRef(null);

  const tableSetupRef: any = React.useRef(null);

  const GalleryRef: any = React.useRef(null);

  const { restaurantId } = useActiveRestaurant();

  /* Add step */
  const nextStep = () => {
    if (editing) {
      setActiveStep(4);
      setEditing(false);
    } else {
      setActiveStep((oldval) => {
        return oldval + 1;
      });
    }
  };

  /* reduce step */
  const prevStep = () => {
    if (activeStep !== 0) {
      setActiveStep((oldval) => {
        return oldval - 1;
      });
    }
  };

  /* handle before adding step */
  const handleNext = async () => {
    if (activeStep === 0) {
      const { submitForm } = restaurantProfileRef.current || {};
      if (submitForm) submitForm();
    } else if (activeStep === 1) {
      const { submitForm } = businessHoursRef.current || {};
      if (submitForm) submitForm();
    } else if (activeStep === 2) {
      const { submitForm } = tableSetupRef.current || {};
      if (submitForm) submitForm();
    } else if (activeStep === 3) {
      const { submitForm } = GalleryRef.current || {};
      if (submitForm) submitForm();
    } else if (activeStep === 4) {
      history.goBack();
    } else {
      return;
    }
  };

  /* opens service dialog to add new service */
  const addService = () => {
    const { setOpenServiceDialog } = businessHoursRef.current;
    if (setOpenServiceDialog) setOpenServiceDialog(true);
  };

  return (
    <Box className={classes.pageContainer}>
      <Grid container={true} alignItems="center">
        <Grid item={true} xs={12} sm={4} md={4}>
          <Typography variant="h5">Restaurant Info</Typography>
        </Grid>
        <Grid item={true} xs={12} sm={8} md={8}>
          {editing ? (
            <Box textAlign="right">
              <Button type="submit" variant="outlined" className={classes.headerBtns} startIcon={<CancelIcon />} onClick={nextStep}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} startIcon={<SaveIcon />} onClick={handleNext}>
                Save
              </Button>
              {activeStep === 1 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={addService}>
                  Add service
                </Button>
              )}
            </Box>
          ) : (
            <Box textAlign="right">
              {activeStep === 1 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={addService}>
                  Add service
                </Button>
              )}
              {activeStep !== 4 && <CancelBtn className={classes.headerBtns} restaurantId={restaurantId || ''} touched={true} />}
              {activeStep !== 4 && activeStep !== 0 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={prevStep}>
                  Prev
                </Button>
              )}
              {activeStep < 3 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} onClick={handleNext}>
                  Save & Next
                </Button>
              )}
              {activeStep >= 3 && (
                <Button type="submit" variant="contained" color="primary" className={classes.headerBtns} startIcon={<SaveIcon />} onClick={handleNext}>
                  {activeStep === 4 ? 'Done' : 'Save'}
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {activeStep !== 4 && <CustomStepper activeStep={activeStep} steps={steps} />}
      <Grid container={true} className={classes.pageContainer}>
        {activeStep === 0 && <RestaurantProfile ref={restaurantProfileRef} nextStep={nextStep} />}
        {activeStep === 1 && <BusinessHours ref={businessHoursRef} nextStep={nextStep} />}
        {activeStep === 2 && <TableSetup ref={tableSetupRef} nextStep={nextStep} />}
        {activeStep === 3 && <Gallery ref={GalleryRef} nextStep={nextStep} />}
        {activeStep === 4 && <RestaurantInfoPreview setActiveStep={setActiveStep} setEditing={setEditing} />}
      </Grid>
    </Box>
  );
};

export default RestaurantInfo;

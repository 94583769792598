import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px'
  },
  iconEdit: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  iconDelete: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  table: {
    border: 'solid 1px ' + theme.palette.grey[300],
    borderRadius: 5,
    '& tr:not(:last-child),thead': {
      borderBottom: 'solid 1px ' + theme.palette.grey[300]
    }
  },
  hoursNotFound: {
    padding: theme.spacing(1)
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[300],
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  tableRow: {
    width: '100%'
  },
  tableCell: {
    padding: theme.spacing(1),
    border: 'none'
  },
  headerTitle: {
    textTransform: 'capitalize'
  }
}));

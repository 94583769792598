import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Dialog } from '@lokobee/lokobee-ui';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useDeleteDishCategory } from 'graphql/hooks/dish.hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { CATEGORY, HOME, ALL } from 'util/strings';

interface IProps {
  categoryId: string;
  activeCategory: string;
}

const DeleteCategory = ({ categoryId, activeCategory }: IProps) => {
  const history = useHistory();

  const { restaurantId } = useActiveRestaurant();

  const { deleteCategory } = useDeleteDishCategory(restaurantId ? restaurantId : '', categoryId);

  const snackbar = useSnackbar();

  const classes = makeStyles((theme) => ({
    deleteIcon: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.5),
      color: 'white',
      cursor: 'pointer',
      borderRadius: '4px',
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  }))();

  const onClick = async () => {
    const { data } = await deleteCategory();

    if (data && data.deleteDishCategory) {
      snackbar.enqueueSnackbar('Dish category deleted successfully !', {
        variant: 'success'
      });
      if (activeCategory === categoryId) {
        history.push(`/${HOME}/${restaurantId}?${CATEGORY}=${ALL}`);
      }
    } else {
      snackbar.enqueueSnackbar('Delete request failed.', {
        variant: 'error'
      });
    }
  };

  return (
    <Box paddingX={1}>
      <Dialog>
        <DeleteIcon fontSize="small" className={classes.deleteIcon} />
        <Dialog.DialogContent>
          <Typography variant="body2">All the dishes in this category will be deleted. Are you sure you want to delete this category?</Typography>
        </Dialog.DialogContent>
        <Dialog.DialogActions saveBtnText="Yes" cancelBtnText="No" onSave={onClick} />
      </Dialog>
    </Box>
  );
};

export default DeleteCategory;

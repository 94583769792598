import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Dialog } from '@lokobee/lokobee-ui';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDeleteCoupon } from 'graphql/hooks/coupons.hooks';
import { error } from 'console';

interface IProps {
  bizId: string;
  code: string;
}

const DeleteCoupon: React.FC<IProps> = ({ bizId, code }) => {
  const { deleteCoupon } = useDeleteCoupon(bizId, code);

  const snackbar = useSnackbar();

  const classes = makeStyles((theme) => ({
    removeBtn: {
      background: theme.palette.error.main,
      color: '#fff',
      '&:hover': {
        background: theme.palette.error.dark
      }
    },
    deleteIcon: {
      cursor: 'pointer',
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark
      }
    }
  }))();

  const onClick = async () => {
    try {
      const { data } = await deleteCoupon();

      if (data && data.deleteCoupon) {
        snackbar.enqueueSnackbar('Coupon deleted successfully !', {
          variant: 'success'
        });
      } else {
        snackbar.enqueueSnackbar('Delete request failed.', {
          variant: 'error'
        });
      }
    } catch (err) {
      snackbar.enqueueSnackbar('Delete request failed.', {
        variant: 'error'
      });
    }
  };

  return (
    <Box paddingX={1}>
      <Dialog>
        <IconButton edge="end" aria-label="delete">
          <DeleteIcon fontSize="small" className={classes.deleteIcon} />
        </IconButton>
        <Dialog.DialogContent>
          <Typography variant="body2">Are you sure you want to delete this coupon?</Typography>
        </Dialog.DialogContent>
        <Dialog.DialogActions saveBtnText="Yes" cancelBtnText="No" onSave={onClick} />
      </Dialog>
    </Box>
  );
};

export default DeleteCoupon;

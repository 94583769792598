import React, { useReducer, useContext } from 'react';
import { loaderInitialState, loaderActions } from './loaderActions';
import { restaurantActions, restaurantInitialState } from './restaurantActions';
import { waiterPageInitialState, waiterPageActions } from './waiterPageActions';
import { drawerInitialState, drawerActions } from './drawerActions';
import { menuPageInitialState, menuPageActions } from './restaurantMenuPageActions';
import { gloabalLoaderActionInitialState, globalLoaderActions } from './globalLoaderActions';
import { groceryStoreActions, groceryStoreInitialState } from './groceryStoreActions';

const initialState: any = {
  ...loaderInitialState,
  ...restaurantInitialState,
  ...waiterPageInitialState,
  ...drawerInitialState,
  ...menuPageInitialState,
  ...gloabalLoaderActionInitialState,
  ...groceryStoreInitialState
};

const Actions = {
  ...loaderActions,
  ...restaurantActions,
  ...waiterPageActions,
  ...drawerActions,
  ...menuPageActions,
  ...globalLoaderActions,
  ...groceryStoreActions
};

const reducer = (state: any, action: any) => {
  const act = (Actions as any)[action.type];
  const update = act(state, action.payload);
  return { ...state, ...update };
};

const LoaderContext = React.createContext(initialState);

export const LoaderStore: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);

  return <LoaderContext.Provider value={{ state, dispatch }}>{children}</LoaderContext.Provider>;
};

export const useStore = () => {
  const { state, dispatch } = useContext(LoaderContext);
  return { state, dispatch };
};

export default {};

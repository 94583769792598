import { Box, Button, Typography } from '@material-ui/core';
import { FormikProps, getIn } from 'formik';
import React from 'react';
import { ITaxModeItem } from '../../types';
import InfoInputField from '../InfoInputField';
import { IGroceryStoreProfile } from '../types';
interface IProps {
  index: number;
  formik: FormikProps<IGroceryStoreProfile>;
  taxModeItem: ITaxModeItem;
}

const TaxMode = ({ index, formik, taxModeItem }: IProps) => {
  const removeTaxMode = (index: number) => {
    const taxModesTemp = [...formik.values.taxModes];
    taxModesTemp.splice(index, 1);
    formik.setValues({
      ...formik.values,
      taxModes: taxModesTemp
    });
  };
  return (
    <Box padding={1}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="body2">
          Tax mode {index + 1}: {formik.values.taxModes[index].title}
        </Typography>
        {formik.values.taxModes && formik.values.taxModes.length > 1 && (
          <Box>
            <Button size="small" onClick={() => removeTaxMode(index)}>
              Remove
            </Button>
          </Box>
        )}
      </Box>

      <InfoInputField
        name={`taxModes[${index}].title`}
        type="text"
        placeholder="Title *"
        inputLabel="Title"
        formik={formik}
        value={taxModeItem.title}
        inputProps={{
          maxLength: 200
        }}
        required={true}
        variant="outlined"
      />
      <InfoInputField
        name={`taxModes[${index}].tax`}
        type="text"
        placeholder="Tax *"
        inputLabel="Tax"
        formik={formik}
        value={taxModeItem.tax}
        inputProps={{
          maxLength: 200
        }}
        required={true}
        variant="outlined"
      />
    </Box>
  );
};

export default TaxMode;

import gql from 'graphql-tag';

export const Profile = gql`
  fragment Profile on GroceryStore {
    name

    logo {
      id
      url
    }
    phone
    address {
      text
      street
      city
      state
      country
      zipcode
      lat
      lng
    }
    description
    taxModes {
      title
      tax {
        intValue
        shift
      }
    }
    timezone
    email
    reportEmails
    orderEmails
    googleReviewLink
    nanoid
    bizHours {
      serviceType
      serviceName
      hours {
        openDay
        closeDay
        openHour
        openMinute
        closeHour
        closeMinute
      }
    }
  }
`;

export const BusinessHours = gql`
  fragment BusinessHours on GroceryStore {
    bizHours {
      serviceType
      serviceName
      hours {
        openDay
        closeDay
        openHour
        openMinute
        closeHour
        closeMinute
      }
    }
  }
`;

export const Announcement = gql`
  fragment Announcement on GroceryStore {
    announcement
  }
`;

export const RewardSetting = gql`
  fragment RewardSetting on GroceryStore {
    issueRewardPoint
    taxModes {
      title
      tax {
        intValue
        shift
      }
    }
    rewardSetting {
      minStartPoints
      maxRedeemPointsPerUsage
      minRedeemPurchase
      pricePointRatio
    }
  }
`;

export const Gallery = gql`
  fragment Gallery on GroceryStore {
    gallery {
      md {
        id
        url
      }
    }
  }
`;

export const PaymentSettings = gql`
  fragment PaymentSettings on GroceryStore {
    enableTakeoutOrder
    enableDeliveryOrder
    takeoutWaitMinutes
    takeoutPaymentSetting {
      onlinePaymentTypes
      onsitePaymentTypes
      serviceFee {
        amount
        threshold
      }

      tipDefaultPercent
      tipPercentOptions
    }
    deliveryPaymentSetting {
      onlinePaymentTypes
      onsitePaymentTypes
      serviceFee {
        amount
        threshold
      }

      tipDefaultPercent
      tipPercentOptions
    }
    hasStripeAccount
    deliveryProvider
    deliverySetting {
      waitMinutes
      freeDeliveryAmount
      minDeliveryAmount
      fees {
        mile
        fee
      }
    }
  }
`;

export const storeItemFragment = gql`
  fragment storeItemFragment on GroceryStoreItem {
    id
    categoryId
    subCategoryId
    title
    price
    description
    upcCode
    status
    size
    upcCode
    brand
    taxMode
    isReward
    isDiscounted
    rewardPoints
  }
`;

export const Status = gql`
  fragment Status on GroceryStore {
    id
    status
  }
`;

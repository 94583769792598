import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';
import { Dialog } from '@lokobee/lokobee-ui';
import { useStyles } from './style';
import { useSnackbar } from 'notistack';
import { useDeleteGroceryStoreMutation } from 'graphql/hooks/grocery.hooks';

interface IProps {
  id: string;
}

const DeleteGroceryStore = ({ id }: IProps) => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const { deleteGroceryStore } = useDeleteGroceryStoreMutation();

  const _deletegroceryStore = async () => {
    const response = await deleteGroceryStore({
      variables: {
        input: {
          id,
          status: 'DELETED'
        }
      }
    });

    if (response) {
      snackbar.enqueueSnackbar('Grocery store deleted successfully !', {
        variant: 'success'
      });
    } else {
      snackbar.enqueueSnackbar('Delete request failed.', {
        variant: 'error'
      });
    }
  };

  return (
    <Dialog>
      <DeleteIcon className={classes.deleteIcon} />
      <Dialog.DialogContent>
        <Typography variant="body1">Are you sure you want to delete this grocery store ?</Typography>
      </Dialog.DialogContent>
      <Dialog.DialogActions saveBtnText="Yes" onSave={_deletegroceryStore} cancelBtnText="No" />
    </Dialog>
  );
};

export default DeleteGroceryStore;

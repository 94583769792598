export const parseCsv = (uploadedData: any[], defaultItems: string[]) => {
  const errorRows: string[] = [];
  let items: string[] = [];
  if (uploadedData && uploadedData.length) {
    uploadedData.forEach((csvRow: string | any, index: number) => {
      if (csvRow['phone_number']) {
        let phone_number: string = csvRow['phone_number']
          ? csvRow['phone_number']
              .toString()
              .trim()
              .replace('+1', '')
              .replace(/\D/g, '')
          : '';
        if (phone_number.length !== 10 && phone_number[0] === '1') {
          phone_number = phone_number.slice(1);
        }
        if (phone_number === '' || phone_number.length !== 10) {
          errorRows.push(`row : ${index + 1}, content: ${csvRow['phone_number']} ignored because of an invalid phone number`);
        } else {
          items.push(`${phone_number}`);
        }
      }
    });
    if (items.length === 0) {
      errorRows.push(`phone_number column couldn't be found in a file!`);
    }
  }
  return {
    items,
    errorRows
  };
};

export const arrayEquals = (a: any[], b: any[]) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

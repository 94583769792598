import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { useActiveRestaurant } from 'hooks/restaurant';
import { getPizzaPartsQuery } from 'graphql/query/getPizzaParts.query';
import {
  updatePizzaSizeMutation,
  updatePizzaCrustMutation,
  updateRestaurantPizzaDensityMultipliers,
  updatePizzaCheeseMutation,
  updatePizzaSauceMutation,
  updatePizzaToppingMutation
} from 'graphql/mutations/pizza.mutation';
import { MutationFunctionOptions } from '@apollo/react-common';
import { ExecutionResult } from 'apollo-boost';

export const useGetPizzaParts = (id: string) => {
  const { data } = useQueryWithLoader(getPizzaPartsQuery, {
    variables: {
      input: {
        bizId: id
      }
    }
  });

  return data;
};

export const useUpdatePizzaSize = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updatePizzaSizeCaller, { data, loading, error }] = useMutationWithLoader(updatePizzaSizeMutation, {
    update: (cache, { data: { updateRestaurantPizzaSizes } }) => {
      try {
        const { getPizzaParts: oldData }: any = cache.readQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        cache.writeQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          },
          data: {
            getPizzaParts: {
              ...oldData,
              sizes: updateRestaurantPizzaSizes,
              __typename: 'PizzaParts'
            }
          }
        });
      } catch {
        console.log('Failed to update cache');
      }
    }
  });

  const updatePizzaSize = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updatePizzaSizeCaller({
      ...options,
      variables: {
        ...options?.variables
      }
    });
  };

  return {
    updatePizzaSize,
    data,
    loading,
    error
  };
};

export const useUpdatePizzaCrusts = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updatePizzaCrustCaller, { data, loading, error }] = useMutationWithLoader(updatePizzaCrustMutation, {
    update: (cache, { data: { updateRestaurantPizzaCrusts } }) => {
      try {
        const { getPizzaParts: oldData }: any = cache.readQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        cache.writeQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          },
          data: {
            getPizzaParts: {
              ...oldData,
              crusts: updateRestaurantPizzaCrusts
            }
          }
        });
      } catch {
        console.log('Failed to update cache');
      }
    }
  });

  const updatePizzaCrust = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updatePizzaCrustCaller({
      ...options,
      variables: {
        ...options?.variables
        // input: { ...options?.variables?.input, id: restaurantId }
      }
    });
  };

  return {
    updatePizzaCrust,
    data,
    loading,
    error
  };
};

export const useUpdatePizzaDensityMultiplier = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updatePizzaDensityMultiplierCaller, { data, loading, error }] = useMutationWithLoader(updateRestaurantPizzaDensityMultipliers, {
    update: (cache, { data: { updateRestaurantPizzaDensityMultipliers } }) => {
      try {
        const { getPizzaParts: oldData }: any = cache.readQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        cache.writeQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          },
          data: {
            getPizzaParts: {
              ...oldData,
              densityMultipliers: updateRestaurantPizzaDensityMultipliers,
              __typename: 'PizzaParts'
            }
          }
        });
      } catch {
        console.log('Failed to update cache');
      }
    }
  });

  const updatePizzaDensityMultiplier = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updatePizzaDensityMultiplierCaller({
      ...options,
      variables: {
        ...options?.variables
      }
    });
  };

  return {
    updatePizzaDensityMultiplier,
    data,
    loading,
    error
  };
};

export const useUpdatePizzaSauces = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updatePizzaSaucesCaller, { data, loading, error }] = useMutationWithLoader(updatePizzaSauceMutation, {
    update: (cache, { data: { updateRestaurantPizzaSauces } }) => {
      try {
        const { getPizzaParts: oldData }: any = cache.readQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        cache.writeQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          },
          data: {
            getPizzaParts: {
              ...oldData,
              sauces: updateRestaurantPizzaSauces,
              __typename: 'PizzaParts'
            }
          }
        });
      } catch {
        console.log('Failed to update cache');
      }
    }
  });

  const updatePizzaSauces = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updatePizzaSaucesCaller({
      ...options,
      variables: {
        ...options?.variables
      }
    });
  };

  return {
    updatePizzaSauces,
    data,
    loading,
    error
  };
};

export const useUpdatePizzaToppings = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updatePizzaToppingsCaller, { data, loading, error }] = useMutationWithLoader(updatePizzaToppingMutation, {
    update: (cache, { data: { updateRestaurantPizzaToppings } }) => {
      try {
        const { getPizzaParts: oldData }: any = cache.readQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        cache.writeQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          },
          data: {
            getPizzaParts: {
              ...oldData,
              toppings: updateRestaurantPizzaToppings,
              __typename: 'PizzaParts'
            }
          }
        });
      } catch {
        console.log('Failed to update cache');
      }
    }
  });

  const updatePizzaToppings = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updatePizzaToppingsCaller({
      ...options,
      variables: {
        ...options?.variables
      }
    });
  };

  return {
    updatePizzaToppings,
    data,
    loading,
    error
  };
};

export const useUpdatePizzaCheeses = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updatePizzaCheesesCaller, { data, loading, error }] = useMutationWithLoader(updatePizzaCheeseMutation, {
    update: (cache, { data: { updateRestaurantPizzaCheeses } }) => {
      try {
        const { getPizzaParts: oldData }: any = cache.readQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        cache.writeQuery({
          query: getPizzaPartsQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          },
          data: {
            getPizzaParts: {
              ...oldData,
              cheeses: updateRestaurantPizzaCheeses,
              __typename: 'PizzaParts'
            }
          }
        });
      } catch {
        console.log('Failed to update cache');
      }
    }
  });

  const updatePizzaCheeses = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updatePizzaCheesesCaller({
      ...options,
      variables: {
        ...options?.variables
      }
    });
  };

  return {
    updatePizzaCheeses,
    data,
    loading,
    error
  };
};

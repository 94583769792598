import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  uploadImages: {
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  imagesContainer: {
    padding: theme.spacing(2, 0),
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageBox: {
    margin: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
    '&:hover': {
      boxShadow: theme.shadows[6]
    }
  },
  defaultImageBox: {
    border: 'dashed',
    margin: theme.spacing(1)
  },
  uploadImageBox: {
    margin: theme.spacing(1.5)
  },
  image: {
    width: '100%',
    height: '100%'
  },
  closeButton: {
    textAlign: 'right',
    margin: theme.spacing(0.5),
    '& svg': {
      cursor: 'pointer'
    }
  },
  dialogImageContainer: {
    margin: theme.spacing(1) + 'px auto',
    width: '100%',
    height: '400px',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      padding: 0,
      height: 'auto'
    }
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: theme.spacing(2) + 'px auto',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between'
    }
  }
}));

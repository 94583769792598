import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RestaurantDashboard from './RestaurantDashboard';
import BizList from 'pages/Home/BizList';
import { PrivateGroceryStoreDashboard, PrivateRestaurantDashboard } from 'components/PrivateRoute';
import Profile from 'pages/Home/Profile';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import UserAgreement from 'pages/UserAgreement';
import GroceryDashboard from './GroceryDashboard';

const DashboardRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/home/profile" component={Profile} />
      <Route path="/home/privacypolicy" component={PrivacyPolicy} />
      <Route path="/home/useragreement" component={UserAgreement} />
      <PrivateGroceryStoreDashboard path="/home/grocery/:groceryStoreId" component={GroceryDashboard} />
      <PrivateRestaurantDashboard path="/home/:restaurantId" component={RestaurantDashboard} />
      <Route path="/home" component={BizList} />
    </Switch>
  );
};

export default DashboardRoutes;

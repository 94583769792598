import { makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    transform: 'translateY(50%)',
    textAlign: 'center'
  },
  loadingIcon: {
    marginTop: '-20px'
  },
  image: {
    height: '100%',
    width: '100%'
  },

  defaultImg: {
    // backgroundImage: props.defaultImage ? `url(${props.defaultImage})` : `url(${empty_image})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center'
    backgroundColor: grey[200]
  }
}));

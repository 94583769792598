import React, { useState, useRef, useImperativeHandle } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useStyles } from './style';
import UploadImage from 'components/UploadImage';
import S3Image from 'components/S3Image';
import * as _ from 'lodash';
import { Dialog } from '@lokobee/lokobee-ui';
import { IImage } from '../types';
import { useFormik } from 'formik';
import validate from './validations';

interface IFormValues {
  images: IImage[];
}

interface IProps {
  initialValues: IImage[];
  nextStep: () => void;
  getUploadId: (filename: string, imageData: string) => Promise<string | null>;
  updateRestaurant: (ids: string[]) => Promise<any>;
}

const Gallery = React.forwardRef(({ initialValues, getUploadId, updateRestaurant, nextStep }: IProps, ref) => {
  const classes = useStyles();

  const [openImageDialog, setOpenImageDialog] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [curImage, setCurImage] = useState<IImage>();

  const uploadImageRef: any = useRef(null);

  const formik = useFormik<IFormValues>({
    initialValues: {
      images: initialValues
    },
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });

  const handleFormSubmit = (formValues: IFormValues) => {
    const ids = [..._.map(formValues.images, (img) => img.id)];

    updateRestaurant(ids)
      .then((response: any) => {
        const { data } = response;
        if (data && data.updateRestaurant) {
          nextStep();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onCrop = (filename: string, imageData: string) => {
    getUploadId(filename, imageData)
      .then((uploadId) => {
        if (uploadId) {
          const ids = [...formik.values.images];

          formik.setValues({
            ...formik.values,
            images: [
              ...ids,
              {
                id: uploadId,
                url: URL.createObjectURL(imageData)
              }
            ]
          });
        }
        clearImage();
      })
      .catch((e) => {
        clearImage();
        console.log(e);
      });
  };

  const clearImage = () => {
    const { removeImage } = uploadImageRef.current || {};
    if (removeImage) removeImage();
  };

  const onImageClick = (img: any) => {
    setCurImage(img);
    setOpenImageDialog(true);
  };

  const onDelete = () => {
    if (curImage) {
      const { images: _images } = formik.values;

      formik.setValues({
        images: _.filter(_images, (img: IImage) => img.id !== curImage.id)
      });

      setOpenDeleteDialog(false);
      setOpenImageDialog(false);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      submitForm: () => {
        return formik.submitForm();
      }
    };
  });

  const { images } = formik.values;

  return (
    <>
      <Box width="100%" padding={1} display="flex" justifyContent="center">
        <Typography variant="body2" color="secondary">
          First gallery image will be used as display picture in home page.
        </Typography>
      </Box>
      <Grid container={true} className={classes.uploadImages} spacing={2}>
        {_.map(images, (img: IImage) => {
          return (
            <Grid item={true} xs={12} sm={6} md={4} key={img.id} onClick={() => onImageClick(img)}>
              <Box height="200px" width="100%">
                <S3Image src={img.url} />
              </Box>
            </Grid>
          );
        })}

        {images.length < 6 && (
          <Grid item={true} xs={12} sm={6} md={4}>
            <UploadImage ref={uploadImageRef} width="100%" height={200} onCrop={onCrop} />
          </Grid>
        )}

        <Dialog open={openImageDialog} setOpen={setOpenImageDialog} dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: false }}>
          <Dialog.DialogContent>
            <Box className={classes.dialogImageContainer}>{curImage && curImage.url && <S3Image src={curImage.url} />}</Box>
          </Dialog.DialogContent>
          <Dialog.DialogActions saveBtnText="Delete" cancelBtnText="close" onSave={() => setOpenDeleteDialog(true)} onCancel={() => setOpenImageDialog(false)} />
        </Dialog>

        <Dialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} dialogProps={{ disableBackdropClick: true }}>
          <Dialog.DialogContent>
            <Typography variant="body1">Are you sure you want to delete this image ?</Typography>
          </Dialog.DialogContent>
          <Dialog.DialogActions saveBtnText="Yes" onSave={onDelete} cancelBtnText="No" onCancel={() => setOpenDeleteDialog(false)} />
        </Dialog>
      </Grid>
    </>
  );
});

export default Gallery;

import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { TWeeklyRevenue } from '../types';

interface IProps {
  data: TWeeklyRevenue[];
}

const RevenueChart: React.FC<IProps> = ({ data }) => {
  const [weekRevenue, setWeekRevenue] = useState<number[]>([]);

  const [weekRange, setWeekRange] = useState<string[]>([]);

  useEffect(() => {
    if (data && data.length) {
      const weekRevenue: number[] = [];
      const weekRange: string[] = [];

      data.forEach((weekData: TWeeklyRevenue) => {
        weekRevenue.push(weekData.revenue);
        weekRange.push(weekData.week);
      });

      setWeekRevenue(weekRevenue);

      setWeekRange(weekRange);
    }
  }, [data]);

  var plotData = [
    {
      x: weekRange,
      y: weekRevenue,
      name: 'Week Revenue',
      marker: { color: '#023e8a' },
      type: 'bar',
      width: 0.3
    }
  ];

  return (
    <>
      <Plot
        data={plotData as any}
        layout={{
          autosize: true,
          xaxis: {
            tickfont: {
              size: 12,
              color: 'rgb(107, 107, 107)'
            }
          },
          yaxis: {
            title: 'Revenue',
            titlefont: {
              size: 16,

              color: 'rgb(107, 107, 107)'
            },
            tickfont: {
              size: 14,
              color: 'rgb(107, 107, 107)'
            },
            tickprefix: '$',
            rangemode: 'nonnegative'
          },
          legend: {
            x: 0,
            y: 1.0,
            bgcolor: 'rgba(255, 255, 255, 0)',
            bordercolor: 'rgba(255, 255, 255, 0)'
          },
          barmode: 'group',
          bargap: 0.3,
          bargroupgap: 0.1
        }}
        config={{
          displayModeBar: true,
          modeBarButtons: [['toImage']],
          displaylogo: false
        }}
        style={{ width: '100%', height: '100%' }}
      />
    </>
  );
};

export default RevenueChart;

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './style';

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footerContainer} boxShadow={2}>
      <Typography variant="body2">v{(global as any).appVersion}</Typography>
    </Box>
  );
};

export default Footer;

import React from 'react';
import { ISize, IDensityMultiplier, IPizzaToppingPrice } from '../../types';
import { Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
import { useStyles } from '../style';
import { map, filter } from 'lodash';
import Row from './Row';

interface IProps {
  sizes: ISize[];
  densities: IDensityMultiplier[];
  prices: IPizzaToppingPrice[];
  onChange: (prices: IPizzaToppingPrice[]) => void;
}

const PricesTable = ({ sizes, densities, prices, onChange }: IProps) => {
  const classes = useStyles();

  const allowedDensities = filter(densities, ({ densityAllowed }) => densityAllowed);

  const onPriceChange = (rowSizeId: string, value: string, action: 'add' | 'edit') => {
    if (action === 'add') {
      const pricesCopy = [...prices];

      pricesCopy.push({
        sizeId: rowSizeId,
        regularPrice: parseFloat(value).toFixed(2)
      });

      onChange(pricesCopy);
    } else {
      const pricesCopy = map(prices, (price) => {
        const { sizeId } = price;

        if (sizeId === rowSizeId) {
          return {
            ...price,
            regularPrice: parseFloat(value).toFixed(2)
          };
        } else {
          return price;
        }
      });

      onChange(pricesCopy);
    }
  };

  if (allowedDensities.length === 0 || prices.length === 0) {
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="center" className={classes.cell} style={{ width: '15%' }}>
              Pizza size
            </TableCell>
            <TableCell colSpan={densities.length + 1} align="center">
              Density
            </TableCell>
            <TableCell />
          </TableRow>

          <TableRow>
            <TableCell colSpan={2} align="center">
              No group selected.
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    );
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} align="center" className={classes.cell} style={{ width: '15%' }}>
            Pizza size
          </TableCell>
          <TableCell colSpan={densities.length + 1} align="center">
            Density
          </TableCell>
        </TableRow>

        <TableRow>
          {map(allowedDensities, ({ density }, index) => {
            return (
              <TableCell key={index} align="center" className={density === 'Regular' ? classes.cell : ''} style={{ width: density === 'Regular' ? '20%' : '' }}>
                {density}
              </TableCell>
            );
          })}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {map(sizes, ({ id, title }, index) => {
          const pricesBySize = filter(prices, ({ sizeId }) => sizeId === id);

          if (pricesBySize && pricesBySize.length) {
            const { regularPrice } = pricesBySize[0];
            return (
              <Row
                key={`${index}-${Date.now()}`}
                sizeTitle={title}
                allowedDensities={allowedDensities}
                regularDensityPrice={regularPrice}
                onChange={(value: string) => onPriceChange(id ? id : '', value, 'edit')}
              />
              // <TableRow key={`${index}-${Date.now()}`}>
              //   <TableCell className={classes.cell}>{title}</TableCell>

              //   {map(allowedDensities, ({ density, multiplier }) => {
              //     const { regularPrice } = pricesBySize[0];

              //     if (density === 'Regular') {
              //       return <RegularDensityInput value={regularPrice} onBlur={(value: string) => onPriceChange(id ? id : '', value, 'edit')} />;
              //     } else {
              //       return <TableCell align="center">{parseFloat(regularPrice) ? (parseFloat(regularPrice) * multiplier).toFixed(2) : '0.00'}</TableCell>;
              //     }
              //   })}
              // </TableRow>
            );
          } else {
            /* Price not available for that size
             * That means its a new size so set default price 0.00
             */

            onPriceChange(id ? id : '', '0.00', 'add');

            // return (
            //   <Row
            //     key={`${index}-${Date.now()}`}
            //     sizeTitle={title}
            //     allowedDensities={allowedDensities}
            //     regularDensityPrice="0.00"
            //     onChange={(value: string) => onPriceChange(id ? id : '', value, 'add')}
            //   />
            //   // <TableRow key={`${index}-${Date.now()}`}>
            //   //   <TableCell className={classes.cell}>{title}</TableCell>

            //   //   {map(allowedDensities, ({ density, multiplier }) => {
            //   //     if (density === 'Regular') {
            //   //       return <RegularDensityInput value={'0.00'} onBlur={(value: string) => onPriceChange(id ? id : '', value, 'add')} />;
            //   //     } else {
            //   //       return <TableCell align="center"> 0.00</TableCell>;
            //   //     }
            //   //   })}
            //   // </TableRow>
            // );
          }

          return null;
        })}
      </TableBody>
    </Table>
  );
};

export default PricesTable;

import { makeStyles } from '@material-ui/core';
import { orange, yellow } from '@material-ui/core/colors';
import whiteLines from 'assets/img/mobile-bg-white-line.svg';
import yellowLines from 'assets/img/mobile-bg-yellow-line.svg';

export const useStyle = makeStyles((theme) => {
  return {
    divider: {
      margin: theme.spacing(2, 0)
    },
    walletHead: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage: `url('${whiteLines}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center'
    },
    yellowCard: {
      background: theme.palette.primary.main,
      border: 'none',
      borderRadius: theme.spacing(1)
    },
    greenText: {
      color: theme.palette.success.main
    },
    marginRight: {
      marginRight: theme.spacing(0.5)
    },
    askToSell: {
      backgroundColor: orange[50],
      padding: theme.spacing(2)
      // fontStyle: 'italic'
    },
    coinImg: {
      maxWidth: '100px'
    },
    cardItem: {
      backgroundImage: `url('${yellowLines}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'right center'
    }
  };
});

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5)
    }
  },
  modalBtns: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
    '& button': {
      margin: theme.spacing(1)
    }
  },

  container: {
    padding: theme.spacing(1),
    width: '100%'
  },
  addressContainer: {
    alignSelf: 'center',
    margin: '16px auto',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },

  map: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(4)
  }
}));

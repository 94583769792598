import { IconButton, InputAdornment } from '@material-ui/core';
import React, { useState } from 'react';
import InfoInputField from '../InfoInputField';
import { useStyles } from '../styles';
import CreateIcon from '@material-ui/icons/Create';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const EditControlledInputField = ({ errorMsg, name, label, inputLabel, children, formik, required, ...props }: any) => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(true);

  //   useEffect(() => {
  //     if (formik && formik.value && formik.value[name]) {
  //       setIsDisabled(true);
  //     } else {
  //       setIsDisabled(false);
  //     }
  //   }, [formik, name]);
  const handleEditableField = () => {
    let isDisabledvar = isDisabled;
    setIsDisabled(!isDisabledvar);
  };
  const customHandleBlur = () => {
    setIsDisabled(true);
  };
  return (
    <InfoInputField
      className={classes.textfield}
      variant="outlined"
      disabled={isDisabled}
      formik={formik}
      name={name}
      label={label}
      customHandleBlur={customHandleBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleEditableField}>{isDisabled ? <CreateIcon /> : <LockOpenIcon />}</IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

export default EditControlledInputField;

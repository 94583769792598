import {useEffect, useCallback} from 'react';
import { useActiveRestaurant } from 'hooks/restaurant';
import { usePostRequest } from '../useRequests';

export const useGetDataBetweenDates = () => {
 return usePostRequest("/analytics/range", true);
}

export const useGetDailyWeeklyData = () => {
  const { restaurantId } = useActiveRestaurant();
  
  const {sendRequest, data, error, loading} = usePostRequest("/analytics", true);

  const makeRequest = useCallback(async ()=> {
    await sendRequest({sellerBizId: restaurantId})
  }, [restaurantId, sendRequest]);

  useEffect(() => {
    if (restaurantId) {
      makeRequest()
    }
  }, [makeRequest, restaurantId]);
  
  return {data, error, loading, sendRequest}
}
export type TFrequencyList = { value: string; freq: number }

export type TMonthlyRevenue = {month: string, revenue: number, percentage?: number}

export type TWeeklyRevenue = {week: string, revenue: number}

export type IFormValues = {
  startDate: string;
  endDate: string;
};

export const initialValues: IFormValues = {
  startDate: '',
  endDate: ''
};

export type TDailyData = {
  subtotal: number | string;
  totalOrders: number | string;
  averageOrders: number | string;
  tax: number | string;
  tipPercentage: number | string;
};

export const initialDailyData: TDailyData = {
  subtotal: '0.00',
  totalOrders: '0',
  averageOrders: '0.00',
  tax: '0.00',
  tipPercentage: '0'
};

export type TRangeData = {
  totalOrders: number | string;
  totalRevenue: number | string;
  averageOrders: number | string;
  totalCustomers: number | string;
  tipPercentage: number | string;
  dishFrequency: TFrequencyList[];
  pairedDishFrequency: TFrequencyList[];
};

export const initialRangeData: TRangeData = {
  totalOrders: '0',
  totalRevenue: '0.00',
  averageOrders: '0.00',
  totalCustomers: '0',
  tipPercentage: '0',
  dishFrequency: [],
  pairedDishFrequency: [],
};

import { convertPrice, convertPriceTo } from 'util/number';
import { filter } from 'lodash';
import Big from 'big.js';

export const calculatePizzaDishPrice = (pizzaDish: any) => {
  let finalPrice = Big(0.0);

  let strikedPrice = Big(0);

  let discountPercent = null;

  const { pizzaBasePrice, pizzaToppings, pizzaCheeses, pizzaSauces, pizzaCrust, isDiscounted } = pizzaDish;

  const {
    price: { intValue, shift },
    discountAmount
  } = pizzaBasePrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

  finalPrice = finalPrice.plus(Big(convertPrice(intValue, shift)));

  if (pizzaCrust && pizzaCrust.price) {
    const {
      price: { intValue: crustPriceVal, shift: crustPriceShift }
    } = pizzaCrust || { price: { intValue: 0, shift: 0 } };

    finalPrice = finalPrice.plus(Big(convertPrice(crustPriceVal, crustPriceShift)));
  }

  const leftToppings: any = {
    exclusions: [],
    additions: []
  };
  const leftSauces: any = {
    exclusions: [],
    additions: []
  };
  const leftCheeses: any = {
    exclusions: [],
    additions: []
  };

  const rightToppings: any = {
    exclusions: [],
    additions: []
  };
  const rightSauces: any = {
    exclusions: [],
    additions: []
  };
  const rightCheeses: any = {
    exclusions: [],
    additions: []
  };

  let halfAndHalf = false;

  if (pizzaToppings && pizzaToppings.length) {
    pizzaToppings.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
      const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

      let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

      const excludeTitle = `No ${toppingTitle}`;

      const toppingsText = {
        title: toppingTitle,
        density: toppingDensity,
        price: toppingPriceToBeAdded
      };

      const excludeText = {
        title: excludeTitle,
        price: toppingPriceToBeAdded
      };
      const alreadyPresentRightAdd = filter(rightToppings.additions, ({ title }) => title === toppingTitle)[0];
      const alreadyPresentLeftAdd = filter(leftToppings.additions, ({ title }) => title === toppingTitle)[0];

      if (preset) {
        if (side === 'Left') {
          halfAndHalf = true;

          if (toppingDensity === 'None') {
            leftToppings.exclusions.push(excludeText);
          } else {
            if (toppingDensity !== 'Regular') {
              leftToppings.additions.push(toppingsText);
            }
          }

          if (alreadyPresentRightAdd) {
            if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
              /*
               * Topping already present in right so price for right topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Right') {
          halfAndHalf = true;

          if (toppingDensity === 'None') {
            rightToppings.exclusions.push(excludeText);
          } else {
            if (toppingDensity !== 'Regular') {
              rightToppings.additions.push(toppingsText);
            }
          }

          if (alreadyPresentLeftAdd) {
            if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
              /*
               * Topping already present in left so price for left topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Whole' && toppingDensity === 'None') {
          leftToppings.exclusions.push(excludeText);
          rightToppings.exclusions.push(excludeText);
        }
        if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
          leftToppings.additions.push(toppingsText);
          rightToppings.additions.push(toppingsText);
        }
      } else {
        if (side === 'Left') {
          halfAndHalf = true;
          leftToppings.additions.push(toppingsText);
          if (alreadyPresentRightAdd) {
            if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
              /*
               * Topping already present in right so price for right topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Right') {
          halfAndHalf = true;
          rightToppings.additions.push(toppingsText);
          if (alreadyPresentLeftAdd) {
            if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
              /*
               * Topping already present in left so price for left topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Whole') {
          leftToppings.additions.push(toppingsText);
          rightToppings.additions.push(toppingsText);
        }
      }
      finalPrice = finalPrice.plus(toppingPriceToBeAdded);
    });
  }

  if (pizzaSauces && pizzaSauces.length) {
    pizzaSauces.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
      const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

      let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

      const excludeTitle = `No ${toppingTitle}`;

      const toppingsText = {
        title: toppingTitle,
        density: toppingDensity,
        price: toppingPriceToBeAdded
      };

      const excludeText = {
        title: excludeTitle,
        price: toppingPriceToBeAdded
      };
      const alreadyPresentRightAdd = filter(rightSauces.additions, ({ title }) => title === toppingTitle)[0];
      const alreadyPresentLeftAdd = filter(leftSauces.additions, ({ title }) => title === toppingTitle)[0];

      if (preset) {
        /*
         * If preset
         * If left
         * If not none & not regular - add to leftAdd
         * theres possibility that it is not present in right - so add to rightExc
         * If present in right than remove from rightExc
         * vice versa for right
         */

        if (side === 'Left') {
          halfAndHalf = true;

          if (toppingDensity === 'None') {
            leftSauces.exclusions.push(excludeText);
          } else {
            if (toppingDensity !== 'Regular') {
              leftSauces.additions.push(toppingsText);
            }
          }
          if (alreadyPresentRightAdd) {
            if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
              /*
               * Topping already present in right so price for right topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }

        if (side === 'Right') {
          halfAndHalf = true;

          if (toppingDensity === 'None') {
            rightSauces.exclusions.push(excludeText);
          } else {
            if (toppingDensity !== 'Regular') {
              rightSauces.additions.push(toppingsText);
            }
          }

          if (alreadyPresentLeftAdd) {
            if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
              /*
               * Topping already present in left so price for left topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Whole' && toppingDensity === 'None') {
          leftSauces.exclusions.push(excludeText);
          rightSauces.exclusions.push(excludeText);
        }
        if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
          rightSauces.additions.push(toppingsText);
          leftSauces.additions.push(toppingsText);
        }
      } else {
        if (side === 'Left') {
          halfAndHalf = true;
          leftSauces.additions.push(toppingsText);
          if (alreadyPresentRightAdd) {
            if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
              /*
               * Topping already present in right so price for right topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Right') {
          halfAndHalf = true;
          rightSauces.additions.push(toppingsText);
          if (alreadyPresentLeftAdd) {
            if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
              /*
               * Topping already present in left so price for left topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Whole') {
          leftSauces.additions.push(toppingsText);
          rightSauces.additions.push(toppingsText);
        }
      }
      finalPrice = finalPrice.plus(toppingPriceToBeAdded);
    });
  }

  if (pizzaCheeses && pizzaCheeses.length) {
    pizzaCheeses.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
      const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

      let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

      const excludeTitle = `No ${toppingTitle}`;

      const toppingsText = {
        title: toppingTitle,
        density: toppingDensity,
        price: toppingPriceToBeAdded
      };

      const excludeText = {
        title: excludeTitle,
        price: toppingPriceToBeAdded
      };
      const alreadyPresentRightAdd = filter(rightCheeses.additions, ({ title }) => title === toppingTitle)[0];
      const alreadyPresentLeftAdd = filter(leftCheeses.additions, ({ title }) => title === toppingTitle)[0];

      if (preset) {
        if (side === 'Left') {
          halfAndHalf = true;

          if (toppingDensity === 'None') {
            leftCheeses.exclusions.push(excludeText);
          } else {
            if (toppingDensity !== 'Regular') {
              leftCheeses.additions.push(toppingsText);
            }
          }

          if (alreadyPresentRightAdd) {
            if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
              /*
               * Topping already present in right so price for right topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Right') {
          halfAndHalf = true;

          if (toppingDensity === 'None') {
            rightCheeses.exclusions.push(excludeText);
          } else {
            if (toppingDensity !== 'Regular') {
              rightCheeses.additions.push(toppingsText);
            }
          }

          if (alreadyPresentLeftAdd) {
            if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
              /*
               * Topping already present in left so price for left topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Whole' && toppingDensity === 'None') {
          leftCheeses.exclusions.push(excludeText);
          rightCheeses.exclusions.push(excludeText);
        }
        if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
          rightCheeses.additions.push(toppingsText);
          leftCheeses.additions.push(toppingsText);
        }
      } else {
        if (side === 'Left') {
          halfAndHalf = true;
          leftCheeses.additions.push(toppingsText);
          if (alreadyPresentRightAdd) {
            if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
              /*
               * Topping already present in right so price for right topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Right') {
          halfAndHalf = true;
          rightCheeses.additions.push(toppingsText);
          if (alreadyPresentLeftAdd) {
            if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
              /*
               * Topping already present in left so price for left topping is already added
               * If current side price is more than we have to add the remaining price
               * which is already not added
               */
              toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
            } else {
              toppingPriceToBeAdded = Big(0);
            }
          }
        }
        if (side === 'Whole') {
          leftCheeses.additions.push(toppingsText);
          rightCheeses.additions.push(toppingsText);
        }
      }
      finalPrice = finalPrice.plus(toppingPriceToBeAdded);
    });
  }

  if (!!discountAmount && isDiscounted) {
    strikedPrice = finalPrice;

    finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
  }

  return {
    ...pizzaDish,
    dishPrice: finalPrice.toFixed(2),
    discountPercent,
    strikedPrice: !!discountAmount && isDiscounted ? strikedPrice.toFixed(2) : null,
    toppings: { leftToppings, rightToppings },
    sauces: { leftSauces, rightSauces },
    cheeses: { leftCheeses, rightCheeses },
    halfAndHalf,
    dishOriginalPrice: pizzaBasePrice
  };
};

export const calculateServiceCharges = (orderData: any, serviceCharges: any) => {
  const { intValue: subTotalI, shift: subTotalS } = orderData.subTotal || { intValue: 0, shift: -2 };

  const { intValue: totalI, shift: totalS } = orderData.total || { intValue: 0, shift: -2 };

  const { intValue: couponDiscountI, shift: couponDiscountS } = orderData.couponDiscount || { intValue: 0, shift: -2 };

  const { intValue: taxI, shift: taxS } = orderData.tax || { intValue: 0, shift: -2 };

  const { intValue: bizChargeI, shift: bizChargeS } = (serviceCharges && serviceCharges.bizCharge) || { intValue: 0, shift: -2 };

  const { intValue: stripeFeeI, shift: stripeFeeS } = (serviceCharges && serviceCharges.stripeFee) || { intValue: 0, shift: -2 };

  const { intValue: chargebackProtectionChargeI, shift: chargebackProtectionChargeS } = (serviceCharges && serviceCharges.chargebackProtectionCharge) || { intValue: 0, shift: -2 };

  const { intValue: tipI, shift: tipS } = (serviceCharges && serviceCharges.tip) || { intValue: 0, shift: -2 };

  const { intValue: deliveryFeeI, shift: deliveryFeeS } = (serviceCharges && serviceCharges.deliveryFee) || { intValue: 0, shift: -2 };

  const { intValue: couponCostI, shift: couponCostS } = (serviceCharges && serviceCharges.couponCost) || { intValue: 0, shift: -2 };

  const subtotal = convertPrice(subTotalI, subTotalS);
  let earnings = Big(subtotal);

  const tax = convertPrice(taxI, taxS);
  earnings = earnings.add(tax);

  const couponDiscount = convertPrice(couponDiscountI, couponDiscountS);
  earnings = earnings.minus(Big(couponDiscount));
  let couponCost = '';
  if (orderData.couponDiscount) {
    if (serviceCharges && serviceCharges.couponCost) {
      couponCost = convertPrice(couponCostI, couponCostS);
      earnings = earnings.minus(Big(couponCost));
    }
  }

  const bizCharge = convertPrice(bizChargeI, bizChargeS);
  earnings = earnings.minus(Big(bizCharge));

  let stripeFee = '0.00';

  if (serviceCharges && serviceCharges.stripeFee) {
    stripeFee = convertPrice(stripeFeeI, stripeFeeS);
    earnings = earnings.minus(Big(stripeFee));
  } else {
    if (orderData.paymentType !== 'ONSITE') {
      const stripeFee = Big(convertPrice(totalI, totalS))
        .mul(0.029)
        .add(0.3)
        .round()
        .toString();

      earnings = earnings.minus(Big(stripeFee));
    }
  }

  const chargebackProtectionCharge = convertPrice(chargebackProtectionChargeI, chargebackProtectionChargeS);
  earnings = earnings.minus(Big(chargebackProtectionCharge));
  stripeFee = Big(stripeFee)
    .add(Big(chargebackProtectionCharge))
    .toFixed(2);

  let tip = '';
  let freeDelivery = '';
  if (orderData.type === 'DELIVERY') {
    // In case of doordash delivery tip goes to driver
    // In self delivery it goes to restaurant
    if (orderData.deliveryProvider !== 'DOORDASH_CLASSIC') {
      tip = convertPrice(tipI, tipS);
      earnings = earnings.plus(Big(tip));
    } else {
      // doordash delivery

      const { deliveryRequest } = orderData;
      if (deliveryRequest && deliveryRequest.doordashClassicPayload && deliveryRequest.doordashClassicPayload.fee) {
        const deliveryFeeChargedToCustomer = convertPrice(deliveryFeeI, deliveryFeeS);
        const deliveryFeePaidToDD = convertPriceTo(deliveryRequest.doordashClassicPayload.fee, 'DOLLAR');

        const diffrence = Big(deliveryFeePaidToDD).minus(Big(deliveryFeeChargedToCustomer));

        if (diffrence.gt(Big(0))) {
          freeDelivery = diffrence.toFixed(2);
          earnings = earnings.minus(diffrence);
        }
      }
    }
  } else {
    if (orderData.paymentType === 'ONSITE') {
      const { intValue: tipI, shift: tipS } = orderData.tip || { intValue: 0, shift: -2 };
      tip = convertPrice(tipI, tipS);
      earnings = earnings.plus(Big(tip));
    } else {
      tip = convertPrice(tipI, tipS);
      earnings = earnings.plus(Big(tip));
    }
  }

  return {
    subtotal,
    couponDiscount,
    tax,
    bizCharge,
    stripeFee,
    chargebackProtectionCharge,
    earnings: earnings.toFixed(2),
    tip,
    freeDelivery,
    couponCost
  };
};

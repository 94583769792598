import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Typography } from '@material-ui/core';
import { useStyle } from '../style';
import Moment from 'moment';
import { useGetTextHistory } from 'graphql/hooks/textMarketing.hooks';
import { ITextBroadcastHistory } from '../types';

interface IProps {
  restaurantId: string | null;
}

const TextHistory: React.FC<IProps> = ({ restaurantId }) => {
  const classes = useStyle();

  const [marketingHistory, setMarketingHistory] = React.useState<ITextBroadcastHistory[]>([]);

  const { getTextHistory } = useGetTextHistory(restaurantId);

  React.useEffect(() => {
    if (getTextHistory) {
      setMarketingHistory(getTextHistory);
    }
  }, [getTextHistory]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="text-history">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell># of Text Sent</TableCell>
              {/* <TableCell>Photo of Text Schedular</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {marketingHistory.length > 0 &&
              marketingHistory.map((historyItem: ITextBroadcastHistory) => {
                const { createdAt, noOfTextSent, id } = historyItem;
                return (
                  <TableRow hover tabIndex={-1} key={id} className={classes.tableRow}>
                    <TableCell>{Moment(createdAt).format('MMM/DD/YYYY')}</TableCell>
                    <TableCell>{noOfTextSent}</TableCell>
                  </TableRow>
                );
              })}
            {marketingHistory.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Typography variant="body1">No Texts are sent</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TextHistory;

import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './style';
import empty_image from 'assets/img/empty_image.png';

interface IProps {
  src: string | null;
  className?: string;
  getImageUrl?: (signedUrl: string) => void;
  defaultImage?: string;
}

const S3Image: React.FC<IProps> = ({ src, className, getImageUrl, defaultImage }) => {
  const classes = useStyles();

  const [hidden, setHidden] = useState(false);

  const [_src, setSrc] = useState(src);

  const onError = () => {
    setHidden(true);
    setSrc(null);
    setTimeout(() => {
      setSrc(src);
    }, 5000);
  };

  const onLoad = () => {
    setHidden(false);
  };

  const renderDefaultImage = () => {
    return (
      <Box width="100%" height="100%" margin={0} padding={0} display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.defaultImg}>
        <img src={defaultImage ? defaultImage : empty_image} width="50%" alt="" />
      </Box>
    );
  };

  return (
    <>
      {!_src ? (
        renderDefaultImage()
      ) : (
        <>
          {hidden && renderDefaultImage()}
          {_src !== null && <img src={_src} className={className ? className : classes.image} onError={onError} onLoad={onLoad} hidden={hidden} alt="S3" />}
        </>
      )}
    </>
  );
};

export default S3Image;

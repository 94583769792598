import React, { useState, useEffect, useImperativeHandle } from 'react';
import { IDensityMultiplier, PizzaToppingDensityValues, PizzaToppingDensity } from '../types';
import { Box, Button, Table, TableBody, TableHead, TableCell, TableRow, Typography, Checkbox, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './style';
import { map, filter } from 'lodash';
import { useFormik, getIn } from 'formik';
import validate from './validations';
import { CurrencyInputField } from '@lokobee/lokobee-ui';

interface IProps {
  initialDensityMultipliers: IDensityMultiplier[];
  nextStep: () => void;
  updateAPI: (sizes: IDensityMultiplier[]) => Promise<any>;
}

const DensityMultiplier = React.forwardRef(({ initialDensityMultipliers, nextStep, updateAPI }: IProps, ref) => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const formik = useFormik({
    initialValues: { densityMultipliers: initialDensityMultipliers },
    enableReinitialize: true,
    onSubmit: (formValues) => handleSubmit(formValues),
    validationSchema: validate
  });

  const handleSubmit = async (formValues: { densityMultipliers: IDensityMultiplier[] }) => {
    const { densityMultipliers: submitedValues } = formValues;

    const selectedDensities = filter(submitedValues, ({ densityAllowed }) => densityAllowed);

    if (selectedDensities && submitedValues.length) {
      const response = await updateAPI(selectedDensities);

      if (response) {
        nextStep();
      } else {
        snackbar.enqueueSnackbar('Something went wrong', {
          variant: 'error'
        });
      }
    }
  };

  useImperativeHandle(ref, () => {
    return {
      submitForm: () => formik.submitForm()
    };
  });

  const { handleBlur, handleChange, errors, touched } = formik;

  const { densityMultipliers } = formik.values;

  return (
    <Box display="flex" justifyContent="center" padding={2}>
      <Box>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Density</TableCell>
              <TableCell>Multiplier</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(densityMultipliers, (densityMultiplier, index) => {
              const { density, densityAllowed, multiplier } = densityMultiplier;

              return (
                <TableRow key={index}>
                  {density === 'Regular' ? (
                    <TableCell />
                  ) : (
                    <TableCell>
                      <Checkbox
                        checked={densityAllowed}
                        onChange={(e: any, checked) => {
                          const densityMultipliersCopy = [...densityMultipliers];
                          densityMultipliersCopy[index].densityAllowed = checked;
                          formik.setValues({
                            densityMultipliers: densityMultipliersCopy
                          });
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell>{density}</TableCell>
                  {density === 'Regular' ? (
                    <TableCell>1</TableCell>
                  ) : (
                    <TableCell>
                      {densityAllowed ? (
                        <CurrencyInputField
                          className={classes.textField}
                          name={`densityMultipliers[${index}].multiplier`}
                          variant="outlined"
                          type="number"
                          size="small"
                          value={multiplier.toString()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: <div></div>
                          }}
                          touched={!!getIn(touched, `densityMultipliers[${index}].multiplier`)}
                          error={!!getIn(touched, `densityMultipliers[${index}].multiplier`) && !!getIn(errors, `densityMultipliers[${index}].multiplier`)}
                        />
                      ) : (
                        'NA'
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
});

export default DensityMultiplier;

import { gql } from 'apollo-boost';
import { Coupon } from 'graphql/fragments/coupon.fragments';

export const getCouponsByRestaurantQuery = gql`
  query getCouponsByRestaurant($input: GetCouponsByRestaurantInput!) {
    getCouponsByRestaurant(input: $input) {
      ...Coupon
    }
  }
  ${Coupon}
`;

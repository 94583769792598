import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Login from 'pages/Login';
import Register from 'pages/Register';
import ForgotPassword from 'pages/ForgotPassword';

export const history = createBrowserHistory();

const AuthenticationRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/register" component={Register} />
      <Route path="/" component={Login} />
    </Switch>
  );
};

export default AuthenticationRoutes;

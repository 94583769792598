import React from 'react';
import { Box, Typography, Grid, Divider, Container } from '@material-ui/core';
import S3Image from 'components/S3Image';
import { RestaurantEmptyScreen } from 'components/EmptyScreen';
import AddIcon from '@material-ui/icons/Add';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../style';
import { useRestaurantList } from 'hooks/restaurant';
import restaurantEmptyIcon from 'assets/img/restaurant-empty-icon.png';
import DeleteGroceryStore from './DeleteGroceryStore';
import EditIcon from '@material-ui/icons/Edit';
import { useCreateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { useCreateGroceryStoreMutation, useGroceryStoreList } from 'graphql/hooks/grocery.hooks';
import { Biztype } from 'components/EmptyScreen/RestaurantEmptyScreen';

const GroceryStoreList: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const { stores } = useGroceryStoreList();

  const { createGroceryStore } = useCreateGroceryStoreMutation();

  const redirectToNewGroceryStorePage = async () => {
    try {
      const response = await createGroceryStore({
        variables: {
          input: {}
        }
      });

      console.log(response);

      if (response && response.data) {
        const { createGroceryStore } = response.data;
        const id = createGroceryStore.id;

        const REDIRECT_URL = `/home/grocery/${id}/info`;

        history.push(REDIRECT_URL);
      }
    } catch (e) {
      console.log('[ERROR] Create grocery store failed');
    }
  };

  const redirectToGroceryStoreDashboard = (id: string) => {
    history.push(`/home/grocery/${id}`);
  };

  let count = 1;

  return (
    <Box marginTop={2} marginLeft={-2}>
      {stores && stores.length === 0 ? (
        <RestaurantEmptyScreen onAdd={redirectToNewGroceryStorePage} bizType={Biztype.GROCERY} />
      ) : (
        <Grid container={true} alignItems="stretch">
          {map(stores, ({ id, name, logo }, index) => {
            const storeName = name || 'Unnamed grocery store ' + count++;
            const { url } = logo || {};

            return (
              <Grid item={true} xs={12} sm={6} md={4} key={id} className={classes.addItemContainer}>
                <Box boxShadow={1} borderRadius={5}>
                  <Box width="100%" height="250px" margin={0} padding={0} className={classes.dataBox} onClick={() => redirectToGroceryStoreDashboard(id)}>
                    <S3Image src={url} defaultImage={restaurantEmptyIcon} />
                  </Box>

                  <Box padding={2} bgcolor="white" display="flex">
                    <Typography variant="body1">{storeName}</Typography>
                    <Box flex="1" textAlign="right">
                      <EditIcon className={classes.editIcon} onClick={() => redirectToGroceryStoreDashboard(id)} />
                      <DeleteGroceryStore id={id} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
          <Grid item={true} xs={12} sm={6} md={4} className={classes.addItemContainer} onClick={redirectToNewGroceryStorePage}>
            <Box className={classes.addItemBox} display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
              <AddIcon className={classes.addIcon} />
              <Typography variant="body1">Add Grocery Store</Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default GroceryStoreList;

import React, { useState, useEffect, useImperativeHandle } from 'react';
import { ISize, IDensityMultiplier, IPizzaToppingGroup, IPizzaToppingPrice, IPizzaToppingItem } from '../types';
import { Box, Grid, Typography, Checkbox, Button } from '@material-ui/core';
import { map, filter } from 'lodash';
import { useStyles } from './style';
import { useFormik } from 'formik';
import GroupTable from './GroupTable';
import PricesTable from './PricesTable';
import ItemsTable from './ItemsTable';
import validate from './validations';

interface IProps {
  initialGroups: IPizzaToppingGroup[];
  sizes: ISize[];
  densityMultipliers: IDensityMultiplier[];
  nextStep: () => void;
  updateAPI: (groups: IPizzaToppingGroup[]) => Promise<any>;
  defaultPrices: IPizzaToppingPrice[];
  defaultDensities: IDensityMultiplier[];
  allowDelete: boolean;
}

const PizzaGroup = React.forwardRef(({ initialGroups, sizes, densityMultipliers, nextStep, updateAPI, defaultDensities, defaultPrices, allowDelete }: IProps, ref) => {
  const classes = useStyles();

  const [selectedToppingGroup, setSelectedToppingGroup] = useState(0);

  const formik = useFormik({
    initialValues: {
      groups: initialGroups
    },
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: async (formValues) => {
      await updateAPI(formValues.groups);
    }
  });

  useImperativeHandle(ref, () => {
    return {
      submitForm: () => formik.submitForm()
    };
  });

  const { groups } = formik.values;

  const setGroups = (updatedGroups: IPizzaToppingGroup[]) => {
    formik.setValues({
      groups: updatedGroups
    });
  };

  const { densities = [], groupPrices = [], items = [], title } = groups[selectedToppingGroup] || { densities: [], prices: [], items: [] };

  const updateDensities = (updatedDensities: IDensityMultiplier[]) => {
    const groupsCopy = [...groups];

    groupsCopy[selectedToppingGroup].densities = [...updatedDensities];

    groupsCopy[selectedToppingGroup].densities = [...updatedDensities];

    setGroups(groupsCopy);
  };

  const updatePrices = (updatedPrices: IPizzaToppingPrice[]) => {
    const groupsCopy = [...groups];
    groupsCopy[selectedToppingGroup].groupPrices = [...updatedPrices];
    setGroups(groupsCopy);
  };

  const updateItems = (updatedItems: IPizzaToppingItem[]) => {
    const groupsCopy = [...groups];
    groupsCopy[selectedToppingGroup].items = [...updatedItems];
    setGroups(groupsCopy);
  };

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="center" alignItems="flex-start">
        <Box className={classes.densityContainer}>
          <Box padding={2} className={classes.densityContainerHeader}>
            <Typography variant="body2">Density supported</Typography>
          </Box>
          {densities.length > 0 ? (
            <Grid container={true}>
              {map(densities, ({ density, densityAllowed }, index) => {
                return (
                  <Grid key={index} item={true} xs={4}>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        color="primary"
                        checked={densityAllowed}
                        onChange={(e: any, checked: boolean) => {
                          if (density !== 'Regular') {
                            const densitiesCopy = [...densities];
                            densitiesCopy[index].densityAllowed = checked;
                            updateDensities(densitiesCopy);
                          }
                        }}
                      />
                      <Typography variant="body2">{density}</Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box padding={2}>
              <Typography variant="body2">No group selected.</Typography>
            </Box>
          )}
        </Box>
        <Box paddingX={2}>
          <GroupTable
            allowDelete={allowDelete}
            groups={groups}
            selectedIndex={selectedToppingGroup}
            setSelected={(i: number) => setSelectedToppingGroup(i)}
            setGroups={setGroups}
            defaultDensities={defaultDensities}
            defaultPrices={defaultPrices}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="flex-start">
        <Box padding={2} width="70%">
          <PricesTable sizes={sizes} densities={densities} prices={groupPrices} onChange={updatePrices} />
        </Box>
        <Box padding={2} width="30%">
          <ItemsTable allowDelete={allowDelete} groupTitle={title} items={items} onChange={updateItems} />
        </Box>
      </Box>
    </Box>
  );
});

export default PizzaGroup;

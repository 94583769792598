import { gql } from 'apollo-boost';

export default gql`
  query getOrderServiceCharges($input: GetOrderServiceChargesInput!) {
    getOrderServiceCharges(input: $input) {
      id
      bizCharge {
        intValue
        shift
      }
      tip {
        intValue
        shift
      }
      tax {
        intValue
        shift
      }
      subtotal {
        intValue
        shift
      }
      deliveryFee {
        intValue
        shift
      }
      doordashCharge {
        intValue
        shift
      }
      stripeFee {
        intValue
        shift
      }
      chargebackProtectionCharge {
        intValue
        shift
      }
      couponCost {
        intValue
        shift
      }
    }
  }
`;

import { Box, Container } from '@material-ui/core';
import { useGetGroceryStoreById } from 'graphql/hooks/grocery.hooks';
import useGroceryStore from 'hooks/grocery/useGroceryStore';
import React from 'react';
import GroceryStoreInfoDisplay from './GroceryStoreInfoDisplay';
import StoreItemsTable from './StoreItemsTable';

const GroceryStoreHome = () => {
  return (
    <Container disableGutters maxWidth="lg">
      <GroceryStoreInfoDisplay />
      <StoreItemsTable />
    </Container>
  );
};

export default GroceryStoreHome;

import { makeStyles } from '@material-ui/core';
import { lightGreen } from '@material-ui/core/colors';

export const useStyle = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: '100%'
    },
    container: {
      // maxHeight: 580
    },
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    customClassTimePicker: {
      border: 'none'
    },
    inputStyle: {
      width: '100%'
    },
    divider: {
      margin: theme.spacing(2, 0)
    },
    checkIconStyle: {
      fontSize: '50px',
      color: theme.palette.success.main,
      marginRight: theme.spacing(2)
    },
    checkIconStyleRed: {
      fontSize: '50px',
      color: theme.palette.error.main,
      marginRight: theme.spacing(2)
    },
    customInput: {
      padding: theme.spacing(1.5),
      border: 'solid 1px ' + theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
      '& input': {
        border: 'none',
        outline: 'none'
      },
      '&:hover': {
        border: 'solid 1px black'
      },
      '& .MuiSelect-select': {
        backgroundColor: 'white'
      },
      '&:after': { border: 'none', outline: 'none' },
      '&:before': { content: 'none', outline: 'none' },
      '& .PhoneInputCountryIcon': {
        border: 'none',
        outline: 'none',
        boxShadow: 'none'
      }
    },
    table: {
      border: 'solid 1px ' + theme.palette.grey[200],
      borderRadius: theme.spacing(0.5)
    },
    customInputError: {
      border: 'solid 1px ' + theme.palette.error.main,
      '&:hover': {
        border: 'solid 1px ' + theme.palette.error.main
      },
      '& input': {
        border: 'none'
      }
    },
    greenText: {
      color: theme.palette.success.main
    },
    greenBg: {
      backgroundColor: lightGreen[100]
      // '#DFFFE1'
    },
    inputLabel: {
      fontSize: theme.spacing(1.5),
      marginBottom: theme.spacing(0.5)
    },
    successBtn: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    inputContainer: {
      alignItems: 'center',
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 0)
      }
    },
    loadMoreButton: {
      margin: '0 auto',
      marginTop: theme.spacing(2)
    }
  };
});

import { ISize, ICrust, IDensityMultiplier, PizzaToppingDensityValues, IPizzaToppingGroup, IPizzaToppingPrice } from './types';
import { map, filter, groupBy } from 'lodash';
import { convertPrice } from 'util/number';

export const getDefaultSizes = () => {
  return [
    {
      title: 'Personal ( Mini )',
      description: 'For one person',
      enableHalfHalf: false
    },
    {
      title: 'Small',
      description: 'Cut into 4 slices and serves 2-3 people',
      enableHalfHalf: false
    },
    {
      title: 'Medium',
      description: 'Cut into 6 slices and serves 3-4 people',
      enableHalfHalf: true
    },
    {
      title: 'Large',
      description: 'Cut into 8 slices and serves 3-5 people',
      enableHalfHalf: true
    },
    {
      title: 'XL',
      description: 'Cut into 12 slices and serves 5-6 people',
      enableHalfHalf: true
    }
  ];
};

export const parseSizes = (dataSizes: any) => {
  return map(dataSizes, ({ id, title, description, enableHalfHalf }) => {
    return { id, title, description, enableHalfHalf };
  });
};

export const getDefaultCrusts = (pizzaSizes: ISize[]) => {
  const defaultCrustTitles = ['Regular', 'Thin', 'Hand Tossed', 'Stuffed', 'Heart', 'Gluten Free'];

  const defaultCrusts: ICrust[] = [];

  defaultCrustTitles.forEach((title) => {
    const prices: any = [];

    pizzaSizes.forEach(({ id, title: sizeTitle }: ISize) => {
      if (title === 'Heart' || title === 'Gluten Free') {
        if (sizeTitle.trim() === 'Medium') {
          prices.push({
            sizeId: id,
            price: '0.00'
          });
        }
      } else {
        prices.push({
          sizeId: id,
          price: '0.00'
        });
      }
    });

    defaultCrusts.push({
      title,
      description: '',
      prices
    });
  });

  return defaultCrusts;
};

export const parseCrusts = (crustData: any) => {
  return map(crustData, (crust) => {
    const { id, description, title, prices } = crust;

    return {
      id,
      description,
      title,
      prices: map(prices, ({ size, price }) => {
        const { id: sizeId } = size || { id: '' };
        const { intValue, shift } = price || { intValue: 0, shift: -2 };

        return {
          sizeId,
          price: convertPrice(intValue, shift)
        };
      })
    };
  });
};

export const getDefaultDensityMultipliers = () => {
  const defaultData: IDensityMultiplier[] = [
    {
      density: 'Regular',
      multiplier: 1,
      densityAllowed: true
    },
    {
      density: 'None',
      multiplier: 0.0,
      densityAllowed: true
    },
    {
      density: 'Light',
      multiplier: 0.5,
      densityAllowed: true
    },
    {
      density: 'Extra',
      multiplier: 1.5,
      densityAllowed: true
    },
    {
      density: 'Double',
      multiplier: 2.0,
      densityAllowed: true
    },
    {
      density: 'Triple',
      multiplier: 3.0,
      densityAllowed: false
    }
  ];

  return defaultData;
};

export const parseDensityMultipliers = (densityMultiplierData: any) => {
  return map(PizzaToppingDensityValues, (densityValue) => {
    const row: any = filter(densityMultiplierData, ({ density }: any) => density === densityValue);

    if (row && row.length) {
      return {
        density: row[0].density,
        multiplier: row[0].multiplier,
        densityAllowed: true
      };
    } else {
      return {
        density: densityValue,
        multiplier: 1,
        densityAllowed: false
      };
    }
  });
};

export const getDefaulGroups = (defaultData: Array<{ title: string; items: string[] }>, pizzaSizes: ISize[], pizzaDensityMultipliers: IDensityMultiplier[]) => {
  return defaultData.map(({ title, items }) => {
    const groupPrices: IPizzaToppingPrice[] = map(pizzaSizes, (size) => {
      const { id: sizeId = '' } = size;

      return {
        sizeId,
        regularPrice: '0.00'
      };
    });

    const groupItems = map(items, (item) => {
      return {
        title: item,
        description: '',
        prices: groupPrices
      };
    });

    const group: IPizzaToppingGroup = {
      description: '',
      enableHalfHalf: true,
      title,
      groupPrices,
      densities: pizzaDensityMultipliers.map((multiplier) => ({ ...multiplier })),
      items: groupItems
    };

    return group;
  });
};

export const parseGroups = (pizzaGroups: any, pizzaSizes: ISize[], pizzaDensityMultipliers: IDensityMultiplier[]) => {
  return map(pizzaGroups, (group) => {
    const { title = '', description = '', enableHalfHalf = true, id = '', items = [] } = group;

    const densities: IDensityMultiplier[] = [];

    let groupPrices: IPizzaToppingPrice[] = [];

    if (items && items.length) {
      const pizzaPrices = items[0].prices || [];

      groupPrices = map(pizzaPrices, (price) => {
        const sizeId = price.size ? price.size.id || '' : '';

        const densityPrices = price.densityPrices || [{ regular: '0.00' }];

        const regularDensity = filter(densityPrices, ({ density }) => density === 'Regular');

        const regularPrice = regularDensity.length ? (regularDensity[0].price ? convertPrice(regularDensity[0].price.intValue || 0, regularDensity[0].price.shift || -2) : '0.00') : '0.00';

        return {
          sizeId,
          regularPrice
        };
      });

      /*
       * If new size added regular price for that size will not be available
       * So need to add that check and add 0.00 in regular price
       */
      const groupPricesGroupedBySizeId = groupBy(groupPrices, 'sizeId');

      pizzaSizes.forEach((size) => {
        if (size.id) {
          if (!groupPricesGroupedBySizeId[size.id]) {
            groupPrices.push({
              sizeId: size.id,
              regularPrice: '0.00'
            });
          }
        }
      });

      if (pizzaPrices && pizzaPrices.length) {
        const pizzaDensities: any[] = pizzaPrices[0].densities || [];

        pizzaDensityMultipliers.forEach((multiplier) => {
          const { density } = multiplier;

          densities.push({
            ...multiplier,
            densityAllowed: pizzaDensities.indexOf(density) > -1
          });
        });
      }
    }

    return {
      id,
      title,
      description,
      enableHalfHalf,
      densities,
      groupPrices,
      items: map(items, (item) => {
        const { id = '', title = '', description = '' } = item;

        const itemPrices = item.prices || [];

        const itemPricesConverted: IPizzaToppingPrice[] = map(itemPrices, (price) => {
          const sizeId = price.size ? price.size.id || '' : '';

          const priceId = price.id || '';

          const densityPrices = price.densityPrices || [{ regular: '0.00' }];

          const regularDensity = filter(densityPrices, ({ density }) => density === 'Regular');

          const regularPrice = regularDensity.length ? (regularDensity[0].price ? convertPrice(regularDensity[0].price.intValue || 0, regularDensity[0].price.shift || -2) : '0.00') : '0.00';

          return {
            id: priceId,
            sizeId,
            regularPrice
          };
        });

        return {
          id,
          title,
          description,
          prices: itemPricesConverted
        };
      })
    };
  });
};

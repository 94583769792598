import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => {
  return {
    root: {
      width: '100%'
    },
    container: {
      maxHeight: 580
    },
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    }
  }
})
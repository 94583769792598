import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import menu_empty_screen from 'assets/img/menu_empty_screen.png';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStyles } from './style';
import CSVReader from 'react-csv-reader';

interface IProps {
  onDataUpload: (data: any[]) => void;
}

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_')
};

const GroceryEmptyScreen = ({ onDataUpload }: IProps) => {
  const classes = useStyles();

  return (
    <>
      <Box padding={2}>
        <Typography variant="h5">Grocery items</Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.emptyScreenContainer} display="flex" flexDirection="column" alignItems="center">
        <img src={menu_empty_screen} className={classes.emptyScreenImage} alt="" />
        <Box display="flex" alignItems="center" padding={1}>
          <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
            <CSVReader cssInputClass={classes.csvReader} cssClass="react-csv-input" onFileLoaded={onDataUpload} parserOptions={papaparseOptions} />
            <Typography variant="h6">Update items sheet ( .csv )</Typography>
            <Typography variant="body2" align="center">
              Make sure the column names are upc_code, price, description,tax_mode,reward,is_discounted
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GroceryEmptyScreen;

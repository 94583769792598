import React, { useImperativeHandle } from 'react';
import { IGroceryStoreProfile } from './types';
import { useFormik, FormikProps, getIn } from 'formik';
import { validationSchema } from './validations';
import { useStyles } from './style';
import * as _ from 'lodash';
import { Grid, Typography, FormControl, Select, MenuItem, FormHelperText, InputLabel, Box, Button, TextField } from '@material-ui/core';
import UploadImage from 'components/UploadImage';
import InfoInputField from './InfoInputField';
import PhoneInput from 'react-phone-number-input';
import AddressInputField from 'components/AddressInputField';
import CloseIcon from '@material-ui/icons/Close';
import 'react-phone-number-input/style.css';
import timeZones from './timeZone';
import { useUpdateGroceryStoreMutation } from 'graphql/hooks/grocery.hooks';
import { IAddress, ITaxModeItem } from '../types';
import TaxMode from './TaxMode';

interface ITimeZone {
  name: string;
  link: string;
}

interface IProps {
  initialValues: IGroceryStoreProfile;
  nextStep: () => void;
  getUploadId: (filename: string, imageData: string) => Promise<string | null>;
}

const GroceryStoreProfile = React.forwardRef(({ initialValues, nextStep, getUploadId }: IProps, ref) => {
  const classes = useStyles();

  const { updateGroceryStore } = useUpdateGroceryStoreMutation('PROFILE');
  const formik: FormikProps<IGroceryStoreProfile> = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });
  const handleFormSubmit = async (formValues: IGroceryStoreProfile) => {
    try {
      let uploadId;

      const { logoImageData } = formValues;
      if (logoImageData) {
        uploadId = await getUploadId(logoImageData.filename, logoImageData.imageFiledata);
      } else {
        uploadId = formValues.logo ? formValues.logo.id : '';
      }

      if (!uploadId || uploadId === '') {
        throw Error('Image Upload failed');
      }

      const response = await updateGroceryStore({
        variables: {
          input: {
            name: formValues.name,
            phone: formValues.phone,
            address: { ...formValues.address },
            logoId: uploadId,
            taxModes: formValues.taxModes.map((taxModeItem: ITaxModeItem) => {
              return {
                title: taxModeItem.title,
                tax: taxModeItem.tax
              };
            }),
            description: formValues.description,
            timezone: formValues.timeZone,
            email: formValues.email,
            reportEmails: formValues.reportEmails.replace(/\s/g, '').split(','),
            orderEmails: formValues.orderEmails.replace(/\s/g, '').split(','),
            googleReviewLink: formValues.googleReviewLink
          }
        }
      });

      const { data } = response;

      if (data && data.updateGroceryStore) {
        nextStep();
      }
    } catch (e) {
      console.log(e);
    }
  };
  useImperativeHandle(ref, () => {
    return {
      submitForm: () => {
        return formik.submitForm();
      }
    };
  });

  /* 
    custom change handler for tax field to add % at end and make sure the values are between 0 to 100 
  */
  const onChange = (e: any, name: string) => {
    formik.setValues({
      ...formik.values,
      [name]: e
    });
  };

  const onAddressSelect = (selectedAddress: IAddress) => {
    const { text, country, state, city, zipcode, lat, lng, street } = selectedAddress;
    formik.setValues({
      ...formik.values,
      address: {
        text,
        country,
        state,
        city,
        zipcode,
        lat,
        lng,
        street: street
      }
    });
  };

  const onAddressChange = (addr: string) => {
    formik.setValues({
      ...formik.values,
      address: {
        text: '',
        country: '',
        state: '',
        city: '',
        zipcode: '',
        lat: 0,
        lng: 0,
        street: ''
      }
    });
  };

  const onCrop = (filename: string, imageFiledata: string) => {
    formik.setValues({
      ...formik.values,
      logoImageData: {
        filename,
        imageFiledata
      }
    });
  };

  const { logo } = formik.values;
  const addNewTaxMode = () => {
    formik.setValues({
      ...formik.values,
      taxModes: [
        ...formik.values.taxModes,
        {
          title: '',
          tax: ''
        }
      ]
    });
  };
  return (
    <React.Fragment>
      <Grid container={true} className={classes.formContainer}>
        <Grid item={true} md={4}>
          <UploadImage width={300} height={200} onCrop={onCrop} initialValue={logo ? logo.url : undefined} />
          {!formik.values.logoImageData && getIn(formik.errors, 'logoImageData') && getIn(formik.touched, 'logoImageData') && (
            <Typography variant="body2" color="error">
              Logo Image Required
            </Typography>
          )}
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <InfoInputField
            name="name"
            type="text"
            placeholder="Name *"
            inputLabel="Name"
            formik={formik}
            inputProps={{
              maxLength: 200
            }}
            required={true}
            variant="outlined"
          />

          <InfoInputField inputLabel="Phone" type="hidden" required={true}>
            <PhoneInput
              placeholder="Phone Number *"
              className={formik.errors.phone && formik.touched.phone ? `${classes.customInputError} + ${classes.customInput}` : `${classes.customInput}`}
              name="phone"
              defaultCountry="US"
              value={formik.values.phone}
              onChange={(value: string) => onChange(value, 'phone')}
              onBlur={formik.handleBlur('phone')}
              variant="outlined"
              label="Phone Number"
            />
            {formik.errors.phone && formik.touched.phone && (
              <Typography variant="caption" color="error">
                {formik.errors.phone}
              </Typography>
            )}
          </InfoInputField>

          <InfoInputField inputLabel="Address" type="hidden" required={true}>
            <AddressInputField
              name="address"
              variant="outlined"
              validationError={formik.touched.address && formik.errors.address !== '' && formik.errors.address !== undefined}
              value={formik.values.address.text}
              defaultValue={formik.initialValues.address.text}
              onChange={onAddressChange}
              onBlur={formik.handleBlur}
              onAddressSelect={onAddressSelect}
              errorMsg={formik.errors.address}
              label="Address"
            />
          </InfoInputField>
          <InfoInputField>
            <FormControl error={formik.errors.timeZone !== '' && formik.errors.timeZone !== undefined && formik.touched.timeZone} fullWidth={true}>
              <InputLabel>Timezone</InputLabel>
              <Select
                id="demo-simple-select-error"
                value={formik.values.timeZone}
                className={
                  formik.errors.timeZone !== '' && formik.errors.timeZone !== undefined && formik.touched.timeZone ? `${classes.customInputError} + ${classes.customInput}` : `${classes.customInput}`
                }
                onChange={(e) => onChange(e.target.value, 'timeZone')}
                onBlur={formik.handleBlur('timeZone')}>
                {_.map(timeZones, (zone: ITimeZone) => {
                  return (
                    <MenuItem value={zone.name} key={zone.name}>
                      {zone.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.errors.timeZone !== '' && formik.errors.timeZone !== undefined && formik.touched.timeZone && <FormHelperText>{formik.errors.timeZone}</FormHelperText>}
            </FormControl>
          </InfoInputField>
          <Box>
            {formik &&
              formik.values &&
              formik.values.taxModes &&
              formik.values.taxModes.map((taxModeItem: ITaxModeItem, index: number) => {
                return <TaxMode key={index} index={index} formik={formik} taxModeItem={taxModeItem} />;
              })}
            <Box margin={1}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  addNewTaxMode();
                }}>
                Add new Tax mode
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <InfoInputField
            id="standard-multiline-flexible"
            name="description"
            type="text"
            multiline
            rowsMax="6"
            placeholder="Description"
            inputLabel="Description"
            formik={formik}
            inputProps={{
              maxLength: 300
            }}
            required={true}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <CloseIcon
                    cursor="pointer"
                    onClick={() => {
                      formik.setValues({ ...formik.values, description: '' });
                    }}
                  />
                </React.Fragment>
              )
            }}
          />
          <InfoInputField name="email" type="email" placeholder="Contact Email *" inputLabel="Contact Email" formik={formik} required={true} variant="outlined" />
          <InfoInputField
            name="orderEmails"
            type="text"
            placeholder="Order emails (comma seperated) *"
            inputLabel="Order Emails ( comma seperated )"
            formik={formik}
            required={true}
            variant="outlined"
          />

          <InfoInputField
            name="reportEmails"
            type="text"
            placeholder="Report emails (comma seperated) *"
            inputLabel="Report Emails ( comma seperated )"
            formik={formik}
            required={true}
            variant="outlined"
          />

          <InfoInputField name="googleReviewLink" type="text" multiline rowsMax={3} placeholder="Google review link" inputLabel="Google review link" formik={formik} variant="outlined" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default GroceryStoreProfile;

import * as Yup from 'yup';

export default Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email')
    .min(2, 'Too short!')
    .max(100, 'Too long!')
    .required('Please enter valid email'),
  displayName: Yup.string()
    .min(1, 'Too short!')
    .max(100, 'Too long!')
    .required('Please enter name'),
  password: Yup.string()
    .min(1, 'Too short!')
    .max(100, 'Too long!')
    .required('Please enter password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password do not match')
    .required('Please enter password')
});

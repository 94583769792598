import React, { useEffect, useState } from 'react';
import logoImg from 'assets/img/icon-1024.png';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, Button, Hidden, Menu, MenuItem, Badge } from '@material-ui/core';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import HomeIcon from '@material-ui/icons/Home';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MicIcon from '@material-ui/icons/Mic';
import PollIcon from '@material-ui/icons/Poll';
import PromotionIcon from '@material-ui/icons/Redeem';
import { useStyles } from './style';
import Profile from './Profile';
import { useActiveRestaurant } from 'hooks/restaurant';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useActiveGroceryStore from 'hooks/grocery/useActiveGroceryStore';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
const Header = () => {
  const classes = useStyles();

  const { groceryStoreId } = useActiveGroceryStore();
  const activeRestaurant = useActiveRestaurant();
  const restaurantId = activeRestaurant.restaurantId || '';
  const { data } = useGetRestaurantById(restaurantId, 'PROFILE');

  const [isMspDisabled, setIsMspDisables] = useState<boolean>(false);
  const [restaurantName, setRestaurantName] = useState<string | null>();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const restaurantData = data.ownerGetRestaurantById;
      if (restaurantData && restaurantData.name && restaurantData.name[0] && restaurantData.name[0].text) {
        setRestaurantName(restaurantData.name[0].text);
      }
      if (!(restaurantData.name && restaurantData.address && restaurantData.phone && restaurantData.description)) {
        setIsMspDisables(true);
      } else {
        setIsMspDisables(false);
      }
    }
  }, [data, restaurantId]);
  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar disableGutters={true}>
          <img src={logoImg} className={classes.logo} alt="App logo" />
          <Box flexGrow={1} display="flex" alignItems={'center'}>
            <Typography variant="h6">
              LOKOBEE
              <span className={classes.colorSpan}>BIZ</span>
            </Typography>
            <Box marginLeft={1}>{restaurantId && restaurantName && <Typography variant="subtitle1">({restaurantName})</Typography>}</Box>
          </Box>
          {restaurantId && (
            <Box className={classes.navigationWrapper}>
              <Hidden mdDown={true}>
                <NavLink exact={true} to={`/home`} activeClassName={classes.activeLink}>
                  <Button startIcon={<HomeIcon />}>Home</Button>
                </NavLink>

                <NavLink exact={true} to={`/home/${restaurantId}`} activeClassName={classes.activeLink}>
                  <Button startIcon={<RestaurantMenuIcon />}>Menu</Button>
                </NavLink>

                <NavLink exact={true} to={`/home/${restaurantId}/analytics`} activeClassName={classes.activeLink}>
                  <Button startIcon={<PollIcon />}>Analytics</Button>
                </NavLink>
                <a>
                  {'   '}
                  <Badge
                    badgeContent={'New'}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    color="error">
                    <Button onClick={handleMenu} startIcon={<PollIcon />}>
                      Promotions
                    </Button>
                  </Badge>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={open}
                    onClose={handleClose}>
                    <MenuItem onClick={handleClose}>
                      <NavLink exact={true} to={`/home/${restaurantId}/text-marketing`} activeClassName={classes.activeLink}>
                        <Button>Text Marketing</Button>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink exact={true} to={`/home/${restaurantId}/crypto-promotions`} activeClassName={classes.activeLink}>
                        <Button>CashBack Promos</Button>
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </a>
                {/* <NavLink exact={true} to={`/home/${restaurantId}/wallet`} activeClassName={classes.activeLink}>
                  <Button startIcon={<AccountBalanceWalletIcon />}>Wallet</Button>
                </NavLink> */}

                <NavLink exact={true} to={`/home/${restaurantId}/reward`} activeClassName={classes.activeLink}>
                  <Button startIcon={<MonetizationOnIcon />}>Reward</Button>
                </NavLink>

                <NavLink exact={true} to={`/home/${restaurantId}/announcement`} activeClassName={classes.activeLink}>
                  <Button startIcon={<MicIcon />}>Announcement</Button>
                </NavLink>

                {restaurantId &&
                  (isMspDisabled ? (
                    <Button disabled={isMspDisabled} startIcon={<ReceiptIcon />}>
                      MSP
                    </Button>
                  ) : (
                    <NavLink exact={true} to={`/home/${restaurantId}/msp`} activeClassName={classes.activeLink}>
                      <Button startIcon={<ReceiptIcon />}>MSP</Button>
                    </NavLink>
                  ))}
              </Hidden>
            </Box>
          )}

          {groceryStoreId && (
            <Box className={classes.navigationWrapper}>
              <Hidden mdDown={true}>
                <NavLink exact={true} to={`/home`} activeClassName={classes.activeLink}>
                  <Button startIcon={<HomeIcon />}>Home</Button>
                </NavLink>

                <NavLink exact={true} to={`/home/grocery/${groceryStoreId}`} activeClassName={classes.activeLink}>
                  <Button startIcon={<RestaurantMenuIcon />}>Grocery items</Button>
                </NavLink>

                <NavLink exact={true} to={`/home/grocery/${groceryStoreId}/promotions`} activeClassName={classes.activeLink}>
                  <Button startIcon={<PromotionIcon />}>Promotions</Button>
                </NavLink>

                <NavLink exact={true} to={`/home/grocery/${groceryStoreId}/reward`} activeClassName={classes.activeLink}>
                  <Button startIcon={<MonetizationOnIcon />}>Reward</Button>
                </NavLink>

                <NavLink exact={true} to={`/home/grocery/${groceryStoreId}/announcement`} activeClassName={classes.activeLink}>
                  <Button startIcon={<MicIcon />}>Announcement</Button>
                </NavLink>
              </Hidden>
            </Box>
          )}
          <Profile isMspDisabled={isMspDisabled} />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;

import React from 'react';
import { useStore } from 'store';
import { useGetRestaurantFeedback } from 'graphql/hooks/restaurant.hooks';
import { RestaurantRating } from '@lokobee/lokobee-ui';
import { Grid, Box, Button, CircularProgress } from '@material-ui/core';
import moment from 'moment';

const RestaurantRatings = () => {
  const {
    state: { restaurant }
  } = useStore();

  const { data, fetchMoreData, hasMore, fetching } = useGetRestaurantFeedback(restaurant);

  if (data) {
    return (
      <>
        <Grid container={true}>
          {data.getBizReviews.edges.map((item: any, index: number) => {
            const { node } = item;
            const { email, displayName, phoneNumber } = node.buyer || { email: '', displayName: '', phoneNumber: '' };
            const { rateText, rateScore, createdAt, order } = node;

            const { orderPhoneNumber } = order || { orderPhoneNumber: '' };

            const starName = `${index}-${Date.now()}`;
            return (
              <Grid key={index} item={true} xs={12} sm={6} md={4}>
                <RestaurantRating
                  restaurantName={displayName}
                  restaurantMobileNumber={phoneNumber ? phoneNumber : orderPhoneNumber}
                  date={moment(createdAt).format('L')}
                  mailId={email}
                  message={rateText}
                  starValue={rateScore}
                  starName={starName}
                />
              </Grid>
            );
          })}
        </Grid>

        {hasMore && (
          <Box paddingY={1} marginBottom={1} display="flex" justifyContent="center" alignItems="center">
            <Button onClick={fetchMoreData} variant="contained" color="secondary" disableElevation={true} disabled={fetching}>
              {fetching ? 'Loading' : 'Load more'}
            </Button>
            {fetching && (
              <Box paddingX={1}>
                <CircularProgress size={20} color="secondary" />
              </Box>
            )}
          </Box>
        )}
      </>
    );
  } else {
    return null;
  }
};
export default RestaurantRatings;
